<template>
  <div id="apartment" class="layer home">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path class="cls-8" d="M51.5,613.6c-1.1,0-2.2.2-3.2.7-1.3-2.5-3.8-4.2-6.8-4.2s-4.8,1.2-6.2,3.1c-1-.5-2.2-.8-3.4-.8-3.5,0-6.5,2.4-7.4,5.7-.8-.3-1.8-.5-2.7-.5-4.2,0-7.7,3.4-7.7,7.7s.2,1.9.5,2.8c-2.2,1.3-3.6,3.8-3.6,6.5s2.6,6.7,6,7.5c.4,3.9,3.6,6.9,7.6,6.9,9.3,0,17.7-1,27-1s7.7-3.4,7.7-7.7-.2-1.8-.5-2.6c3-1,5.1-3.9,5.1-7.2s-2.1-6.1-5.1-7.2c.2-.6.3-1.3.3-2,0-4.2-3.4-7.6-7.6-7.6h0Z"/>
  <path class="cls-8" d="M145.2,613.6c-1.1,0-2.2.2-3.2.7-1.3-2.5-3.8-4.2-6.8-4.2s-4.8,1.2-6.2,3.1c-1-.5-2.2-.8-3.4-.8-3.5,0-6.5,2.4-7.4,5.7-.8-.3-1.8-.5-2.7-.5-4.2,0-7.7,3.4-7.7,7.7s.2,1.9.5,2.8c-2.2,1.3-3.6,3.8-3.6,6.5s2.6,6.7,6,7.5c.4,3.9,3.6,6.9,7.6,6.9,9.3,0,17.7-1,27-1s7.7-3.4,7.7-7.7-.2-1.8-.5-2.6c3-1,5.1-3.9,5.1-7.2s-2.1-6.1-5.1-7.2c.2-.6.3-1.3.3-2,0-4.2-3.4-7.6-7.6-7.6h0Z"/>
  <polygon v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-22" points="103.5 592.8 103.5 564.5 105.6 564.5 105.6 550.3 33.2 550.3 33.2 564.5 35.3 564.5 35.3 653 93.5 653 93.5 653.2 133.9 653.2 133.9 611 138 611 138 592.8 103.5 592.8"/>
  <g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <g>
        <rect class="cls-24" x="97.7" y="597.7" width="35.5" height="8.1"/>
        <rect class="cls-24" x="97.7" y="597.7" width="5.1" height="8.1"/>
        <rect class="cls-24" x="107.8" y="597.7" width="5.1" height="8.1"/>
        <rect class="cls-24" x="117.9" y="597.7" width="5.1" height="8.1"/>
        <rect class="cls-24" x="128.1" y="597.7" width="5.1" height="8.1"/>
        <rect class="cls-23" x="40.5" y="559.3" width="58.2" height="88.6"/>
        <rect class="cls-1" x="38.4" y="555.2" width="62.4" height="4.1"/>
        <g>
          <rect class="cls-22" x="50.6" y="596.6" width="12.7" height="19.8"/>
          <rect class="cls-23" x="48.4" y="603.6" width="17" height="2.1"/>
          <rect class="cls-22" x="75.9" y="596.6" width="12.7" height="19.8"/>
          <rect class="cls-23" x="74.1" y="603.6" width="16.3" height="2.1"/>
        </g>
        <rect class="cls-22" x="50.6" y="569" width="12.7" height="19.8"/>
        <rect class="cls-23" x="49.4" y="576.1" width="15.1" height="2.1"/>
        <rect class="cls-22" x="75.9" y="569" width="12.7" height="19.8"/>
        <rect class="cls-23" x="74.1" y="576.1" width="16.3" height="2.1"/>
        <rect class="cls-1" x="98.7" y="606.7" width="30.4" height="41.3"/>
        <rect class="cls-22" x="103.8" y="617.6" width="20.2" height="16.4"/>
        <rect class="cls-23" x="100.8" y="634" width="26.2" height="3.9"/>
        <path class="cls-1" d="M76.3,648h-13.3v-26.2h13.3v26.2Z"/>
        <rect class="cls-1" x="113" y="616.9" width="1.8" height="17.1"/>
      </g>
      <polygon class="cls-4" points="62.9 621.8 76.3 621.8 76.3 645.6 62.9 621.8"/>
    </g>
    <g>
      <path class="cls-2" d="M143.5,640.5c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM143.5,642.3c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-19" d="M143.5,641.9c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM143.5,642.3c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-15" d="M143.8,646.8c0,.3,0,.5-.3.6-.3,0-.5,0-.6-.3l-5.5-14.1v-.3c-.1,0,.1-.2.1-.2l.2-.2.7.7h0l5.4,13.8h0Z"/>
      <path class="cls-10" d="M137.8,632.3l2-1.6h.4s6.5.9,1.8,4.1c-.2.2-.5,0-.7,0-.2-.2,0-.5.1-.7,2.2-1.5-.4-2.1-1.1-2.2l-1.7,1.4-.7-.7h-.1v-.3Z"/>
      <path class="cls-10" d="M133.4,645.1h-1.7c-.3,0-.5.2-.5.5h0c0,.3.2.5.5.5h1.7c.3,0,.5-.2.5-.5h0c0-.3-.2-.5-.5-.5Z"/>
      <path class="cls-15" d="M138.2,636.1c.3-.3.7-.3,1,0s.3.7,0,1l-9.7,11.3c-.3.3-.7.3-1,0s-.3-.7,0-1l9.7-11.3Z"/>
      <path class="cls-15" d="M138.3,635.1c.4,0,.8.2.9.6,0,.4-.2.8-.6.9l-12.7,2.8c-.4,0-.8-.2-.9-.6,0-.4.2-.8.6-.9l12.7-2.8h0Z"/>
      <path class="cls-15" d="M123.5,634.1c0-.3,0-.5.3-.6.3,0,.5,0,.6.3l5,13.8c0,.3,0,.5-.3.6-.3,0-.5,0-.6-.3l-5-13.8h0Z"/>
      <path class="cls-2" d="M117.7,640.5c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM117.7,642.3c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-19" d="M117.7,641.9c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM117.7,642.3c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-10" d="M121.8,632.4h5.3c1.2,0,1,1.1-.2,1.1s-2.3.8-3.3.8h-1.8c-.5,0-.9-.4-.9-.9h0c0-.5.4-.9.9-.9h0Z"/>
      <path class="cls-2" d="M128.1,645c.1,0,.3,0,.3.2s0,.3-.2.3l-10.9,1.7s-.3,0-.3-.2,0-.3.2-.3l11-1.7h-.1Z"/>
      <path class="cls-2" d="M128.6,649.6c.1,0,.2,0,.2.3s-.1.3-.3.2l-11.1-.6s-.2,0-.2-.3,0-.3.3-.2l11.1.6Z"/>
      <path class="cls-15" d="M125.3,638.5c.2-.2.5-.2.7,0s.2.5,0,.7l-7.7,8.9c-.2.2-.5.2-.7,0s-.2-.5,0-.7l7.7-8.9Z"/>
      <circle class="cls-22" cx="143.5" cy="648" r="1.2"/>
      <path class="cls-15" d="M129.1,647.4c.3,0,.5.2.5.5s-.2.5-.5.5h-11.5c-.3,0-.5-.2-.5-.4,0-.3.2-.5.5-.5h11.5Z"/>
      <circle class="cls-22" cx="117.7" cy="648" r="1.2"/>
      <path class="cls-6" d="M128.8,645.2c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
      <path class="cls-21" d="M128.8,646.7c.5,0,.9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9Z"/>
      <path class="cls-2" d="M126.5,650.9h-.3v-.4l2.4-3.2h.3c.1,0,.1.2,0,.4,0,0-2.4,3.2-2.4,3.2Z"/>
      <path class="cls-10" d="M127.2,650.6h-1.7c-.3,0-.5.2-.5.5h0c0,.3.2.5.5.5h1.7c.3,0,.5-.2.5-.5h0c0-.3-.2-.5-.5-.5Z"/>
    </g>
    <g>
      <g>
        <rect class="cls-17" x="58.8" y="650.7" width="2.1" height="14.6"/>
        <rect class="cls-25" x="49.6" y="638.1" width="20.5" height="14.2"/>
        <g>
          <path class="cls-13" d="M51.6,645v-4.1h3c.2,0,.4.2.5.4s.2.4.2.7v.6c0,.2-.2.3-.4.4,0,0-.3,0-.4.2.2,0,.3,0,.3.2l.2.2s0,.2.2.3l.6,1.2h-1.4l-.7-1.2c0-.2-.2-.3-.2-.3h-.4v1.7h-1.3v-.4h-.2,0ZM52.9,642.6h1v-.5h-1.1v.8h.2v-.3h-.1Z"/>
          <path class="cls-13" d="M56,640.9h3.4v.9h-2.1v.6h2v.8h-2v.8h2.2v.9h-3.4v-4.1h-.1Z"/>
          <path class="cls-13" d="M60.1,640.9h1.2l1.5,2.3v-2.3h1.2v4.1h-1.2l-1.5-2.2v2.2h-1.2v-4.1Z"/>
          <path class="cls-13" d="M64.6,640.9h3.8v1h-1.3v3.1h-1.3v-3.1h-1.3v-1h.1Z"/>
          <path class="cls-13" d="M54.3,646.1v4.2h-1.1v-2.7c-.2,0-.4.3-.5.3-.2,0-.4.2-.7.3v-.9c.4,0,.7-.3.9-.4s.4-.4.5-.6h.9v-.2h0Z"/>
          <path class="cls-13" d="M55.7,646.2h2.4c.4,0,.7,0,.9.3s.3.4.3.7,0,.4-.2.6c0,0-.2.2-.4.3.3,0,.5.2.6.4s.2.4.2.6v.6c0,.2-.2.3-.4.4h-3.3v-4.1h0v.2h0ZM57,647.8h.9v-.6h-1v.8h0v-.2h0ZM57,649.4h1.1v-.6h-1.1v.8h0v-.2Z"/>
          <path class="cls-13" d="M60.1,646.2h3.4v.9h-2.1v.6h2v.8h-2v.8h2.2v.9h-3.4v-4.1h-.1Z"/>
          <path class="cls-13" d="M64.3,646.2h1.9c.4,0,.7,0,.9.2s.4.2.6.4c0,.2.3.4.3.7v1.8c0,.2-.2.4-.4.6-.2.2-.4.3-.6.3h-2.7v-4.1h0ZM65.5,647.1v2.2h.9s.2-.2.3-.3v-.7c0-.3,0-.7-.2-.9,0-.2-.4-.2-.7-.2h-.3Z"/>
        </g>
      </g>
      <path class="cls-5" d="M59.9,661.6c.8.6,1.3,1.5,1.4,2.5.8-.6,1.8-.9,2.8-.8,0,1.1-.3,2.2-1.1,3-.7.7-1.6,1-2.5,1h-1c-.9,0-1.8-.3-2.5-1-.8-.8-1.2-1.9-1.1-3,1,0,2,0,2.8.8,0-1,.6-2,1.4-2.6h-.2Z"/>
    </g>
  </g>
  <g id="apt-girl">
    <path id="apt-girl-arm" class="cls-12" d="M78.2,578s-1.7.3-1.9.3c-4.2.4-6.8-2.4-8.3-5.9v-.4h0c.2,0,.3-.7.3-.9l.2-1c0-.2,0-.4-.3-.5h0c-.2,0-.4,0-.4.3h0l-.2-1.8c0-.2-.2-.4-.5-.3h0c-.2,0-.4.2-.3.5v.6l-.3-.9c0-.2-.3-.3-.5-.2h0c-.2,0-.3.3-.2.5l.7,1.9-.8-1.8c0-.2-.3-.3-.5-.2h0c-.2,0-.3.3-.2.5l.8,1.8-.7-.9c0-.2-.4-.2-.6,0h0c-.2,0-.2.4,0,.6l.6.8v.2l.8,1.2c.2.3.3.4.5.5,1.5,4.5,4.6,8.3,10.7,8.2l1.5-3h-.4,0Z"/>
    <path class="cls-14" d="M77.4,577.8h-4l-.3,3.1c.9.2,1.8.3,2.8.3l1.5-3.4h0Z"/>
    <path class="cls-7" d="M82.6,567.3c.5,0,1.4-2.7,2-1.7,2.7,2,.5,8.3,3.2,7.9-.8,3.4-6.3,5.2-8.7,3.1,0,0,1.8-8.3,3.5-9.4h0Z"/>
    <path class="cls-7" d="M78.8,567.3c-.5,0-1.7-2.7-2.3-1.7-2.7,2-.2,8.4-3,8,.8,3.4,6.3,5.2,8.7,3.1,0,0-1.8-8.3-3.5-9.4h.1Z"/>
    <path class="cls-14" d="M85.6,588.9h-9.9v-9c0-1.2,1-2.2,2.2-2.2h5.5c1.2,0,2.2,1,2.2,2.2,0,0,0,12,0,9Z"/>
    <path class="cls-19" d="M77.8,577.8l.3.4,2,2.9.5.7.5-.7,2-2.9.3-.4h-5.6Z"/>
    <polygon class="cls-12" points="82.7 575 78.6 575 78.6 577.8 80.7 580.7 82.7 577.8 82.7 575"/>
    <polygon class="cls-9" points="81.9 579 78.6 575.8 78.6 575 82.7 575 82.7 577.8 81.9 579"/>
    <path class="cls-12" d="M85.5,582.2s.5,3.6,1,6.7h1.9c-.5-3.3-1-7-1-7l-2,.3h0Z"/>
    <path class="cls-14" d="M87.7,582.5l-2.4.4-.9-4.9c1.8-.3,2.6,1.4,2.8,2.2l.5,2.3Z"/>
    <path class="cls-12" d="M80.7,563.1c1.3,0,5.2.2,5.3,4.7,0,4.6-.9,6.3-1.5,6.9-.6.7-1.3,1.3-2.5,1.6-.3.3-.7.5-1.2.5s-.9-.2-1.2-.5c-1.3-.3-1.9-.9-2.5-1.6-.6-.7-1.6-2.4-1.5-6.9,0-4.6,4-4.7,5.3-4.7h-.2Z"/>
    <path class="cls-20" d="M80.7,563.1c1.3,0,5.2.2,5.3,4.7,0,4.6-.9,6.3-1.5,6.9-.6.7-1.3,1.3-2.5,1.6-.3.3-.7.5-1.2.5v-13.8h-.1Z"/>
    <path class="cls-12" d="M76.1,570.1c0-.3-.3-.5-.6-.6-.4,0-1.1,0-1.1,1.2s.9,2,1.5,1.9.3-2.2.2-2.5h0Z"/>
    <path class="cls-20" d="M75.8,570.5c0-.2-.2-.3-.4-.4-.2,0-.6,0-.6.7s.5,1.2.8,1.1c0,0,.2-.2.2-.4-.2,0-.3-.3-.3-.6s.2-.4.3-.4h0Z"/>
    <path class="cls-20" d="M85.2,570.2c.2-.3.3-.5.7-.6.4,0,1.1,0,1.1,1.2s-.9,2-1.5,1.9-.3-2.4-.3-2.4h0Z"/>
    <path class="cls-9" d="M85.5,570.5c0-.2.2-.3.4-.4.2,0,.6,0,.6.7s-.5,1.2-.8,1.1c0,0-.2-.2-.2-.4.2,0,.3-.3.3-.6s-.2-.4-.3-.4h0Z"/>
    <path class="cls-18" d="M83.5,565.7c.5,1.1.5,2.8,2.5,3.9,1.3-5-2.1-7.3-5.5-7.2-3.5,0-6.7,2.5-5.7,7.4,2.1,0,2.8-2.3,2.9-2.3,2.3,0,4,.3,5.8-1.7h0Z"/>
    <path class="cls-19" d="M82.2,573.3c0,.5-.8,1-1.5,1s-1.4-.4-1.5-1h3.1,0Z"/>
    <path class="cls-9" d="M82.9,569.2s-.2,0-.2-.2,0-.2.2-.2h.8s.2.2,0,.3c0,0-.2.2-.3,0h-.7.2Z"/>
    <path class="cls-9" d="M78.5,568.8s.2,0,.2.2,0,.2-.2.2h-1v-.3c.4-.2.8,0,.8,0h.2Z"/>
    <path class="cls-11" d="M83.9,570.5h.2v-.2h0c-.3-.3-.8-.7-1.6,0v.2h.2c.6-.5,1-.2,1.3,0h0Z"/>
    <circle class="cls-16" cx="83.2" cy="570.5" r=".4"/>
    <path class="cls-11" d="M83.9,570h0l-.2.3h0l.2-.3Z"/>
    <path class="cls-11" d="M84.1,570.1h0l-.3.3h0l.3-.3Z"/>
    <path class="cls-11" d="M77.5,570.5h-.2v-.2h0c.3-.3.8-.7,1.6,0v.2h-.2c-.6-.5-1-.2-1.3,0h0Z"/>
    <circle class="cls-16" cx="78.2" cy="570.5" r=".4"/>
    <path class="cls-11" d="M77.5,570h0l.2.3h0l-.2-.3Z"/>
    <path class="cls-11" d="M77.3,570.1h0l.3.3h0l-.3-.3Z"/>
    <path class="cls-3" d="M76.7,565.7h.5l.9,1.4v.5h-.5l-.9-1.4v-.5Z"/>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  mounted: function(){
    var hb = gsap.timeline({repeat: -1, repeatDelay:2, ease:"Ease.none"});
    hb.set('#apt-girl',{y:27})
    hb.fromTo('#apt-girl', .3,  {scaleY: 0, alpha:0},{scaleY: 1, alpha:1, transformOrigin:"100% 100%"})
    hb.to('#apt-girl', .3,  {scaleY: 0, alpha:0},'+=6')

    var hb2 = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
    hb2.to('#apt-girl-arm', .5, {y: .7, rotation: 10, transformOrigin:"100% 100%"})
  },
  methods:{
    clickHome: function(){
      this.found = true
      this.$parent.showModal('Apartment')
    },
    showHighlight: function(){
      this.highlight = true
    },
    hideHighlight: function(){
      this.highlight = false
    }
  }
}
</script>

<style scoped>
  .cls-1 {
        fill: #cd7f35;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #5b412a;
      }

      .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-14, .cls-15, .cls-18, .cls-19, .cls-20, .cls-21 {
        fill-rule: evenodd;
      }

      .cls-3 {
        fill: #f1d02d;
      }

      .cls-4 {
        fill: #b8722f;
      }

      .cls-5 {
        fill: #70a30a;
      }

      .cls-6 {
        fill: #a6a6a6;
      }

      .cls-7 {
        fill: #362112;
      }

      .cls-8 {
        fill: #69990a;
      }

      .cls-9 {
        fill: #d4a15e;
      }

      .cls-10 {
        fill: #285a8f;
      }

      .cls-11, .cls-16 {
        fill: #471e04;
      }

      .cls-12 {
        fill: #f2d291;
      }

      .cls-13 {
        fill: #000;
      }

      .cls-14 {
        fill: #0082e6;
      }

      .cls-24 {
        fill: none;
        stroke: #e3e3e0;
        stroke-miterlimit: 10;
        stroke-width: 1.7px;
      }

      .cls-15 {
        fill: #00d5ff;
      }

      .cls-17 {
        fill: #630;
      }

      .cls-18 {
        fill: #54341d;
      }

      .cls-25 {
        stroke: #000;
        stroke-width: .5px;
      }

      .cls-25, .cls-19, .cls-22 {
        fill: #fff;
      }

      .cls-20 {
        fill: #edba68;
      }

      .cls-21 {
        fill: #ffebb4;
      }

      .cls-23 {
        fill: #f69654;
      }
</style>
