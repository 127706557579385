<template>
    <div id="reno" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
        <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-15" d="M97.4,492.3l-26-26h0c-1-1.1-2.3-1.7-3.7-1.9h-.3l-67.3-.4v58.9h95.6v-20.9c.4-.2.8-.5,1.2-.8l.3-.3c2.3-2.3,2.5-6,.3-8.6h0Z"/>
        <polygon class="cls-14" points="0 470.4 51.8 470.7 27 495.6 0 495.6 0 470.4"/>
        <g>
          <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
            <polygon class="cls-2" points="90.6 517.9 90.6 494.3 66.9 470.6 35.4 502.2 35.4 517.9 90.6 517.9"/>
            <polygon class="cls-2" points="35.4 502.2 35.4 517.9 0 517.9 0 494.3 20.5 494.3 13.1 502.2 35.4 502.2"/>
            <rect class="cls-4" x="49.1" y="498.4" width="10.6" height="19.6"/>
            <rect class="cls-15" x="20.9" y="506.6" width="7.1" height="6.3"/>
            <rect class="cls-15" y="499.5" width="8.1" height="12.4"/>
            <polygon class="cls-7" points="59.8 498.4 49.1 498.4 49.1 517.9 59.8 498.4"/>
            <polygon class="cls-6" points="36.2 503.2 66.9 472.5 69.4 475 41.3 503.2 36.2 503.2"/>
          </g>
          <rect class="cls-1" x="-.6" y="481" width="34" height="3" transform="translate(-336.4 152.9) rotate(-45)"/>
          <polygon class="cls-13" points="42.5 473.1 13.1 502.2 35.4 502.2 66.3 471.2 44.5 470.6 42.5 473.1"/>
          <path class="cls-5" d="M67.8,469.7c-.5-.5-1.3-.5-1.9,0l-31.6,31.7c-.5.5-.5,1.3,0,1.9.5.5,1.3.5,1.9,0l30.7-30.7,24.8,24.8c.5.5,1.3.5,1.9,0,.5-.5.5-1.3,0-1.9l-25.7-25.7h-.1Z"/>
          <path class="cls-5" d="M35.4,503.5c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3H13.1c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h22.3Z"/>
          <path class="cls-5" d="M66.7,472c.7,0,1.3-.6,1.3-1.4s-.6-1.3-1.3-1.3h-22.3c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h22.3Z"/>
          <g>
            <rect class="cls-15" x="67.1" y="494.6" width="13.9" height="16.1"/>
            <polygon class="cls-10" points="81 494.6 67.1 494.6 67.1 510.8 81 494.6"/>
          </g>
          <path class="cls-5" d="M45.4,471.7c.7-.7.8-1.7.3-2.2s-1.5-.4-2.2.3l-31.1,31.1c-.7.7-.8,1.7-.3,2.2s1.5.4,2.2-.3l31.1-31.1Z"/>
          <path class="cls-2" d="M8.1,503.1H0v2h8.1c.5,0,1-.4,1-1s-.4-1-1-1Z"/>
          <polygon class="cls-1" points="0 469 0 471.9 66.7 471.9 67.1 469.4 0 469"/>
          <polygon class="cls-1" points="0 484.2 12.7 471.5 10.6 469.4 0 479.9 0 484.2"/>
          <path class="cls-5" d="M20.5,493H0v2.6h20.4c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3h.1Z"/>
          <g>
            <path class="cls-8" d="M85.5,532.3c-2.5,2.9-12.6,1.4-12.6,1.4l-1.3-6.4h17s-1,2.6-3.2,5.1h0Z"/>
            <rect class="cls-9" x="70.9" y="526.4" width="18.3" height="1.2" rx=".4" ry=".4"/>
            <path class="cls-12" d="M86.7,536.7l-3.2-3.1h-10.2l-1.4-5.9s0-.2-.2-.3l-9.5-3.8c-.2,0-.4,0-.5.2,0,.2,0,.4.2.5l9.3,3.7,1.4,6,2.2,5.7c0,.3.5.9,1.3.9h0c.9,0,1.3-.8,1.4-.8l2.8-5.5h2.8l3.1,3h.6v-.5h-.1ZM76.8,539.3s-.3.4-.8.4-.6-.4-.6-.4l-1.9-5h5.9l-2.6,5Z"/>
            <rect class="cls-3" x="62.7" y="522.3" width="1.3" height="3.9" rx=".4" ry=".4" transform="translate(-448.6 394.6) rotate(-68.9)"/>
            <circle class="cls-3" cx="86.4" cy="537" r="2.7"/>
            <circle class="cls-11" cx="86.4" cy="537" r="2.1"/>
            <circle class="cls-3" cx="86.4" cy="537" r="1.3"/>
          </g>
        </g>
      </svg>
    </div>
  </template>

  <script>
  export default {
    name: 'reno',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('Reno')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #edd970;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #b3ada4;
      }

      .cls-2, .cls-5, .cls-6, .cls-7, .cls-10, .cls-13 {
        fill-rule: evenodd;
      }

      .cls-3 {
        fill: #514e61;
      }

      .cls-4 {
        fill: #f77917;
      }

      .cls-5 {
        fill: #738781;
      }

      .cls-6 {
        fill: #a6a098;
      }

      .cls-7 {
        fill: #eb6600;
      }

      .cls-8 {
        fill: #f8bc4c;
      }

      .cls-9 {
        fill: #e9a039;
      }

      .cls-10 {
        fill: #e6e6e6;
      }

      .cls-11 {
        fill: #7a797d;
      }

      .cls-12 {
        fill: #c7c5cc;
      }

      .cls-13 {
        fill: #879e97;
      }

      .cls-14 {
        fill: #81b023;
      }

      .cls-15 {
        fill: #fff;
      }
  </style>
