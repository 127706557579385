<template>
  <div id="trees" class="layer">
    <svg id="trees_copy" data-name="trees copy" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <g>
    <path class="cls-7" d="M684.3,620.5c-.6,0-1.2.1-1.7.4-.7-1.3-2.1-2.3-3.7-2.3s-2.6.7-3.4,1.7c-.6-.3-1.2-.4-1.9-.4-1.9,0-3.5,1.3-4,3.1-.5-.2-1-.3-1.5-.3-2.3,0-4.2,1.9-4.2,4.2s.1,1.1.3,1.5c-1.2.7-2,2-2,3.5s1.4,3.6,3.3,4.1c.2,2.1,2,3.8,4.1,3.8,5,0,9.6-.6,14.7-.6s4.2-1.9,4.2-4.2,0-1-.3-1.4c1.6-.6,2.8-2.1,2.8-3.9s-1.1-3.3-2.8-3.9c0-.3.1-.7.1-1.1,0-2.3-1.9-4.2-4.2-4.2Z"/>
    <rect class="cls-4" x="675.9" y="633.2" width="2.7" height="26.9"/>
    <path class="cls-3" d="M670.2,633.7s-.9,8,6.6,10.8l-.4,1.2c-8.5-3.1-7.5-12.1-7.5-12.1h1.3Z"/>
    <path class="cls-3" d="M683.9,629.5s1.8,6.7-5.9,8l-.2-1.3c6.3-1,4.9-6.4,4.9-6.4l1.3-.3Z"/>
    <path class="cls-3" d="M671.6,628.8s-1.5,5.7,5,6.7l.2-1.1c-5.3-.9-4.1-5.4-4.1-5.4l-1.1-.3Z"/>
    <path class="cls-1" d="M686,638.8c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
    <path class="cls-1" d="M681.9,639.9c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
    <path class="cls-3" d="M683.1,639.7s.8,6.7-5.5,9l.4,1c7.1-2.6,6.3-10.2,6.3-10.2h-1.1Z"/>
    <path class="cls-1" d="M683.5,637.5c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8-2.8-1.2-2.8-2.8,1.2-2.8,2.8-2.8Z"/>
  </g>
  <g>
    <path class="cls-6" d="M651.9,613.7c.7,0,1.4.2,2,.4.8-1.6,2.5-2.7,4.4-2.7s3.1.8,3.9,2c.7-.3,1.4-.5,2.2-.5,2.3,0,4.2,1.5,4.7,3.6.5-.2,1.1-.3,1.7-.3,2.7,0,4.9,2.2,4.9,4.9s-.1,1.2-.3,1.8c1.4.9,2.3,2.4,2.3,4.2s-1.6,4.3-3.8,4.8c-.2,2.5-2.3,4.4-4.9,4.4-5.9,0-11.3-.7-17.2-.7s-4.9-2.2-4.9-4.9.1-1.2.3-1.7c-1.9-.7-3.3-2.5-3.3-4.6s1.4-3.9,3.2-4.6c-.1-.4-.2-.8-.2-1.3,0-2.7,2.2-4.9,4.9-4.9Z"/>
    <rect class="cls-4" x="658.6" y="628.7" width="3.2" height="31.7"/>
    <path class="cls-3" d="M668.5,629.2s1.1,9.4-7.8,12.7l.5,1.4c10-3.7,8.8-14.3,8.8-14.3l-1.5.2Z"/>
    <path class="cls-3" d="M652.3,624.3s-2.1,7.9,7,9.4l.2-1.5c-7.4-1.2-5.7-7.5-5.7-7.5l-1.5-.4Z"/>
  </g>
  <g>
    <path class="cls-7" d="M467.1,381c.7,0,1.4.2,2,.4.8-1.6,2.5-2.7,4.4-2.7s3.1.8,3.9,2c.7-.3,1.4-.5,2.2-.5,2.3,0,4.2,1.5,4.7,3.6.5-.2,1.1-.3,1.7-.3,2.7,0,4.9,2.2,4.9,4.9s-.1,1.2-.3,1.8c1.4.9,2.3,2.4,2.3,4.2s-1.6,4.3-3.8,4.8c-.2,2.5-2.3,4.4-4.9,4.4-5.9,0-11.3-.7-17.2-.7s-4.9-2.2-4.9-4.9.1-1.2.3-1.7c-1.9-.7-3.3-2.5-3.3-4.6s1.4-3.9,3.2-4.6c-.1-.4-.2-.8-.2-1.3,0-2.7,2.2-4.9,4.9-4.9Z"/>
    <rect class="cls-4" x="473.8" y="396" width="3.2" height="31.7"/>
    <path class="cls-3" d="M483.7,396.5s1.1,9.4-7.8,12.7l.5,1.4c10-3.7,8.8-14.3,8.8-14.3l-1.5.2Z"/>
    <path class="cls-3" d="M467.5,391.6s-2.1,7.9,7,9.4l.2-1.5c-7.4-1.2-5.7-7.5-5.7-7.5l-1.5-.4Z"/>
  </g>
  <g>
    <path class="cls-5" d="M401.4,510.1c.7,0,1.4.2,1.9.4.8-1.5,2.4-2.6,4.2-2.6,1.6,0,2.9.8,3.8,1.9.6-.3,1.3-.5,2.1-.5,2.2,0,4,1.5,4.5,3.5.5-.2,1.1-.3,1.7-.3,2.6,0,4.7,2.1,4.7,4.7s-.1,1.2-.3,1.7c1.3.8,2.2,2.3,2.2,4,0,2.2-1.6,4.1-3.7,4.6-.2,2.4-2.2,4.3-4.7,4.3-5.7,0-10.9-.6-16.6-.6s-4.7-2.1-4.7-4.7c0-.6.1-1.1.3-1.6-1.8-.6-3.2-2.4-3.2-4.4s1.3-3.8,3.1-4.4c-.1-.4-.2-.8-.2-1.2,0-2.6,2.1-4.7,4.7-4.7Z"/>
    <rect class="cls-4" x="408.1" y="523.1" width="2.7" height="40.4"/>
    <path class="cls-3" d="M416.4,523.4s.9,8-6.6,10.7l.4,1.2c8.5-3.1,7.4-12.1,7.4-12.1h-1.3Z"/>
    <path class="cls-3" d="M402.8,523s-1.8,6.7,5.9,8l.2-1.3c-6.3-1-4.9-6.4-4.9-6.4l-1.3-.3Z"/>
    <path class="cls-1" d="M399.4,535.1c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-1" d="M404.3,536.4c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-3" d="M402.9,536.1s-.9,8,6.6,10.7l-.4,1.2c-8.5-3.1-7.4-12.1-7.4-12.1h1.3Z"/>
    <path class="cls-1" d="M402.4,533.5c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3Z"/>
  </g>
  <g>
    <path class="cls-5" d="M443.9,607c.7,0,1.4.2,1.9.4.8-1.5,2.4-2.6,4.2-2.6,1.6,0,2.9.8,3.8,1.9.6-.3,1.3-.5,2.1-.5,2.2,0,4,1.5,4.5,3.5.5-.2,1.1-.3,1.7-.3,2.6,0,4.7,2.1,4.7,4.7s-.1,1.2-.3,1.7c1.3.8,2.2,2.3,2.2,4s-1.6,4.1-3.7,4.6c-.2,2.4-2.2,4.3-4.7,4.3-5.7,0-10.9-.6-16.6-.6s-4.7-2.1-4.7-4.7.1-1.1.3-1.6c-1.8-.6-3.2-2.4-3.2-4.4,0-2,1.3-3.8,3.1-4.4-.1-.4-.2-.8-.2-1.2,0-2.6,2.1-4.7,4.7-4.7Z"/>
    <rect class="cls-4" x="450.6" y="620" width="2.7" height="40.4"/>
    <path class="cls-3" d="M458.9,620.3s.9,8-6.6,10.7l.4,1.2c8.5-3.1,7.4-12.1,7.4-12.1h-1.3Z"/>
    <path class="cls-3" d="M445.2,619.9s-1.8,6.7,5.9,8l.2-1.3c-6.3-1-4.9-6.4-4.9-6.4l-1.3-.3Z"/>
    <path class="cls-1" d="M441.9,632c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-1" d="M446.7,633.3c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8c0-1.5-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-3" d="M445.4,633s-.9,8,6.6,10.7l-.4,1.2c-8.5-3.1-7.4-12.1-7.4-12.1h1.3Z"/>
    <path class="cls-1" d="M444.9,630.4c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3Z"/>
  </g>
  <g>
    <path class="cls-5" d="M246.3,600.4c.7,0,1.4.2,1.9.4.8-1.5,2.4-2.6,4.2-2.6,1.6,0,2.9.8,3.8,1.9.6-.3,1.3-.5,2.1-.5,2.2,0,4,1.5,4.5,3.5.5-.2,1.1-.3,1.7-.3,2.6,0,4.7,2.1,4.7,4.7s-.1,1.2-.3,1.7c1.3.8,2.2,2.3,2.2,4,0,2.2-1.6,4.1-3.7,4.6-.2,2.4-2.2,4.3-4.7,4.3-5.7,0-10.9-.6-16.6-.6s-4.7-2.1-4.7-4.7c0-.6.1-1.1.3-1.6-1.8-.6-3.2-2.4-3.2-4.4s1.3-3.8,3.1-4.4c-.1-.4-.2-.8-.2-1.2,0-2.6,2.1-4.7,4.7-4.7Z"/>
    <rect class="cls-4" x="253" y="613.4" width="2.7" height="40.4"/>
    <path class="cls-3" d="M261.4,613.7s.9,8-6.6,10.7l.4,1.2c8.5-3.1,7.4-12.1,7.4-12.1h-1.3Z"/>
    <path class="cls-3" d="M247.7,613.3s-1.8,6.7,5.9,8l.2-1.3c-6.3-1-4.9-6.4-4.9-6.4l-1.3-.3Z"/>
    <path class="cls-1" d="M244.3,625.4c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-1" d="M249.2,626.7c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-3" d="M247.8,626.4s-.9,8,6.6,10.7l-.4,1.2c-8.5-3.1-7.4-12.1-7.4-12.1h1.3Z"/>
    <path class="cls-1" d="M247.3,623.8c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3Z"/>
  </g>
  <g>
    <path class="cls-6" d="M262.7,522.2c-.7,0-1.4.2-2,.4-.8-1.6-2.5-2.7-4.4-2.7s-3.1.8-3.9,2c-.7-.3-1.4-.5-2.2-.5-2.3,0-4.2,1.5-4.7,3.6-.5-.2-1.1-.3-1.7-.3-2.7,0-4.9,2.2-4.9,4.9,0,.6.1,1.2.3,1.8-1.4.9-2.3,2.4-2.3,4.2,0,2.3,1.6,4.3,3.8,4.8.2,2.5,2.3,4.4,4.9,4.4,5.9,0,11.3-.7,17.2-.7,2.7,0,4.9-2.2,4.9-4.9,0-.6-.1-1.2-.3-1.7,1.9-.7,3.3-2.5,3.3-4.6,0-2.1-1.4-3.9-3.2-4.6.1-.4.2-.8.2-1.3,0-2.7-2.2-4.9-4.9-4.9Z"/>
    <rect class="cls-4" x="252.8" y="537.2" width="3.2" height="31.7"/>
    <path class="cls-3" d="M246.2,537.7s-1.1,9.4,7.8,12.7l-.5,1.4c-10-3.7-8.8-14.3-8.8-14.3l1.5.2Z"/>
    <path class="cls-3" d="M262.3,532.8s2.1,7.9-7,9.4l-.2-1.5c7.4-1.2,5.7-7.5,5.7-7.5l1.5-.4Z"/>
  </g>
  <g>
    <path class="cls-7" d="M109.1,484.2c.7,0,1.4.2,1.9.4.8-1.5,2.4-2.6,4.2-2.6,1.6,0,2.9.8,3.8,1.9.6-.3,1.3-.5,2.1-.5,2.2,0,4,1.5,4.5,3.5.5-.2,1.1-.3,1.7-.3,2.6,0,4.7,2.1,4.7,4.7s-.1,1.2-.3,1.7c1.3.8,2.2,2.3,2.2,4,0,2.2-1.6,4.1-3.7,4.6-.2,2.4-2.2,4.3-4.7,4.3-5.7,0-10.9-.6-16.6-.6s-4.7-2.1-4.7-4.7.1-1.1.3-1.6c-1.8-.6-3.2-2.4-3.2-4.4s1.3-3.8,3.1-4.4c-.1-.4-.2-.8-.2-1.2,0-2.6,2.1-4.7,4.7-4.7Z"/>
    <rect class="cls-4" x="115.7" y="497.2" width="2.7" height="40.4"/>
    <path class="cls-3" d="M124.1,497.5s.9,8-6.6,10.7l.4,1.2c8.5-3.1,7.4-12.1,7.4-12.1h-1.3Z"/>
    <path class="cls-3" d="M110.4,497.1s-1.8,6.7,5.9,8l.2-1.3c-6.3-1-4.9-6.4-4.9-6.4l-1.3-.3Z"/>
    <path class="cls-1" d="M107.1,509.2c-1.5,0-2.8,1.2-2.8,2.8,0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-1" d="M111.9,510.5c-1.5,0-2.8,1.2-2.8,2.8s1.2,2.8,2.8,2.8,2.8-1.2,2.8-2.8-1.2-2.8-2.8-2.8Z"/>
    <path class="cls-3" d="M110.5,510.2s-.9,8,6.6,10.7l-.4,1.2c-8.5-3.1-7.4-12.1-7.4-12.1h1.3Z"/>
    <path class="cls-1" d="M110,507.6c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3-1.5-3.3-3.3-3.3Z"/>
  </g>
  <g>
    <path class="cls-1" d="M151.9,327.6c-.6,0-1.2.1-1.7.4-.7-1.3-2.1-2.3-3.7-2.3s-2.6.7-3.4,1.7c-.6-.3-1.2-.4-1.9-.4-1.9,0-3.5,1.3-4,3.1-.5-.2-1-.3-1.5-.3-2.3,0-4.2,1.9-4.2,4.2s.1,1.1.3,1.5c-1.2.7-2,2-2,3.5s1.4,3.6,3.3,4.1c.2,2.1,2,3.8,4.1,3.8,5,0,9.6-.6,14.7-.6s4.2-1.9,4.2-4.2,0-1-.3-1.4c1.6-.6,2.8-2.1,2.8-3.9s-1.1-3.3-2.8-3.9c0-.3.1-.7.1-1.1,0-2.3-1.9-4.2-4.2-4.2Z"/>
    <rect class="cls-4" x="143.5" y="340.4" width="2.7" height="26.9"/>
    <path class="cls-3" d="M137.8,340.8s-.9,8,6.6,10.8l-.4,1.2c-8.5-3.1-7.5-12.1-7.5-12.1h1.3Z"/>
    <path class="cls-3" d="M151.6,336.7s1.8,6.7-5.9,8l-.2-1.3c6.3-1,4.9-6.4,4.9-6.4l1.3-.3Z"/>
    <path class="cls-3" d="M139.2,336s-1.5,5.7,5,6.7l.2-1.1c-5.3-.9-4.1-5.4-4.1-5.4l-1.1-.3Z"/>
    <path class="cls-1" d="M153.6,346c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
    <path class="cls-1" d="M149.5,347.1c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
    <path class="cls-3" d="M150.7,346.9s.8,6.7-5.5,9l.4,1c7.1-2.6,6.3-10.2,6.3-10.2h-1.1Z"/>
    <path class="cls-1" d="M151.1,344.7c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8-2.8-1.2-2.8-2.8,1.2-2.8,2.8-2.8Z"/>
  </g>
  <g>
    <path class="cls-6" d="M246.3,403.9c-.7,0-1.4.2-1.9.4-.8-1.5-2.4-2.6-4.2-2.6-1.6,0-2.9.8-3.8,1.9-.6-.3-1.3-.5-2.1-.5-2.2,0-4,1.5-4.5,3.5-.5-.2-1.1-.3-1.7-.3-2.6,0-4.7,2.1-4.7,4.7s.1,1.2.3,1.7c-1.3.8-2.2,2.3-2.2,4s1.6,4.1,3.7,4.6c.2,2.4,2.2,4.3,4.7,4.3,5.7,0,10.9-.6,16.6-.6s4.7-2.1,4.7-4.7-.1-1.1-.3-1.6c1.8-.6,3.2-2.4,3.2-4.4s-1.3-3.8-3.1-4.4c.1-.4.2-.8.2-1.2,0-2.6-2.1-4.7-4.7-4.7Z"/>
    <rect class="cls-4" x="236.9" y="416.9" width="2.7" height="40.4"/>
    <path class="cls-3" d="M231.2,417.2s-.9,8,6.6,10.7l-.4,1.2c-8.5-3.1-7.4-12.1-7.4-12.1h1.3Z"/>
    <path class="cls-3" d="M244.9,416.8s1.8,6.7-5.9,8l-.2-1.3c6.3-1,4.9-6.4,4.9-6.4l1.3-.3Z"/>
    <path class="cls-1" d="M248.3,428.9c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8-2.8-1.2-2.8-2.8,1.2-2.8,2.8-2.8Z"/>
    <path class="cls-1" d="M243.4,430.2c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8-2.8-1.2-2.8-2.8,1.2-2.8,2.8-2.8Z"/>
    <path class="cls-3" d="M244.8,429.9s.9,8-6.6,10.7l.4,1.2c8.5-3.1,7.4-12.1,7.4-12.1h-1.3Z"/>
    <path class="cls-1" d="M245.3,427.3c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3-3.3-1.5-3.3-3.3,1.5-3.3,3.3-3.3Z"/>
  </g>
  <g>
    <path class="cls-1" d="M675.8,143.8c-.6,0-1.2.1-1.7.4-.7-1.3-2-2.2-3.6-2.2s-2.5.7-3.3,1.7c-.5-.3-1.2-.4-1.8-.4-1.9,0-3.5,1.3-3.9,3-.4-.2-.9-.3-1.4-.3-2.2,0-4.1,1.8-4.1,4.1s.1,1,.3,1.5c-1.2.7-1.9,2-1.9,3.5,0,1.9,1.4,3.6,3.2,4,.2,2.1,1.9,3.7,4,3.7,4.9,0,9.4-.6,14.3-.6,2.2,0,4.1-1.8,4.1-4.1,0-.5,0-1-.2-1.4,1.6-.6,2.7-2.1,2.7-3.8,0-1.8-1.1-3.3-2.7-3.8,0-.3.1-.7.1-1.1,0-2.2-1.8-4.1-4.1-4.1Z"/>
    <rect class="cls-4" x="667.6" y="156.2" width="2.6" height="26.3"/>
    <path class="cls-3" d="M662.1,156.7s-.9,7.8,6.5,10.5l-.4,1.2c-8.3-3.1-7.3-11.8-7.3-11.9h1.3Z"/>
    <path class="cls-3" d="M675.5,152.6s1.8,6.6-5.8,7.8l-.2-1.3c6.2-1,4.8-6.2,4.8-6.3l1.2-.3Z"/>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.cls-1 {
        fill: #a3cc50;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke-width: 0px;
      }

      .cls-1, .cls-3, .cls-5, .cls-6, .cls-7 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: none;
      }

      .cls-3, .cls-4 {
        fill: #2c3b2e;
      }

      .cls-5 {
        fill: #628f09;
      }

      .cls-6 {
        fill: #f2a100;
      }

      .cls-7 {
        fill: #f6ff09;
      }
</style>
