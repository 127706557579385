<template>
  <div id="office" class="layer home">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <polygon v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-26" points="209.2 443.4 209.2 396.5 213.8 396.5 213.8 381.5 202.4 381.5 202.4 377.8 199.4 377.8 199.4 351.9 153.2 351.9 153.2 377.8 150.3 377.8 150.3 381.5 137.9 381.5 137.9 396.5 142.4 396.5 142.4 565.2 199.2 565.2 199.2 565.3 227.4 565.3 227.4 443.4 209.2 443.4"/>
  <g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <rect class="cls-7" x="147.1" y="392" width="56.8" height="168.2"/>
      <rect class="cls-7" x="203.9" y="448.4" width="18.2" height="111.9"/>
      <g>
        <rect class="cls-20" x="155.2" y="396.3" width="40.5" height="109"/>
        <rect class="cls-7" x="155.2" y="450.6" width="40.5" height="2"/>
        <rect class="cls-7" x="155.2" y="468.9" width="40.5" height="2"/>
        <rect class="cls-7" x="155.2" y="432.3" width="40.5" height="2"/>
        <rect class="cls-7" x="155.2" y="414.1" width="40.5" height="2"/>
        <rect class="cls-7" x="155.2" y="487.3" width="40.5" height="2"/>
        <rect class="cls-7" x="167.4" y="393.4" width="2" height="113.5"/>
        <rect class="cls-7" x="181.6" y="393.3" width="2" height="113.5"/>
      </g>
      <g>
        <rect class="cls-13" x="156.9" y="517.5" width="37.1" height="42.7"/>
        <rect class="cls-13" x="153.5" y="517.5" width="43.9" height="3.9"/>
        <rect class="cls-23" x="161.6" y="525.7" width="27.9" height="34.5"/>
        <rect class="cls-7" x="163.4" y="527.8" width="24.3" height="32.4"/>
        <rect class="cls-23" x="174.9" y="527.8" width="1.1" height="32.4"/>
        <polygon class="cls-4" points="163.4 527.8 174.9 527.8 174.9 548.5 163.4 527.8"/>
        <polygon class="cls-4" points="176.1 527.8 187.6 527.8 187.6 548.5 176.1 527.8"/>
      </g>
      <rect class="cls-13" x="142.6" y="386.5" width="65.9" height="5"/>
      <rect class="cls-20" x="203.9" y="507.6" width="11.6" height="34.5"/>
      <rect class="cls-7" x="203.9" y="524" width="16.7" height="1.9"/>
      <rect class="cls-20" x="203.9" y="470.8" width="11.6" height="34.5"/>
      <rect class="cls-7" x="203.9" y="487.2" width="16.7" height="1.9"/>
      <rect class="cls-20" x="203.9" y="452.5" width="11.6" height="16.1"/>
      <rect class="cls-7" x="203.9" y="450.6" width="16.7" height="1.9"/>
    </g>
    <g>
      <rect class="cls-16" x="147.1" y="391.5" width="56.8" height=".5"/>
      <path class="cls-16" d="M188.5,382.8v-3.3h5.6v-22.6h-36.2v22.6h6.3v3.3h-9.2v3.7h42.1v-3.7h-8.6ZM167.5,379.5h17.5v3.3h-17.5v-3.3Z"/>
    </g>
    <g>
      <path class="cls-20" d="M169.7,366.3v-5h-1.8v-1h4.8v1h-1.8v5h-1.2Z"/>
      <path class="cls-20" d="M173.3,366.3v-6h1.2v2.4h2.4v-2.4h1.2v6h-1.2v-2.6h-2.4v2.6h-1.2Z"/>
      <path class="cls-20" d="M179.3,366.3v-6h4.5v1h-3.2v1.3h3v1h-3v1.6h3.4v1h-4.7Z"/>
      <path class="cls-20" d="M161.2,371.4c0-.6,0-1.1.3-1.5.1-.3.3-.6.6-.8.2-.2.5-.4.8-.5.4-.2.8-.2,1.3-.2.9,0,1.6.3,2.1.8s.8,1.3.8,2.3-.3,1.7-.8,2.3c-.5.5-1.2.8-2.1.8s-1.6-.3-2.1-.8-.8-1.3-.8-2.3h-.1ZM162.4,371.3c0,.7.2,1.2.5,1.5.3.4.7.5,1.2.5s.9-.2,1.2-.5.5-.9.5-1.6-.2-1.2-.5-1.5-.7-.5-1.2-.5-.9.2-1.2.5-.5.9-.5,1.5h0Z"/>
      <path class="cls-20" d="M167.9,374.3v-6h4.1v1h-2.9v1.4h2.5v1h-2.5v2.6h-1.2Z"/>
      <path class="cls-20" d="M172.9,374.3v-6h4.1v1h-2.9v1.4h2.5v1h-2.5v2.6h-1.2Z"/>
      <path class="cls-20" d="M177.9,374.3v-6h1.2v6h-1.2Z"/>
      <path class="cls-20" d="M184.1,372.1l1.2.4c-.2.7-.5,1.1-.9,1.5-.4.3-1,.5-1.6.5s-1.5-.3-2-.8-.8-1.3-.8-2.2.3-1.8.8-2.3c.5-.6,1.2-.8,2-.8s1.3.2,1.8.7c.3.3.5.6.6,1.1l-1.2.3c0-.3-.2-.6-.4-.7-.2-.2-.5-.3-.8-.3s-.8.2-1.1.5-.4.8-.4,1.6.1,1.3.4,1.6.6.5,1.1.5.6-.1.8-.3c.2-.2.4-.5.5-1v-.3Z"/>
      <path class="cls-20" d="M186.2,374.3v-6h4.5v1h-3.2v1.3h3v1h-3v1.6h3.4v1h-4.7Z"/>
    </g>
    <rect class="cls-16" x="153.5" y="521.3" width="43.9" height=".4"/>
  </g>
  <g>
    <path class="cls-9" d="M197.9,559c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.4-1,.5-2,.1-3-.9.4-1.7,1.1-2.2,2,0,0,0,.2-.1.3,0,0,0-.2-.1-.3-.4-1-1.2-1.7-2.2-2-.3.9-.3,2,.1,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h-.1ZM209.3,559c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
    <path class="cls-12" d="M203.6,551.2c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
  </g>
  <g>
    <path class="cls-9" d="M209.6,559c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.5-1,.5-2,.1-3-.9.4-1.7,1.1-2.2,2,0,0,0,.2-.1.3,0,0,0-.2-.1-.3-.5-1-1.2-1.7-2.2-2-.3.9-.3,2,.1,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h-.1ZM221.1,559c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
    <path class="cls-12" d="M215.3,551.2c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
  </g>
  <g>
    <polygon class="cls-2" points="172.9 580.6 170 582 168.4 576 172.9 574.7 172.9 580.6"/>
    <g>
      <g>
        <rect class="cls-10" x="168.4" y="576" width="1.6" height="6.1"/>
        <path class="cls-25" d="M171.6,574.9c.3-1.4-2.4-1.1-2,.7"/>
      </g>
      <g>
        <g>
          <rect class="cls-20" x="161.1" y="559.4" width="4" height="10.9"/>
          <path class="cls-21" d="M163,546c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8-3.8,1.7-3.8,3.8,1.7,3.8,3.8,3.8Z"/>
          <path class="cls-8" d="M160.8,553.6h4.4v4c0,2.9-4.4,2.9-4.4,0v-4Z"/>
          <path class="cls-5" d="M165,558.5l-4.2-4.1v-.8h4.4v4c0,.4,0,.7-.2.9h0Z"/>
          <path class="cls-8" d="M163,542.2c1.3,0,5,0,5.1,4.6,0,4.4-.9,6-1.5,6.7s-1.2,1.3-2.4,1.6c-.3.3-.7.4-1.2.5-.5,0-.9-.2-1.2-.5-1.2-.3-1.8-.9-2.4-1.6-.6-.7-1.5-2.3-1.5-6.7s3.9-4.5,5.1-4.6h0Z"/>
          <path class="cls-19" d="M163,542.2c1.3,0,5,0,5.1,4.6,0,4.4-.9,6-1.5,6.7s-1.2,1.3-2.4,1.6c-.3.3-.7.4-1.2.5v-13.3h0Z"/>
          <path class="cls-8" d="M158.6,549c-.1-.3-.3-.5-.6-.6-.4,0-1,0-1,1.1s.9,1.9,1.4,1.8c.5,0,.3-2.1.2-2.4h0Z"/>
          <path class="cls-19" d="M167.4,549c.2-.3.3-.5.7-.6.4,0,1,0,1,1.1s-.9,1.9-1.4,1.8c-.5,0-.3-2.4-.3-2.4h0Z"/>
          <path class="cls-21" d="M165.8,544.7c.5,1,.5,2.7,2.4,3.7,1.3-4.8-2.1-7-5.3-7s-6.5,2.4-5.5,7.1c2.3-.5,3.5-1.6,4.6-2.9-.4,1.1-1.1,1.6-.9,1.6,2.2-.5,3-.7,4.7-2.5h0Z"/>
          <g>
            <g>
              <path class="cls-12" d="M159.9,584h2.3c.3.9.2,1.6,0,2.6h-.6v-.4c0-.2-.2-.4-.4-.4h0c-.2,0-.4.2-.4.4v.4h-3.5c0-.6.4-1,.8-1.4.6-.5,1.7-.9,1.9-1.2h-.1Z"/>
              <path class="cls-8" d="M162.3,570.2h-2.7l.2,13.8c-.2.3-1.1.6-1.7,1.2,1.5.3,3.1,0,4.2-1.2v-13.8Z"/>
            </g>
            <g>
              <path class="cls-12" d="M166.2,584h-2.3c-.3.9-.2,1.6,0,2.6h.6v-.4c0-.2.2-.4.4-.4h0c.2,0,.4.2.4.4v.4h3.5c0-.6-.4-1-.8-1.4-.6-.5-1.7-.9-1.9-1.2h.1Z"/>
              <path class="cls-8" d="M163.8,570.2h2.7l-.2,13.8c.2.3,1.1.6,1.7,1.2-1.5.3-3.1,0-4.2-1.2v-13.8Z"/>
            </g>
          </g>
          <rect class="cls-11" x="158.2" y="568.1" width="9.6" height="8.4"/>
          <path class="cls-1" d="M157.8,570.4h3.3v-11h1.1l-2-2.8h-.1c-1.2,0-2.2,1.1-2.2,2.3v11.5h-.1ZM165,570.4h3.3v-11.5c0-1.3-1-2.3-2.3-2.3h0l-2.1,2.8h1.1v11Z"/>
          <g>
            <path class="cls-8" d="M156.3,573.4l-.2-.5v1.8c0,.2-.1.4-.4.5h0c-.2,0-.4-.2-.5-.4v-.2h0c-.2,0-.4-.2-.5-.4v-.2c-.1,0-.2-.2-.2-.4v-2.7c-.1,0,0,0,0-.2v-.8l2.1.9v1.1l.5,1.3c0,.2,0,.5-.3.6h0c-.2,0-.5,0-.6-.3h0Z"/>
            <polygon class="cls-18" points="154.4 570.6 156.7 570.6 156.7 572 154.4 571.9 154.4 570.6"/>
            <path class="cls-1" d="M154.2,570.8l2.6.5,2.3-14.4c-1.9-.3-2.9,1.5-3,2.4l-1.9,11.6h0Z"/>
          </g>
          <g>
            <path class="cls-8" d="M169.8,573.4l.2-.5v1.8c0,.2.1.4.4.5h0c.2,0,.4-.2.5-.4v-.2h0c.2,0,.4-.2.5-.4v-.2c.1,0,.2-.2.2-.4v-2.7c.1,0,0,0,0-.2v-.8l-2.1.9v1.1l-.5,1.3c0,.2,0,.5.3.6h0c.2,0,.5,0,.6-.3h0Z"/>
            <polygon class="cls-18" points="171.6 570.6 169.3 570.6 169.3 572 171.6 571.9 171.6 570.6"/>
            <path class="cls-1" d="M171.9,570.8l-2.6.5-2.3-14.4c1.9-.3,2.9,1.5,3,2.4l1.9,11.6h0Z"/>
          </g>
          <polygon class="cls-18" points="165.2 556.6 163 559.8 160.8 556.6 160.1 556.6 159.1 559.9 161 559.4 162.2 560.6 163.8 560.6 165 559.4 167 559.9 166 556.6 165.2 556.6"/>
          <path class="cls-18" d="M164.9,551.7c-.1.9-.9,1.7-1.8,1.7s-1.7-.7-1.8-1.7h3.6Z"/>
        </g>
        <g>
          <path class="cls-21" d="M165.1,547.8c-.1,0-.2,0-.2-.2s0-.2.2-.2h.8c.1,0,.2.2.1.3,0,0-.2.2-.3,0h-.6Z"/>
          <path class="cls-21" d="M161.1,547.4c.1,0,.2,0,.2.2s0,.2-.2.2h-.9s0-.2.1-.3h.8Z"/>
          <g>
            <g>
              <path class="cls-21" d="M165.9,549.1h.2v-.2h0c-.3-.2-.8-.6-1.5,0v.2h.2c.6-.5.9-.2,1.2,0h0Z"/>
              <path class="cls-21" d="M165.3,548.7c-.2,0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4Z"/>
              <path class="cls-21" d="M166,548.6h-.1v.3c-.2,0-.1,0-.1,0h.1v-.3h.1Z"/>
              <path class="cls-21" d="M166.1,548.7h-.1l-.2.3h.1l.2-.3Z"/>
            </g>
            <g>
              <path class="cls-21" d="M160.1,549.1h-.2v-.2h0c.3-.2.8-.6,1.5,0v.2h-.2c-.6-.5-.9-.2-1.2,0h0Z"/>
              <path class="cls-21" d="M160.8,548.7c.2,0,.4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4Z"/>
              <path class="cls-21" d="M160.1,548.6h.1v.3c.2,0,.1,0,.1,0h-.1v-.3h-.1Z"/>
              <path class="cls-21" d="M159.9,548.7h.1l.2.3h-.1l-.2-.3Z"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <polygon class="cls-22" points="170 576 168.4 576 171.8 574.5 172.9 574.7 170 576"/>
  </g>
  <g>
    <polygon class="cls-2" points="173.8 582.9 178.3 584.2 176.7 590.2 173.8 588.8 173.8 582.9"/>
    <g>
      <rect class="cls-10" x="176.7" y="584.2" width="1.6" height="6.1"/>
      <path class="cls-25" d="M177.1,583.8c.4-1.8-2.3-2.1-2-.7"/>
    </g>
    <polygon class="cls-22" points="173.8 582.9 174.9 582.7 178.3 584.2 176.7 584.2 173.8 582.9"/>
    <g>
      <g>
        <g>
          <path class="cls-24" d="M188.6,591.8h-4.1c-.4,1-.3,2-.3,3h7.1c0-1.6-2.8-2-2.8-3h.1Z"/>
          <rect class="cls-10" x="184.2" y="594.3" width="7.3" height=".6"/>
          <path class="cls-24" d="M178.7,591.8h4.1c.4,1,.3,2,.3,3h-7.1c0-1.6,2.8-2,2.8-3h-.1Z"/>
          <rect class="cls-10" x="175.8" y="594.3" width="7.3" height=".6"/>
        </g>
        <path class="cls-15" d="M188.8,578.7h-10.3v13.4h4.7l-.4-11.5h1.7l-.4,11.5h4.7v-13.4Z"/>
        <path class="cls-24" d="M183.6,582.3h.2l5.3-.5-.4-14.4c0-1.3-1-2.3-2.3-2.3h-5.6c-1.3,0-2.4,1-2.4,2.3l-.4,14.4,5.5.5h0Z"/>
        <path class="cls-8" d="M181.6,563.9h4.2v1.4c-.3.9-1.1,1.7-2.1,1.7s-1.9-.8-2.1-1.7v-1.4h0Z"/>
        <path class="cls-5" d="M181.6,564.3l4.1-.3v1.4c-.2.8-.8,1.4-1.6,1.6l-2.6-2.6h.1Z"/>
        <path class="cls-8" d="M175.3,579.2v2.7c.1.2.2.3.3.4v.2c0,.2.2.4.5.4h0v.2c0,.2.2.4.5.4h0c.2,0,.4-.2.4-.5v-1.8.5c.1.2.4.3.7.3h0c.2,0,.3-.3.3-.6l-.5-1.3h0v-.7l-1.3-.4c-.1-.4-.9-.4-.8,0h-.1v.2Z"/>
        <polygon class="cls-18" points="177.7 579.4 177.5 580.3 175.2 579.9 175.3 578.9 177.7 579.4"/>
        <path class="cls-24" d="M175.2,579.3l2.5.5,2.2-14.3c-1.9-.3-2.9,1.5-3,2.3l-1.7,11.5Z"/>
        <path class="cls-8" d="M192,579.2v2.7c-.1.2-.2.3-.3.4v.2c0,.2-.2.4-.5.4h0v.2c0,.2-.2.4-.5.4h0c-.2,0-.4-.2-.4-.5v-1.8.5c-.1.2-.4.3-.7.3h0c-.2,0-.3-.3-.3-.6l.5-1.3h0v-.7l1.3-.4c.1-.4.9-.4.8,0h.1v.2Z"/>
        <polygon class="cls-18" points="189.6 579.4 189.8 580.3 192.1 579.9 192 578.9 189.6 579.4"/>
        <path class="cls-24" d="M192.1,579.3l-2.5.5-2.2-14.3c1.9-.3,2.9,1.5,3,2.3l1.7,11.5Z"/>
        <g>
          <path class="cls-8" d="M183.7,552.5c-1.2,0-4.9,0-5,4.5,0,4.3.9,5.9,1.4,6.5.6.6,1.2,1.2,2.4,1.5.3.3.6.4,1.2.5v-13h0Z"/>
          <path class="cls-19" d="M183.7,552.5c1.2,0,4.9,0,5,4.5,0,4.3-.9,5.9-1.4,6.5-.6.6-1.2,1.2-2.4,1.5-.3.3-.6.4-1.2.5v-13h0Z"/>
        </g>
        <path class="cls-6" d="M187,554.6c-.2-.4-.5-.7-1.7-.3-1.2.5-3.1,1.6-5.3,1.4-.7,1.4-.5,3.9-.5,3.9l-1.2-.8s0-2.8.3-3.8c.6-1.7,2.4-3.5,5.1-3.5s3.5,1.4,3.9,1.9c1.7,1.5,1.6,3.2,1.4,5.3l-1.2.8c0-.9-.2-4-.8-5h0Z"/>
        <path class="cls-8" d="M179.3,559.1c-.1-.3-.2-.5-.6-.6-.4,0-1,0-1,1.1s.8,1.9,1.4,1.8c.5,0,.3-2,.2-2.3h0Z"/>
        <path class="cls-19" d="M187.9,559.1c.1-.3.3-.5.7-.6s1,0,1,1.1-.8,1.9-1.4,1.8c-.5,0-.3-2.3-.3-2.3Z"/>
        <polygon class="cls-14" points="182.6 567.5 183.7 575.2 184.7 567.5 183.7 567 182.6 567.5"/>
        <g>
          <polygon class="cls-12" points="183.3 567.9 182.9 569.7 183.7 575.2 183.7 567.9 183.7 567 183.7 567 182.8 567.4 183.3 567.9"/>
          <polygon class="cls-17" points="184 567.9 184.4 569.7 183.7 575.2 183.7 567.9 183.7 567 184.5 567.4 184 567.9"/>
        </g>
        <polygon class="cls-14" points="183.7 567 185 567.6 186 565.2 185.7 565.2 183.7 567"/>
        <polygon class="cls-14" points="183.7 567 182.4 567.6 181.4 565.2 181.5 565.2 183.7 567"/>
        <path class="cls-18" d="M185.4,561.9c-.1.9-.9,1.6-1.8,1.6s-1.6-.7-1.8-1.6h3.6Z"/>
      </g>
      <g>
        <path class="cls-6" d="M180.6,557.7h1.3c.2,0,.3,0,.3-.3h0c0-.2-.1-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2.1.3.3.3Z"/>
        <path class="cls-6" d="M185.6,557.7h1.3c.2,0,.3,0,.3-.3h0c0-.2-.1-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2.1.3.3.3Z"/>
        <g>
          <path class="cls-6" d="M186.3,558.6c-.3,0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5Z"/>
          <path class="cls-6" d="M186.9,559h.2v-.2h0c-.3-.3-.8-.7-1.6,0v.2h.2c.6-.5,1-.2,1.3,0h0Z"/>
          <path class="cls-6" d="M181.1,558.6c.3,0,.5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"/>
          <path class="cls-6" d="M180.5,559h-.2v-.2h0c.3-.3.8-.7,1.6,0v.2h-.2c-.6-.5-1-.2-1.3,0h0Z"/>
        </g>
      </g>
    </g>
    <circle class="cls-3" cx="184.7" cy="577.1" r=".5"/>
    <circle class="cls-3" cx="184.7" cy="578.9" r=".5"/>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('Office')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
.cls-1 {
        fill: #9cc;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-4, .cls-5, .cls-6, .cls-8, .cls-9, .cls-12, .cls-14, .cls-15, .cls-17, .cls-18, .cls-19, .cls-21, .cls-22, .cls-24 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #262117;
      }

      .cls-3, .cls-15 {
        fill: #666;
      }

      .cls-4, .cls-13 {
        fill: #4d5975;
      }

      .cls-5 {
        fill: #d4a15e;
      }

      .cls-6 {
        fill: #471e04;
      }

      .cls-7 {
        fill: #5a698a;
      }

      .cls-8 {
        fill: #f2d291;
      }

      .cls-9 {
        fill: #8bb408;
      }

      .cls-25 {
        fill: none;
        stroke: #000;
        stroke-width: .3px;
      }

      .cls-25, .cls-26 {
        stroke-miterlimit: 10;
      }

      .cls-10, .cls-22 {
        fill: #000;
      }

      .cls-11 {
        fill: #699;
      }

      .cls-12 {
        fill: #f1502d;
      }

      .cls-14 {
        fill: #c9eaf0;
      }

      .cls-16 {
        fill: #2f3233;
      }

      .cls-17 {
        fill: #c34125;
      }

      .cls-18, .cls-20, .cls-26 {
        fill: #fff;
      }

      .cls-19 {
        fill: #edba68;
      }

      .cls-21 {
        fill: #1a1a1a;
      }

      .cls-23 {
        fill: #363e52;
      }

      .cls-26 {
        stroke: #fff;
      }

      .cls-24 {
        fill: #333;
      }
</style>
