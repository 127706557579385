<template>
  <div id="clouds" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
      <g id="cloud-group">
        <path class="cls-1" d="M139.1,151.7c-.7,0-1.4.1-2,.4v-.6c0-2.2-1.8-4-4-4s-1.7.3-2.3.7c-.8-3.5-3.9-6-7.6-6s-6.8,2.6-7.6,6c-.9-.8-2.1-1.2-3.4-1.2-2.5,0-4.6,1.7-5.1,4h-.3c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3h32.2c2.7,0,5-2.2,5-5s-2.2-5-5-5h.1Z"/>
        <path class="cls-1" d="M156.7,133.7c.3,0,.7,0,1,.2v-.3c0-1.1.9-2,2-2s.8.1,1.1.4c.4-1.7,1.9-3,3.7-3s3.3,1.3,3.7,3c.5-.4,1-.6,1.7-.6,1.2,0,2.2.8,2.5,2h.1c1.4,0,2.6,1.2,2.6,2.6s-1.2,2.6-2.6,2.6h-15.9c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4h0Z"/>
        <path class="cls-1" d="M379.6,158.6c.8,0,1.6.2,2.3.5v-.7c0-2.6,2.1-4.6,4.6-4.6s1.9.3,2.7.9c.9-4,4.5-7.1,8.9-7.1s7.9,3,8.8,7c1.1-.9,2.5-1.4,4-1.4,2.9,0,5.3,2,6,4.7h.3c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2h-37.6c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8h0Z"/>
        <path class="cls-1" d="M354.2,138.5c.3,0,.7,0,1,.2v-.3c0-1.1.9-2,2-2s.8.1,1.1.4c.4-1.7,1.9-3,3.7-3s3.3,1.3,3.7,3c.5-.4,1-.6,1.7-.6,1.2,0,2.2.8,2.5,2h.1c1.4,0,2.6,1.2,2.6,2.6s-1.2,2.6-2.6,2.6h-15.9c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4h0Z"/>
        <path class="cls-1" d="M32.1,105.9c-.5,0-.9,0-1.3.3v-.4c0-1.5-1.2-2.6-2.6-2.6s-1.1.2-1.5.5c-.5-2.3-2.6-4-5-4s-4.5,1.7-5,4c-.6-.5-1.4-.8-2.2-.8-1.6,0-3,1.1-3.4,2.6h-.2c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5h21.3c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3h0Z"/>
        <path class="cls-1" d="M67.9,87.2c-.7,0-1.3,0-1.8.4v-.5c0-2-1.6-3.7-3.7-3.7s-1.5.3-2.1.7c-.7-3.2-3.6-5.6-7-5.6s-6.2,2.4-7,5.5c-.8-.7-1.9-1.1-3.1-1.1-2.3,0-4.2,1.6-4.7,3.7h-.2c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9h29.6c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5v-.2Z"/>
        <path class="cls-2" d="M577.7,154.4c-.3,0-.7,0-1,.2v-.3c0-1.1-.9-1.9-1.9-1.9s-.8.1-1.1.4c-.4-1.7-1.9-3-3.7-3s-3.3,1.3-3.7,2.9c-.5-.4-1-.6-1.7-.6-1.2,0-2.2.8-2.5,2h0c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h15.8c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4h0Z"/>
        <path class="cls-2" d="M536.3,133.3c.6,0,1.1.1,1.6.3v-.5c0-1.7,1.4-3.1,3.1-3.1s1.3.2,1.8.6c.6-2.7,3.1-4.8,6-4.8s5.4,2,6,4.7c.7-.6,1.7-1,2.7-1,2,0,3.6,1.3,4.1,3.2h.2c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2h-25.5c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9h0Z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){
    gsap.fromTo('#cloud-group', 200, {x:-590},{x:700, repeat:-1, ease:'none'});
  }
}
</script>

<style scoped>
  .cls-1 {
    fill: #c9ebf5;
  }

  .cls-1, .cls-2 {
    fill-rule: evenodd;
    stroke-width: 0px;
  }

  .cls-2 {
    fill: #c9ebf5;
  }   
</style>
