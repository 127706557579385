<template>
  <div id="radon" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
      <g id="radguy">
      <g id="rleg">
        <path class="cls-30" d="M332.2,520.9c-1-.8-2.3.2-2.3.2,0,0,0-.4-.4-.6h0c.3-.4.3-.9.1-1.4-.3-.7-1-1-1.6-.8-.2,0-.4.2-.5.4-.5.2-.7.6-.7.6,0,0-.8,5.8,5.4,3.9,0,0,1-1.6,0-2.3"/>
        <path class="cls-23" d="M330,523.8c-.9,0-1.6-.2-2.1-.7-1.5-1.2-1.1-3.6-1.1-3.7,0,0,.2-.5.7-.7.1-.2.3-.4.5-.4.3,0,.7,0,1,0,.3.2.6.4.8.8s.2,1,0,1.4c.2,0,.3.3.3.4.4-.2,1.4-.7,2.3,0h0c.9.7.4,1.9,0,2.5h0c-.9.3-1.6.4-2.3.4h0ZM328.5,518.4h-.4c-.2,0-.3.2-.4.4h0c-.5.2-.6.5-.6.5,0,0-.3,2.3,1,3.5.9.7,2.3.8,4.2.2.1-.2.8-1.5,0-2.1h0c-.9-.7-2.1.2-2.1.2h-.2c0,0,0,0,0,0,0,0,0-.4-.3-.5h0v-.2h0c.2-.4.3-.8.1-1.3-.1-.3-.4-.5-.6-.7-.1,0-.3,0-.5,0h-.2Z"/>
        <path class="cls-34" d="M329.2,523.6c.8.2,1.8,0,3.1-.3,0,0,1-1.6,0-2.3-1-.8-2.3.2-2.3.2,0,0-1.4.8-.8,2.5"/>
        <path class="cls-23" d="M330,523.8h-.8c-.6-1.7.8-2.6.8-2.6,0,0,1.3-1,2.4-.2.9.7.4,1.9,0,2.5h0c-.9.3-1.6.4-2.3.4h-.1ZM329.3,523.5c.8,0,1.8,0,2.9-.3.1-.2.8-1.5,0-2.1-.9-.7-2.1.2-2.1.2,0,0-1.2.8-.8,2.3h0Z"/>
        <path class="cls-34" d="M326.6,519.5h0c-.2.9-.1,3.3,1.1,4.1,1.3.8,3.1.6,3.1.6.9,0,1.3-.3,1.4-.3,0,0,.1,0,.1,0v-.5c-6.2,2-5.4-3.9-5.4-3.9-.1,0-.2,0-.3,0"/>
        <path class="cls-23" d="M330.2,524.4c-.7,0-1.7,0-2.6-.7-1.3-.9-1.4-3.4-1.2-4.1,0,0,0,0,.1-.2h0s.2,0,.3-.2h.2c0,0-.3,2.4,1,3.6.9.8,2.3.8,4.2.2h.1s.1.3,0,.6c0,0-.1.2-.2.2-.2,0-.6.2-1.5.3h-.6l.2.3ZM326.7,519.6h0c-.2.8,0,3.1,1.1,3.9,1.2.8,3,.6,3,.6.9,0,1.3-.3,1.4-.3h0v-.3c-1.9.6-3.4.5-4.3-.3-1.2-1-1.2-2.9-1.1-3.5h-.1Z"/>
        <path class="cls-21" d="M327.7,518.8v.8h0c-.2-.2-.2-.4-.2-.6v-.2c0-.2.3,0,.3,0"/>
        <path class="cls-21" d="M332.7,514.5c.2,2.6-.9,5.1-3.6,5.6-.9.2-1.2-1.1-.3-1.3h.3c.8,0,1.6-.6,2.1-1.4.4-.6.6-1.3.7-2.1v-.8c0-.6.8-.7.9,0"/>
      </g>
      <g id="lleg">
        <path class="cls-30" d="M341.2,520.8c1.1,0,1.3,1,1.3,1.7s-.4.9-.4.9c-3.6.9-6.3-.2-6.3-.2,0,0-.5-.9.1-2-.1,0-.2-.2-.2-.3,0,0-.2-.4.3-.7s1.5-.4,2.1,0c0,0,.5,0,.5.6s0,.3-.2.4c.2,0,.7,0,.9.3,0,0,.6-.8,1.8-.7"/>
        <path class="cls-23" d="M339.2,524c-2.1,0-3.5-.5-3.5-.5h0s-.5-.9,0-2.1c0,0-.2-.2-.2-.3,0-.2,0-.6.4-.9.5-.3,1.6-.4,2.2,0,0,0,.6.2.6.7v.3c.2,0,.5,0,.7.2.2-.2.8-.7,1.8-.6h0c1.1,0,1.5,1,1.4,1.9,0,.7-.4,1-.4,1h0c-1.1.3-2.1.4-2.9.4h-.1ZM335.9,523.2c.4,0,3,.9,6.2,0,0,0,.3-.3.3-.8s-.2-1.5-1.2-1.6h0c-1.1,0-1.7.6-1.7.6v.2c-.1,0-.2-.2-.2-.2-.2-.3-.7-.3-.8-.3h-.3l.2-.2s.1-.2.1-.3c0-.3-.4-.4-.4-.5-.6-.2-1.6-.2-2,0-.4.2-.3.5-.3.6,0,0,0,0,.2.2h.1v.2c-.5.9-.2,1.7-.1,1.9h-.1Z"/>
        <path class="cls-29" d="M342.1,523.5c-3.6.9-6.3-.2-6.3-.2,0,0-.2.3,0,.6,0,0,0,0,.2,0,3.1.8,5.4.3,6.2,0,0,0,.1,0,.2,0,.1-.3,0-.5,0-.5"/>
        <path class="cls-23" d="M339.2,524.6c-1,0-2.1,0-3.4-.5-.1,0-.2,0-.2-.2-.1-.3,0-.7,0-.7h.1s2.8,1,6.3.2h0s.2.3,0,.6c0,0-.1.2-.2.2-.4,0-1.4.4-2.8.4h.2ZM335.9,523.5v.4h0c3.1.8,5.4.3,6.1,0h0v-.3c-3.1.7-5.6,0-6.2,0h.1Z"/>
        <path class="cls-29" d="M342.1,523.5s.3-.3.4-.9c0-.7-.2-1.6-1.3-1.7-1.2-.2-1.8.7-1.8.7,0,0-.8,1,.1,2.3.8,0,1.6,0,2.5-.3"/>
        <path class="cls-23" d="M339.5,524h0c-1-1.5-.1-2.5-.1-2.6,0,0,.6-.9,1.9-.7,1.1,0,1.5,1,1.4,1.9,0,.7-.4,1-.4,1h0c-.8.2-1.7.3-2.6.4h-.2ZM340.9,521c-.9,0-1.4.6-1.4.6,0,0-.7.9.1,2.1.8,0,1.6,0,2.4-.3,0,0,.3-.3.3-.8s-.2-1.5-1.2-1.6h-.3.1Z"/>
        <path class="cls-21" d="M335.9,521.2c.3,0,.6.2.8.2h-.8c-.1,0-.2,0-.2-.2h.2"/>
        <path class="cls-21" d="M336.6,514.7c1.8,1.7,2.6,4.1,1.1,6.2s-1.6,0-1.2-.8v-.2c.6-.6.8-1.4.7-2.2-.1-.7-.4-1.3-.8-1.8-.1-.2-.3-.4-.5-.5-.4-.4.2-1.1.7-.7"/>    
      </g>
      <polygon class="cls-4" points="341.4 502.9 335.8 496.9 329.4 502.9 329.7 503 329.7 516.6 341.3 516.6 341.3 503.4 341.4 502.9"/>
      <polygon class="cls-8" points="329.7 503.5 326.7 503 326.7 514.6 329.7 516.6 329.7 503.5"/>
      <polygon class="cls-23" points="324.7 503.2 331.8 496.4 336 495.7 343.6 503.4 342.4 504.1 336 497.6 329.3 504.1 324.7 503.2"/>
      <path class="cls-35" d="M333.9,508.8s.6.6,1.5.7c.5,0,1,0,1.6-.3.3-.2.5-.6.3-.9s-.6-.5-.9-.3h-.8c-.4,0-.7-.3-.7-.3-.3-.3-.8-.3-1.1,0-.3.3-.3.8,0,1.1"/>
      <path class="cls-23" d="M335.8,509.7h-.4c-.9-.1-1.6-.8-1.6-.8h0c-.3-.3-.3-.9,0-1.2.3-.3.9-.3,1.2,0h0s.3.2.6.3h.7c.2,0,.4-.1.6,0,.2,0,.4.2.5.4.2.4,0,.9-.3,1.1-.4.2-.9.3-1.3.3h0ZM334,508.7s.6.6,1.4.7c.6,0,1.1,0,1.6-.3.3-.1.4-.5.2-.7,0-.1-.2-.2-.3-.3h-.4c-.3,0-.6.1-.8,0-.4,0-.8-.3-.8-.4-.2-.3-.6-.3-.9,0s-.3.6,0,.9h0Z"/>
      <path class="cls-30" d="M333.7,508.2c.4,0,1.1-.2,1.7.4,0,0,1.3-.3,1.4.7-.5.2-.9.2-1.3.2-.9-.1-1.5-.7-1.5-.7-.2-.2-.2-.4-.2-.6"/>
      <path class="cls-23" d="M335.8,509.7h-.4c-.8-.1-1.4-.6-1.6-.8h0c-.2-.2-.3-.4-.2-.7h0c.4-.2,1.2-.3,1.8.3.2,0,.7-.1,1.1.2.2.2.3.4.4.7h0c-.3.2-.7.3-1,.3h0ZM334,508.7s.6.6,1.4.7c.4,0,.8,0,1.2-.1,0-.2-.1-.3-.2-.4-.4-.2-1,0-1,0h0c-.4-.5-1-.5-1.4-.4,0,.1,0,.3.2.4h0l-.2-.2Z"/>
      <path class="cls-30" d="M335.4,508.6s.2.3.2.6"/>
      <path class="cls-21" d="M335.5,508.6c.1.2.2.5.1.7h0c0-.2,0-.4-.2-.6,0-.1,0-.2.2-.1"/>
      <path class="cls-29" d="M335.1,504.1c0,.9-.2,1.6-.3,2.1h-1.8c-.1-.5-.3-1.3-.3-2.1,0-1.5.8-1.7,1.2-1.7h0c.4,0,1.2.1,1.2,1.7"/>
      <path class="cls-23" d="M333,506.4h0c-.1-.7-.3-1.4-.3-2.3s.1-1.1.4-1.4.5-.4.9-.4.7.1.9.4c.3.3.4.8.4,1.5h0c0,.9-.2,1.6-.3,2.2h0c0,.1-.1,0-.1,0-.5-.1-1.1-.1-1.7,0h-.2ZM334,506h.8c.1-.5.3-1.2.3-2h0c0-.6,0-1-.3-1.3s-.4-.3-.7-.3-.5.1-.7.3c-.2.3-.3.7-.3,1.3s.2,1.5.3,2h.8-.2Z"/>
      <path class="cls-35" d="M334,506.1h.1c0-.4.2-.9.2-1.5l-.7-.3.7-.3c0-1.2-.8-1.3-1.1-1.3h-.2c-.2.2-.4.6-.3,1.3,0,.9.2,1.6.3,2.1h.9"/>
      <path class="cls-21" d="M333.1,506.3c-.1,0-.2.1-.4.1h0c0-.1.1-.2.2-.3h.2"/>
      <path class="cls-21" d="M334.8,506.1c.2,0,.3.1.4.3h0c-.1,0-.3-.1-.4-.1s0-.2,0-.2"/>
      <path class="cls-29" d="M336.2,504.1c0,.9.2,1.6.3,2.1h1.8c.1-.5.3-1.3.3-2.1,0-1.5-.8-1.7-1.2-1.7h0c-.4,0-1.2.1-1.2,1.7"/>
      <path class="cls-23" d="M338.4,506.4h-.1c-.5-.2-1.1-.2-1.7,0h-.1c-.1-.5-.3-1.3-.3-2.2h0c0-.7.1-1.1.4-1.5.2-.2.5-.4.9-.4s.7.1.9.4c.3.3.4.8.4,1.4,0,.9-.2,1.6-.3,2.2h0ZM337.4,502.5c-.3,0-.6.1-.7.3-.2.3-.4.7-.3,1.3h0c0,.8.2,1.5.3,2h1.5c.1-.5.3-1.2.3-2s0-1-.3-1.3-.4-.3-.7-.3h0Z"/>
      <path class="cls-35" d="M337.4,506.1h.1c0-.4.2-.9.2-1.5l-.7-.3.7-.3c0-1.2-.8-1.3-1.1-1.3h-.2c-.2.2-.4.7-.3,1.3,0,.9.2,1.6.3,2.1h.9"/>
      <path class="cls-21" d="M338.3,506.1c.2,0,.3.2.4.3h0c-.1,0-.3-.1-.4-.1s0-.2,0-.2"/>
      <path class="cls-21" d="M336.6,506.3c-.1,0-.2.1-.4.1h0c0-.1.4-.4.5-.2h0"/>
      <path class="cls-21" d="M335.6,507c.3-.3.8-.6,1.2-.3.3.2.3.6,0,.9s-.8.3-1.2.1h0c.4,0,.8,0,1.1-.2.3-.3,0-.7-.4-.7s-.5.2-.7.3h0"/>
      <path class="cls-21" d="M330.3,513.8c-1.5.4-3.4.5-4.8-.4-.4-.3-.7-.6-.8-1-.5-1.6,1.7-3.4,2.8-4.2.3-.3.8-.3,1.1,0,.2.3.1.7-.2,1-.9.6-2.1,1.4-2.5,2.3-.1.3-.2.6,0,.8.4.4,1.1.6,1.7.7.7,0,1.5,0,2.2,0h.1c.6,0,.8.7.2.9"/>
      <g id="rad-arm">
        <path class="cls-21" d="M345.3,511.2c-.2.4-.7.7-1.1.6-.6,0-1-.4-1.5-.7-.5-.4-1-.9-1.4-1.4v-1.9s.1.2.2.3c.5.7,1.1,1.5,1.8,2,.2.2.6.6.9.5s.3-.6.2-.8c0-.4-.2-.8-.3-1.2,0-.3-.2-.7-.2-1s0-.4-.1-.5h0c0-.2,0-.3-.1-.5h0c0-.2-.1-.4-.2-.6,0-.2,0-.4.1-.6.3-.2.7-.2.9.2,0,.2.2.4.2.7h0c0,.2,0,.4.1.5h0c0,.2.1.4.2.6.1.6.3,1.1.4,1.7.1.7.3,1.5,0,2.2"/>
        <g id="rad-hand">
        <path class="cls-34" d="M341.3,507.7c-.3-.4-.1-1.5,1.2-1.5h0v-.8h0c0-.1-.1-.2-.2-.4-.3-.5-.7-.7-.9-.8-.2-.1-.6-.5-.4-.9s.9-.7,1.6.1c.5.6.6,1,.6,1.2h.1c0-.3-.2-.9-.4-1.5-.3-.8-.3-1.2.2-1.4.5-.2,1,.2,1.1,1.3v1.4h.2c.1-.4.3-1.2.4-1.7.1-.7.6-.8,1-.5.5.3.6,1.7-.4,2.8.1,0,.1.2.1.2,0,0,.6.7-.1,1.9,0,0,0,.1-.2.3l.2.3v.3c-.2.2-.5.4-.8.5h-1c-.1,0-.2-.1-.2-.3v-.4h0s-.3-.2-.5-.4c0,0-.1,0-.5.4s-.8.8-1.3.2"/>
        <path class="cls-23" d="M344.2,508.4h-.6c-.2,0-.3-.2-.2-.4v-.3s-.2-.2-.4-.3c0,0-.2.1-.3.3-.2.2-.5.5-.9.5h0c-.2,0-.4-.1-.6-.3h0c-.2-.2-.2-.6,0-1,.1-.3.4-.7,1.2-.7h0v-.8h0c0-.1-.1-.2-.2-.3-.3-.5-.6-.7-.9-.8-.5-.3-.6-.7-.4-1.1.1-.3.4-.4.7-.5.3,0,.7,0,1.1.6.2.3.4.5.5.7,0-.3-.2-.6-.3-.9-.2-.5-.3-.9-.1-1.2,0-.2.2-.3.4-.4h.7c.2.1.5.5.7,1.3v.8c0-.4.2-.8.2-1.1,0-.5.3-.7.4-.7.2,0,.5,0,.7.1.2.2.4.5.4.9,0,.7-.2,1.4-.7,2h0s.7.8-.1,2h0v.2c-.1,0,.1.2.1.2.1.1.1.3,0,.5-.1.1-.4.4-.9.5h-.5v.2ZM343.1,507h0c0,.2.3.4.4.5h0v.6h0c.1.1.5.2.9.1s.7-.4.8-.5h0c0-.1-.3-.5-.3-.5h0c.1-.2.2-.3.2-.3.7-1.1.2-1.7.1-1.7v-.2h-.1v-.2c.5-.5.8-1.3.7-1.9,0-.3-.1-.6-.3-.7-.2-.1-.3-.2-.5-.1-.1,0-.2.2-.3.5,0,.5-.2,1.2-.4,1.7h0c0,.1-.1,0-.1,0h-.3v-.2c0-.3.1-.7,0-1.4,0-.6-.3-1-.5-1.1h-.4c-.1,0-.2.1-.3.2,0,.2,0,.5.1,1,.2.6.4,1.2.4,1.5h0q0,.1-.1.1h-.2c0,.1,0,0,0,0,0-.1,0-.6-.6-1.1-.4-.4-.7-.5-.9-.5s-.4.1-.4.3c-.2.3,0,.6.3.7.3.2.6.4,1,.9.1.2.2.3.2.4h0v.7h0v.2h-.1c-.8,0-1,.4-1.1.6-.1.3,0,.6,0,.7h0c.1.1.2.2.4.2h0c.3,0,.6-.3.7-.5.3-.3.5-.4.5-.4h0l.2.4Z"/>
        <path class="cls-21" d="M345.4,507.2c-.2.2-.5.3-.7.2,0,0-.1,0-.1-.1h0c.2,0,.4,0,.5-.2s.3,0,.2.2"/>
        <path class="cls-21" d="M342.6,506.1c.3,0,.6.2.7.4h0c-.2,0-.5-.2-.7-.2s-.1-.3,0-.3"/>
        <path class="cls-23" d="M343.5,504.5v-.2c.4-.2.9-.2.9-.2v.3s-.5,0-.9.1h0Z"/>
        <path class="cls-21" d="M343.4,504.6c-.2.2-.6.4-.3,0,0,0,0-.1.1-.2s.3,0,.2.2"/>
        <path class="cls-21" d="M344.6,504.1c.1,0,.2,0,.3.2v.2h-.4c-.2,0,0-.3,0-.3"/>
        <path class="cls-34" d="M345.5,504.8s-.1,0-.2-.2"/>
        <path class="cls-21" d="M345.4,504.9h0c-.1-.3,0-.4.1-.3.1,0,.2.1.2.3h-.2"/>
        <path class="cls-21" d="M342.5,505.2c0-.1.2-.2.3-.2.2,0,0,.3,0,.4-.1.1-.3,0-.2-.2"/>
        </g>
      </g>
      <path class="cls-34" d="M335.9,512.2c-.3-.4-1-.3-1.4-.2-.4,0-1.7.4-2.1.2-.1,0-.2,0-.3-.2-.1-.2,0-.5,0-.5h0c0-.1.2-.3.3-.5h0c.2-.6,0-1.3-.6-1.6-.6-.3-.8,0-.8,0h0c-.1.1-.1.3,0,.5.1.4.4.6,0,1.2-.1.2-.3.4-.5.6h0s-.2.1-.2.2h0s-.3.3-.5.9h-.3c-.1,0-.2,0-.3,0,0,.3-.2.9,0,1.6,0,0,.2.2.3,0h.4c.1,0,.2.2.3.3.2.2.7.6,1.4.8.5,0,1.1.2,1.7-.2s.3-.9.3-.9c.4,0,.5-.5.4-.9s-.6-.4-.6-.4c0,0,1.4,0,2,0,.6,0,.9-.6.6-1"/>
      <path class="cls-23" d="M332.2,515.9h-.7c-.7-.2-1.2-.6-1.4-.8-.1,0-.2-.2-.3-.3h-.3c0,0-.2,0-.3,0,0,0-.2,0-.2-.2-.2-.7,0-1.4,0-1.7s.3-.3.4-.2h.2c.2-.5.5-.8.5-.8h.1c.2-.3.3-.4.4-.6.2-.5.1-.7,0-1v-.9l.2-.3h0c.2,0,.4,0,.7.3.6.3.9,1,.7,1.7h0c0,.3-.2.5-.3.6,0,0-.2.2,0,.4h.2c.1,0,.6,0,2-.1h0c.3,0,1.1-.2,1.5.3.2.2.2.6,0,.8-.2.3-.4.4-.8.4h-1.4s.1,0,.2.2c.1.5,0,.8-.3,1v.5c0,.2-.2.4-.4.5-.3.2-.7.3-1.1.3h.4ZM329.9,514.3h0c0,.3.2.4.3.5.2.2.6.6,1.3.7.6,0,1.2,0,1.6-.2.5-.3.3-.7.2-.8h0c0,0,.1-.2.1-.2.3,0,.4-.4.3-.7s-.4-.3-.4-.3v-.3s1.4,0,2,0c.3,0,.4-.2.5-.2.1-.2,0-.4,0-.5-.3-.3-.8-.3-1.3-.2h0c-.8.2-1.8.4-2.1.2-.2,0-.3,0-.3-.2-.2-.3,0-.7,0-.7.1-.1.2-.3.2-.4h.1-.1c.1-.6,0-1.2-.6-1.4-.5-.2-.7,0-.7,0h0v.4h0c.1.4.3.7,0,1.3-.1.2-.3.4-.5.5h0c0,.1-.2.2-.2.2h0c0,.2-.3.4-.4.9h0c0,0-.5,0-.5,0h-.1c0,.3-.2.9,0,1.5h0l.5-.2h.1Z"/>
      <path class="cls-21" d="M333.3,513.4h-.7c.2,0,.5-.2.7-.2,0,0,.1,0,.2,0,0,0,0,0-.1.2"/>
      <path class="cls-21" d="M333.5,514.7h-.5.4c.2,0,.2.2,0,.3"/>
      <path class="cls-21" d="M329.9,512.8c0,0,0,.3-.1.4v.2s-.1,0-.1-.2c0-.2,0-.7.2-.6,0,0,.1,0,0,.2"/>
      <path class="cls-21" d="M329.7,514.5c0-.2-.1-.4,0-.6h0c0,0,.1.3.2.4h0c0,.3-.2.4-.3.2"/>
    </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){

    //office
    var rad = gsap.timeline({repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad.fromTo('#radguy', 2, {x:-125,y:0,scaleX:0,rotation:0},{x:-107,y:0,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad.to('#radguy', 1, {x:-125,y:0,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //school
    var rad2 = gsap.timeline({delay:5, repeatDelay:90, repeat: -1, defaults: {ease: "Power2:easeInOut"}});
    rad2.fromTo('#radguy', 2, {x:145,y:-210,scaleX:0,rotation:0},{x:170,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad2.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad2.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad2.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad2.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad2.to('#radguy', 1, {x:145,y:-210,rotation:0,scaleX:0,ease:'Power2:EaseOut'});
    //condo
    var rad3 = gsap.timeline({delay:10, repeatDelay:90,repeat: -1, defaults: {ease: "Power2:easeInOut"}});
    rad3.fromTo('#radguy', 2, {x:-40,y:-55,scaleX:0,rotation:0},{x:-58,rotation:-5,scaleX:-1,ease: "Power2:easeIn"});
    rad3.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad3.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad3.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad3.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad3.to('#radguy', 1, {x:-40,y:-55,scaleX:0,ease:'Power2:EaseOut'});
    //mobile
    var rad4 = gsap.timeline({delay:15, repeatDelay:90,repeat: -1, defaults: {ease: "Power2:easeInOut"}});
    rad4.fromTo('#radguy', 2, {x:340,y:-55,scaleX:0,rotation:0},{x:315,rotation:-5,scaleX:-1,ease: "Power2:easeIn"});
    rad4.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad4.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad4.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad4.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad4.to('#radguy', 1, {x:340,y:-55,scaleX:0,ease:'Power2:EaseOut'});
    //treehouse
    var rad5 = gsap.timeline({delay:20, repeatDelay:90,repeat: -1, defaults: {ease: "Power2:easeInOut"}});
    rad5.fromTo('#radguy', 2, {x:343,y:-195,scaleX:0,rotation:0},{x:355,rotation:5,scaleX:1,ease: "Power2:easeIn"});
    rad5.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad5.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad5.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad5.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad5.to('#radguy', 1, {x:343,y:-195,scaleX:0,ease:'Power2:EaseOut'});
    //doghouse
    var rad6 = gsap.timeline({delay:25, repeatDelay:90,repeat: -1, defaults: {ease: "Power2:easeInOut"}});
    rad6.fromTo('#radguy', 2, {x:228,y:17,scaleX:1,rotation:0},{x:228,y:-1,rotation:-5,scaleX:1,ease: "Power2:easeIn"});
    rad6.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad6.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad6.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad6.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad6.to('#radguy', 1, {x:228,y:17,scaleX:1,ease:'Power2:EaseOut'});
    //for rent
    var rad7 = gsap.timeline({delay:30, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad7.fromTo('#radguy', 2, {x:275,y:-140,scaleX:0,rotation:0},{x:296,y:-140,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad7.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad7.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad7.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad7.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad7.to('#radguy', 1, {x:275,y:-140,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //geothermal
    var rad8 = gsap.timeline({delay:35, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad8.fromTo('#radguy', 2, {x:0,y:110,scaleX:0,rotation:0},{x:-20,y:110,scaleX:-1,rotation:-5,ease: "Power2:easeIn"});
    rad8.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad8.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad8.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad8.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad8.to('#radguy', 1, {x:0,y:110,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //old home
    var rad9 = gsap.timeline({delay:40, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad9.fromTo('#radguy', 2, {x:-110,y:-295,scaleX:0,rotation:0},{x:-100,y:-295,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad9.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad9.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad9.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad9.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad9.to('#radguy', 1, {x:-110,y:-295,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //construction
    var rad10 = gsap.timeline({delay:45, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad10.fromTo('#radguy', 2, {x:350,y:20,scaleX:0,rotation:0},{x:330,y:20,scaleX:-1,rotation:-5,ease: "Power2:easeIn"});
    rad10.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad10.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad10.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad10.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad10.to('#radguy', 1, {x:350,y:20,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //neighbours
    var rad11 = gsap.timeline({delay:50, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad11.fromTo('#radguy', 2, {x:-240,y:-170,scaleX:0,rotation:0},{x:-230,y:-190,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad11.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad11.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad11.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad11.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad11.to('#radguy', 1, {x:-240,y:-180,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
   //newhome
    var rad12 = gsap.timeline({delay:55, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad12.fromTo('#radguy', 2, {x:220,y:-316,scaleX:0,rotation:0},{x:245,y:-316,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad12.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad12.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad12.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad12.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad12.to('#radguy', 1, {x:220,y:-316,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //apartment
    var rad13 = gsap.timeline({delay:60, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad13.fromTo('#radguy', 2, {x:-245,y:78,scaleX:0,rotation:0},{x:-225,y:78,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad13.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad13.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad13.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad13.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad13.to('#radguy', 1, {x:-245,y:78,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //daycare
    var rad14 = gsap.timeline({delay:65, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad14.fromTo('#radguy', 2, {x:40,y:-100,scaleX:0,rotation:0},{x:30,y:-100,scaleX:-1,rotation:-5,ease: "Power2:easeIn"});
    rad14.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad14.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad14.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad14.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad14.to('#radguy', 1, {x:40,y:-100,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //reno
    var rad15 = gsap.timeline({delay:70, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad15.fromTo('#radguy', 2, {x:-260,y:-20,scaleX:0,rotation:0},{x:-245,y:-30,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad15.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad15.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad15.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad15.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad15.to('#radguy', 1, {x:-260,y:-20,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //for sale
    var rad16 = gsap.timeline({delay:75, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad16.fromTo('#radguy', 2, {x:120,y:-10,scaleX:0,rotation:0},{x:110,y:-10,scaleX:-1,rotation:-5,ease: "Power2:easeIn"});
    rad16.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad16.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad16.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad16.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad16.to('#radguy', 1, {x:120,y:-10,scaleX:0,rotation:0,ease:'Power2:EaseOut'});
    //houseboat
    var rad17 = gsap.timeline({delay:80, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad17.fromTo('#radguy', 2, {x:-280,y:-310,scaleX:1,rotation:0},{x:-280,y:-334,scaleX:1,rotation:0,ease: "Power2:easeIn"});
    rad17.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad17.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad17.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad17.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad17.to('#radguy', 1, {x:-280,y:-310,scaleX:1,rotation:0,ease:'Power2:EaseOut'});
    //well
    var rad18 = gsap.timeline({delay:85, repeat: -1, repeatDelay:90, defaults: {ease: "Power2:easeInOut"}});
    rad18.fromTo('#radguy', 2, {x:330,y:-130,scaleX:0,rotation:0},{x:340,y:-130,scaleX:1,rotation:5,ease: "Power2:easeIn"});
    rad18.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad18.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad18.to('#rad-arm', .5, {rotation:20,x:-1,y:2,transformOrigin:"100% 100%"});
    rad18.to('#rad-arm', .5, {rotation:0,x:0,y:0});
    rad18.to('#radguy', 1, {x:330,y:-130,scaleX:0,rotation:0,ease:'Power2:EaseOut'});

    //  var rad = gsap.timeline({repeat: -1, defaults: {ease: "none"}});
    //   rad.fromTo('#radguy', 12, {x:-140,y:0,scaleX:1},{x:150,y:0,scaleX:1});
    //   rad.fromTo('#radguy', 5, {x:155, y:-210},{x:220, y:-150},'+=2');
    //   rad.fromTo('#radguy', 5, {x:-145, y:-50},{x:-50, y:-120},'+=5');
    //   rad.fromTo('#radguy', 8, {x:200, y:-316},{x:400, y:-316},'+=6');
    //   rad.fromTo('#radguy', 6, {x:400, y:-100, scaleX:-1},{x:260, y:-70},'+=5');
    //   rad.to('#radguy', 12, {x:-18, y:-146,scaleX:-1});
    //   rad.fromTo('#radguy', 10, {x:-260, y:120, scaleX:1},{x:0, y:110},'+=4');
    //   rad.fromTo('#radguy', 4, {x:-340, y:-124},{x:-230, y:-137},'+=4');
    //   rad.fromTo('#radguy', 8, {x:-230, y:-137},{x:-40, y:-100});
    //   rad.fromTo('#radguy', 6, {x:-40, y:-50,scaleX:-1},{x:-140, y:0},'+=2');

    // var rad = gsap.timeline({repeat: -1, ease:"Cubic.easeInOut"});
    // rad.to('#rleg', .1, {y:-.5});
    // rad.to('#lleg', .1, {y:-.5});
    // rad.to('#rleg', .1, {y:0});
    // rad.to('#lleg', .1, {y:0});

  }
}
</script>

<style scoped>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37, .cls-38 {
        stroke-width: 0px;
      }

      .cls-1, .cls-6, .cls-7, .cls-10, .cls-11, .cls-12, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-22, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-35, .cls-36, .cls-37 {
        fill-rule: evenodd;
      }

      .cls-1, .cls-38 {
        fill: #9cc;
      }

      .cls-2, .cls-32 {
        fill: #ffc;
      }

      .cls-3, .cls-11 {
        fill: #d4a15e;
      }

      .cls-4 {
        fill: #fbd617;
      }

      .cls-5 {
        fill: #be574d;
      }

      .cls-6 {
        fill: #70a30a;
      }

      .cls-7 {
        fill: #699;
      }

      .cls-8 {
        fill: #cdaf2c;
      }

      .cls-9 {
        fill: #f27350;
      }

      .cls-10 {
        fill: #ff6;
      }

      .cls-12 {
        fill: #2c3b2e;
      }

      .cls-13, .cls-15 {
        fill: #bf3611;
      }

      .cls-14 {
        fill: #471e04;
      }

      .cls-16 {
        fill: #f2d291;
      }

      .cls-17 {
        fill: #fc0;
      }

      .cls-18 {
        fill: #900;
      }

      .cls-19 {
        fill: #ccc;
      }

      .cls-20 {
        fill: #f1502d;
      }

      .cls-21, .cls-35 {
        fill: #2f3233;
      }

      .cls-22 {
        fill: #f5b800;
      }

      .cls-23 {
        fill: #2f3333;
      }

      .cls-24 {
        fill: #963;
      }

      .cls-25 {
        fill: #f60;
      }

      .cls-26 {
        fill: #fbca0f;
      }

      .cls-27 {
        fill: #ff0;
      }

      .cls-28 {
        fill: #5b8408;
      }

      .cls-29, .cls-34 {
        fill: #fff;
      }

      .cls-30 {
        fill: #ef4f41;
      }

      .cls-31 {
        fill: #edba68;
      }

      .cls-33 {
        fill: #83694e;
      }

      .cls-36 {
        fill: #d43c13;
      }

      .cls-37 {
        fill: #c63;
      }
</style>
