import { render, staticRenderFns } from "./Radon.vue?vue&type=template&id=3a0ec971&scoped=true"
import script from "./Radon.vue?vue&type=script&lang=js"
export * from "./Radon.vue?vue&type=script&lang=js"
import style0 from "./Radon.vue?vue&type=style&index=0&id=3a0ec971&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0ec971",
  null
  
)

export default component.exports