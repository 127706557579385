<template>
  <div id="people" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
  <g id="duck1">
    <path class="cls-24" d="M555.8,279c.5,1.2.7,1.5,2.9,3.5-1.8-.7-2.2-.9-4.8-1-3.6,0-5.4,1.5-6.3,0-.6,1.2,0,6.6,8.1,6.4,6.6-.1,7.5-2.7,7.5-3.7s-.9-2.2-2.2-2.7c-1.8-.7-2-1.3-1.8-1.8s-3-1.4-3.4-.7h0Z"/>
    <path class="cls-6" d="M559.4,280.5c-.3-.3-.3-.6-.2-.9.2-.5-3-1.4-3.4-.7.3.7.5,1.1,1,1.7h0c.4.1,1.2.3,2.6-.1h0Z"/>
    <path class="cls-22" d="M559.4,285c-.4.4.5,1.4,1.8,1.8,1.7-.9,2-2,2-2.6,0-1-.9-2.2-2.2-2.7,0,0-.3-.1-.4-.2h0c-1.1.4-1.9.5-2.6.6.2.2.4.4.7.6-.8-.3-1.3-.5-1.8-.7l2.5,3.2h0Z"/>
    <path class="cls-29" d="M556.8,280.6c.4.1,1.2.3,2.6-.1.2.3.6.5,1.2.8-1.1.4-1.9.5-2.6.6-.6-.5-1-.9-1.2-1.2h0Z"/>
    <path class="cls-20" d="M559.4,277.7s1.4.8,2.5.7c1.2-.1-1.2,1.5-2.8,1.1s.2-1.6.3-1.7h0Z"/>
    <path class="cls-6" d="M557.7,276.2c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2Z"/>
    <path class="cls-12" d="M558.1,277.1c.3,0,.5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"/>
    <path class="cls-10" d="M558.3,277.8c-.8,0-1.4.4-1.4,1s.6,1,1.4,1,1.4-.4,1.4-1-.6-1-1.4-1Z"/>
    <path class="cls-19" d="M558.7,282.5c.7.4,3.5,2.5.2,3.8-3.2,1.3-9.1-.6-10.4-4.1,1.1.2,2.8-.7,5.4-.6,2.6,0,3,.3,4.8,1h0Z"/>
    <path class="cls-29" d="M550.4,284.6c.4-.5.9-1.4,1-2.9.4,0,.7-.1,1.1-.2,0,1.8-.7,3-1.2,3.7-.3-.2-.6-.4-.9-.6h0Z"/>
    <path class="cls-29" d="M551.7,285.4c.5-.6,1.2-1.9,1.3-3.9h1.1c0,2.1-.9,3.6-1.4,4.4-.4-.1-.7-.3-1-.5h0Z"/>
  </g>
  <g>
    <rect class="cls-9" x="589.2" y="644.7" width="34.6" height="4.3"/>
    <rect class="cls-5" x="589.2" y="649" width="34.6" height="4.3"/>
    <rect class="cls-5" x="594.9" y="653.3" width="1.4" height="2.9"/>
    <rect class="cls-5" x="616.5" y="653.3" width="1.4" height="2.9"/>
    <rect class="cls-9" x="589.2" y="656.2" width="34.6" height="3.4"/>
    <rect class="cls-5" x="594" y="659.6" width="3.3" height="5.3"/>
    <rect class="cls-5" x="615.6" y="659.6" width="3.3" height="5.3"/>
    <rect class="cls-5" x="629.7" y="652.1" width="2.9" height="9.8"/>
    <rect class="cls-9" x="632.6" y="652.1" width="2.9" height="9.8"/>
    <rect class="cls-5" x="635.5" y="652.1" width="2.9" height="9.8"/>
    <rect class="cls-33" x="633.2" y="662" width="1.6" height="2.9"/>
  </g>
  <g id="duck2">
    <path class="cls-24" d="M619,252.8c-.5,1.2-.7,1.5-2.9,3.5,1.8-.7,2.2-.9,4.8-1,3.6,0,5.4,1.5,6.3,0,.6,1.2,0,6.6-8.1,6.4-6.6-.1-7.5-2.7-7.5-3.7s.9-2.2,2.2-2.7c1.8-.7,2-1.3,1.8-1.8s3-1.4,3.4-.7h0Z"/>
    <path class="cls-28" d="M615.4,254.4c.3-.3.3-.6.2-.9-.2-.5,3-1.4,3.4-.7-.3.7-.5,1.1-1,1.7h0c-.4.1-1.2.3-2.6-.1h0Z"/>
    <path class="cls-22" d="M615.4,258.9c.4.4-.5,1.4-1.8,1.8-1.7-.9-2-2-2-2.6,0-1,.9-2.2,2.2-2.7,0,0,.3-.1.4-.2h0c1.1.4,1.9.5,2.6.6-.2.2-.4.4-.7.6.8-.3,1.3-.5,1.8-.7l-2.5,3.2Z"/>
    <path class="cls-29" d="M618,254.5c-.4.1-1.2.3-2.6-.1-.2.3-.6.5-1.2.8,1.1.4,1.9.5,2.6.6.6-.5,1-.9,1.2-1.2h0Z"/>
    <path class="cls-20" d="M615.3,251.6s-1.4.8-2.5.7c-1.2-.1,1.2,1.5,2.8,1.1,1.7-.4-.2-1.6-.3-1.7h0Z"/>
    <path class="cls-28" d="M617.1,250.1c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"/>
    <path class="cls-12" d="M616.7,250.9c-.3,0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5Z"/>
    <path class="cls-27" d="M616.4,251.7c.8,0,1.4.4,1.4,1s-.6,1-1.4,1-1.4-.4-1.4-1,.6-1,1.4-1Z"/>
    <path class="cls-12" d="M616.1,256.4c-.7.4-3.5,2.5-.2,3.8,3.2,1.3,9.1-.6,10.4-4.1-1.1.2-2.8-.7-5.4-.6-2.6,0-3,.3-4.8,1h0Z"/>
    <path class="cls-29" d="M624.3,258.5c-.4-.5-.9-1.4-1-2.9-.4,0-.7-.1-1.1-.2,0,1.8.7,3,1.2,3.7.3-.2.6-.4.9-.6h0Z"/>
    <path class="cls-29" d="M623,259.3c-.5-.6-1.2-1.9-1.3-3.9h-1.1c0,2.1.9,3.6,1.4,4.4.4-.1.7-.3,1-.5h0Z"/>
  </g>
  <g id="fish2">
    <path class="cls-10" d="M157.3,258.2c-.8-1.1-2-1.8-3.4-1.8s-2.7.7-3.4,1.8c.8,1.1,2,1.8,3.4,1.8s2.7-.7,3.4-1.8Z"/>
    <g>
      <path class="cls-10" d="M150.8,257.9c-.5-.1-1,0-1.4.3s-.5.8-.5,1.3c.5.1,1,0,1.3-.3.4-.3.5-.8.5-1.3h0Z"/>
      <path class="cls-10" d="M150.8,258.4c-.5.1-1,0-1.4-.3s-.5-.8-.5-1.3c.5-.1,1,0,1.3.3.4.3.5.8.5,1.3h0Z"/>
    </g>
    <path class="cls-29" d="M156.3,257.8c0-.1,0-.2-.2-.2s-.2,0-.2.2,0,.2.2.2.2,0,.2-.2Z"/>
  </g>
  <g id="fish1">
    <path class="cls-25" d="M155.8,265.7c1.1-1.6,2.9-2.6,5-2.6s3.9,1,5,2.6c-1.1,1.6-2.9,2.6-5,2.6s-3.9-1-5-2.6Z"/>
    <g>
      <path class="cls-25" d="M165.2,265.4c.7-.2,1.4,0,1.9.5s.8,1.2.7,1.9c-.7.2-1.4,0-1.9-.5s-.8-1.2-.7-1.9Z"/>
      <path class="cls-25" d="M165.2,266c.7.2,1.4,0,1.9-.5s.8-1.2.7-1.9c-.7-.2-1.4,0-1.9.5s-.8,1.2-.7,1.9Z"/>
    </g>
    <circle class="cls-34" cx="157.5" cy="265.2" r=".3"/>
  </g>
  <g>
    <path class="cls-16" d="M624.9,219.2c0,.2,0,.4-.2.6h2.9c-.3-.2-.7-.4-.8-.6v-.5h-1.9v.5Z"/>
    <g>
      <path class="cls-36" d="M623.7,219.7c0,.4.2.8,0,1.2h-3.9c0-.6.5-.9.9-1.2h2.9Z"/>
      <path class="cls-36" d="M624.7,219.7c0,.4-.2.8,0,1.2h3.9c0-.6-.5-.9-.9-1.2h-2.9Z"/>
    </g>
    <path class="cls-16" d="M623.5,219.2c0,.2,0,.4.2.6h-2.9c.3-.2.7-.4.8-.6v-.5h1.9v.5Z"/>
    <rect class="cls-13" x="619.9" y="220.7" width="4" height=".2"/>
    <rect class="cls-13" x="624.5" y="220.7" width="4" height=".2"/>
    <polygon class="cls-7" points="624.4 208.8 625.8 208.8 627.3 208.8 627.3 218.8 624.6 218.8 624.6 211.1 623.8 211.1 623.8 218.8 621 218.8 621 208.8 622.9 208.8 623.9 208.8 624.4 208.8"/>
    <path class="cls-15" d="M627.3,190.4c.7-.9,2.4-1.4,3.7.3,1.6,2.3-1.3,5-1.2,6.2,0,.9.6,1.4.6,1.4,0,0-1.7.2-2.7-1.3-.9-1.5.8-5.2.9-6,0-.7-.5-.5-.8,0-.3.4-.4-.6-.4-.6h0Z"/>
    <path class="cls-26" d="M620.5,210.6h7.3v-8.9c0-1-.8-1.7-1.7-1.6v1.9c0,1.1-.9,2-2,2h0c-1.1,0-2-.9-2-2v-1.9c-.9,0-1.7.6-1.7,1.6v8.9h.1Z"/>
    <path class="cls-17" d="M626.6,189.5c1.1,0,2.1.9,2.1,2.1s-.9,2.1-2.1,2.1-2.1-.9-2.1-2.1.9-2.1,2.1-2.1Z"/>
    <path class="cls-16" d="M622.6,198h3v2.1h.4v1.9c0,1.1-.9,2-2,2h0c-1.1,0-2-.9-2-2v-1.9h.4v-2.1h.2Z"/>
    <path class="cls-11" d="M626.1,202l-3.5-3.5v-.5h3v2.1h.4v1.9h0Z"/>
    <path class="cls-16" d="M624.1,190.3c.9,0,3.6.1,3.7,3.3,0,3.2-.6,4.3-1.1,4.8-.4.5-.9.9-1.8,1.1-.2.2-.5.3-.9.4-.4,0-.6-.1-.9-.4-.9-.2-1.3-.7-1.8-1.1-.4-.5-1.1-1.6-1.1-4.8s2.8-3.3,3.7-3.3h.2Z"/>
    <path class="cls-31" d="M624.1,190.3c.9,0,3.6.1,3.7,3.3,0,3.2-.6,4.3-1.1,4.8-.4.5-.9.9-1.8,1.1-.2.2-.5.3-.9.4v-9.6h0Z"/>
    <path class="cls-16" d="M620.9,195.2c0-.2-.2-.4-.4-.4-.3,0-.7,0-.7.8s.6,1.4,1,1.3c.4,0,.2-1.5.2-1.7h0Z"/>
    <path class="cls-31" d="M627.2,195.3c0-.2.2-.4.5-.4s.7,0,.7.8-.6,1.4-1,1.3c-.4,0-.2-1.7-.2-1.7h0Z"/>
    <path class="cls-36" d="M626.1,192.1c.4.7.3,1.9,1.7,2.7.9-3.5-1.5-5.1-3.8-5-2.5,0-4.7,1.7-4,5.1,1.7-.3,2.5-1.2,3.3-2.1-.3.8-.8,1.2-.6,1.1,1.6-.4,2.2-.5,3.4-1.8h0Z"/>
    <path class="cls-29" d="M625.3,197.4c0,.6-.6,1.1-1.2,1.1s-1.1-.5-1.2-1.1h2.4Z"/>
    <rect class="cls-38" x="620.8" y="218.2" width="3" height=".8"/>
    <rect class="cls-38" x="624.5" y="218.2" width="3" height=".8"/>
    <g>
      <g>
        <rect class="cls-2" x="620.5" y="209" width="7.3" height=".9"/>
        <rect class="cls-2" x="620.5" y="207.5" width="7.3" height=".9"/>
        <rect class="cls-2" x="620.5" y="206" width="7.3" height=".9"/>
        <rect class="cls-2" x="620.5" y="204.4" width="7.3" height=".9"/>
        <path class="cls-32" d="M620.7,203.8h2.7c-.4-.2-.8-.5-1-.9h-1.6l-.2.9h0ZM624.9,203.8h2.7l-.2-.9h-1.6c-.2.4-.6.7-1,.9h0Z"/>
      </g>
      <g>
        <path class="cls-32" d="M621.2,201.4h1v.9h-1.2l.2-.9h0ZM626.1,201.4h1l.2.9h-1.2v-.9h0Z"/>
        <path class="cls-32" d="M621.3,200.7h.9v-.6c-.3,0-.6,0-.8.1v.5h-.1ZM626.1,200.7h.9v-.5h0c-.3-.1-.5-.2-.8-.1v.6h-.1Z"/>
      </g>
    </g>
    <path class="cls-31" d="M620.7,195.5c0-.1,0-.2-.3-.3-.2,0-.4,0-.4.5s.3.9.6.8c0,0,0-.1,0-.3,0,0-.2-.2-.2-.4s0-.2.2-.3h0Z"/>
    <path class="cls-11" d="M627.5,195.5c0-.1,0-.2.3-.3.2,0,.4,0,.4.5s-.3.9-.6.8c0,0,0-.1,0-.3,0,0,.2-.2.2-.4s0-.2-.2-.3h0Z"/>
    <path class="cls-14" d="M625.7,194.6s-.2,0-.2-.2c0,0,0-.2.2-.2h.6s0,.1,0,.2c0,0,0,.1-.2,0h-.5v.2Z"/>
    <path class="cls-14" d="M622.6,194.3s.2,0,.2.2c0,0,0,.2-.2.2h-.7v-.2c.3-.1.6,0,.6,0v-.2Z"/>
    <g>
      <g>
        <path class="cls-14" d="M626.4,195.6h0c-.2-.3-.6-.6-1.2-.1h0c0,.1,0,.1,0,.1.5-.4.7-.2.9,0h.1Z"/>
        <path class="cls-14" d="M625.9,195.3c-.2,0-.3.1-.3.3s0,.3.3.3.3-.1.3-.3,0-.3-.3-.3Z"/>
        <path class="cls-14" d="M626.4,195.2h0v.2q0,0,0,0h0v-.2Z"/>
        <path class="cls-14" d="M626.6,195.3h0l-.2.2h0l.2-.2Z"/>
      </g>
      <g>
        <path class="cls-14" d="M621.8,195.6h0c.2-.3.6-.6,1.2-.1h0c0,.1,0,.1,0,.1-.5-.4-.7-.2-.9,0h-.1Z"/>
        <path class="cls-14" d="M622.3,195.3c.2,0,.3.1.3.3s0,.3-.3.3-.3-.1-.3-.3,0-.3.3-.3Z"/>
        <path class="cls-14" d="M621.8,195.2h0v.2q0,0,0,0h0v-.2Z"/>
        <path class="cls-14" d="M621.6,195.3h0l.2.2h0l-.2-.2Z"/>
      </g>
    </g>
    <polygon class="cls-15" points="621.3 200.2 622.2 200.2 628.7 208.9 627.7 210.3 621.3 200.2"/>
    <path class="cls-15" d="M630,214h-2.2c-.2,0-.3-.2-.3-.5v-5c0-.3.2-.5.3-.5h.8c1,0,1.7,1.1,1.7,2.5v3c0,.3-.2.5-.3.5Z"/>
    <path class="cls-16" d="M619,203.6c0,1.1-.5,4.1-.8,6.1v2.1c0,.1,0,.2.2.2v.2c0,.2.2.3.3.3h0v.2c0,.2.2.3.3.3h0c.2,0,.3-.2.3-.3v-1.2.3c0,.2.2.2.4.2h0c.2,0,.2-.2.2-.4l-.3-.9h0c.3-1.9.8-5.4.9-6.7h.2l.6-3.6c-2,0-2.1,2.1-2.3,3.4v-.2Z"/>
    <path class="cls-16" d="M629.6,203.3c.3,1.1,1,4,1.6,6,0,0,0,.1,0,.2l.2,1.9c0,.1,0,.2,0,.3v.2c0,.2,0,.3-.3.3h0v.2c0,.2,0,.3-.3.3h0c-.2,0-.3-.1-.3-.3v-1.2c0,0-.2.4-.2.4,0,.2-.2.3-.4.2h0c-.2,0-.3-.2-.2-.4l.2-1h0c-.5-2-1.4-5.4-1.8-6.6h-.6l-.6-3.6c2-.3,2.3,1.9,2.7,3.1h0Z"/>
  </g>
  <g>
    <g>
      <path class="cls-1" d="M567.5,649.7h10.8v-13.2c0-1.3-1.1-2.4-2.4-2.4h-6c-1.3,0-2.4,1.1-2.4,2.4v13.2h0Z"/>
      <path class="cls-18" d="M567.7,662.1h4.2c.4,1,.3,2.1.3,3.1h-7.4c0-1.7,2.9-2.1,2.9-3.1Z"/>
      <path class="cls-11" d="M567.2,662.9c-.2,0-.3,0-.3-.3s0-.3.3-.3c0,0,.5,0,1,.7v.4h-.4c-.3-.4-.6-.4-.6-.4h0Z"/>
      <rect class="cls-3" x="564.7" y="664.6" width="7.6" height=".6"/>
      <path class="cls-11" d="M566.3,663.5c-.2,0-.3,0-.3-.3s0-.3.3-.3c0,0,.5,0,1,.7v.4h-.4c-.3-.4-.6-.4-.6-.4h0Z"/>
      <path class="cls-18" d="M578,662.1h-4.2c-.4,1-.3,2.1-.3,3.1h7.4c0-1.7-2.9-2.1-2.9-3.1Z"/>
      <path class="cls-11" d="M578.5,662.4c.2,0,.3,0,.3.3s0,.3-.3.3c0,0-.2,0-.6.4,0,0-.3,0-.4,0,0,0,0-.3,0-.4.5-.7,1-.7,1-.7h0Z"/>
      <rect class="cls-3" x="573.4" y="664.6" width="7.6" height=".6"/>
      <path class="cls-11" d="M579.4,663c.2,0,.3,0,.3.3s0,.3-.3.3c0,0-.2,0-.6.4,0,0-.3,0-.4,0,0,0,0-.3,0-.4.5-.7,1-.7,1-.7h0Z"/>
      <path class="cls-37" d="M578.3,648.3h-10.8v13.8h4.9l-.4-11.8h1.8l-.4,11.8h4.9v-13.8Z"/>
      <polygon class="cls-18" points="569.2 648.4 569.8 648.9 567.9 651.3 567.3 650.8 569.2 648.4"/>
      <polygon class="cls-18" points="576.5 648.4 575.9 648.9 577.8 651.3 578.4 650.8 576.5 648.4"/>
      <path class="cls-29" d="M575.8,634.2h0v.2h0c-.2.7-.6,1.3-1.1,1.7s-1.1.6-1.8.6-1.3-.2-1.8-.6c-.5-.4-.9-1-1.1-1.6h0v-.2h1.4c0,.3.3.6.6.8s.6.3,1,.3.7,0,1-.3.5-.5.6-.8h1.4-.2Z"/>
      <path class="cls-16" d="M570.7,632.9h4.4v1.4c-.3,1-1.2,1.7-2.2,1.7s-2-.7-2.2-1.7v-1.4Z"/>
      <path class="cls-11" d="M570.7,633.2l4.3-.4v1.4c-.2.8-.9,1.4-1.7,1.6l-2.7-2.7h.1Z"/>
      <g>
        <g>
          <path class="cls-16" d="M567.7,639.1s-.9,7-1.5,10.6h0c0,0,.5,1.5.5,1.5,0,.2,0,.5-.3.6h0c-.2,0-.5,0-.6-.3l-.2-.5v1.8c0,.2,0,.5-.4.5h0c-.2,0-.5-.2-.5-.4v-.2h0c-.2,0-.5-.2-.5-.4v-.3c0,0-.2-.2-.2-.4v-2.8c0,0,0-.2,0-.3.6-3.8,1.3-9.7,1.3-9.7l2.2.3h.2Z"/>
          <path class="cls-1" d="M565.2,639.4l2.7.5,1-5.5c-2-.3-2.9,1.6-3.1,2.4l-.6,2.5h0Z"/>
        </g>
        <path class="cls-16" d="M578,639.1s.9,7,1.5,10.6h0c0,0-.5,1.5-.5,1.5,0,.2,0,.5.3.6h0c.2,0,.5,0,.6-.3l.2-.5v1.8c0,.2,0,.5.4.5h0c.2,0,.5-.2.5-.4v-.2h0c.2,0,.5-.2.5-.4v-.3c0,0,.2-.2.2-.4v-2.8c0,0,0-.2,0-.3-.6-3.8-1.3-9.7-1.3-9.7l-2.2.3h-.2Z"/>
      </g>
      <path class="cls-1" d="M580.4,639.4l-2.7.5-1-5.5c2-.3,2.9,1.6,3.1,2.4l.6,2.5h0Z"/>
      <g>
        <g>
          <path class="cls-16" d="M572.9,620.4c-1.3,0-5.1,0-5.1,4.6s.9,6.1,1.5,6.7c.6.7,1.2,1.3,2.5,1.6.3.3.7.4,1.2.5v-13.4h-.1Z"/>
          <path class="cls-31" d="M572.9,620.4c1.3,0,5.1,0,5.1,4.6s-.9,6.1-1.5,6.7c-.6.7-1.2,1.3-2.5,1.6-.3.3-.7.4-1.2.5v-13.4h.1Z"/>
        </g>
        <path class="cls-16" d="M568.4,627.3c0-.3-.3-.5-.6-.6-.4,0-1,0-1,1.2s.9,1.9,1.4,1.8c.5,0,.3-2.1.2-2.4h0Z"/>
        <path class="cls-31" d="M577.3,627.3c.2-.3.3-.5.7-.6.4,0,1,0,1,1.2s-.9,1.9-1.4,1.8c-.5,0-.3-2.4-.3-2.4Z"/>
        <path class="cls-14" d="M578,626.7c-.3-2.6-.8-2.6-.9-2.8-.2,0-1.6-.5-3.1-1.5-.2,0,1.4,1.4,1.2,1.4-.6,0-1.4,0-2.2-.2-1.2-.2-2.5-.5-3.5-1.1-1.4,1.8-1.4,2.8-1.8,4.2-.7-2.6-.3-4.4.6-5.5,1.3-1.8,4.2-2.5,6.4-1.8,4.5,1.5,4.2,6.2,3.3,7.2h0Z"/>
      </g>
      <path class="cls-29" d="M574.6,629.7c0,.8-.9,1.5-1.7,1.5s-1.6-.6-1.7-1.5h3.5,0Z"/>
      <path class="cls-29" d="M570.3,664c.4,0,.7-.3.7-.7s-.3-.7-.7-.7-.7.3-.7.7.3.7.7.7Z"/>
      <path class="cls-29" d="M575.1,664c.4,0,.7-.3.7-.7s-.3-.7-.7-.7-.7.3-.7.7.3.7.7.7Z"/>
    </g>
    <g>
      <path class="cls-14" d="M569.6,625.5h1.3c.2,0,.3,0,.3-.3h0c0-.2,0-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2,0,.3.3.3Z"/>
      <path class="cls-14" d="M574.9,625.5h1.3c.2,0,.3,0,.3-.3h0c0-.2,0-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2,0,.3.3.3Z"/>
      <g>
        <path class="cls-14" d="M575.7,626.4c-.3,0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5Z"/>
        <path class="cls-14" d="M576.4,626.8h.2v-.2h0c-.3-.3-.9-.7-1.7,0v.2h.2c.7-.6,1.1-.2,1.4,0h-.1Z"/>
        <path class="cls-14" d="M570.2,626.4c.3,0,.5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"/>
        <path class="cls-14" d="M569.5,626.8h-.2v-.2h0c.3-.3.9-.7,1.8,0v.2h-.2c-.7-.6-1.1-.2-1.4,0h0Z"/>
      </g>
    </g>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){
    // gsap.fromTo('#fish1', 5, {x:40,alpha:0},{x:-25,alpha:1, repeat:-1});

    var f1 = gsap.timeline({repeat:-1, repeatDelay:6.7, ease:"Cubic.easeInOut"});
    f1.set('#fish1',{rotation:0,x:20,alpha:0});
    f1.to('#fish1', .5,{alpha:1});
    f1.to('#fish1', 12,{x:-25});
    f1.set('#fish1',{rotation:180,y:5,x:-15});
    f1.to('#fish1', 12, {x:30});
    f1.to('#fish1', .5, {alpha:0});

    var f2 = gsap.timeline({repeat:-1, repeatDelay:4.4, ease:"Cubic.easeInOut"});
    f2.set('#fish2',{rotation:0,x:0,alpha:0});
    f2.to('#fish2', .5,{alpha:1});
    f2.to('#fish2', 8,{x:25});
    f2.set('#fish2',{rotation:180,y:4,x:33});
    f2.to('#fish2', 8, {x:0});
    f2.to('#fish2', .5, {alpha:0});

    var d1 = gsap.timeline({repeat: -1, yoyo: true, ease:"Cubic.easeInOut"});
    d1.to('#duck1', 1.1, {rotation:10, transformOrigin:"50% 50%"});

    var d11 = gsap.timeline({repeat: -1, yoyo: true, ease:"Cubic.easeInOut"});
    d11.to('#duck1', 8.8, {x:10});

    var d2 = gsap.timeline({repeat: -1, yoyo: true, ease:"Cubic.easeInOut"});
    d2.to('#duck2', 1.3, {rotation:10, transformOrigin:"50% 50%"});

    var d22 = gsap.timeline({delay:3, repeat: -1, yoyo: true, ease:"Cubic.easeInOut"});
    d22.to('#duck2', 8.2, {x:-10});

    // var rad = gsap.timeline({repeat: -1, defaults: {ease: "none"}});
    // rad.from('#radon', 5, {x:-10});
    // rad.to('#radon', 6, {x:30,y:20});
    // rad.to('#radon',.1,{x:50,scaleX:-1});
    // rad.to('#radon', 8, {x:40,y:50,});
    // rad.to('#radon', 7, {x:0,y:50,});
    // rad.to('#radon', 8, {x:-20,y:25});
    // rad.to('#radon',.1,{x:-35,scaleX:1});
    // rad.to('#radon', 7, {x:-10,y:0});

    // var rad = gsap.timeline({repeat: -1, ease:"Cubic.easeInOut"});
    // rad.to('#rleg', .2, {y:-.5});
    // rad.to('#lleg', .2, {y:-.5});
    // rad.to('#rleg', .2, {y:0});
    // rad.to('#lleg', .2, {y:0});

  }
}
</script>

<style scoped>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37, .cls-38 {
        stroke-width: 0px;
      }

      .cls-1, .cls-6, .cls-7, .cls-10, .cls-11, .cls-12, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-22, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-35, .cls-36, .cls-37 {
        fill-rule: evenodd;
      }

      .cls-1, .cls-38 {
        fill: #9cc;
      }

      .cls-2, .cls-32 {
        fill: #ffc;
      }

      .cls-3, .cls-11 {
        fill: #d4a15e;
      }

      .cls-4 {
        fill: #fbd617;
      }

      .cls-5 {
        fill: #be574d;
      }

      .cls-6 {
        fill: #70a30a;
      }

      .cls-7 {
        fill: #699;
      }

      .cls-8 {
        fill: #cdaf2c;
      }

      .cls-9 {
        fill: #f27350;
      }

      .cls-10 {
        fill: #ff6;
      }

      .cls-12 {
        fill: #2c3b2e;
      }

      .cls-13, .cls-15 {
        fill: #bf3611;
      }

      .cls-14 {
        fill: #471e04;
      }

      .cls-16 {
        fill: #f2d291;
      }

      .cls-17 {
        fill: #fc0;
      }

      .cls-18 {
        fill: #900;
      }

      .cls-19 {
        fill: #ccc;
      }

      .cls-20 {
        fill: #f1502d;
      }

      .cls-21, .cls-35 {
        fill: #2f3233;
      }

      .cls-22 {
        fill: #f5b800;
      }

      .cls-23 {
        fill: #2f3333;
      }

      .cls-24 {
        fill: #963;
      }

      .cls-25 {
        fill: #f60;
      }

      .cls-26 {
        fill: #fbca0f;
      }

      .cls-27 {
        fill: #ff0;
      }

      .cls-28 {
        fill: #5b8408;
      }

      .cls-29, .cls-34 {
        fill: #fff;
      }

      .cls-30 {
        fill: #ef4f41;
      }

      .cls-31 {
        fill: #edba68;
      }

      .cls-33 {
        fill: #83694e;
      }

      .cls-36 {
        fill: #d43c13;
      }

      .cls-37 {
        fill: #c63;
      }
</style>
