<template>
    <div id="wellwater" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-13" d="M670.8,355.9h-26.4l-4.9,13.6v7.5h2.1v6.1h1.3c-.9,1.3-1.3,3-.8,4.6l1.1,4.1c.2.7.5,1.3.8,1.8v8.3h27.4v-7.1l2-7.1c.4-1.6,0-3.3-.8-4.6h1.5v-6.1h1.8v-7.5l-5-13.6h0Z"/>
  <path class="homeHotSpot cls-9" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight" d="M667.3,360.9h-19.4l-3.4,9.5v1.6h3.4v13.7h-.4c-.4,0-.6.4-.6.8l1.1,4c0,.4.5.8,1,.8v5.6h17.4v-5.6c.4,0,.9-.4.9-.8h.1l1.1-4c.1-.4,0-.8-.6-.8h-.7v-13.7h3.6v-1.6l-3.5-9.5Z"/>
  <g >
    <g>
      <path class="cls-2" d="M644.1,382.3h-1v-.2h1c.4,0,.7-.3.7-.7v-3c0-.5.3-.9.6-.9h.5v.2h-.5c-.2,0-.4.3-.4.7v3c0,.5-.4.9-.9.9h0Z"/>
      <rect class="cls-12" x="646.6" y="377.1" width="22.4" height="1"/>
      <rect class="cls-5" x="668.4" y="376.6" width="1.2" height="1.9"/>
      <rect class="cls-5" x="651.5" y="375.5" width="12.2" height="4.1"/>
      <rect class="cls-5" x="645.8" y="376.6" width="1.2" height="1.9"/>
      <rect class="cls-6" x="647.9" y="372" width="2.1" height="13.9"/>
      <rect class="cls-6" x="665.1" y="372" width="2.1" height="13.9"/>
      <rect class="cls-3" x="644.5" y="370.4" width="26.3" height="1.6"/>
      <polygon class="cls-7" points="670.8 370.4 644.5 370.4 647.9 360.9 667.3 360.9 670.8 370.4"/>
      <rect class="cls-1" x="657.1" y="375" width=".7" height="10.9"/>
      <rect class="cls-1" x="656" y="375" width=".7" height="5.2"/>
      <rect class="cls-1" x="658.3" y="375" width=".7" height="5.2"/>
      <rect class="cls-1" x="654.8" y="375" width=".7" height="5.2"/>
      <rect class="cls-1" x="653.7" y="375" width=".7" height="5.2"/>
      <rect class="cls-2" x="649" y="391" width="17.4" height="5.9"/>
      <path class="cls-4" d="M667.3,390.5c0,.4-.6.8-1,.8h-17.3c-.5,0-.9-.4-1-.8l-1.1-4c0-.4.2-.8.6-.8h20.4c.5,0,.7.4.6.8l-1.1,4h-.1Z"/>
      <rect class="cls-5" x="642.2" y="381.9" width="2.1" height=".5"/>
    </g>
    <g>
      <g>
        <path class="cls-8" d="M675.7,364.2c.2,1,.8,2,1.7,2.6s2,.9,3.1.7v3.2h.4v-6.3c.7-.3,1.4-.9,1.7-1.7.4-.9.4-1.8,0-2.6-.8.3-1.5.9-1.9,1.8v.3-.3c-.4-.9-1.1-1.5-1.9-1.8-.3.8-.3,1.8,0,2.6.4.8,1,1.4,1.7,1.7v3c-.2-1-.8-1.9-1.7-2.5s-2-.9-3.1-.7h0ZM685.7,364.2c-.2,1-.8,2-1.7,2.6s-2,.9-3.1.7c.2-1,.8-2,1.7-2.6s2-.9,3.1-.7Z"/>
        <path class="cls-10" d="M680.6,357.4c.8,0,1.4.6,1.4,1.4s-.6,1.4-1.4,1.4-1.4-.6-1.4-1.4.6-1.4,1.4-1.4Z"/>
      </g>
      <g>
        <path class="cls-8" d="M686.1,364.2c.2,1,.8,2,1.7,2.6s2,.9,3.1.7v3.2h.4v-6.3c.7-.3,1.4-.9,1.7-1.7.4-.9.4-1.8,0-2.6-.8.3-1.5.9-1.9,1.8v.3-.3c-.4-.9-1.1-1.5-1.9-1.8-.3.8-.3,1.8,0,2.6.4.8,1,1.4,1.7,1.7v3c-.2-1-.8-1.9-1.7-2.5s-2-.9-3.1-.7h0ZM696.1,364.2c-.2,1-.8,2-1.7,2.6s-2,.9-3.1.7c.2-1,.8-2,1.7-2.6s2-.9,3.1-.7Z"/>
        <path class="cls-10" d="M691,357.4c.8,0,1.4.6,1.4,1.4s-.6,1.4-1.4,1.4-1.4-.6-1.4-1.4.6-1.4,1.4-1.4Z"/>
      </g>
      <g>
        <path class="cls-8" d="M696.4,364.2c.2,1,.8,2,1.7,2.6s2,.9,3.1.7v3.2h.4v-6.3c.7-.3,1.4-.9,1.7-1.7.4-.9.4-1.8,0-2.6-.8.3-1.5.9-1.9,1.8v.3-.3c-.4-.9-1.1-1.5-1.9-1.8-.3.8-.3,1.8,0,2.6.4.8,1,1.4,1.7,1.7v3c-.2-1-.8-1.9-1.7-2.5s-2-.9-3.1-.7h0ZM706.4,364.2c-.2,1-.8,2-1.7,2.6s-2,.9-3.1.7c.2-1,.8-2,1.7-2.6s2-.9,3.1-.7Z"/>
        <path class="cls-10" d="M701.3,357.4c.8,0,1.4.6,1.4,1.4s-.6,1.4-1.4,1.4-1.4-.6-1.4-1.4.6-1.4,1.4-1.4Z"/>
      </g>
    </g>
  </g>
  <rect class="cls-11" x="668.4" y="376.6" width="1.2" height="1.9"/>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'forsale',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('WellWater')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #edd970;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #465572;
      }

      .cls-3 {
        fill: #473123;
      }

      .cls-4, .cls-7, .cls-8, .cls-9, .cls-10 {
        fill-rule: evenodd;
      }

      .cls-4, .cls-11 {
        fill: #65789e;
      }

      .cls-5, .cls-7 {
        fill: #8c4c22;
      }

      .cls-6 {
        fill: #7b3f18;
      }

      .cls-8 {
        fill: #70a30a;
      }

      .cls-9 {
        fill: #69990a;
      }

      .cls-10 {
        fill: #fc0;
      }

      .cls-12 {
        fill: #a46b44;
      }

      .cls-13 {
        fill: #fff;
      }
  </style>
