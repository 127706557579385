<template>
    <div id="neighbour" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path class="cls-11" d="M84.3,310.1c-.9,0-1.7.2-2.5.5-1-1.9-3-3.2-5.3-3.2s-3.7,1-4.8,2.4c-.8-.4-1.7-.6-2.6-.6-2.7,0-5.1,1.9-5.7,4.4-.7-.2-1.4-.4-2.1-.4-3.3,0-5.9,2.7-5.9,5.9s0,1.5.4,2.2c-1.7,1-2.8,2.9-2.8,5s2,5.2,4.6,5.8c.3,3,2.8,5.4,5.9,5.4,7.2,0,13.7-.8,20.9-.8s5.9-2.7,5.9-5.9,0-1.4-.4-2c2.3-.8,4-3,4-5.6s-1.6-4.8-3.9-5.6c0-.5.2-1,.2-1.6,0-3.3-2.7-5.9-5.9-5.9h0Z"/>
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-24" d="M109,338.9h0l-20.2-20c-5.1-5-10.2-10-14-13.8-5.2-5.1-8.5-7.7-10-7.7h0c-1.3,0-2.4.4-3.8,1.4H0v107.3h53.8v-11.8c1.5-.1,2.9-.8,4-1.9,2.6-2.6,2.6-6.6,0-9.2l-21.6-21.6h68.7v-11.8c1.5-.1,2.9-.8,3.9-1.9,1.2-1.2,1.9-2.9,1.9-4.6s-.7-3.4-1.9-4.6l.2.2Z"/>
  <g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
    <polygon class="cls-18" points="0 303.8 0 339.1 29.7 339.1 65 303.8 0 303.8"/>
    <g>
      <polygon class="cls-3" points="65 303.8 29.7 339.1 29.7 356.7 65 356.7 100.3 356.7 100.3 339.1 65 303.8"/>
      <path class="cls-18" d="M66,302.8c-.3-.3-.7-.4-1-.4v3.5l38.6,38.6c.6.6,1.5.6,2.1,0s.6-1.5,0-2.1l-39.7-39.7h0Z"/>
      <polygon class="cls-22" points="30.7 340.3 65 305.9 67.8 308.7 36.4 340.3 30.7 340.3"/>
      <g>
        <rect class="cls-4" x="58.8" y="334.8" width="12.5" height="21.9"/>
        <polygon class="cls-7" points="71.2 334.8 58.8 334.8 58.8 356.7 71.2 334.8"/>
      </g>
      <g>
        <rect class="cls-24" x="75.9" y="331.9" width="9.1" height="13.9"/>
        <path class="cls-3" d="M85,338.1c.4,0,.8-.5.8-1.1s-.3-1.1-.8-1.1h-9.1c-.4,0-.8.5-.8,1.1s.3,1.1.8,1.1h9.1Z"/>
      </g>
      <g>
        <rect class="cls-24" x="45.1" y="331.9" width="9.1" height="13.9"/>
        <path class="cls-3" d="M54.2,338.1c.4,0,.8-.5.8-1.1s-.3-1.1-.8-1.1h-9.1c-.4,0-.8.5-.8,1.1s.3,1.1.8,1.1h9.1Z"/>
      </g>
      <circle class="cls-24" cx="65" cy="322" r="4.3" transform="translate(-260.6 365.5) rotate(-86.2)"/>
    </g>
    <rect class="cls-10" y="339.1" width="29.7" height="17.6"/>
    <path class="cls-5" d="M29.7,337.6H0v2.9h29.7c.8,0,1.5-.7,1.5-1.5s-.7-1.5-1.5-1.5h0Z"/>
    <path class="cls-18" d="M63.9,302.8c.3-.3.7-.4,1-.4v3.5l-38.6,38.6c-.6.6-1.5.6-2.1,0s-.6-1.5,0-2.1l39.7-39.7h0Z"/>
    <polygon class="cls-9" points="13.9 348.2 0 362.1 0 401.1 13.9 401.1 49.1 401.1 49.1 383.4 13.9 348.2"/>
    <polygon class="cls-18" points="0 348.2 0 362.1 13.9 348.2 0 348.2"/>
    <path class="cls-18" d="M12.8,347.2l-12.8,12.7v4.2l13.8-13.8v-3.5c-.4,0-.8.1-1,.4h0Z"/>
    <g>
      <path class="cls-18" d="M14.9,347.2c-.3-.3-.7-.4-1-.4v3.5l38.6,38.6c.6.6,1.5.6,2.1,0s.6-1.5,0-2.1l-39.7-39.7h0Z"/>
      <g>
        <rect class="cls-4" x="7.6" y="379.2" width="12.5" height="21.9"/>
        <polygon class="cls-7" points="20.1 379.2 7.6 379.2 7.6 401.1 20.1 379.2"/>
      </g>
      <g>
        <rect class="cls-24" x="24.7" y="376.3" width="9.1" height="13.9"/>
        <path class="cls-9" d="M33.8,382.5c.4,0,.8-.5.8-1.1s-.3-1.1-.8-1.1h-9.1c-.4,0-.8.5-.8,1.1s.3,1.1.8,1.1h9.1Z"/>
      </g>
      <rect class="cls-24" y="376.3" width="3.1" height="13.9"/>
      <circle class="cls-24" cx="13.9" cy="366.3" r="4.3" transform="translate(-352.6 373.7) rotate(-89)"/>
    </g>
    <path class="cls-9" d="M3.1,380.3H0v2.2h3.1c.4,0,.8-.5.8-1.1s-.3-1.1-.8-1.1Z"/>
    </g>
    <g>
      <path class="cls-17" d="M33.8,358.3c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.4-1,.5-2,0-3-.9.4-1.7,1.1-2.2,2v.3-.3c-.4-1-1.2-1.7-2.2-2-.3.9-.3,2,0,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM45.2,358.3c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-18" d="M39.5,350.5c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <g>
      <path class="cls-17" d="M45.5,358.3c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.5-1,.5-2,0-3-.9.4-1.7,1.1-2.2,2v.3-.3c-.5-1-1.2-1.7-2.2-2-.3.9-.3,2,0,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM56.9,358.3c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-18" d="M51.2,350.5c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <path class="cls-11" d="M109,342.6c-.7,0-1.3.1-1.8.4-.7-1.4-2.2-2.4-4-2.4s-2.8.7-3.6,1.8c-.6-.3-1.3-.5-2-.5-2.1,0-3.8,1.4-4.3,3.3-.5-.2-1-.3-1.6-.3-2.4,0-4.4,2-4.4,4.4s0,1.1.3,1.6c-1.3.8-2.1,2.2-2.1,3.8s1.5,3.9,3.5,4.3c.2,2.3,2.1,4,4.4,4,5.4,0,10.3-.6,15.6-.6s4.4-2,4.4-4.4,0-1-.3-1.5c1.7-.6,3-2.2,3-4.2s-1.2-3.6-2.9-4.2c0-.4.2-.8.2-1.2,0-2.4-2-4.4-4.4-4.4h0Z"/>
    <g>
      <polygon class="cls-16" points="103.6 375.9 101.2 375.9 101.4 388.4 103.5 388.3 103.6 383 103.6 375.9"/>
      <polygon class="cls-16" points="105.4 375.9 107.7 375.9 107.5 388.4 105.4 388.3 105.4 383 105.4 375.9"/>
      <g>
        <path class="cls-21" d="M107.6,387.8h-2.3c-.3.7-.5,1.3-.4,2.2h4.8c0-1.2-1.8-1.8-2.1-2.2Z"/>
        <rect class="cls-19" x="104.8" y="389.8" width="5" height=".3"/>
      </g>
      <g>
        <path class="cls-21" d="M101.3,387.8h2.3c.3.7.5,1.3.4,2.2h-4.8c0-1.2,1.8-1.8,2.1-2.2Z"/>
        <rect class="cls-19" x="99.1" y="389.8" width="5" height=".3"/>
      </g>
      <path class="cls-2" d="M100.3,376.3v4h3.7v-2.2h.9v2.2h3.7v-4h-8.4.1Z"/>
      <path id="arm1" class="cls-16" d="M102.2,364.3s-1.6.3-1.7.3c-3.9.3-6.3-2.3-7.7-5.5v-.3h0c.2-.1.2-.6.3-.8l.2-.9c0-.2,0-.4-.3-.4h0c-.2,0-.3,0-.4.2h0l-.2-1.6c0-.2-.2-.4-.4-.3h0c-.2,0-.4.2-.3.4v.5l-.2-.9c0-.2-.3-.3-.5-.2h0c-.2,0-.3.3-.2.5l.6,1.8-.8-1.6c0-.2-.3-.3-.5-.2h0c-.2,0-.3.3-.2.5l.8,1.6-.6-.8c0-.2-.4-.2-.5,0h0c-.2.1-.2.4,0,.5l.6.8v.2l.7,1.1c.2.3.3.4.5.5,1.4,4.2,4.2,7.7,9.9,7.6l1.4-2.8-.4-.2h-.1Z"/>
      <path class="cls-20" d="M101.5,364.1h-3.7l-.3,2.9c.8.2,1.7.3,2.6.3l1.4-3.1h0Z"/>
      <g>
        <path class="cls-26" d="M106.2,354.4c.4.1,1.3-2.5,1.9-1.5,2.5,1.9.4,7.7,3,7.3-.7,3.2-5.8,4.8-8.1,2.9,0,0,1.7-7.7,3.2-8.7h0Z"/>
        <path class="cls-26" d="M102.7,354.4c-.4.1-1.6-2.5-2.1-1.6-2.5,1.9-.2,7.8-2.8,7.4.7,3.2,5.8,4.8,8.1,2.9,0,0-1.7-7.7-3.2-8.7h0Z"/>
      </g>
      <path class="cls-20" d="M109,377.2h-9.1v-11.1c0-1.1.9-2,2-2h5.1c1.1,0,2,.9,2,2v11.1Z"/>
      <path class="cls-21" d="M101.8,364.1l.3.4,1.9,2.7.5.7.5-.7,1.9-2.7.3-.4h-5.4Z"/>
      <polygon class="cls-16" points="106.4 361.5 102.6 361.5 102.6 364.1 104.5 366.8 106.4 364.1 106.4 361.5"/>
      <polygon class="cls-12" points="105.6 365.2 102.6 362.2 102.6 361.5 106.4 361.5 106.4 364.1 105.6 365.2"/>
      <g>
        <path class="cls-16" d="M108.9,368.2s.8,6.2,1.3,9.1h.7l1.1-.9c-.5-3.2-1.2-8.4-1.2-8.5l-1.8.2h0Z"/>
        <path class="cls-20" d="M111,368.4l-2.2.4-.9-4.6c1.7-.3,2.5,1.3,2.6,2.1l.5,2.1Z"/>
        <path class="cls-16" d="M110.3,378.6l.4-1.1c0-.2,0-.4-.2-.5h0c-.2,0-.4,0-.5.2l-.4,1.1c0,.2,0,.4.2.5h0c.2,0,.4,0,.5-.2Z"/>
        <path class="cls-16" d="M111.3,377.4v2.4c0,.2-.3.4-.5.4h0c-.2,0-.4-.2-.4-.4v-2.4c0-.2.3-.4.5-.4h0c.2,0,.4.2.4.4Z"/>
        <path class="cls-16" d="M111.8,376.9v2.4c0,.2-.3.4-.5.4h0c-.2,0-.4-.2-.4-.4v-2.4c0-.2.3-.4.5-.4h0c.2,0,.4.2.4.4Z"/>
        <path class="cls-16" d="M111.9,376.4v2.4c0,.2-.3.4-.5.4h0c-.2,0-.4-.2-.4-.4v-2.4c0-.2.3-.4.5-.4h0c.2,0,.4.2.4.4Z"/>
      </g>
      <path class="cls-16" d="M104.5,350.5c1.2,0,4.8.1,4.9,4.4,0,4.2-.8,5.8-1.4,6.4s-1.2,1.2-2.3,1.5c-.3.3-.6.4-1.1.5-.5,0-.8-.2-1.1-.5-1.2-.3-1.8-.9-2.3-1.5-.6-.6-1.5-2.2-1.4-6.4,0-4.2,3.7-4.4,4.9-4.4h-.2Z"/>
      <path class="cls-23" d="M104.5,350.5c1.2,0,4.8.1,4.9,4.4,0,4.2-.8,5.8-1.4,6.4s-1.2,1.2-2.3,1.5c-.3.3-.6.4-1.1.5v-12.8h0Z"/>
      <path class="cls-16" d="M100.2,357c0-.3-.2-.5-.6-.6-.3,0-1,.1-1,1.1s.8,1.8,1.4,1.7c.5-.1.3-2,.2-2.3h0Z"/>
      <path class="cls-23" d="M100,357.3c0-.2-.2-.3-.4-.3s-.5,0-.5.7.4,1.1.7,1.1.2-.2.2-.4c-.2,0-.3-.3-.3-.5s0-.3.3-.4h0v-.2h0Z"/>
      <path class="cls-23" d="M108.6,357c0-.3.3-.5.7-.6.4,0,1,.1,1,1.1s-.8,1.8-1.4,1.7c-.5-.1-.3-2.3-.3-2.3h0Z"/>
      <path class="cls-12" d="M109,357.3c0-.2.2-.3.4-.3s.5,0,.5.7-.4,1.1-.7,1.1-.2-.2-.2-.4c.2,0,.3-.3.3-.5s0-.3-.3-.4h0v-.2h0Z"/>
      <path class="cls-8" d="M107.1,352.9c.5,1,.4,2.6,2.3,3.6,1.2-4.6-2-6.8-5.1-6.7-3.3,0-6.2,2.3-5.3,6.8,1.9,0,2.6-2.1,2.7-2.1,2.1,0,3.7.2,5.3-1.6h0Z"/>
      <path class="cls-21" d="M105.9,359.9c0,.5-.7.9-1.4.9s-1.3-.4-1.4-.9h2.8Z"/>
      <g>
        <path class="cls-12" d="M106.5,356.2s-.2,0-.2-.2,0-.2.2-.2c0,0,.4,0,.8.1,0,0,.2.2,0,.3,0,.1-.2.2-.3.1-.3-.1-.6-.1-.6-.1h0Z"/>
        <path class="cls-12" d="M102.4,355.8s.2.1.2.2,0,.2-.2.2c0,0-.3,0-.6.1,0,0-.2,0-.3-.1v-.3c.4-.1.8-.1.8-.1h0Z"/>
      </g>
      <g>
        <g>
          <path class="cls-14" d="M107.4,357.4h.2v-.2h0c-.3-.2-.8-.6-1.5,0v.2h.2c.6-.5,1-.2,1.2,0h0Z"/>
          <circle class="cls-19" cx="106.8" cy="357.4" r=".4"/>
          <path class="cls-14" d="M107.5,356.9h0c0,0,0-.1,0-.1v.3c-.1,0-.1,0,0,.1h0v-.3h0Z"/>
          <path class="cls-14" d="M107.6,357h0c0-.1-.2.2-.2.2h0c0,.1.2-.2.2-.2Z"/>
        </g>
        <g>
          <path class="cls-14" d="M101.5,357.4h-.2v-.2h0c.3-.2.8-.6,1.5,0v.2h-.2c-.6-.5-1-.2-1.2,0h0Z"/>
          <circle class="cls-19" cx="102.1" cy="357.4" r=".4"/>
          <path class="cls-14" d="M101.5,356.9h0c0,0,0-.1,0-.1v.3c.1,0,.1,0,0,.1h0v-.3h0Z"/>
          <path class="cls-14" d="M101.3,357h0c0,0,0-.1,0-.1l.2.3h0c0,.1-.2-.2-.2-.2Z"/>
        </g>
      </g>
      <path class="cls-25" d="M100.8,352.9h.4l.9,1.3v.4h-.4l-.9-1.3v-.4Z"/>
    </g>
    <g>
      <path class="cls-24" d="M56.9,380.8h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <path class="cls-24" d="M56.9,387.8h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <g>
        <path class="cls-24" d="M61.1,375.4c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-24" d="M72.4,375.4c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-24" d="M83.7,375.4c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-24" d="M95,375.4c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-24" d="M106.3,375.4c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-24" d="M118.6,376.3c0-.5-.4-.9-.9-.9s-.9.4-.9.9v16.1h1.8v-16.1Z"/>
      </g>
    </g>
    <g>
      <polygon class="cls-16" points="77.3 386.1 79.6 386.1 79.4 398.7 77.3 398.6 77.3 393.2 77.3 386.1"/>
      <polygon class="cls-16" points="75.4 386.1 73.1 386.1 73.2 398.7 75.4 398.6 75.4 393.2 75.4 386.1"/>
      <g>
        <path class="cls-18" d="M73.1,398.1h2.4c.3.8.5,1.4.4,2.3h-4.9c0-1.2,1.8-1.8,2.1-2.3Z"/>
        <rect class="cls-19" x="70.9" y="400.1" width="5" height=".3"/>
      </g>
      <g>
        <path class="cls-18" d="M79.6,398.1h-2.4c-.3.8-.5,1.4-.4,2.3h4.9c0-1.2-1.8-1.8-2.1-2.3Z"/>
        <rect class="cls-19" x="76.7" y="400.1" width="5" height=".3"/>
      </g>
      <path class="cls-18" d="M80.6,386.4v4h-3.7v-2.2h-1v2.2h-3.7v-4s8.4,0,8.4,0Z"/>
      <path id="arm2" class="cls-16" d="M78.6,374.4s1.6.3,1.7.3c3.9.3,6.3-2.3,7.8-5.5v-.3h0c-.2-.1-.2-.6-.3-.8l-.2-.9c0-.2,0-.4.3-.4h0c.2,0,.3,0,.4.2h0l.2-1.7c0-.2.2-.4.4-.3h0c.2,0,.4.2.3.4v.5l.2-.9c0-.2.3-.3.5-.2h0c.2,0,.3.3.2.5l-.6,1.8.8-1.6c0-.2.3-.3.5-.2h0c.2,0,.3.3.2.5l-.8,1.7.6-.8c0-.2.4-.2.5,0h0c.2.1.2.4,0,.5l-.6.8v.2l-.7,1.1c-.2.3-.3.4-.5.5-1.4,4.2-4.3,7.7-10,7.7l-1.4-2.8.4-.3h0Z"/>
      <path class="cls-13" d="M79.4,374.2h3.7l.3,2.9c-.8.2-1.7.3-2.6.3l-1.4-3.2h0Z"/>
      <g>
        <path class="cls-15" d="M74.6,364.3c-.4.1-1.3-2.5-1.9-1.6-2.5,1.9-.4,7.8-3,7.4.7,3.2,5.9,4.9,8.1,2.9,0,0-1.7-7.7-3.2-8.8h0Z"/>
        <path class="cls-15" d="M78.1,364.3c.4.1,1.6-2.6,2.1-1.6,2.5,1.9.2,7.8,2.8,7.5-.7,3.2-5.9,4.9-8.1,2.9,0,0,1.7-7.7,3.2-8.8Z"/>
      </g>
      <path class="cls-13" d="M71.7,387.3h9.2v-11.2c0-1.1-.9-2-2-2h-5.1c-1.1,0-2,.9-2,2v11.2h0Z"/>
      <path class="cls-21" d="M79,374.1l-.3.4-1.9,2.7-.5.7-.5-.7-1.9-2.7-.3-.4h5.4Z"/>
      <polygon class="cls-16" points="74.4 371.6 78.2 371.6 78.2 374.2 76.3 376.9 74.4 374.2 74.4 371.6"/>
      <polygon class="cls-12" points="75.2 375.2 78.2 372.2 78.2 371.6 74.4 371.6 74.4 374.2 75.2 375.2"/>
      <g>
        <path class="cls-16" d="M71.9,378.3s-.8,6.2-1.3,9.2h-.7l-1.1-.9c.5-3.2,1.2-8.5,1.2-8.5l1.9.2h0Z"/>
        <path class="cls-13" d="M69.8,378.5l2.3.4.9-4.6c-1.7-.3-2.5,1.3-2.6,2.1l-.5,2.1h-.1Z"/>
        <path class="cls-16" d="M70.4,388.8l-.4-1.2c0-.2,0-.4.2-.5h0c.2,0,.4,0,.5.2l.4,1.2c0,.2,0,.4-.2.5h0c-.2,0-.4,0-.5-.2Z"/>
        <path class="cls-16" d="M69.5,387.6v2.4c0,.2.3.4.5.4h0c.2,0,.4-.2.4-.4v-2.4c0-.2-.3-.4-.5-.4h0c-.2,0-.4.2-.4.4Z"/>
        <path class="cls-16" d="M69,387v2.4c0,.2.3.4.5.4h0c.2,0,.4-.2.4-.4v-2.4c0-.2-.3-.4-.5-.4h0c-.2,0-.4.2-.4.4Z"/>
        <path class="cls-16" d="M68.8,386.5v2.4c0,.2.3.4.5.4h0c.2,0,.4-.2.4-.4v-2.4c0-.2-.3-.4-.5-.4h0c-.2,0-.4.2-.4.4Z"/>
      </g>
      <path class="cls-16" d="M76.3,360.4c-1.2,0-4.9.1-5,4.4,0,4.3.9,5.8,1.4,6.5.6.6,1.2,1.2,2.4,1.5.3.3.6.4,1.2.5.5,0,.8-.2,1.2-.5,1.2-.3,1.8-.9,2.4-1.5s1.5-2.2,1.4-6.5c0-4.3-3.7-4.4-4.9-4.4h0Z"/>
      <path class="cls-23" d="M76.3,360.4c-1.2,0-4.9.1-5,4.4,0,4.3.9,5.8,1.4,6.5.6.6,1.2,1.2,2.4,1.5.3.3.6.4,1.2.5v-12.9Z"/>
      <path class="cls-16" d="M80.6,367c0-.3.2-.5.6-.6.4,0,1,.1,1,1.1s-.8,1.9-1.4,1.7c-.5-.1-.3-2-.2-2.3h0Z"/>
      <path class="cls-23" d="M80.9,367.3c0-.2.2-.3.4-.3s.5,0,.5.7-.4,1.1-.8,1.1-.2-.2-.2-.4c.2,0,.3-.3.3-.5s0-.3-.3-.4h0v-.2h0Z"/>
      <path class="cls-23" d="M72.1,367c0-.3-.3-.5-.7-.6-.4,0-1,.1-1,1.1s.8,1.9,1.4,1.7c.5-.1.3-2.3.3-2.3h0Z"/>
      <path class="cls-12" d="M71.8,367.3c0-.2-.2-.3-.4-.3s-.5,0-.5.7.4,1.1.8,1.1.2-.2.2-.4c-.2,0-.3-.3-.3-.5s0-.3.3-.4h0v-.2h0Z"/>
      <path class="cls-6" d="M73.7,362.8c-.5,1-.4,2.6-2.3,3.6-1.3-4.7,2-6.8,5.1-6.7,3.3,0,6.3,2.3,5.4,6.9-1.9,0-2.7-2.1-2.8-2.2-2.1,0-3.7.2-5.4-1.6h0Z"/>
      <path class="cls-21" d="M74.9,369.9c0,.5.7.9,1.4.9s1.3-.4,1.4-.9h-2.9,0Z"/>
      <g>
        <path class="cls-12" d="M74.2,366.1s.2,0,.2-.2,0-.2-.2-.2c0,0-.4,0-.8.1,0,0-.2.2,0,.3,0,.1.2.2.3.1.3-.1.6-.1.6-.1h0Z"/>
        <path class="cls-12" d="M78.4,365.7s-.2.1-.2.2,0,.2.2.2c0,0,.3,0,.6.1,0,0,.2,0,.3-.1v-.3c-.4-.1-.8-.1-.8-.1h0Z"/>
      </g>
      <g>
        <g>
          <path class="cls-14" d="M73.3,367.3h-.2v-.2h0c.3-.2.8-.6,1.5,0v.2h-.2c-.6-.5-1-.2-1.2,0h0Z"/>
          <circle class="cls-19" cx="74" cy="367.3" r=".4"/>
          <path class="cls-14" d="M73.3,366.9h0c0,0,0-.1,0-.1v.3c.1,0,.1,0,0,.1h0v-.3h0Z"/>
          <path class="cls-14" d="M73.1,366.9h0c0,0,0-.1,0-.1l.2.3h0c0,.1-.2-.2-.2-.2Z"/>
        </g>
        <g>
          <path class="cls-14" d="M79.3,367.3h.2v-.2h0c-.3-.2-.8-.6-1.5,0v.2h.2c.6-.5,1-.2,1.2,0h0Z"/>
          <circle class="cls-19" cx="78.7" cy="367.3" r=".4"/>
          <path class="cls-14" d="M79.3,366.9h0c0,0,0-.1,0-.1v.3c-.1,0-.1,0,0,.1h0v-.3h0Z"/>
          <path class="cls-14" d="M79.5,366.9h0c0,0,0-.1,0-.1l-.2.3h0c0,.1.2-.2.2-.2Z"/>
        </g>
      </g>
      <path class="cls-18" d="M80.1,362.9h-.4l-.9,1.3v.4h.4l.9-1.3v-.4Z"/>
    </g>
    <g>
      <path class="cls-17" d="M35.6,403.6c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.4-1,.5-2,0-3-.9.4-1.7,1.1-2.2,2v.3-.3c-.4-1-1.2-1.7-2.2-2-.3.9-.3,2,0,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM47,403.6c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-18" d="M41.3,395.8c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <g>
      <path class="cls-17" d="M21.7,403.3c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.5-1,.5-2,.1-3-.9.4-1.7,1.1-2.2,2,0,.1,0,.2-.1.3,0-.1,0-.2-.1-.3-.5-1-1.2-1.7-2.2-2-.3.9-.3,2,.1,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM33.1,403.3c-.2,1.2-.9,2.3-2,3-1.1.7-2.3,1-3.5.8.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-18" d="M27.4,395.5c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <polygon class="cls-1" points="0 369.8 16.7 353.1 13.8 350.3 0 364.1 0 369.8"/>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'forsale',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
        found:false
      }
    },
    mounted: function(){
      var hb = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
      hb.fromTo('#arm1', .9, {rotation:1},{rotation:10, transformOrigin:"100% 100%"})

      var hb2 = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
      hb2.to('#arm2', .8, {rotation:7, transformOrigin:"0% 50%"})
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('Neighbour')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #7bc3ad;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-3, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-20, .cls-21, .cls-22, .cls-23, .cls-25, .cls-26 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #4a2df1;
      }

      .cls-3 {
        fill: #f5c9c9;
      }

      .cls-4 {
        fill: #f77917;
      }

      .cls-5 {
        fill: #f58787;
      }

      .cls-6 {
        fill: #ff9;
      }

      .cls-7 {
        fill: #eb6600;
      }

      .cls-8 {
        fill: #ff4621;
      }

      .cls-9 {
        fill: #c9ebf5;
      }

      .cls-10, .cls-22 {
        fill: #f5abab;
      }

      .cls-11 {
        fill: #69990a;
      }

      .cls-12 {
        fill: #d4a15e;
      }

      .cls-13 {
        fill: #aac905;
      }

      .cls-14, .cls-19 {
        fill: #471e04;
      }

      .cls-15 {
        fill: #f5e97d;
      }

      .cls-16 {
        fill: #f2d291;
      }

      .cls-17 {
        fill: #8bb408;
      }

      .cls-18 {
        fill: #f1502d;
      }

      .cls-20 {
        fill: #057bc9;
      }

      .cls-21, .cls-24 {
        fill: #fff;
      }

      .cls-23 {
        fill: #edba68;
      }

      .cls-25 {
        fill: #000;
      }

      .cls-26 {
        fill: #cf391b;
      }
  </style>
