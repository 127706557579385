<template>
  <div id="houseboat" class="layer home">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-33" d="M91.3,186.8l-8.9-1.3-5.2-7-.5-.6-.3-.2c-.3-.3-.6-.5-1-.8l-.4-.3h-.3c-.4-.3-.8-.4-1.3-.5-.1,0-.2,0-.3,0h-.4c0,0-41.3,0-41.3,0v-9.8h-11.1v10.6c-.5.3-1,.7-1.4,1.2l-8.1,8.9c-1.6,1.8-2,4.3-1,6.5,1,2.2,3.1,3.6,5.5,3.6h3.1v2.9h-6.4v1.8h-.5v5c0,6.6,5.3,11.9,11.9,11.9h53.5c5.4,0,16-4.2,16-11.9v-5h-1.2v-1.8h-2.3v-1.1h.1c3.3.5,6.3-1.8,6.8-5,.5-3.3-1.8-6.3-5-6.8Z"/>
  <g >
    <g id="boat" class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <rect class="cls-35" x="25.4" y="171.2" width="1.1" height="10.8"/>
      <polygon class="cls-22" points="89.5 191.9 78.5 191.9 78.5 197.1 89.9 193 89.5 191.9"/>
      <polygon class="cls-11" points="72.3 182 65.7 190.9 15.3 190.9 23.4 182 72.3 182"/>
      <rect class="cls-5" x="23.4" y="191.1" width="42.7" height="13.7"/>
      <polygon class="cls-29" points="79 204.8 65.7 204.8 65.7 191.1 72.3 182 79 191.1 79 204.8"/>
      <circle class="cls-40" cx="29.8" cy="198.7" r="3.1"/>
      <circle class="cls-40" cx="37.6" cy="198.7" r="3.1"/>
      <circle class="cls-40" cx="45.4" cy="198.7" r="3.1"/>
      <circle class="cls-6" cx="55.2" cy="196.3" r="2.3"/>
      <circle class="cls-6" cx="60.8" cy="196.3" r="2.3"/>
      <line class="cls-2" x1="51.1" y1="190.9" x2="51.1" y2="205.1"/>
      <line class="cls-2" x1="83.9" y1="191.9" x2="83.9" y2="206.1"/>
      <line class="cls-2" x1="64.9" y1="190.9" x2="64.9" y2="205.1"/>
      <polygon class="cls-1" points="72.3 182 65.7 190.9 15.3 190.9 23.4 182 72.3 182"/>
      <rect class="cls-26" x="17" y="204.8" width="69.7" height="2.9"/>
      <path class="cls-39" d="M87.9,206.6h0c0,3.8-7.2,6.9-11,6.9H23.4c-3.8,0-6.9-3.1-6.9-6.9h0"/>
      <polyline class="cls-1" points="90.4 192.8 79 191.1 72.3 182"/>
      <g id="guy">
    <g>
      <path class="cls-27" d="M69.6,204.8h5.5v-5.4c0-.7-.5-1.2-1.2-1.2h-3.1c-.7,0-1.2.5-1.2,1.2,0,0,0,6.4,0,5.4Z"/>
      <path class="cls-27" d="M68.5,200.7l1.3.2.5-2.7c-1-.2-1.4.8-1.5,1.2l-.3,1.2h0Z"/>
      <path class="cls-27" d="M76.1,200.7l-1.3.2-.5-2.7c1-.2,1.4.8,1.5,1.2l.3,1.2h0Z"/>
      <g>
        <path class="cls-30" d="M70.1,199.3h-1.3v.2h0c0,.1,1.3.1,1.3.1v-.4h0Z"/>
        <path class="cls-30" d="M69.4,198.5h.9v.4h-1.2c0-.1.2-.3.3-.4Z"/>
        <polygon class="cls-30" points="68.7 200 70 200 69.9 200.3 68.6 200.3 68.7 200"/>
        <polygon class="cls-30" points="69.8 200.7 68.5 200.7 68.5 200.7 69.8 201 69.8 200.7"/>
      </g>
      <g>
        <path class="cls-30" d="M75.8,199.3h-1.3v.4h1.4v-.4h0Z"/>
        <path class="cls-30" d="M74.4,198.5h.9c0,.1.2.2.3.4h-1.1v-.4h-.1Z"/>
        <polygon class="cls-30" points="74.6 200 76 200 76 200.3 74.7 200.3 74.6 200"/>
        <polygon class="cls-30" points="76.1 200.7 74.8 200.7 74.8 201 76.1 200.7 76.1 200.7"/>
      </g>
      <g>
        <g>
          <path class="cls-30" d="M75.1,199.3h-5.5v.3h5.5v-.2h0Z"/>
          <path class="cls-30" d="M69.9,198.5h4.8c0,.1.2.2.3.4h-5.3c0-.1.2-.3.3-.4h-.1Z"/>
          <rect class="cls-33" x="69.6" y="200" width="5.5" height=".4"/>
          <path class="cls-30" d="M74.9,200.7h-5.2v.4h5.4v-.4h-.2Z"/>
          <rect class="cls-33" x="69.6" y="201.4" width="5.5" height=".4"/>
        </g>
        <g>
          <rect class="cls-33" x="69.6" y="202.2" width="5.5" height=".4"/>
          <rect class="cls-33" x="69.6" y="202.9" width="5.5" height=".4"/>
          <path class="cls-30" d="M75,203.6h-5.4v.4h5.3v-.4h0Z"/>
          <path class="cls-30" d="M69.8,204.4h5v.4h-4.7v-.4h-.3Z"/>
        </g>
      </g>
      <path class="cls-30" d="M73.7,198.2h0c0,.4-.3.7-.5.9s-.6.3-.9.3-.6-.1-.9-.3-.4-.5-.5-.8h0c0-.1.7-.1.7-.1,0,.1,0,.3.3.4,0,.1.3.2.5.2s.3,0,.5-.2c0,0,.2-.2.3-.4h.7-.2Z"/>
      <path class="cls-20" d="M71.2,197.5h2.1v.6c0,.5-.6.8-1.1.8s-1-.4-1.1-.8v-.6h0Z"/>
      <path class="cls-13" d="M71.3,197.7l2.1-.2h0v.6c0,.4-.4.7-.8.8l-1.3-1.3h0Z"/>
      <g>
        <path class="cls-20" d="M72.3,191c-.7,0-2.8,0-2.8,2.5s.5,3.4.8,3.7c.3.4.7.7,1.4.9.2.2.4.2.7.3v-7.4h0Z"/>
        <path class="cls-31" d="M72.3,191c.7,0,2.8,0,2.8,2.5s-.5,3.4-.8,3.7c-.3.4-.7.7-1.4.9-.2.2-.4.2-.7.3v-7.4h0Z"/>
      </g>
      <path class="cls-12" d="M69.6,194.6c.2-1.4.4-1.4.5-1.5.3,0,2.7.2,4.1-.7,1,.9,1,2.2,1,2.2,0,0,1-3.3-1.9-3.9.2-.4,0-.8-.2-.9,0,.3-.4.8-.5.8,0-.1.2-.4-.2-.6,0,.2-.3.5-.9.8-2.4.8-2.2,3.3-1.8,3.9h0Z"/>
      <path class="cls-20" d="M69.9,194.8c0-.2,0-.3-.3-.3s-.6,0-.6.6.5,1.1.8,1c.3,0,.2-1.2,0-1.3h0Z"/>
      <path class="cls-31" d="M69.7,195s0-.2-.2-.2-.3,0-.3.4.2.7.4.6v-.5c0-.1,0-.2.2-.2h0Z"/>
      <path class="cls-31" d="M74.7,194.8c0-.2.2-.3.4-.3s.6,0,.6.6-.5,1.1-.8,1c-.3,0-.2-1.3-.2-1.3Z"/>
      <path class="cls-13" d="M74.9,195s0-.2.2-.2.3,0,.3.4-.2.7-.4.6v-.5c0-.1,0-.2-.2-.2h0Z"/>
      <path class="cls-12" d="M70.7,194.1h.7s.2,0,.2-.2h0s0-.2-.2-.2h-.7s-.2,0-.2.2h0s0,.2.2.2Z"/>
      <path class="cls-12" d="M73.3,194.1h.7s.2,0,.2-.2h0s0-.2-.2-.2h-.7s-.2,0-.2.2h0s0,.2.2.2Z"/>
      <g>
        <path class="cls-34" d="M73.7,194.6s-.3.1-.3.3,0,.3.3.3.3-.1.3-.3,0-.3-.3-.3Z"/>
        <path class="cls-34" d="M74.1,194.8h0c-.2-.2-.5-.5-.9-.1h0c.3-.2.6,0,.7.1h.2Z"/>
        <path class="cls-34" d="M70.9,194.6s.3.1.3.3,0,.3-.3.3-.3-.1-.3-.3,0-.3.3-.3Z"/>
        <path class="cls-34" d="M70.5,194.8h0c.2-.2.5-.5.9-.1h0c-.3-.2-.6,0-.7.1h-.2Z"/>
      </g>
      <path class="cls-30" d="M73.2,196.3c0,.4-.4.7-.9.7s-.8-.3-.9-.7h1.8Z"/>
    </g>
    <path class="cls-20" d="M74.6,204.8h1.3c.8-2.1,0-4.4,0-4.4h0l-1,.2s.6,2.1-.2,3.9c0,0,0,.2-.2.3Z"/>
    <g>
      <path class="cls-7" d="M69.7,200.7h0Z"/>
      <path class="cls-7" d="M70,204.8v-.2c-.8-1.6-.4-3.4-.3-3.8l-1-.3s-.7,2.3,0,4.3h1.3Z"/>
    </g>
  </g>
    </g>
    <g>
    <path id="tail" class="cls-15" d="M55.9,265.7l1.6.3c0,1-.7,1.6-1.2,2.1s-1.2.7-1.8.8c-.7,0-1.4,0-1.9-.3-.7-.3-1.2-.8-1.5-1.5-.4-1.1-.4-1.9,0-2.6.3-.7,1-1.2,1.6-1.4.3,0,1.4,0,1.4.3s-.7,0-1.1.5c-.4.4-.5.8-.7,1.4-.3,2.7,3.4,2.1,3.7.4h-.1Z"/>
    <path class="cls-15" d="M56.3,261.6l1.8,9.5,2.6-.5c-.4-2.3-1-4.7-1.4-7.1,0-.7-.7-3.4-1.9-3.7-.7,0-.5,1.8-1.2,1.9h.1Z"/>
    <path class="cls-4" d="M59.2,269.6c-.7,0-1.5.3-1.5,1.1s0,1,.5,1,.4,0,.5-.3c0,0,.4.3.7.3h1.1c.3,0,.7-.4.5-.8,0-1-1.1-1.1-1.9-1.1h0Z"/>
    <path class="cls-15" d="M57.6,261.6l-1.8,9.5-2.6-.5c.4-2.3,1-4.7,1.4-7.1,0-.7.7-3.7,2.2-3.8.7,0,.3,1.9,1,2.1h-.1Z"/>
    <path class="cls-4" d="M54.7,269.6c.7,0,1.5.3,1.5,1.1s0,1-.5,1-.4,0-.5-.3c0,0-.4.3-.7.3h-1.1c-.3,0-.7-.4-.5-.8,0-1,1.1-1.1,1.9-1.1h0Z"/>
    <path class="cls-14" d="M57.3,259.4h3.7c.8,0,1.5.7,1.5,1.5v4c0,.8-.7,1.5-1.5,1.5h-3.7c-.8,0-1.5-.7-1.5-1.5v-4c0-.8.7-1.5,1.5-1.5Z"/>
    <rect class="cls-14" x="51.9" y="264.6" width="10" height="2.6" transform="translate(-210.4 315.4) rotate(-88.4)"/>
    <path class="cls-10" d="M57.2,270c.7,0,1.5.3,1.5,1.1s0,1-.5,1-.4,0-.5-.3c0,0-.4.3-.7.3h-1.1c-.3,0-.7-.4-.5-.8,0-1,1.1-1.1,1.9-1.1h0Z"/>
    <rect class="cls-14" x="59.6" y="261.4" width="2.6" height="10" transform="translate(-7.4 1.8) rotate(-1.6)"/>
    <path class="cls-10" d="M61.2,270c-.7,0-1.5.3-1.5,1.1s0,1,.5,1,.4,0,.5-.3c0,0,.4.3.7.3h1.1c.3,0,.7-.4.5-.8,0-1-1.1-1.1-1.9-1.1h0Z"/>
    <path id="tongue" class="cls-8" d="M60.2,259.3v2.7c0,.5-.4,1.1-1.1,1.1h0c-.5,0-1.1-.4-1.1-1.1v-2.7h2.2Z"/>
    <path class="cls-9" d="M59.1,260.5h0v1.6h0v-1.6Z"/>
    <path class="cls-18" d="M55.9,253.6c-1.2,1-.5,3-.4,3.7,0,.7.3,1.6,1.2,2.2l2.3-.4,2.5.4c1-.5,1.1-1.5,1.2-2.2,0-.8.8-2.9-.4-3.7-1.5-1.2-4.8-1.2-6.5,0h0Z"/>
    <path class="cls-10" d="M63.2,255.8c0,.7-.3,1.2-.3,1.6,0,.7-.3,1.6-1.2,2.2-.4,0-.7,0-.8-.5,0,0-.5-.4-.5-.5,0-1.2,0-2.9,2.9-2.7h0Z"/>
    <path class="cls-15" d="M62.7,252h-1c-.5,0-.8.4-.5,1.2.8,4.1,2.1,4.1,3,0,0-1,0-1.4-.5-1.2h-1,0Z"/>
    <path class="cls-15" d="M55.7,252h-1c-.5,0-.8.4-.5,1.2.8,4.1,2.1,4.1,3,0,0-1,0-1.4-.5-1.2h-1,0Z"/>
    <path class="cls-10" d="M59.2,260.2c0,.4-.4.8-1.2.8-1.2,0-1.2-1.2-1.2-1.4,0-.3.8-1,2.5,0,1.6-1.1,2.3-.4,2.5,0,0,.3,0,1.4-1.2,1.4s-1.1-.4-1.2-.8h0Z"/>
    <path class="cls-19" d="M58.7,259.8h1.1c.5,0,1-.4,1-1h0c0-.3,0-.4-.4-.4h-2.2c-.3,0-.4,0-.4.4h0c0,.5.4,1,1,1h0Z"/>
    <path class="cls-3" d="M58.1,259h.5-.5Z"/>
    <path class="cls-36" d="M57,256.9c.3,0,.4.3.4.4v.4c-.3-.3-.5-.3-.8-.3h0c0-.3.3-.4.4-.4h0Z"/>
    <path class="cls-30" d="M57.2,257.3h0Z"/>
    <path class="cls-36" d="M61.4,256.9c-.3,0-.4.3-.4.4v.4c.3-.3.5-.3.8-.3h0c0-.3-.3-.4-.4-.4h0Z"/>
    <path class="cls-30" d="M61.6,257.3h0Z"/>
  </g>
    <g>
      <g>
        <path class="cls-28" d="M83,260.6h-2.5v5.3c-.2,1.4-.8,2.5.8,2.4h4.1c.4,0,1,0,.4-.7-.8-.8-2.8-1.1-2.8-1.8v-5.3h0Z"/>
        <path class="cls-28" d="M76,260.6h2.5v5.3c.2,1.4.8,2.5-.8,2.4h-4.1c-.4,0-1,0-.4-.7.8-.8,2.8-1.1,2.8-1.8v-5.3h0Z"/>
      </g>
      <path class="cls-21" d="M85.5,248.3l-2.4.4-.9-4.2c1.8-.3,2.6,1.2,2.8,1.9l.5,1.9h0Z"/>
      <path id="sfb1" class="cls-25" d="M100.4,252.1c0-3.3-12.1-4.3-12.8-4.3-7.5-.4-16.1-.4-21.9,4.3,5.8,4.7,14.4,4.7,21.9,4.3.6,0,12.8-1.1,12.8-4.3h0Z"/>
      <path id="sfb2" class="cls-37" d="M100.4,252.1h-34.6c5.8,4.7,14.4,4.7,21.9,4.3.6,0,12.8-1.1,12.8-4.3h-.1Z"/>
      <path class="cls-28" d="M73.5,248c0,.4-.3,1.1-.7,1.6,0,.1-.2.2-.3.3h-.2c-.4-.3-.9-1-1.4-2.5l-1.6.6c.7,1.9,1.4,2.9,2.1,3.3.6.4,1.1.4,1.6.3.5-.1.8-.4,1.2-.8.6-.8,1-2,1.1-2.7h-1.8,0Z"/>
      <path class="cls-21" d="M73.3,248.3l2.1.4.8-4.2c-1.5-.3-2.3,1.2-2.4,1.9l-.4,1.9h0Z"/>
      <g id="rhand">
        <path class="cls-28" d="M68.6,246.9l.6.8c0,.1.3.2.4,0h0s.2-.3,0-.4l-.6-.8c0-.1-.3-.2-.4,0h0s-.2.3,0,.4Z"/>
        <path class="cls-23" d="M70.8,246.2v1c0,.4,0,.6-.2.7l-.9.2c-.3,0-.4,0-.5-.4l-.5-1c0-.2.2,0,.5,0l1.1-.4c.2,0,.5-.4.5,0v-.2h0Z"/>
        <path class="cls-28" d="M70.8,245v1.5c0,.1-.2.3-.4.2h0s-.3-.1-.2-.3v-1.5c0-.1.2-.3.4-.2h0s.3.1.2.3Z"/>
        <path class="cls-28" d="M69.2,245.2l.4,1.4c0,.1.2.2.3.2h0s.2-.2.2-.3l-.4-1.4c0-.1-.2-.2-.3-.2h0s-.2.2-.2.3Z"/>
        <path class="cls-28" d="M69.4,246.2l.5.7v.4h-.4l-.5-.7v-.4h.4Z"/>
        <path class="cls-28" d="M70.1,247.3l.5.4h.4v-.4l-.5-.4h-.4v.4Z"/>
      </g>
      <g id="hand">
        <path class="cls-28" d="M86.9,256.5h0c0,.3,0,.4-.3.4h0c-.2,0-.3-.1-.3-.3h0c0-.3,0-.4.3-.4h0c.2,0,.3.1.3.3Z"/>
        <path class="cls-28" d="M85.6,256.5h0c0,.3,0,.4.3.4h0c.2,0,.3-.1.3-.3h0c0-.3,0-.4-.3-.4h0c-.2,0-.3.1-.3.3Z"/>
        <path class="cls-28" d="M84.4,256.5h0c0,.3,0,.4.3.4h0c.2,0,.3-.1.3-.3h0c0-.3,0-.4-.3-.4h0c-.2,0-.3.1-.3.3Z"/>
        <path class="cls-28" d="M85.6,256.5h0c0,.3,0,.4-.3.4h0c-.2,0-.3-.1-.3-.3h0c0-.3,0-.4.3-.4h0c.2,0,.3.1.3.3Z"/>
      </g>
      <path class="cls-21" d="M75.3,256.3h8.4v-10.3c0-1-.8-1.9-1.9-1.9h-4.7c-1,0-1.9.8-1.9,1.9v10.3h0Z"/>
      <path class="cls-16" d="M83.7,255.1h-8.4v6h3.8l-.3-4.4h1.4l-.3,4.4h3.8v-6Z"/>
      <polygon class="cls-30" points="76.6 255.2 77.1 255.7 75.6 257.5 75.2 257.1 76.6 255.2"/>
      <polygon class="cls-30" points="82.3 255.2 81.9 255.7 83.3 257.5 83.8 257.1 82.3 255.2"/>
      <path class="cls-30" d="M81.8,244.1h0v.2c0,.5-.4.9-.8,1.2s-.9.5-1.4.5-1-.2-1.4-.5-.7-.7-.8-1.2h0v-.2h1.1c0,.2.2.4.4.6.2.2.5.3.8.3s.6,0,.8-.3c.2-.2.4-.4.4-.6h1.1-.2Z"/>
      <path class="cls-28" d="M77.8,243.1h3.4v1.1c-.2.8-.9,1.3-1.7,1.3s-1.5-.6-1.7-1.3v-1h0Z"/>
      <path class="cls-32" d="M77.8,243.4l3.4-.3h0v1.1c-.2.6-.7,1.1-1.3,1.3l-2.1-2.1Z"/>
      <rect class="cls-38" x="75.3" y="247.6" width="8.4" height="1.5"/>
      <g>
        <path class="cls-17" d="M85.2,235c-.9,1.5-1.7,1.3-2.3.9v-2c0,.2,1.1.6,1.1.7s.4.5,1.2.3h0Z"/>
        <path class="cls-23" d="M79.2,232.4c1.1,0,4.5.1,4.6,4.1,0,3.9-.8,5.4-1.3,6s-1.1,1.1-2.2,1.4c-.3.3-.6.4-1.1.5v-11.9h0Z"/>
        <path class="cls-28" d="M79.2,232.4c-1.1,0-4.5.1-4.6,4.1,0,3.9.8,5.4,1.3,6s1.1,1.1,2.2,1.4c.3.3.6.4,1.1.5v-11.9h0Z"/>
        <path class="cls-17" d="M77.7,234c1.1.4,2.8,1.4,4.8,1.3.8.2,1,.9,1.2,2.7.2-3.4-.3-6.4-4.6-6.5-3.8,0-4.7,3.1-4.5,6.4.3-1.4.9-4.7,3-3.9h0Z"/>
        <path class="cls-23" d="M83.1,238.4c0-.3.2-.4.6-.5.3,0,.9,0,.9,1s-.8,1.7-1.3,1.6c-.5-.1-.3-1.9-.2-2.1Z"/>
        <path class="cls-24" d="M83.3,238.7c0-.2.2-.3.4-.3s.5,0,.5.6-.4,1-.7,1c0,0,0-.2-.2-.3.2,0,.3-.3.3-.5s0-.3-.3-.4h0Z"/>
        <path class="cls-28" d="M75.3,238.5c0-.2-.3-.5-.6-.5s-.9,0-.9,1,.8,1.7,1.3,1.6c.5-.1.3-2.1.3-2.1h-.1Z"/>
        <path class="cls-24" d="M75,238.7c0-.2-.2-.3-.4-.3s-.5,0-.5.6.4,1,.7,1c0,0,0-.2.2-.3-.2,0-.3-.3-.3-.5s0-.3.3-.4h0Z"/>
        <path class="cls-17" d="M81.8,237.9h-1.1s-.2-.1-.2-.2h0c0-.1,0-.2.2-.2h1.1s.2.1.2.2h0c0,.1,0,.2-.2.2Z"/>
        <path class="cls-17" d="M77.7,237.9h-1.1s-.2-.1-.2-.2h0c0-.1,0-.2.2-.2h1.1s.2.1.2.2h0c0,.1,0,.2-.2.2Z"/>
        <path class="cls-17" d="M81.7,238.8h.2v-.2c0-.1-.2-.2-.3-.3h-.8s-.2.1-.3.3v.2h.2s0-.1.2-.2h.4l.2.2h.2Z"/>
        <path class="cls-17" d="M77.6,238.8h.2v-.2c0-.1-.2-.2-.3-.3h-.8s-.2.1-.3.3v.2h.2s0-.1.2-.2h.4l.2.2h.2Z"/>
        <path class="cls-30" d="M78.2,241.3c0,.5.5.8,1,.8s.9-.4,1-.8h-2Z"/>
        <path class="cls-17" d="M85.1,233.1c0,2.9-2.2,2.1-2.5,2.1s-3.1-.8-6.3-2c-.5.2-.3.5-1.3,1.2.8-1.7,1.7-2.8,4.3-2.9,1.5,0,3.6.8,4.4,2,0,.1.7.2,1.4-.4h0Z"/>
      </g>
    </g>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  mounted: function(){

    var hb = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
    hb.to('#boat', 2, {y:2});

    var hbg = gsap.timeline({repeat: -1, repeatDelay:2, ease:"Power2.easeInOut"});
    hbg.to('#guy', 6, {x:-18})
    hbg.to('#guy', 10, {x:2},'+=2')
    hbg.to('#guy', 1, {x:0},'+=4')

    var hb2 = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
    hb2.to('#tongue', .23, {y:.5})

    var hb3 = gsap.timeline({repeat: -1, yoyo: true, repeatDelay:6, ease:"Power2.easeInOut"});
    hb3.fromTo('#sfb1', 1, {rotation:-4},{rotation:2});
    hb3.fromTo('#sfb2', 1, {rotation:-4,x:0},{rotation:1.5,x:-.2},'-=1');
    hb3.fromTo('#hand', 1, {y:-1.4},{y:.8},'-=1');

    var hb4 = gsap.timeline({repeat: -1, yoyo: true, repeatDelay:3, ease:"Power2.easeInOut"});
    hb4.fromTo('#rhand', 1, {x:-1.2,y:2,rotation:-45},{x:-1,rotation:0, transformOrigin:"50% 50%"});



    // var hb3 = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
    // hb3.to('#tail', .15, {rotation:20})
  },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('HouseBoat')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
.cls-1 {
        stroke: #bd8573;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
      }

      .cls-1, .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: #deb8a0;
      }

      .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37, .cls-38, .cls-39 {
        stroke-width: 0px;
      }

      .cls-3, .cls-4, .cls-8, .cls-9, .cls-10, .cls-12, .cls-13, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-23, .cls-24, .cls-25, .cls-27, .cls-28, .cls-30, .cls-31, .cls-32, .cls-34, .cls-36, .cls-37 {
        fill-rule: evenodd;
      }

      .cls-4 {
        fill: #b0743c;
      }

      .cls-5 {
        fill: #965144;
      }

      .cls-2 {
        stroke: #633036;
      }

      .cls-2, .cls-40 {
        stroke-miterlimit: 10;
      }

      .cls-6, .cls-40 {
        fill: #32d1f0;
      }

      .cls-7, .cls-20 {
        fill: #f2d291;
      }

      .cls-8 {
        fill: #fcc;
      }

      .cls-9 {
        fill: #f99;
      }

      .cls-10 {
        fill: #d1965e;
      }

      .cls-11 {
        fill: #f28a69;
      }

      .cls-12, .cls-38 {
        fill: #ff6;
      }

      .cls-13 {
        fill: #d4a15e;
      }

      .cls-14, .cls-18 {
        fill: #a14a1b;
      }

      .cls-15 {
        fill: #823105;
      }

      .cls-16 {
        fill: #aac905;
      }

      .cls-17 {
        fill: #471e04;
      }

      .cls-19 {
        fill: #331502;
      }

      .cls-21 {
        fill: #fc0;
      }

      .cls-40 {
        stroke: #fff;
        stroke-width: .6px;
      }

      .cls-22 {
        fill: #7a3b43;
      }

      .cls-23 {
        fill: #d49d4a;
      }

      .cls-24 {
        fill: #c48b35;
      }

      .cls-25 {
        fill: #f1502d;
      }

      .cls-26 {
        fill: #f3d08e;
      }

      .cls-27 {
        fill: #188c8c;
      }

      .cls-28 {
        fill: #e0ad5e;
      }

      .cls-29 {
        fill: #633036;
      }

      .cls-30, .cls-33 {
        fill: #fff;
      }

      .cls-31 {
        fill: #edba68;
      }

      .cls-32 {
        fill: #c9923e;
      }

      .cls-34 {
        fill: #1a1a1a;
      }

      .cls-35 {
        fill: #524345;
      }

      .cls-36 {
        fill: #000;
      }

      .cls-37 {
        fill: #c44125;
      }

      .cls-39 {
        fill: #ce323f;
      }
</style>
