<template>
  <div id="app">
    <div class="container" id="cont">
      <div id="title" @click="hideTitle" v-if="!gameState">
        <div id="title-inner">
          <div v-if='!mobile'><img src="./assets/title_screen.svg"></div>
          <div v-else><img src="./assets/title_screen_m.svg"></div>
          <div id="title-controls">
            <p>{{ appData.introText }}</p>
            <button>{{ appData.play}}</button>
            <button>{{ appData.instructions}}</button>
          </div>
        </div>
      </div>
      <div id="modal" class="layer">
        <div v-if="modalState" id="modalInner" v-bind:class="{ modalInnerGmvr: gameOver }">
          <div v-if="showAnswer" @click="hideModal" id="closeModalBtn"><img src="./assets/circle-xmark-prpl.svg"></div>
          <div class="modal-content" v-if="!gameOver">
            <div class="modal-content"><img src="./assets/knowvember-modal.svg"></div>
            <p class="question">{{ question }}</p>
            <div v-if="showAnswer" id="answerContent" class="answer-content"><p class="answer" v-html="answer"></p></div>
            <div v-if="!showAnswer" class="modal-content"><button @click="showAnswerFN(1)">Yes</button><button @click="showAnswerFN(0)">No</button></div>
            <div v-if="showAnswer" class="modal-content"><button @click="hideModal">Close</button></div>
          </div>
          <div class="modal-content" v-if="gameOver">
            <div @click="playAgain" id="closeModalBtn"><div class="replay">Replay?</div><img src="./assets/circle-rply-prpl.svg"></div>
            <div class="gm-top">
              <div class="gm-left">
                <img src="./assets/ray.svg">
              </div>
              <div>
                <p class="question gmvr" v-html="question"></p>
              </div>
            </div>
            <p class="answer" v-html="answer"></p>
            <div v-if="gameOver" class="modal-content">
              <!-- <button @click="playAgain">Play Again?</button> -->
              <div class="display:inline;"><a href="https://knowvember.wpenginepowered.com/the-contest/contest-entry/" target="_blank"
                class="pseudo-button">Enter The Contest</a></div>
              <div v-if="!sharing">
                <button @click="shareFN">Share</button>
              </div>

              <div v-if="sharing" class="mt24px">
                <p><strong>Share:</strong> https://radongame.netlify.app/</p>
                <div class="flex">
                  <a href="https://twitter.com/intent/tweet?url=https://radongame.netlify.app/&text=Knowvember%20Get%20to%20Know%20Radon&via=" class="icon" target="_blank" rel="nofollow noopener"><img src="./assets/share/twitter.svg" alt="Twitter" title="Twitter"></a>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://radongame.netlify.app/" class="icon" target="_blank" rel="nofollow noopener"><img src="./assets/share/facebook.svg" alt="Facebook" title="Facebook"></a>
                  <a href="mailto:%7Bemail_address%7D?subject=Get%20to%20Know%20Radon&body=https://radongame.netlify.app/%20" class="icon" target="_blank" rel="nofollow noopener"><img src="./assets/share/envelope.svg" alt="Email" title="Email"></a>
                  <a href="whatsapp://send?text=https://radongame.netlify.app/" class="icon" target="_blank" rel="nofollow noopener" title="WhatsApp"><img src="./assets/share/whatsapp.svg" alt="WhatsApp" title="WhatsApp"></a>
                  <a href="sms:%7Bphone_number%7D?body=https://radongame.netlify.app/" class="icon" target="_blank" rel="nofollow noopener"><img src="./assets/share/sms.svg" alt="SMS" title="SMS"></a>
                </div>
                <p class="download"><a href="#" download>Download a shareable image</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="ui" class="layer" v-if="gameState">
        <div id="uiContainer">
          <div class="layer knowvember"><img src="./assets/knowvember.svg"></div>
          <div class="layer score"><p>{{ appData.found }} {{itemFound}}/{{itemTotal}}</p></div>
          <div class="layer uibg"></div>
          <button id="hintBT" @click="toggleHints">Hints</button>
          <button id="zoomBT" class="layer" @click="showZoomBox">
            <img v-if="!zoomed && !cancelzoom" id="magGlass" src="./assets/magnifying-glass-plus.svg">
            <img v-if="zoomed && !cancelzoom" id="magGlass" src="./assets/magnifying-glass-minus.svg">
            <img v-if="zoomed && cancelzoom" id="magGlass" src="./assets/circle-xmark.svg">
          </button>
        </div>
      </div>
      <div class="view" id="viewbox">
        <div id="zoomBox" class="layer" @click="zoomIn"></div>

        <!-- <Houses msg=""/> -->
        <Roads msg=""/>
        <Background msg=""/>
        <WaterRipples msg=""/>
        <Clouds msg=""/>
        <Radon msg=""/>
        <Birds msg=""/>
        <People msg=""/>
        <Trees msg=""/>

        <div class="layer homes">
          <Sailboat/>
          <Apartment msg="" :manualHighlight="hints" :key="`${componentKey}-1`"/>
          <ForSale msg="" :manualHighlight="hints" :key="`${componentKey}-2`"/>
          <DayCare msg="" :manualHighlight="hints" :key="`${componentKey}-3`"/>
          <HouseBoat msg="" :manualHighlight="hints" :key="`${componentKey}-4`"/>
          <BirdHouse msg="" :manualHighlight="hints"/>
          <School msg="" :manualHighlight="hints" :key="`${componentKey}-5`"/>
          <Geothermal msg="" :manualHighlight="hints" :key="`${componentKey}-6`"/>
          <Treehouse msg="" :manualHighlight="hints" :key="`${componentKey}-7`"/>
          <Office msg="" :manualHighlight="hints" :key="`${componentKey}-8`"/>
          <Condo msg="" :manualHighlight="hints" :key="`${componentKey}-9`"/>
          <ForRent msg="" :manualHighlight="hints" :key="`${componentKey}-10`"/>
          <NewHome msg="" :manualHighlight="hints" :key="`${componentKey}-11`"/>
          <Reno msg="" :manualHighlight="hints" :key="`${componentKey}-12`"/>
          <Neighbour msg="" :manualHighlight="hints" :key="`${componentKey}-13`"/>
          <NewBuild msg="" :manualHighlight="hints" :key="`${componentKey}-14`"/>
          <WellWater msg="" :manualHighlight="hints" :key="`${componentKey}-15`"/>
          <MobileHome msg="" :manualHighlight="hints" :key="`${componentKey}-16`"/>
          <OldHome msg="" :manualHighlight="hints" :key="`${componentKey}-17`"/>
          <DogHouse msg="" :manualHighlight="hints" :key="`${componentKey}-18`"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import ForSale from './components/ForSale.vue'
import DayCare from './components/DayCare.vue'
import HouseBoat from './components/HouseBoat.vue'
import BirdHouse from './components/BirdHouse.vue'
import School from './components/School.vue'
import Geothermal from './components/Geothermal.vue'
import Treehouse from './components/Treehouse.vue'
import Office from './components/Office.vue'
import Condo from './components/Condo.vue'
import Apartment from './components/Apartment.vue'
import ForRent from './components/ForRent.vue'
import NewHome from './components/NewHome.vue'
import Reno from './components/Reno.vue'
import Neighbour from './components/Neighbour.vue'
import NewBuild from './components/NewBuild.vue'
import WellWater from './components/WellWater.vue'
import MobileHome from './components/MobileHome.vue'
import OldHome from './components/OldHome.vue'
import DogHouse from './components/DogHouse.vue'

import Sailboat from './components/Sailboat.vue'
import People from './components/People.vue'
import Radon from './components/Radon.vue'
import Trees from './components/Trees.vue'
// import Houses from './components/Houses.vue'
import Roads from './components/Roads.vue'
import Birds from './components/Birds.vue'
import Clouds from './components/Clouds.vue'
import WaterRipples from './components/WaterRipples.vue'
import Background from './components/Background.vue'

export default {
  name: 'App',
  components: {
    Roads,
    Background,
    WaterRipples,
    Clouds,
    Birds,
    // Houses,
    People,
    Radon,
    Trees,
    ForSale,
    DayCare,
    HouseBoat,
    BirdHouse,
    School,
    Geothermal,
    Treehouse,
    Office,
    Condo,
    Apartment,
    ForRent,
    NewHome,
    Reno,
    Neighbour,
    NewBuild,
    WellWater,
    MobileHome,
    OldHome,
    DogHouse,
    Sailboat
  },
  data() {
    return {
      appData: [],
      gameState: false,
      gameOver: false,
      sharing: false,
      zoom: false,
      zoomed: false,
      cancelzoom: false,
      coords: [],
      viewBoxRect: [],
      itemTotal: 1,
      itemFound: 0,
      modalState: false,
      houseType: String,
      question: String,
      answer: String,
      correct: 0,
      showAnswer: false,
      foundAR: [],
      mobile: false,
      hints: false,
      componentKey: 0,
    }
  },
  beforeMount: function(){
    this.loadLanguage();
  },
  mounted: function(){
    gsap.set('#modal',{alpha:0})

    this.resizeElem()
    window.addEventListener("resize", this.resizeElem);

  },
  methods:{
    loadLanguage: function(){
      axios.get('data/knowvember.json')
      .then((response)=> {
        this.appData = response.data
          // console.log(this.appData.ForSale)

      })
    },
    resizeElem: function(){

      if(window.innerWidth <= 620){
        this.mobile = true
      }else{
        this.mobile = false
      }
      // var elem = document.getElementById("cont");
      // elem.style.height = (window.innerHeight*.8 + 40) + 'px'
      // elem.style.width = (((window.innerHeight*.8)*1.12) + 40) + 'px'
      // if (window.innerWidth > 600 && window.innerWidth < 1025){
      //   if(window.innerWidth/window.innerHeight < .97){
      //     elem.style.height = '100vw';
      //     elem.style.width = '86vw';
      //     console.log(22)
      //   }
      // }

        // var elem = document.getElementById("cont");
        // if(window.innerWidth < 600){
        //   elem.style.height = ((elem.offsetWidth * .892)+218) + 'px';
        //   document.getElementById("viewbox").style.marginTop = '200px';
        // }else{
        //   elem.style.height = (elem.offsetWidth * .892) + 'px';
        //   document.getElementById("viewbox").style.marginTop = '0px';
        // }
    },
    zoomIn: function(event){

      document.getElementById("viewbox").style.cursor = "default";

      var zoombox = document.getElementById("zoomBox");
      zoombox.style.height= '0';

      var element = document.getElementById("viewbox");
      var rect = element.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      var w = rect.right - rect.left;
      var h = w * .935;

      // console.log(x/w,y/h);
      this.coords[0] = x
      this.coords[1] = y

      gsap.to('#viewbox', 1, {scale:4,x:-x*3,y:-y*3,transformOrigin:'left center'});

      this.cancelzoom = false

      // alert(x + ' : ' + y)

      // tl.set('#viewbox', {transformOrigin:'left center'});
      // gsap.to('#viewbox', 1, {scale:1.5,transformOrigin:"50% 50%"})
      // gsap.to('#viewbox', 1, {scale:1.5,x:-this.coords[0],y:-this.coords[1],ease:Power2.easeInOut})

    },
    toggleHints: function() {
      this.hints = !this.hints
    },
    showZoomBox: function(){

      var zoombox = document.getElementById("zoomBox");
      zoombox.style.height = 0;

      if(this.zoom){
        this.zoomed = false
        this.zoom = false
        this.cancelzoom = false
        gsap.to('#viewbox', 1, {scale:1,x:0,y:0,ease:Power2.easeInOut})
        document.getElementById("viewbox").style.cursor = "default";
        return
      }

      document.getElementById("viewbox").style.cursor = "zoom-in";

      this.zoom = true
      this.zoomed = true
      this.cancelzoom = true
      var element = document.getElementById("viewbox");
      var rect = element.getBoundingClientRect();
      var w = rect.right - rect.left;
      var h = w * .935;
      zoombox.style.height= h + 'px';

    },
    showModal: function(val){

      for (let item of this.foundAR) {
        if(item == val){
          //alert("Already Found")
          return
        }
      }

      this.foundAR.push(val)
      if (this.itemFound < this.itemTotal) {
        this.itemFound = this.itemFound + 1
      }

      this.showAnswer = false

      this.modalState = true
      gsap.set('#modal', {alpha:0})
      this.houseType = val

      document.getElementById("modal").style.pointerEvents = 'all';

      //modal content
      this.question = this.appData[val].Question
      this.answer = this.appData[val].Answer

      setTimeout(() => {
        gsap.set('#modal', {alpha:1})
        gsap.fromTo('#modalInner', .5,{alpha:0},{alpha:1})
      }, 1);
    },
    hideModal: function(){

      //GameOver
      if(this.itemFound == this.itemTotal){
        var that = this
        setTimeout(function(){
          that.gameOver = true
          that.showModal('gameover')
        },1000)
      }

      gsap.set('#modal', {alpha:0})
      this.modalState = false
      document.getElementById("modal").style.pointerEvents = 'none';
    },
    showAnswerFN: function(){
      this.showAnswer = true
      var that = this
       setTimeout(function(){
        // alert(document.getElementById("answerContent").offsetHeight)
        var elm = document.getElementById("answerContent")
        var modalInr = document.getElementById("modalInner")

        var testH

        if(that.mobile){
          testH = modalInr.offsetHeight * .40
        }else{
          testH = modalInr.offsetHeight * .40
        }

        if(document.getElementById("answerContent").offsetHeight < testH){
          elm.style.height = elm.offsetHeight + 10 + 'px'
        }else{
          elm.style.height = testH + 'px'
        }

       },1)

    },
    hideTitle: function(){
      this.gameState = true;
    },
    playAgain: function(){
      this.itemFound = 0;
      this.gameOver = false;
      this.foundAR = [];
      this.sharing = false;
      // re-render child component to reset "found" data so that the outline around the houses light up
      this.componentKey += 1;
      this.hideModal();
    },
    shareFN: function(){
      // alert('Share')
      this.sharing = true;
    },
  }
}
</script>

<style>
body{
  margin:0;
  background-image: url("@/assets/back-tile.png");
  background-repeat: no-repeat;
  background-position: top center;
  /* background-position-y: 42px; */
  background-color: #598208;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

button, a.pseudo-button {
  font-family: inherit;
  cursor: pointer;
  color: white;
  background:cornflowerblue;
  border: 2px solid aliceblue;
  border-radius: 10px;
  padding: 8px 16px;
}

button:hover, a.pseudo-button:hover {
  background:#A46BBF;
}

p {
  margin: 0;
  padding: 0;
}

.container{
  /* border: 1px grey solid; */
  /* width: 1000px;
  height: 48px; */
  width: calc(80vh * 1.12);
  height: calc(80vh + 32px);
  min-width: 642px;
  min-height: 600px;
  margin: 0 auto;
  margin-top: 2vh;
  position:relative;
  overflow: hidden;
  background-color: aliceblue;
  /* background: linear-gradient(0deg, rgba(112,163,10,1) 0%, rgba(112,163,10,1) 30%, rgba(240,248,255,1) 31%, rgba(240,248,255,1) 100%); */
  border: 20px solid white;
}

#title{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  background-color: rgba(26, 79, 94, 0.4);
  z-index: 1001;
}

#title-inner{
  width:82%;
  margin: 0 auto;
  margin-top: 5%;
}

#title-controls{
  position:absolute;
  top:50%;
  left: 20%;
  width: 55%;
  height: 30%;
  /* border: 4px solid aliceblue; */
  border-radius: 20px;
  /* padding: 2% */
}

#title-controls button{
  font-size: 1.2rem;
  background-color: #A46BBF;
  margin: 0px 5px;
  margin-top: 40px;
  padding: 8px 25px;
}

#title-controls button:hover{
  background-color: cornflowerblue;
}

.layer{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}

#background{
  pointer-events: none;
  z-index: 10;
}

#roads{
  pointer-events: none;
  z-index: 19;
}

#birds{
  pointer-events: none;
  z-index: 15;
}

#clouds{
  pointer-events: none;
  z-index: 9;
}

#waterripples{
  pointer-events: none;
  z-index: 16;
}

#houses{
  z-index: 30;
}

.homes{
  z-index: 80;
}

.home{
  pointer-events: none;
}

.homeHotSpot{
  pointer-events: all;
}

#people{
  pointer-events: none;
  z-index: 40;
}

#radon{
  pointer-events: none;
  z-index: 20;
}

#trees{
  pointer-events: none;
  z-index: 25;
}

.view{
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  position:relative;
  margin-top: 0%;
  margin-left: 0%;
  /* margin-top: -93.8%;
  margin-left: -100%;  */
}

#ui{
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}

#uiContainer{
  height: 100%;
  position: relative;
  pointer-events: none;
}

#zoomBox{
  z-index: 100;
  /* background-color: aqua;
  opacity: .5; */
}

#magGlass{
  width: 10px;
}

#zoomBT{
  width: 9%;
  height: 6%;
  top: 12%;
  left: unset;
  right: 3%;
  pointer-events: all;
  z-index: 100;
}

#zoomBT img{
  width: 50%;
}

#hintBT {
  position: absolute;
  /* width: 9%; */
  height: 6%;
  top: 12%;
  right: 13%;
  left: unset;
  pointer-events: all;
  z-index: 100;
}
#hintBT1 {
  position: absolute;
  /* width: 9%; */
  height: 6%;
  /* top: 12%;
  right: 13%; */
  left: unset;
  pointer-events: all;
  z-index: 100;
}

.knowvember{
  width: 34%;
  height: -moz-fit-content;
  height: fit-content;
  top: 2%;
  left: 3%;
  z-index: 1;
}

.score{
  font-size: 1.2rem;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: right;
  top: 3%;
  left: unset;
  right: 2%;
  z-index: 2;
}

.uibg{
  background-color: aliceblue;
  top:0;
  left:0;
  width: 100%;
  height: 10%;
  z-index: -1;
}

.gmvr{
  margin-top:46px;
}

#modal{
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: none;
}

#modalInner{
  position: relative;
  /* height: 70%; */
  height: 80%;
  width: 80%;
  /* margin: 15% auto; */
  margin: 10% auto;
  background-color: white;
  border: 6px solid #A46BBF;
  text-align: center;
  pointer-events: all;
}

/* .modalInnerGmvr{
  height: 80% !important;
  margin: 10% auto !important;
  padding-top: 0%;
} */

#modalInner button{
  font-size: 1.1rem;
  margin: 0 4px;
  padding: 8px 24px;
}

.mt24px {
  margin-top: 24px;
}

#modalInner a.pseudo-button{
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0 4px;
  padding: 8px 24px;
  display: inline-block;
  margin-bottom: 4px;
  text-decoration: none;
}

.flex {
  display: flex;
  justify-content: center;
}

.icon {
  display: inline-block;
  border: 1px solid transparent;
  padding: 4px;
  margin: 0 8px;
}
.icon:hover {
  border-color: #84489B;
  /* border-color: #444; */
}
.icon img {
  display: block;
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.modal-content{
  max-width: 80%;
  margin: 0px auto;
  padding-top: 3%;
  /* text-align: left; */
}

.question{
  font-size: 1.1rem;
  margin-bottom: 1rem;
  /* padding: 0 10px; */
}

.answer-content{
  /* height: 150px; */
  height: fit-content;
  overflow: auto;
}

.answer{
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 10px;
}

.active{
  opacity: .5;
}

.replay {
  position: absolute;
  top:8px;
  right:46px;
  color: #84489B;
  font-size: .9rem;
}

#closeModalBtn{
  position: absolute;
  top:8px;
  right:8px;
  width:4vh;
  height: 4vh;
  border: 1px solid #A46BBF;
  border-radius: 4vh;
  padding:3px;
  background-color: #A46BBF;
  cursor: pointer;
}

#closeModalBtn:hover{
  border-radius: 25px;
  border: 1px solid cornflowerblue;
  background-color: cornflowerblue;
}

.gm-top{
  display: flex;
  margin: 6% 0;
}

.gm-left{
  width:24%;
  margin-right: 1%;
}

.gm-title{
  font-size:1.6rem;
  font-weight: 700;
  color: #84489B;
}

.download{
  margin-top: 3%;
}

.download a{
  color: #84489B;
  text-decoration: none !important;
}

.download a:hover{
  text-decoration: underline !important;
}

/* @media screen and (max-width: 1024px) and (max-height: 1366px){ */
@media only screen and (orientation: portrait) {
  .container {
    width: calc(80vw * 1.12);
    height: calc(80vw + 40px);
    margin-top: 2vh;
    background: aliceblue;
    /* background: linear-gradient(0deg, rgba(112,163,10,1) 0%, rgba(112,163,10,1) 30%, rgba(240,248,255,1) 31%, rgba(240,248,255,1) 100%); */
    border: 20px solid white;
  }
}

@media only screen and (max-width: 800px) {
  p{
    font-size: .9rem;
  }

  #title-controls button {
    font-size: 1rem;
    background-color: #A46BBF;
    margin: 0px 2px;
    margin-top: 20px;
  }

  #zoomBT img{
    width: 70%;
  }
}

@media only screen and (max-width: 620px) {

  #zoomBT {
    top: 22%;
    left: 5%;
    width: 60px;
    height: 40px;
  }

  #zoomBT:hover{
    background:cornflowerblue;
  }

  #hintBT {
    top: 22%;
    left: 21%;
    right: unset;
    height: 40px;
  }

  .score {
      font-size: 1.2rem;
      text-align: left;
      top: 70px;
      left: 5%;
  }

  .knowvember {
      width: 60%;
      top: 4%;
      left: 5%;
  }

  .container {
      /* width: 100%;
      height: 800px; */
      width: 100vw;
      height: 140vw;
      min-width: unset;
      min-height: unset;
      margin: 0 auto;
      border: none;
      /* border-top: 20px solid white; */
      border-bottom: 20px solid white;
  }

  .uibg {
      height: 20%;
  }

  .view {
    margin-top: 46%;
  }

  #modalInner{
    position: relative;
    height: 80%;
    width: 90%;
    margin: 0 auto;
    margin-top: 20%;
  }

  #closeModalBtn{
    top:6px;
    right:6px;
    width:20px;
    height: 20px;
  }

  p{
    font-size: .93rem;
  }

  #title-controls {
    position: absolute;
    top: 36%;
    left: 20%;
    width: 55%;
    height: 30%;
  }

  #title-controls p{
    margin-bottom: 10px;
  }

  #title-controls button {
    font-size: 1rem;
    margin: 0px 2px;
    margin-top: 3px;
  }


  #modalInner{
    width: 90%;
  }

  #modalInner button{
    font-size: 1rem;
    margin: 10px 4px;
    padding: 8px 24px;
  }

  .modal-content{
    max-width: 90%;
  }

  .question{
    font-size: 1rem;
    margin-bottom: .8rem;
  }

  .answer{
    font-size: .9rem;
    line-height: 1.2rem;
  }

}

@media only screen and (max-width: 420px) {

  p{
    font-size: .9rem;
    line-height: 1.2rem;
  }

  #title-controls p{
    margin-bottom: 10px;
  }

  #title-controls button {
    font-size: 1rem;
    margin: 0px 2px;
    margin-top: 3px;
  }

  #modalInner{
    width: 90%;
  }

  #modalInner button{
    font-size: 1rem;
    margin: 10px 4px;
    padding: 8px 24px;
  }

  .modal-content{
    max-width: 90%;
  }

  .question{
    font-size: 1rem;
    margin-bottom: .8rem;
  }

  .answer{
    font-size: .9rem;
    line-height: 1.1rem;
  }
}


</style>
