<template>
    <div id="newbuild" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-23" d="M722.3,499.3h-38.1c-3.1-2.1-7.4-1.8-10.1.9l-31.5,31.5c-3,3-3,8.1,0,11.2,1.6,1.6,3.7,2.3,5.8,2.3v22.8h73.9v-13h-11.4v-9.8c1.7,0,3.4-.5,4.7-1.4h6.7v-30l-1.4-1.4h1.4v-13h0ZM681.1,538.9v3.5h-3.1v-3.5h3.1ZM665,538.9v16h-3.7v-16h3.7ZM670.8,525.8l8.8-8.8,8.8,8.8h-17.7.1ZM694.1,554.9v-16h3.6v16h-3.6ZM715.8,530.7l-18.4-18.4h5.1l18.4,18.4h-5.1Z"/>
  <path class="homeHotSpot cls-9" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight" d="M722.3,504.4h-40l-.6-.6c-1.1-1.1-3-1.1-4.1,0l-31.5,31.5c-1.1,1.1-1.1,3,0,4.1s3,1.1,4.1,0l3.2-3.2v26.8h68.9v-58.6Z"/>
  <g >
    <g>
      <g>
        <path class="cls-3" d="M705.8,562.9h-52.4v-32.8l26.5-26.5,26,26.5v32.8h0ZM656.3,559.9h46.4v-28.5l-23-23.4-23.4,23.4v28.5Z"/>
        <path class="cls-3" d="M689.1,550.4h-19.1v-19.6h19.1v19.6ZM673,547.4h13.1v-13.6h-13.1v13.6Z"/>
      </g>
      <rect class="cls-3" x="670" y="532.4" width="3" height="29.4"/>
      <rect class="cls-3" x="686.1" y="545" width="3" height="16.6"/>
      <rect class="cls-3" x="652.1" y="530.9" width="51.6" height="3"/>
    </g>
    <polygon class="cls-3" points="722.3 535.7 711.8 535.7 683.4 507.3 722.3 507.3 722.3 504.3 679.7 504.3 679.8 507.9 710.6 538.7 722.3 538.7 722.3 535.7"/>
    <polygon class="cls-3" points="722.3 520.8 706.2 504.7 704 506.8 722.3 525.1 722.3 520.8"/>
    <polygon class="cls-3" points="722.3 559.9 705.8 559.9 705.8 538.7 722.3 538.7 722.3 535.7 702.8 535.7 702.8 562.9 722.3 562.9 722.3 559.9"/>
    <path class="cls-18" d="M713.1,535.2c1.1,1.1,1.1,3,0,4.1s-3,1.1-4.1,0l-29.4-29.4-29.4,29.4c-1.1,1.1-3,1.1-4.1,0s-1.1-3,0-4.1l31.5-31.5c1.1-1.1,3-1.1,4.1,0l31.5,31.5h0Z"/>
    <g>
      <g>
        <path class="cls-13" d="M633.5,579.4h9.6v-11.7c0-1.2-1-2.1-2.1-2.1h-5.3c-1.2,0-2.1,1-2.1,2.1v11.7h-.1Z"/>
        <path class="cls-26" d="M633.7,590.4h3.8c.3.9.3,1.9.3,2.8h-6.6c0-1.5,2.6-1.9,2.6-2.8h0Z"/>
        <rect class="cls-7" x="631" y="592.6" width="6.8" height=".5"/>
        <path class="cls-26" d="M642.8,590.4h-3.8c-.3.9-.3,1.9-.3,2.8h6.6c0-1.5-2.6-1.9-2.6-2.8h0Z"/>
        <rect class="cls-7" x="638.7" y="592.6" width="6.8" height=".5"/>
        <path class="cls-19" d="M643.1,578.2h-9.6v12.3h4.3l-.3-10.5h1.6l-.3,10.5h4.3v-12.3Z"/>
        <polygon class="cls-5" points="635 578.3 635.5 578.7 633.8 580.8 633.3 580.3 635 578.3"/>
        <polygon class="cls-5" points="641.5 578.3 640.9 578.7 642.7 580.8 643.2 580.3 641.5 578.3"/>
        <path class="cls-20" d="M640.9,565.6h0v.2c-.2.6-.5,1.1-1,1.4-.5.3-1,.5-1.6.5s-1.2-.2-1.6-.5c-.5-.4-.8-.8-1-1.4h0v-.2h1.2c0,.3.3.5.5.7s.5.3.9.3.6,0,.9-.3c.2-.2.4-.4.5-.7h1.2,0Z"/>
        <path class="cls-22" d="M636.3,564.4h3.9v1.2c-.2.9-1,1.5-2,1.5s-1.8-.6-2-1.5v-1.1h0Z"/>
        <path class="cls-8" d="M636.3,564.7l3.9-.3h0v1.2c-.2.7-.8,1.3-1.5,1.5l-2.4-2.4h0Z"/>
        <g>
          <path class="cls-22" d="M633.6,570s-.8,6.3-1.3,9.5h0l.4,1.3c0,.2,0,.4-.2.5h0c-.2,0-.4,0-.5-.2l-.2-.5v1.6c0,.2,0,.4-.3.4h0c-.2,0-.4-.2-.4-.4v-.2h0c-.2,0-.4-.2-.4-.4v-.2s-.2-.2-.2-.3v-2.8c.5-3.4,1.2-8.6,1.2-8.6l2,.3h0Z"/>
          <path class="cls-13" d="M631.3,570.2l2.4.4.9-4.9c-1.8-.3-2.6,1.4-2.8,2.2l-.5,2.2h0Z"/>
        </g>
        <g>
          <path class="cls-22" d="M638.2,551.8c-1.3,0-5.4.2-5.5,4.9,0,4.7.9,6.4,1.6,7.1.6.7,1.3,1.3,2.6,1.7.3.3.7.5,1.3.5v-14.3h0Z"/>
          <path class="cls-1" d="M638.2,551.8c1.3,0,5.4.2,5.5,4.9,0,4.7-.9,6.4-1.6,7.1-.6.7-1.3,1.3-2.6,1.7-.3.3-.7.5-1.3.5v-14.3h0Z"/>
        </g>
        <path class="cls-22" d="M633.5,559c0-.3-.3-.5-.7-.6-.4,0-1.1,0-1.1,1.2s.9,2.1,1.5,1.9c.6,0,.3-2.2.3-2.5h0Z"/>
        <path class="cls-1" d="M633.3,559.4c0-.2-.2-.3-.5-.4-.2,0-.6,0-.6.7s.5,1.3.8,1.2c0,0,.2-.2.2-.4-.2,0-.3-.3-.3-.6s.2-.4.3-.4h0Z"/>
        <path class="cls-1" d="M642.9,559.1c.2-.3.4-.5.7-.6.4,0,1.1,0,1.1,1.2s-.9,2.1-1.5,1.9c-.6,0-.3-2.5-.3-2.5h0Z"/>
        <path class="cls-8" d="M643.2,559.4c0-.2.2-.3.5-.4.2,0,.6,0,.6.7s-.5,1.3-.8,1.2c0,0-.2-.2-.2-.4.2,0,.3-.3.3-.6s-.2-.4-.3-.4h0Z"/>
        <path class="cls-24" d="M640.2,558.2h.8c.2,0,.3,0,.3-.3h0c0-.2,0-.3-.3-.3h-.8c-.2,0-.3,0-.3.3h0c0,.2,0,.3.3.3Z"/>
        <path class="cls-20" d="M640.6,561.8c0,.9-1.1,1.6-2.4,1.6s-2.4-.7-2.4-1.6h4.8Z"/>
        <path class="cls-24" d="M635.5,558.2h.8c.2,0,.3,0,.3-.3h0c0-.2,0-.3-.3-.3h-.8c-.2,0-.3,0-.3.3h0c0,.2,0,.3.3.3Z"/>
        <path class="cls-24" d="M632.9,557.9c-1.3-4.8,2-7.1,5.3-7.1s6.7,2.3,5.3,7.1c0-1,0-2.3-.6-2.9-.8-1.1-2.7-1.9-4.8-1.8-2,0-4,.7-4.8,1.8-.5.7-.5,1.9-.6,2.9h.2,0Z"/>
        <path class="cls-13" d="M646,569.5l-2.2,1-2.1-4.5c1.6-.7,2.9.7,3.2,1.4l1,2h0Z"/>
        <path class="cls-22" d="M640,573.8l.9.6c.3.2.6.4.8,0l.8-.8c.3-.3.2-.4.2-.8v-.3h0v-1.2c0-.2-.2-.4-.4-.3h0c-.2,0-.4.2-.3.4v.6h0l-1.6-1.4h-.5v.5l.4.3h-.4v.5l.2.2h-.2v.5h.2s-.3.2-.3.4h0c0,.2,0,.4.2.5h0v.3Z"/>
        <path class="cls-22" d="M645.9,569.5c1.5,3.2,1.3,4.8.2,5.7-.4.3-1.1.8-2,.7-.7,0-1.4-.5-2.6-1.3l1.1-1.5c.8.6,1.3.9,1.6.9,1.7-.4,0-3.3-.2-3.7l1.9-.8h0Z"/>
        <g>
          <path class="cls-24" d="M640.8,559.1c-.3,0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5Z"/>
          <path class="cls-24" d="M641.4,559.5h.2v-.2h0c-.3-.3-.8-.7-1.6,0v.2c0,0,0,0,.2,0,.6-.5,1-.2,1.3,0h-.1Z"/>
          <path class="cls-24" d="M635.7,559.1c.3,0,.5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"/>
          <path class="cls-24" d="M635.1,559.5h-.2v-.2h0c.3-.3.8-.7,1.6,0v.2c0,0,0,0-.2,0-.6-.5-1-.2-1.3,0h.1Z"/>
        </g>
        <g>
          <path class="cls-16" d="M644.3,553.2h0v-1.7s-.4-2.2-1.6-3c0-.3-.6-.8-.6-.8l-.9-.2-1.2-1.4h-3.3l-1.2,1.4-.9.2s-.5.5-.6.8c-1.2.8-1.6,3-1.6,3v1.6c0,.2,0,0,0,0-.4,0-.5.7-.5.7v.9c.5.3,4,.7,6.6.7s6.1-.4,6.6-.7v-.9s-.2-.6-.5-.7h-.3Z"/>
          <polygon class="cls-2" points="635.2 550.4 634.5 547.6 633.9 548.4 635.2 550.4"/>
          <polygon class="cls-2" points="636.7 552 636.6 546 635.4 547.4 636.7 552"/>
          <polygon class="cls-2" points="641.3 550.4 642 547.6 642.6 548.4 641.3 550.4"/>
          <polygon class="cls-2" points="639.8 552 639.9 546 641.1 547.4 639.8 552"/>
          <path class="cls-2" d="M638.1,553.9c-1.3,0-4.7-.3-5.8-.5,0,0-.2,0-.2-.2s0-.2.2-.2c1.1.2,4.5.5,5.8.5s5.2-.3,6-.5h.2v.2c-.8.2-4.8.5-6.1.5h0v.2Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class="cls-12" d="M611.5,569.4s-.5,6.4-.7,9.7h0l.5,1.3c0,.2,0,.4-.2.5h0c-.2,0-.4,0-.5-.2l-.2-.5.2,1.7c0,.2,0,.4-.4.5h0c-.2,0-.4,0-.4-.4v-.2h0c-.2,0-.4,0-.4-.4v-.2s-.2-.2-.2-.3l-.3-2.5v-.3c.3-3.5.7-8.8.7-8.9h2v.2h0Z"/>
          <path class="cls-4" d="M608.8,572.8l2.7.4.8-8.1c-1.8-.2-2.6,1.6-2.7,2.4l-.8,5.3h0Z"/>
          <path class="cls-12" d="M620.7,569.4s.5,6.4.7,9.7h0l-.5,1.3c0,.2,0,.4.2.5h0c.2,0,.4,0,.5-.2l.2-.5-.2,1.7c0,.2,0,.4.4.5h0c.2,0,.4,0,.4-.4v-.2h0c.2,0,.4,0,.4-.4v-.2s.2-.2.2-.3l.3-2.5v-.3c-.3-3.5-.7-8.8-.7-8.9h-2v.2h0Z"/>
          <path class="cls-4" d="M623.4,572.8l-2.7.4-.8-8.1c1.8-.2,2.6,1.6,2.7,2.4l.8,5.3h0Z"/>
          <path class="cls-25" d="M611.4,576.5c0,1.1.2,3.8,3.8,4.4h1.8c3.5-.6,3.8-3.2,3.8-4.4-.8-1.7-3.1-2-4.7-1.9-1.6,0-3.9.2-4.7,1.9Z"/>
          <path class="cls-17" d="M612.7,590.6h2.6c0,1,.2,1.3,0,2.2-1.7,0-3.4.2-5,.2,0-1.3,1.9-1.9,2.3-2.4h.1Z"/>
          <rect class="cls-15" x="610.3" y="592.8" width="5.1" height=".3"/>
          <path class="cls-14" d="M620.5,576c-1.1,0-2.8-2-4.5-2s-3.4,2-4.3,2l.4,10.9h3.2v-7.2h1.5v7.2h3.2l.4-10.9h0Z"/>
          <polygon class="cls-17" points="612.2 586.9 612.3 590.9 615.3 590.9 615.3 586.9 612.2 586.9"/>
          <path class="cls-4" d="M620.8,576.5l-.3-9.5c0-.6,0-1-.2-1.3,0-.3-.2-.5-.4-.6h-6.3c-1.1,0-1.9.9-1.9,2l-.3,9.5c.9-.8,2.9-1.3,4.7-1.3s3.8.5,4.7,1.3h0Z"/>
          <path class="cls-12" d="M619.3,565v.4c0,1.5-1.5,2.6-3.3,2.6s-3.3-1.2-3.3-2.6,0-.3,0-.4h1.6v-1.8h3.1v1.9h1.9Z"/>
          <path class="cls-10" d="M618.4,567.3l-3.9-3.9h0l.7-.2h2.4v1.9h1.6v.4c0,.7-.4,1.4-1,1.9h.2Z"/>
          <path class="cls-6" d="M615.8,562.3c-1.1-1-3.4-1.3-4.6,1.1-1.7,3.2,2.5,6.2,2.6,7.7,0,1.2-.5,1.9-.5,1.9,0,0,2.3,0,3.2-2.2.9-2.1-2-6.6-2.1-7.5-.2-1,.7-.9,1.1-.4.4.5.3-.6.3-.6Z"/>
          <path class="cls-12" d="M616.1,551.8c-1.2,0-4.8,0-4.9,4.4,0,4.2.8,5.8,1.4,6.4s1.2,1.2,2.3,1.5c.3.3.6.4,1.1.5v-12.7h.1Z"/>
          <path class="cls-21" d="M616.1,551.8c1.2,0,4.8,0,4.9,4.4,0,4.2-.8,5.8-1.4,6.4s-1.2,1.2-2.3,1.5c-.3.3-.6.4-1.1.5v-12.7h-.1Z"/>
          <path class="cls-12" d="M611.9,558.3c0-.3-.2-.5-.6-.5s-1,0-1,1.1.8,1.8,1.3,1.7.3-2,.2-2.3h0Z"/>
          <path class="cls-21" d="M620.2,558.3c0-.3.3-.5.7-.6.3,0,1,0,1,1.1s-.8,1.8-1.3,1.7-.3-2.3-.3-2.3h-.1Z"/>
          <path class="cls-6" d="M618.7,554.2c.5,1,.4,2.5,2.3,3.5,1.2-4.6-2-6.7-5.1-6.6-3.3,0-6.3,2.3-5.4,6.8,2.2-.5,3.5-1.6,4.5-2.8-.3,1-1,1.6-.8,1.5,2.1-.5,2.9-.6,4.5-2.4Z"/>
          <path class="cls-17" d="M619.5,590.6h-2.6c0,1-.2,1.3,0,2.2,1.7,0,3.4.2,5,.2,0-1.3-1.9-1.9-2.3-2.4h-.1Z"/>
          <rect class="cls-15" x="616.8" y="592.8" width="5.1" height=".3"/>
          <polygon class="cls-17" points="620.1 586.9 619.9 590.9 616.9 590.9 616.9 586.9 620.1 586.9"/>
          <g>
            <path class="cls-20" d="M617.7,560.8c0,.8-.8,1.4-1.6,1.4s-1.5-.6-1.6-1.4h3.2,0Z"/>
            <path class="cls-11" d="M618.2,557.1s-.2,0-.2-.2,0-.2.2-.2h.8s.2.2,0,.3c0,0-.2.2-.3,0h-.6,0Z"/>
            <path class="cls-11" d="M614.1,556.7s.2,0,.2.2,0,.2-.2.2h-.9v-.3h.9Z"/>
            <g>
              <g>
                <path class="cls-11" d="M619.1,558.4h.2v-.2h0c-.3-.2-.8-.6-1.6,0v.2h.2c.6-.5,1-.2,1.2,0h0Z"/>
                <path class="cls-11" d="M618.5,558c-.2,0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4Z"/>
                <path class="cls-11" d="M619.2,557.9h0v.3q-.1,0,0,0h0v-.3h0Z"/>
                <path class="cls-11" d="M619.4,558h0l-.2.3h0l.2-.3Z"/>
              </g>
              <g>
                <path class="cls-11" d="M613.1,558.4h-.2v-.2h0c.3-.2.8-.6,1.6,0v.2h-.2c-.6-.5-1-.2-1.2,0h0Z"/>
                <path class="cls-11" d="M613.7,558c.2,0,.4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4Z"/>
                <path class="cls-11" d="M613,557.9h0v.3q.1,0,0,0h0v-.3h0Z"/>
                <path class="cls-11" d="M612.8,558h0l.2.3h0l-.2-.3Z"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path class="cls-16" d="M622,553.4h0v-1.6s-.3-2.1-1.5-2.9c0-.2-.6-.8-.6-.8l-.8-.2-1.2-1.3h-3.2l-1.2,1.3-.8.2s-.4.5-.6.8c-1.1.8-1.5,2.9-1.5,2.9v1.5c0,.2,0,0,0,0-.4,0-.5.6-.5.6v.8c.5.3,3.8.7,6.3.7s5.8-.4,6.3-.7v-.8s-.2-.6-.5-.6h-.2Z"/>
          <polygon class="cls-2" points="613.4 550.8 612.7 548.1 612.1 548.9 613.4 550.8"/>
          <polygon class="cls-2" points="614.8 552.3 614.7 546.6 613.5 547.9 614.8 552.3"/>
          <polygon class="cls-2" points="619.2 550.8 619.8 548.1 620.4 548.9 619.2 550.8"/>
          <polygon class="cls-2" points="617.7 552.3 617.8 546.6 619 547.9 617.7 552.3"/>
          <path class="cls-2" d="M616.1,554.1c-1.3,0-4.4-.3-5.6-.5,0,0-.2,0,0-.2,0,0,0-.2.2,0,1.1.2,4.3.5,5.5.5s5-.3,5.7-.5h.2v.2c-.8.2-4.6.5-5.8.5h-.2Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'newbuild',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
        found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('NewBuild')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #9e5a29;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26 {
        stroke-width: 0px;
      }

      .cls-1, .cls-4, .cls-5, .cls-6, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-24, .cls-25, .cls-26 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #d2961e;
      }

      .cls-3 {
        fill: #edd970;
      }

      .cls-4 {
        fill: #f69883;
      }

      .cls-5 {
        fill: #c4d621;
      }

      .cls-6 {
        fill: #ff9;
      }

      .cls-7, .cls-10 {
        fill: #d4a15e;
      }

      .cls-8 {
        fill: #804921;
      }

      .cls-9 {
        fill: #69990a;
      }

      .cls-11 {
        fill: #471e04;
      }

      .cls-12 {
        fill: #f2d291;
      }

      .cls-13 {
        fill: #0010f5;
      }

      .cls-14 {
        fill: #f1502d;
      }

      .cls-15 {
        fill: #470e04;
      }

      .cls-16 {
        fill: #ffc533;
      }

      .cls-17 {
        fill: #963;
      }

      .cls-18 {
        fill: #ce323f;
      }

      .cls-19 {
        fill: #5b8408;
      }

      .cls-20, .cls-23 {
        fill: #fff;
      }

      .cls-21 {
        fill: #edba68;
      }

      .cls-22 {
        fill: #d48439;
      }

      .cls-24 {
        fill: #000;
      }

      .cls-25 {
        fill: #dd8976;
      }

      .cls-26 {
        fill: #333;
      }
  </style>
