<template>
    <div id="forsale" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <polygon v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-18" points="502.8 467.4 448.6 467.4 448.6 456.5 425.5 456.5 425.5 473.8 427.3 473.8 427.3 550.4 436.7 550.4 436.7 550.5 455 550.5 455 550.6 521.6 550.6 521.6 550.5 529.2 550.5 529.2 508.1 534 508.1 534 498.6 502.8 467.4"/>
  <g>
    <g>
      <path class="cls-3" d="M516.3,514.9h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <path class="cls-3" d="M516.3,521.9h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <g>
        <path class="cls-3" d="M520.5,509.5c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9h0Z"/>
        <path class="cls-3" d="M531.8,509.5c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9h0Z"/>
        <path class="cls-3" d="M543.2,509.5c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9h0Z"/>
        <path class="cls-3" d="M554.5,509.5c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9h0Z"/>
        <path class="cls-3" d="M565.8,509.5c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9h0Z"/>
        <path class="cls-3" d="M578,510.4c0-.5-.4-.9-.9-.9s-.9.4-.9.9v16.1h1.8v-16.1h0Z"/>
      </g>
    </g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <g>
        <rect class="cls-6" x="441.7" y="500.7" width="82.5" height="44.8"/>
        <polygon class="cls-10" points="529 500.7 441.7 500.7 441.7 472.4 500.7 472.4 529 500.7"/>
        <rect class="cls-4" x="441.7" y="500.7" width="87.3" height="2.4"/>
        <rect class="cls-18" x="446.5" y="517.3" width="9.4" height="18.9"/>
        <rect class="cls-8" x="432.3" y="468.3" width="9.4" height="77.1"/>
        <rect class="cls-6" x="430.5" y="461.5" width="13.1" height="7.3"/>
      </g>
      <rect class="cls-21" x="441.7" y="503.1" width="82.5" height="2.9"/>
      <polygon class="cls-8" points="517.4 512.5 488.3 484.2 459.3 512.5 460 512.5 460 545.6 516.6 545.6 516.6 512.5 517.4 512.5"/>
      <rect class="cls-18" x="464.9" y="524" width="9.4" height="14.2"/>
      <rect class="cls-10" x="483.2" y="526.3" width="10.6" height="19.3"/>
      <rect class="cls-18" x="502.6" y="524" width="9.4" height="14.2"/>
      <path class="cls-18" d="M484.1,495.7h.5c1.8,0,3.3,1.5,3.3,3.3v6.1h-7.1v-6.1c0-1.8,1.5-3.3,3.3-3.3h0Z"/>
      <path class="cls-18" d="M492.3,495.7h.5c1.8,0,3.3,1.5,3.3,3.3v6.1h-7.1v-6.1c0-1.8,1.5-3.3,3.3-3.3h0Z"/>
      <polyline class="cls-1" points="464.2 513.4 492.6 484.8 490 482.2 459.1 513.4"/>
      <path class="cls-4" d="M519.3,515.2c-.5,0-1-.2-1.3-.6l-29.6-29.8-29.6,29.8c-.7.7-1.9.7-2.6,0s-.7-1.9,0-2.6l30.9-31.2c.7-.7,1.9-.7,2.6,0l31,31.2c.7.7.7,1.9,0,2.6-.4.4-.8.5-1.3.5h0Z"/>
      <polygon class="cls-13" points="493.7 526.3 483.2 526.3 483.2 526.9 493.7 537.4 493.7 526.3"/>
    </g>
    <g>
      <g>
        <rect class="cls-14" x="505.6" y="568.6" width="2.2" height="15.1"/>
        <rect class="cls-22" x="496" y="555.6" width="21.3" height="14.7"/>
        <g class="cls-24">
          <g class="cls-24">
            <text class="cls-23" transform="translate(499.9 563.2)"><tspan x="0" y="0">FOR</tspan></text>
          </g>
          <g class="cls-24">
            <text class="cls-23" transform="translate(498.1 567.6)"><tspan x="0" y="0">SALE</tspan></text>
          </g>
        </g>
      </g>
      <path class="cls-7" d="M506.7,580c.8.6,1.3,1.6,1.4,2.6.8-.7,1.9-.9,2.9-.8.1,1.1-.3,2.3-1.2,3.1-.7.7-1.7,1.1-2.6,1.1h-1c-.9,0-1.9-.4-2.6-1.1-.9-.8-1.3-2-1.2-3.1,1,0,2.1,0,2.9.8,0-1.1.6-2,1.4-2.7h0Z"/>
    </g>
    <g>
      <path class="cls-15" d="M474.8,570.3h10.5v-12.2c0-1.3-1-2.3-2.3-2.3h-5.8c-1.3,0-2.3,1-2.3,2.3v12.2h-.1Z"/>
      <path class="cls-20" d="M475.3,581.7h3.9c.3,1,.3,1.9.3,2.9h-6.9c0-1.6,2.7-1.9,2.7-2.9Z"/>
      <rect class="cls-5" x="472.5" y="584" width="7.1" height=".6"/>
      <path class="cls-20" d="M484.9,581.7h-3.9c-.3,1-.3,1.9-.3,2.9h6.9c0-1.6-2.7-1.9-2.7-2.9Z"/>
      <rect class="cls-5" x="480.6" y="584" width="7.1" height=".6"/>
      <path class="cls-12" d="M485.1,568.9h-10v12.8h4.5l-.3-10.9h1.7l-.3,10.9h4.5v-12.8h-.1Z"/>
      <path class="cls-11" d="M475.1,560.5s-1.2,4,.3,7.4c.1.2.3.6.5.9.2.2.3.4.5.5l-1.2,1.6c-.3-.2-.6-.6-.9-1-.3-.5-.6-1-.8-1.3-1.8-4.1-.5-8.8-.4-8.8l2,.6h0Z"/>
      <path class="cls-15" d="M472.8,560.6l2.5.5.9-5.1c-1.9-.3-2.7,1.4-2.9,2.3l-.5,2.3Z"/>
      <polygon class="cls-2" points="476.7 569 477.3 569.5 475.5 571.7 474.9 571.2 476.7 569"/>
      <path class="cls-11" d="M487,559.9s1.4,4.7-.4,8.8c-.1.3-.4.8-.8,1.3-.3.4-.6.7-.9,1l-1.2-1.6c.1,0,.3-.3.5-.5.2-.3.4-.7.5-.9,1.5-3.4.3-7.4.3-7.4l2-.6h0Z"/>
      <path class="cls-15" d="M487.3,560.6l-2.5.5-.9-5.1c1.9-.3,2.7,1.4,2.9,2.3l.5,2.3Z"/>
      <polygon class="cls-2" points="483.5 569 482.9 569.5 484.7 571.7 485.3 571.2 483.5 569"/>
      <g>
        <path class="cls-16" d="M475.9,557.8h-2.4c0,.2-.1.3-.1.5v.2h2.4v-.7h.1Z"/>
        <path class="cls-16" d="M474.5,556.4h1.7v.7h-2.3c.2-.3.4-.5.6-.7h0Z"/>
        <polygon class="cls-16" points="473.1 559.2 475.6 559.2 475.5 559.9 473 559.9 473.1 559.2"/>
        <polygon class="cls-16" points="475.4 560.6 472.8 560.6 472.8 560.6 475.3 561.1 475.4 560.6"/>
      </g>
      <g>
        <path class="cls-16" d="M486.7,557.8h-2.4v.7h2.6v-.2s-.1-.3-.2-.5Z"/>
        <path class="cls-16" d="M484,556.4h1.7c.3.2.5.4.6.7h-2.2v-.7h-.1Z"/>
        <polygon class="cls-16" points="484.5 559.2 487 559.2 487.2 559.9 484.7 559.9 484.5 559.2"/>
        <polygon class="cls-16" points="487.3 560.6 484.8 560.6 484.9 561.1 487.3 560.6 487.3 560.6"/>
      </g>
      <g>
        <g>
          <path class="cls-16" d="M485.3,557.8h-10.4v.7h10.5v-.7h-.1Z"/>
          <path class="cls-16" d="M475.5,556.4h9.1c.2.2.4.4.5.7h-10.1c.1-.3.3-.5.5-.7Z"/>
          <rect class="cls-18" x="474.8" y="559.2" width="10.5" height=".7"/>
          <path class="cls-16" d="M485.1,560.6h-10s0,.4-.2.7h10.3c0-.3-.1-.6-.2-.7h.1Z"/>
          <rect class="cls-18" x="474.8" y="562" width="10.5" height=".7"/>
        </g>
        <g>
          <rect class="cls-18" x="474.8" y="563.4" width="10.5" height=".7"/>
          <rect class="cls-18" x="474.8" y="564.8" width="10.5" height=".7"/>
          <path class="cls-16" d="M485.3,566.1h-10.4c0,.2.1.5.2.7h10c0-.2.1-.5.2-.7h0Z"/>
          <path class="cls-16" d="M475.3,567.5h9.6s0,.2-.2.4c0,0-.1.2-.2.3h-8.9s-.1-.2-.2-.3l-.2-.4h.1Z"/>
        </g>
      </g>
      <path class="cls-16" d="M482.8,555.7h0v.2h0c-.2.7-.5,1.2-1,1.6-.5.4-1.1.6-1.7.6s-1.2-.2-1.7-.6c-.5-.4-.8-.9-1-1.5h0v-.2h1.3c0,.3.3.5.5.7.3.2.6.3.9.3s.7,0,.9-.3c.2-.2.4-.4.5-.7h1.3Z"/>
      <path class="cls-11" d="M478,554.5h4.1v1.3c-.3.9-1.1,1.6-2.1,1.6s-1.8-.7-2.1-1.6v-1.2h.1Z"/>
      <path class="cls-9" d="M478.1,554.8l4-.3h0v1.3c-.2.8-.8,1.3-1.6,1.5l-2.5-2.5h.1Z"/>
      <g>
        <path class="cls-11" d="M480.1,542c-1.3,0-5.3.2-5.4,4.8,0,4.7.9,6.4,1.6,7.1.6.7,1.3,1.3,2.6,1.7.3.3.7.5,1.3.5v-14.1h-.1Z"/>
        <path class="cls-17" d="M480.1,542c1.3,0,5.3.2,5.4,4.8,0,4.7-.9,6.4-1.6,7.1-.6.7-1.3,1.3-2.6,1.7-.3.3-.7.5-1.3.5v-14.1h.1Z"/>
      </g>
      <path class="cls-19" d="M474.9,549c.3-2.6.8-2.7.9-2.9.5,0,5.1.3,7.7-1.3,1.8,1.8,1.8,4.2,1.8,4.2,0,0,2-6.3-3.7-7.5.4-.7.2-1.6-.4-1.7.1.6-.8,1.5-.9,1.5.2-.2.3-.8-.3-1.2-.1.4-.5,1-1.8,1.4-4.6,1.5-4.3,6.3-3.4,7.4h0Z"/>
      <path class="cls-11" d="M475.4,549.3c-.1-.3-.3-.5-.7-.6-.4,0-1.1,0-1.1,1.2s.9,2,1.5,1.9.3-2.2.2-2.5h.1Z"/>
      <path class="cls-17" d="M475.1,549.6c0-.2-.2-.3-.5-.4-.2,0-.6,0-.6.7s.5,1.2.8,1.2.2-.2.2-.4c-.2,0-.3-.3-.3-.6s.2-.4.3-.4h.1Z"/>
      <path class="cls-17" d="M484.7,549.3c.2-.3.4-.5.7-.6s1.1,0,1.1,1.2-.9,2-1.5,1.9-.3-2.5-.3-2.5h0Z"/>
      <path class="cls-9" d="M485,549.6c0-.2.2-.3.5-.4.2,0,.6,0,.6.7s-.5,1.2-.8,1.2-.2-.2-.2-.4c.2,0,.3-.3.3-.6s-.2-.4-.3-.4h-.1Z"/>
      <path class="cls-19" d="M476.9,548h1.3c.2,0,.3,0,.3-.3h0c0-.2-.1-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2.1.3.3.3Z"/>
      <path class="cls-19" d="M482,548h1.3c.2,0,.3,0,.3-.3h0c0-.2-.1-.3-.3-.3h-1.3c-.2,0-.3,0-.3.3h0c0,.2.1.3.3.3Z"/>
      <g>
        <path class="cls-19" d="M482.8,548.9c-.3,0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5Z"/>
        <path class="cls-19" d="M483.5,549.4h.2v-.2h0c-.3-.3-.9-.7-1.7,0v.2h.2c.7-.6,1.1-.2,1.3,0h0Z"/>
        <path class="cls-19" d="M477.4,548.9c.3,0,.5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"/>
        <path class="cls-19" d="M476.7,549.4h-.2v-.2h0c.3-.3.9-.7,1.7,0v.2h-.2c-.7-.6-1.1-.2-1.3,0h0Z"/>
      </g>
      <path class="cls-16" d="M481.7,552.1c-.1.8-.8,1.4-1.6,1.4s-1.5-.6-1.6-1.4h3.3,0Z"/>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'forsale',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
        found: false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('ForSale')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #de6949;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #f96;
      }

      .cls-2, .cls-7, .cls-9, .cls-11, .cls-12, .cls-15, .cls-16, .cls-17, .cls-19, .cls-20 {
        fill-rule: evenodd;
      }

      .cls-3 {
        fill: #edd970;
      }

      .cls-4 {
        fill: #465572;
      }

      .cls-22 {
        stroke: #000;
        stroke-width: .6px;
      }

      .cls-22, .cls-16, .cls-18 {
        fill: #fff;
      }

      .cls-5, .cls-9 {
        fill: #d4a15e;
      }

      .cls-6 {
        fill: #be574d;
      }

      .cls-7 {
        fill: #70a30a;
      }

      .cls-8 {
        fill: #f27350;
      }

      .cls-10 {
        fill: #5a698a;
      }

      .cls-11 {
        fill: #f2d291;
      }

      .cls-12 {
        fill: #900;
      }

      .cls-23 {
        font-family: Arial-BoldMT, Arial;
        font-size: 5.9px;
        font-weight: 700;
      }

      .cls-23, .cls-24 {
        isolation: isolate;
      }

      .cls-23, .cls-20 {
        fill: #000;
      }

      .cls-13 {
        fill: #505d7a;
      }

      .cls-14 {
        fill: #630;
      }

      .cls-15 {
        fill: #8ab8b8;
      }

      .cls-17 {
        fill: #edba68;
      }

      .cls-19 {
        fill: #1a1a1a;
      }

      .cls-21 {
        fill: #a14a41;
      }
  </style>
