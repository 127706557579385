<template>
    <div id="doghouse" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path class="cls-10" d="M550.5,537.3h28.8c1.7,0,3.2,1.2,3.2,2.7h0c0,1.5-1.4,2.7-3.2,2.7h-22.8l-6-5.4Z"/>
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-18" d="M574.4,514.5h-17.1l-10.3,10.2c-1,1-1.6,2.4-1.6,3.9s.4,2.4,1.2,3.4v10.4h37.1v-8.3h1.3v-7.5l-10.5-12Z"/>
  <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
    <g>
      <polygon class="cls-6" points="578.6 537.3 563 537.3 563 525.1 576.1 525.1 578.6 528.4 578.6 537.3"/>
      <rect class="cls-20" x="566.9" y="528.5" width="11.7" height="1.3"/>
      <polygon class="cls-13" points="579.9 528.4 559.4 528.4 559.4 519.5 572.1 519.5 579.9 528.4"/>
      <rect class="cls-2" x="567.8" y="528.4" width="12.1" height=".6"/>
      <polygon class="cls-9" points="567.2 528.3 559.3 520.5 551.3 528.3 551.5 528.3 551.5 537.3 567 537.3 567 528.3 567.2 528.3"/>
      <rect class="cls-13" x="555.6" y="526.8" width="7.4" height="10.5"/>
      <polyline class="cls-1" points="552.7 528.5 560.5 520.7 559.8 520 551.3 528.5"/>
      <path class="cls-2" d="M567.8,529s-.5-.3-.6-.4l-7.9-7.9-8.1,8.2c-.2.2-.5.2-.7,0s-.2-.5,0-.7l8.5-8.5c.2-.2.5-.2.7,0l8.5,8.5c.2.2.2.5,0,.7h-.4Z"/>
      <polygon class="cls-16" points="563 526.8 555.6 526.8 555.6 527.1 563 532.9 563 526.8"/>
    </g>
    <g>
      <path id="tail2" class="cls-12" d="M540.1,545l1.6.3c0,1-.7,1.6-1.2,2.1s-1.2.7-1.8.8c-.7,0-1.4,0-1.9-.3-.7-.3-1.2-.8-1.5-1.5-.4-1.1-.4-1.9,0-2.6.3-.7,1-1.2,1.6-1.4.3,0,1.4,0,1.4.3s-.7,0-1.1.5c-.4.4-.5.8-.7,1.4-.3,2.7,3.4,2.1,3.7.4h-.1Z"/>
      <path class="cls-12" d="M540.5,540.8l1.8,9.5,2.6-.5c-.4-2.3-1-4.7-1.4-7.1,0-.7-.7-3.4-1.9-3.7-.7,0-.5,1.8-1.2,1.9h.1Z"/>
      <path class="cls-4" d="M543.4,548.8c-.7,0-1.5.3-1.5,1.1s0,1,.5,1,.4,0,.5-.3c0,0,.4.3.7.3h1.1c.3,0,.7-.4.5-.8,0-1-1.1-1.1-1.9-1.1h0Z"/>
      <path class="cls-12" d="M541.8,540.8l-1.8,9.5-2.6-.5c.4-2.3,1-4.7,1.4-7.1,0-.7.7-3.7,2.2-3.8.7,0,.3,1.9,1,2.1h-.1Z"/>
      <path class="cls-4" d="M538.9,548.8c.7,0,1.5.3,1.5,1.1s0,1-.5,1-.4,0-.5-.3c0,0-.4.3-.7.3h-1.1c-.3,0-.7-.4-.5-.8,0-1,1.1-1.1,1.9-1.1h0Z"/>
      <path class="cls-11" d="M541.5,538.6h3.7c.8,0,1.5.7,1.5,1.5v4c0,.8-.7,1.5-1.5,1.5h-3.7c-.8,0-1.5-.7-1.5-1.5v-4c0-.8.7-1.5,1.5-1.5Z"/>
      <rect class="cls-11" x="536.1" y="543.8" width="10" height="2.6" transform="translate(-18.9 1070.8) rotate(-88.4)"/>
      <path class="cls-8" d="M541.4,549.2c.7,0,1.5.3,1.5,1.1s0,1-.5,1-.4,0-.5-.3c0,0-.4.3-.7.3h-1.1c-.3,0-.7-.4-.5-.8,0-1,1.1-1.1,1.9-1.1h0Z"/>
      <rect class="cls-11" x="543.8" y="540.6" width="2.6" height="10" transform="translate(-15 15.4) rotate(-1.6)"/>
      <path class="cls-8" d="M545.3,549.2c-.7,0-1.5.3-1.5,1.1s0,1,.5,1,.4,0,.5-.3c0,0,.4.3.7.3h1.1c.3,0,.7-.4.5-.8,0-1-1.1-1.1-1.9-1.1h0Z"/>
      <path id="tongue2" class="cls-5" d="M544.4,538.6v2.7c0,.5-.4,1.1-1.1,1.1h0c-.5,0-1.1-.4-1.1-1.1v-2.7h2.2Z"/>
      <path class="cls-7" d="M543.3,539.7h0v1.6h0v-1.6Z"/>
      <path class="cls-14" d="M540.1,532.9c-1.2,1-.5,3-.4,3.7,0,.7.3,1.6,1.2,2.2l2.3-.4,2.5.4c1-.5,1.1-1.5,1.2-2.2,0-.8.8-2.9-.4-3.7-1.5-1.2-4.8-1.2-6.5,0h0Z"/>
      <path class="cls-8" d="M547.4,535.1c0,.7-.3,1.2-.3,1.6,0,.7-.3,1.6-1.2,2.2-.4,0-.7,0-.8-.5,0,0-.5-.4-.5-.5,0-1.2,0-2.9,2.9-2.7h0Z"/>
      <path class="cls-12" d="M546.9,531.2h-1c-.5,0-.8.4-.5,1.2.8,4.1,2.1,4.1,3,0,0-1,0-1.4-.5-1.2h-1,0Z"/>
      <path class="cls-12" d="M539.8,531.2h-1c-.5,0-.8.4-.5,1.2.8,4.1,2.1,4.1,3,0,0-1,0-1.4-.5-1.2h-1,0Z"/>
      <path class="cls-8" d="M543.4,539.5c0,.4-.4.8-1.2.8-1.2,0-1.2-1.2-1.2-1.4,0-.3.8-1,2.5,0,1.6-1.1,2.3-.4,2.5,0,0,.3,0,1.4-1.2,1.4s-1.1-.4-1.2-.8h0Z"/>
      <path class="cls-15" d="M542.9,539.1h1.1c.5,0,1-.4,1-1h0c0-.3,0-.4-.4-.4h-2.2c-.3,0-.4,0-.4.4h0c0,.5.4,1,1,1h0Z"/>
      <path class="cls-3" d="M542.3,538.2h.5-.5Z"/>
      <path class="cls-19" d="M541.2,536.2c.3,0,.4.3.4.4v.4c-.3-.3-.5-.3-.8-.3h0c0-.3.3-.4.4-.4h0Z"/>
      <path class="cls-17" d="M541.4,536.6h0Z"/>
      <path class="cls-19" d="M545.6,536.2c-.3,0-.4.3-.4.4v.4c.3-.3.5-.3.8-.3h0c0-.3-.3-.4-.4-.4h0Z"/>
      <path class="cls-17" d="M545.8,536.6h0Z"/>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'doghouse',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
        found:false
      }
    },
    mounted: function(){
      var hb = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
      hb.to('#tail2', .15, {rotation:20})

      var hb2 = gsap.timeline({repeat: -1, yoyo: true, ease:"Power2.easeInOut"});
      hb2.to('#tongue2', .32, {y:.7})
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('DogHouse')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
      .cls-1 {
        fill: #de6949;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #465572;
      }

      .cls-3 {
        fill: #deb8a0;
      }

      .cls-3, .cls-4, .cls-5, .cls-7, .cls-8, .cls-10, .cls-12, .cls-14, .cls-15, .cls-17, .cls-19 {
        fill-rule: evenodd;
      }

      .cls-4 {
        fill: #b0743c;
      }

      .cls-5 {
        fill: #fcc;
      }

      .cls-6 {
        fill: #be574d;
      }

      .cls-7 {
        fill: #f99;
      }

      .cls-8 {
        fill: #d1965e;
      }

      .cls-9 {
        fill: #f27350;
      }

      .cls-10 {
        fill: #69990a;
      }

      .cls-11, .cls-14 {
        fill: #a14a1b;
      }

      .cls-12 {
        fill: #823105;
      }

      .cls-13 {
        fill: #5a698a;
      }

      .cls-15 {
        fill: #331502;
      }

      .cls-16 {
        fill: #505d7a;
      }

      .cls-17, .cls-18 {
        fill: #fff;
      }

      .cls-19 {
        fill: #000;
      }

      .cls-20 {
        fill: #a14a41;
      }
  </style>
