<template>
  <div id="condo" class="layer home">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <polygon v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-12" points="336.5 362.6 336.5 347 328.9 347 328.9 344.4 325.9 344.4 325.9 318.5 279.7 318.5 279.7 344.4 276.8 344.4 276.8 347 257.5 347 257.5 362.6 259.2 362.6 259.2 389.5 257.5 389.5 257.5 405.2 259.2 405.2 259.2 427.9 257.5 427.9 257.5 443.6 259.2 443.6 259.2 486.2 334.5 486.2 334.5 443.6 336.5 443.6 336.5 427.9 334.5 427.9 334.5 405.2 336.5 405.2 336.5 389.5 334.5 389.5 334.5 362.6 336.5 362.6"/>
  <g>
    <g>
      <path class="cls-7" d="M335.2,474h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <path class="cls-7" d="M335.2,481h0c0-.5.4-.9.9-.9h63.4c.5,0,.9.4.9.9h0c0,.5-.4.9-.9.9h-63.4c-.5,0-.9-.4-.9-.9Z"/>
      <g>
        <path class="cls-7" d="M339.4,468.6c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-7" d="M350.7,468.6c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-7" d="M362,468.6c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-7" d="M373.3,468.6c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-7" d="M384.7,468.6c-.5,0-.9.4-.9.9v16.1h1.8v-16.1c0-.5-.4-.9-.9-.9Z"/>
        <path class="cls-7" d="M396.9,469.5c0-.5-.4-.9-.9-.9s-.9.4-.9.9v16.1h1.8v-16.1Z"/>
      </g>
    </g>
    <g>
      <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <rect class="cls-9" x="264" y="352" width="65.3" height="129.2"/>
      </g>
      <g>
        <rect class="cls-6" x="284.5" y="323.5" width="36.2" height="22.6"/>
        <rect class="cls-8" x="281.6" y="349.4" width="42.1" height="3.7"/>
        <g>
          <path class="cls-12" d="M288,329c.2-.5.6-.9,1-1.1.4-.3.9-.4,1.4-.4s1.1.2,1.6.5c.4.4.8.9.9,1.5h-1.2c-.1-.3-.3-.5-.5-.7s-.5-.2-.8-.2-.6,0-.8.3c-.2.2-.4.4-.6.7-.1.3-.2.7-.2,1.1s0,.8.2,1.1c.1.3.3.6.6.7.2.2.5.3.8.3s.5,0,.8-.2.4-.4.5-.7h1.2c-.2.6-.5,1.1-.9,1.5s-1,.5-1.6.5-1-.1-1.4-.4-.7-.6-1-1.1-.4-1-.4-1.6.1-1.2.4-1.6v-.2Z"/>
          <path class="cls-12" d="M295.1,333.4c-.4-.3-.7-.7-1-1.1-.2-.5-.4-1-.4-1.6s.1-1.2.4-1.6.6-.9,1-1.1c.4-.3.9-.4,1.4-.4s1,.1,1.4.4.7.7,1,1.1c.2.5.4,1,.4,1.6s-.1,1.2-.4,1.6c-.2.5-.6.9-1,1.1-.4.3-.9.4-1.4.4s-1-.1-1.4-.4ZM297.4,332.4c.2-.2.4-.4.6-.7.1-.3.2-.7.2-1.1s0-.8-.2-1.1c-.1-.3-.3-.6-.6-.7-.2-.2-.5-.2-.8-.2s-.6,0-.9.2c-.2.2-.4.4-.6.7-.1.3-.2.7-.2,1.1s0,.8.2,1.1.3.6.6.7.5.3.9.3.6,0,.8-.3Z"/>
          <path class="cls-12" d="M304.9,333.7h-1.1l-2.4-4.3v4.3h-1.1v-6.2h1.1l2.4,4.3v-4.3h1.1v6.2Z"/>
          <path class="cls-12" d="M309.6,327.9c.4.3.8.6,1,1.1s.3,1,.3,1.6-.1,1.2-.3,1.6-.6.8-1,1.1c-.4.3-.9.4-1.5.4h-1.9v-6.2h1.9c.6,0,1.1.1,1.5.4ZM309.4,332.1c.3-.4.5-.9.5-1.5s-.2-1.2-.5-1.5c-.3-.4-.7-.5-1.3-.5h-.8v4.1h.8c.6,0,1-.2,1.3-.5h0Z"/>
          <path class="cls-12" d="M313.1,333.4c-.4-.3-.7-.7-1-1.1-.2-.5-.4-1-.4-1.6s.1-1.2.4-1.6.6-.9,1-1.1c.4-.3.9-.4,1.4-.4s1,.1,1.4.4.7.7,1,1.1c.2.5.4,1,.4,1.6s-.1,1.2-.4,1.6c-.2.5-.6.9-1,1.1-.4.3-.9.4-1.4.4s-1-.1-1.4-.4ZM315.4,332.4c.2-.2.4-.4.6-.7.1-.3.2-.7.2-1.1s0-.8-.2-1.1c-.1-.3-.3-.6-.6-.7-.2-.2-.5-.2-.8-.2s-.6,0-.9.2c-.2.2-.4.4-.6.7-.1.3-.2.7-.2,1.1s0,.8.2,1.1.3.6.6.7.5.3.9.3.6,0,.8-.3Z"/>
          <path class="cls-12" d="M289.2,341.9h1.8v1h-2.8v-6.2h1.1v5.2h-.1Z"/>
          <path class="cls-12" d="M293.7,336.6v6.2h-1.1v-6.2h1.1Z"/>
          <path class="cls-12" d="M300.5,336.6l-2,6.2h-1.3l-2-6.2h1.1l1.5,5,1.5-5h1.2Z"/>
          <path class="cls-12" d="M303.2,336.6v6.2h-1.1v-6.2h1.1Z"/>
          <path class="cls-12" d="M309.8,342.8h-1.1l-2.4-4.3v4.3h-1.1v-6.2h1.1l2.4,4.3v-4.3h1.1v6.2Z"/>
          <path class="cls-12" d="M315.6,338.5c-.1-.3-.3-.5-.5-.6s-.5-.2-.7-.2-.6,0-.8.2c-.2.2-.4.4-.6.7-.1.3-.2.7-.2,1.1s0,.8.2,1.1.3.5.6.7c.2.2.5.2.9.2s.7-.1,1-.4.4-.6.5-1.1h-1.8v-1h2.9v1.1c0,.4-.2.8-.5,1.2-.2.4-.5.7-.9.9-.4.2-.8.3-1.2.3s-1-.1-1.4-.4-.7-.6-1-1.1-.4-1-.4-1.6.1-1.2.4-1.6.6-.9,1-1.1c.4-.3.9-.4,1.4-.4s1.1.2,1.6.5c.4.3.7.8.9,1.4h-1.4Z"/>
        </g>
        <rect class="cls-8" x="290.8" y="346.1" width="3.4" height="5.2"/>
        <rect class="cls-8" x="311.7" y="346.1" width="3.4" height="5.2"/>
      </g>
      <g>
        <line class="cls-1" x1="296" y1="443.8" x2="297.3" y2="443.8"/>
        <g>
          <rect class="cls-12" x="270.6" y="441.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="270.6" y="450.9" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="289.9" y="441.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="289.9" y="450.9" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="309.2" y="441.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="309.2" y="450.9" width="14.2" height="7.9"/>
        </g>
        <rect class="cls-6" x="269.5" y="458.8" width="16.5" height="2.5"/>
        <rect class="cls-6" x="288.8" y="458.8" width="16.5" height="2.5"/>
        <rect class="cls-6" x="308.1" y="458.8" width="16.5" height="2.5"/>
      </g>
      <g>
        <line class="cls-1" x1="296" y1="407.4" x2="297.3" y2="407.4"/>
        <g>
          <rect class="cls-12" x="270.6" y="405.1" width="14.2" height="7.9"/>
          <rect class="cls-12" x="270.6" y="414.4" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="289.9" y="405.1" width="14.2" height="7.9"/>
          <rect class="cls-12" x="289.9" y="414.4" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="309.2" y="405.1" width="14.2" height="7.9"/>
          <rect class="cls-12" x="309.2" y="414.4" width="14.2" height="7.9"/>
        </g>
        <rect class="cls-6" x="269.5" y="422.3" width="16.5" height="2.5"/>
        <rect class="cls-6" x="288.8" y="422.3" width="16.5" height="2.5"/>
        <rect class="cls-6" x="308.1" y="422.3" width="16.5" height="2.5"/>
      </g>
      <g>
        <line class="cls-1" x1="296" y1="370.9" x2="297.3" y2="370.9"/>
        <g>
          <rect class="cls-12" x="270.6" y="368.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="270.6" y="377.9" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="289.9" y="368.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="289.9" y="377.9" width="14.2" height="7.9"/>
        </g>
        <g>
          <rect class="cls-12" x="309.2" y="368.6" width="14.2" height="7.9"/>
          <rect class="cls-12" x="309.2" y="377.9" width="14.2" height="7.9"/>
        </g>
        <rect class="cls-6" x="269.5" y="385.8" width="16.5" height="2.5"/>
        <rect class="cls-6" x="288.8" y="385.8" width="16.5" height="2.5"/>
        <rect class="cls-6" x="308.1" y="385.8" width="16.5" height="2.5"/>
      </g>
      <g>
        <rect class="cls-6" x="262.3" y="395.8" width="69" height="4.4"/>
        <rect class="cls-10" x="262.3" y="394.5" width="69" height="4.4"/>
      </g>
      <g>
        <rect class="cls-6" x="262.3" y="434.2" width="69" height="4.4"/>
        <rect class="cls-10" x="262.3" y="432.9" width="69" height="4.4"/>
      </g>
      <g>
        <rect class="cls-6" x="262.3" y="353.2" width="69" height="4.4"/>
        <rect class="cls-10" x="262.3" y="352" width="69" height="4.4"/>
      </g>
    </g>
    <path class="cls-5" d="M338.6,474c-.4,0-.8,0-1.1.2-.5-.9-1.4-1.5-2.4-1.5s-1.7.4-2.2,1.1c-.4-.2-.8-.3-1.2-.3-1.3,0-2.3.9-2.7,2-.3-.1-.6-.2-1-.2-1.5,0-2.7,1.2-2.7,2.7s0,.7.2,1c-.8.5-1.3,1.4-1.3,2.3s.9,2.4,2.2,2.7c.1,1.4,1.3,2.5,2.7,2.5,3.3,0,6.4-.4,9.7-.4s2.7-1.2,2.7-2.7,0-.6-.2-.9c1.1-.4,1.8-1.4,1.8-2.6s-.8-2.2-1.8-2.6v-.7c0-1.5-1.2-2.7-2.7-2.7h0Z"/>
    <path class="cls-4" d="M331.3,471.4c-.5,0-1,.1-1.4.3-.6-1.1-1.7-1.9-3.1-1.9s-2.1.6-2.8,1.4c-.5-.2-1-.4-1.5-.4-1.6,0-2.9,1.1-3.3,2.5-.4-.1-.8-.2-1.2-.2-1.9,0-3.4,1.5-3.4,3.4s0,.9.2,1.3c-1,.6-1.6,1.7-1.6,2.9s1.2,3,2.7,3.3c.2,1.7,1.6,3.1,3.4,3.1,4.1,0,7.9-.5,12.1-.5s3.4-1.5,3.4-3.4,0-.8-.2-1.2c1.3-.5,2.3-1.7,2.3-3.2s-.9-2.8-2.3-3.2c0-.3.1-.6.1-.9,0-1.9-1.5-3.4-3.4-3.4h0Z"/>
    <g>
      <rect class="cls-3" x="272.5" y="474.6" width="34.6" height="4.3"/>
      <rect class="cls-2" x="272.5" y="478.9" width="34.6" height="4.3"/>
      <rect class="cls-2" x="278.3" y="483.2" width="1.4" height="2.9"/>
      <rect class="cls-2" x="299.9" y="483.2" width="1.4" height="2.9"/>
      <rect class="cls-3" x="272.5" y="486.1" width="34.6" height="3.4"/>
      <rect class="cls-2" x="277.3" y="489.5" width="3.3" height="5.3"/>
      <rect class="cls-2" x="298.9" y="489.5" width="3.3" height="5.3"/>
      <rect class="cls-2" x="313.1" y="482" width="2.9" height="9.8"/>
      <rect class="cls-3" x="316" y="482" width="2.9" height="9.8"/>
      <rect class="cls-2" x="318.8" y="482" width="2.9" height="9.8"/>
      <rect class="cls-11" x="316.6" y="491.8" width="1.6" height="2.9"/>
    </g>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false,
    }
  },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('Condo')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
  .cls-1 {
        fill: none;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #be574d;
      }

      .cls-3 {
        fill: #f27350;
      }

      .cls-4 {
        fill: #69990a;
      }

      .cls-4, .cls-5 {
        fill-rule: evenodd;
      }

      .cls-5 {
        fill: #547a08;
      }

      .cls-6 {
        fill: #629c8a;
      }

      .cls-7 {
        fill: #334536;
      }

      .cls-8 {
        fill: #2f3233;
      }

      .cls-9 {
        fill: #7bc3ad;
      }

      .cls-10 {
        fill: #99f2d7;
      }

      .cls-11 {
        fill: #83694e;
      }

      .cls-12 {
        fill: #fff;
      }
</style>
