<template>
    <div id="forrent" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-19" d="M636.9,369.8v-.2c-.1,0-35.8-35.7-35.8-35.7-2.3-2.3-5.9-2.5-8.4-.5h-53.9l-22.4,22.4c-3.1.4-5.5,3.1-5.5,6.2s2.4,5.8,5.4,6.2v22.5h108.6c1,.6,2.1,1,3.3,1,3.4,0,6-2.6,6-6v-5.9c.7-.3,1.4-.6,2-1.2l.3-.3c2.3-2.3,2.5-6,.3-8.6Z"/>
  <g>
    <path class="cls-6" d="M526.7,352.7c-.9,0-1.7.2-2.5.5-1-1.9-3-3.2-5.3-3.2s-3.7,1-4.8,2.4c-.8-.4-1.7-.6-2.6-.6-2.7,0-5.1,1.9-5.7,4.4-.7-.2-1.4-.4-2.1-.4-3.3,0-5.9,2.7-5.9,5.9s.1,1.5.4,2.2c-1.7,1-2.8,2.9-2.8,5s2,5.2,4.6,5.8c.3,3,2.8,5.4,5.9,5.4,7.2,0,13.7-.8,20.9-.8s5.9-2.7,5.9-5.9,0-1.4-.4-2c2.3-.8,4-3,4-5.6s-1.6-4.8-3.9-5.6c0-.5.2-1,.2-1.6,0-3.3-2.7-5.9-5.9-5.9h0Z"/>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <polygon class="cls-4" points="630.6 374.2 620.4 374.2 620.3 364 630.6 374.2"/>
      <path class="cls-4" d="M627.3,380.8h-6.9c-.5,0-1,.4-1,1s.4,1,1,1h6.9v3c0,.5.4,1,1,1s1-.4,1-1v-15.4c0-.5-.4-1-1-1s-1,.4-1,1v6.6h-6.9c-.5,0-1,.4-1,1s.4,1,1,1h6.9v2-.2Z"/>
      <polygon class="cls-9" points="620.4 385.8 620.4 362.1 596.7 338.5 565.2 370 565.2 385.8 620.4 385.8"/>
      <polygon class="cls-9" points="565.2 370 565.2 385.8 521.4 385.8 521.4 362.1 550.3 362.1 542.9 370 565.2 370"/>
      <polygon class="cls-2" points="540.9 338.5 596.7 338.5 565.2 370 542.9 370 550.3 362.1 517.3 362.1 540.9 338.5"/>
      <rect class="cls-19" x="593.6" y="362.4" width="17.2" height="16.1"/>
      <rect class="cls-3" x="578.9" y="366.2" width="7.1" height="19.6"/>
      <rect class="cls-19" x="550.7" y="374.4" width="7.1" height="6.3"/>
      <rect class="cls-19" x="526.1" y="367.3" width="11.7" height="12.4"/>
      <path class="cls-9" d="M537.9,372.9c.5,0,1-.4,1-1s-.4-1-1-1h-11.7c-.5,0-1,.4-1,1s.4,1,1,1h11.7Z"/>
      <path class="cls-1" d="M597.6,337.5c-.5-.5-1.3-.5-1.9,0l-31.6,31.7c-.5.5-.5,1.3,0,1.9.5.5,1.3.5,1.9,0l30.7-30.7,34.5,34.5c.5.5,1.3.5,1.9,0,.5-.5.5-1.3,0-1.9l-35.5-35.5h0Z"/>
      <path class="cls-1" d="M565.2,371.3c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3h-22.3c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h22.3Z"/>
      <path class="cls-1" d="M550.3,363.4c.7,0,1.3-.6,1.3-1.3s-.6-1.3-1.3-1.3h-33c-.7,0-1.3.6-1.3,1.3s.6,1.3,1.3,1.3h33Z"/>
      <polygon class="cls-11" points="610.7 362.4 593.6 362.4 593.6 378.6 610.7 362.4"/>
      <polygon class="cls-4" points="586 366.2 578.9 366.2 578.9 385.8 586 366.2"/>
      <polygon class="cls-12" points="566 371 596.7 340.3 599.2 342.9 571.1 371 566 371"/>
    </g>
    <path class="cls-6" d="M530.2,375c-.5,0-1,.1-1.5.3-.6-1.1-1.8-1.9-3.1-1.9s-2.2.6-2.9,1.4c-.5-.2-1-.4-1.6-.4-1.6,0-3,1.1-3.4,2.6-.4-.1-.8-.2-1.3-.2-2,0-3.5,1.6-3.5,3.5s0,.9.2,1.3c-1,.6-1.7,1.7-1.7,3s1.2,3.1,2.8,3.4c.2,1.8,1.7,3.2,3.5,3.2,4.3,0,8.2-.5,12.4-.5s3.5-1.6,3.5-3.5,0-.8-.2-1.2c1.4-.5,2.4-1.8,2.4-3.3s-1-2.8-2.3-3.3c0-.3,0-.6,0-.9,0-2-1.6-3.5-3.5-3.5h0Z"/>
    <g>
      <g>
        <rect class="cls-14" x="518" y="390.5" width="2.2" height="15.1"/>
        <rect class="cls-21" x="508.4" y="377.5" width="21.3" height="14.7"/>
        <g class="cls-23">
          <text class="cls-22" transform="translate(512.3 385.1)"><tspan x="0" y="0">FOR</tspan></text>
          <text class="cls-22" transform="translate(510 389.6)"><tspan x="0" y="0">RENT</tspan></text>
        </g>
      </g>
      <path class="cls-5" d="M519.1,401.9c.8.6,1.3,1.6,1.4,2.6.8-.7,1.9-.9,2.9-.8,0,1.1-.3,2.3-1.2,3.1-.7.7-1.7,1.1-2.6,1.1h-1c-.9,0-1.9-.4-2.6-1.1-.9-.8-1.3-2-1.2-3.1,1-.1,2.1.1,2.9.8,0-1.1.6-2,1.4-2.7h0Z"/>
    </g>
    <g>
      <g>
        <g id="fr-armR">
          <path class="cls-10" d="M601.2,396.5c.2,1.8.9,6.6,1.3,9.3h0c0,.1-.5,1.4-.5,1.4,0,.2,0,.5.3.5h0c.2,0,.5,0,.5-.3l.2-.5v1.7c0,.2,0,.4.3.4h0c.2,0,.4-.2.4-.4v-.2h0c.2,0,.4-.2.4-.4v-.2c0,0,.2-.2.2-.3v-2.6c0-.1,0-.2,0-.3-.5-3.6-1-7.3-1.6-10.9,0-.8-.9-2.6-2.8-2.3l1,5.1h.3Z"/>
        </g>
          <path class="cls-20" d="M599.9,393.3c2.8-1.7,3.1-8.1,1.7-11.6-1.1-2.9-2.2-3.9-5.3-3.9s-4.2.9-5.3,3.9c-1.4,3.5-1.1,9.9,1.7,11.6,2.9,1.8,4.3,1.8,7.3,0h-.1Z"/>
        <g id="fr-armL">
          <path class="cls-10" d="M591.5,396.5c-.2,1.8-.9,6.6-1.3,9.3h0c0,.1.5,1.4.5,1.4,0,.2,0,.5-.3.5h0c-.2,0-.5,0-.5-.3l-.2-.5v1.7c0,.2,0,.4-.3.4h0c-.2,0-.4-.2-.4-.4v-.2h0c-.2,0-.4-.2-.4-.4v-.2c0,0-.2-.2-.2-.3v-2.6c0-.1,0-.2,0-.3.5-3.6,1-7.3,1.6-10.9,0-.8.9-2.6,2.8-2.3l-1,5.1h-.3Z"/>
        </g>
          <polygon class="cls-10" points="597.3 404.4 599.9 404.4 599.7 418.4 597.4 418.3 597.3 412.4 597.3 404.4"/>
        <polygon class="cls-10" points="595.3 404.4 592.7 404.4 592.9 418.4 595.2 418.3 595.3 412.4 595.3 404.4"/>
        <path class="cls-15" d="M591,409.9h10.7l-.6-16.4c0-1.2-.6-2.3-1.9-2.3h-5.7c-1.2,0-1.8,1.1-1.9,2.3l-.6,16.4Z"/>
        <path class="cls-16" d="M592.8,417.8h2.6c.3.8.5,1.5.4,2.5h-5.4c0-1.4,2-2,2.4-2.5Z"/>
        <rect class="cls-13" x="590.3" y="419.9" width="5.6" height=".3"/>
        <path class="cls-16" d="M599.9,417.8h-2.6c-.3.8-.5,1.5-.4,2.5h5.4c0-1.4-2-2-2.4-2.5Z"/>
        <rect class="cls-13" x="596.7" y="419.9" width="5.6" height=".3"/>
        <path class="cls-10" d="M594.6,388.9h3.3v3c0,2.2-3.3,2.2-3.3,0v-3Z"/>
        <path class="cls-7" d="M597.8,392.6l-3.2-3.1v-.6h3.3v3c0,.3,0,.5,0,.7Z"/>
        <path class="cls-10" d="M596.3,377.8c1.2,0,4.8.1,4.8,4.3s-.8,5.7-1.4,6.3c-.6.6-1.2,1.2-2.3,1.5-.3.3-.6.4-1.1.5-.5,0-.8-.2-1.1-.5-1.2-.3-1.7-.9-2.3-1.5s-1.5-2.2-1.4-6.3c0-4.2,3.6-4.3,4.8-4.3Z"/>
        <path class="cls-18" d="M596.3,377.8c1.2,0,4.8.1,4.8,4.3s-.8,5.7-1.4,6.3c-.6.6-1.2,1.2-2.3,1.5-.3.3-.6.4-1.1.5v-12.6h0Z"/>
        <path class="cls-10" d="M592.1,384.2c0-.3-.2-.5-.6-.5s-1,.1-1,1.1.8,1.8,1.3,1.7c.5-.1.3-2,.2-2.2h0Z"/>
        <path class="cls-18" d="M600.4,384.2c0-.3.3-.5.7-.6.3,0,1,.1,1,1.1s-.8,1.8-1.3,1.7c-.5-.1-.3-2.2-.3-2.2h-.1Z"/>
        <path class="cls-20" d="M591.5,383.7c0-.4,0-1,0-1.6,0-4.2,3.7-4.4,4.9-4.4s4.8.1,4.9,4.4v1.6c-.8-.3-1.8-1.3-2.4-3.2-.6,1.1-4.2,2.7-7.2,3.2h-.2Z"/>
        <polygon class="cls-16" points="592.6 391.3 590.7 391 591.5 395.7 592.6 391.3"/>
        <path class="cls-17" d="M597.9,387.3c0,.8-.8,1.4-1.6,1.4s-1.5-.6-1.6-1.4h3.3-.1Z"/>
        <polygon class="cls-16" points="600 391.3 601.9 391 601.2 395.7 600 391.3"/>
        <g>
          <polygon class="cls-16" points="591.1 407.2 601.6 407.2 601.6 408.4 591 408.4 591.1 407.2"/>
          <polygon class="cls-16" points="591.2 405 601.5 405 601.5 406.3 591.1 406.3 591.2 405"/>
        </g>
      </g>
      <g>
        <path class="cls-8" d="M598.5,383.3c0,0-.2,0-.2-.2s0-.2.2-.2c0,0,.4,0,.8.1,0,0,.2.2,0,.3,0,.1-.2.2-.3.1-.3-.1-.6-.1-.6-.1h0Z"/>
        <path class="cls-8" d="M594.2,382.9c0,0,.2.1.2.2s0,.2-.2.2c0,0-.3,0-.6.1,0,0-.2,0-.3-.1,0-.1,0-.2,0-.3.4-.2.8-.1.8-.1h0Z"/>
        <g>
          <g>
            <path class="cls-8" d="M599.4,384.6h.2v-.2h0c-.3-.2-.8-.6-1.6,0v.2h.2c.6-.5,1-.2,1.2,0h0Z"/>
            <path class="cls-8" d="M598.7,384.2c-.2,0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4Z"/>
            <path class="cls-8" d="M599.4,384.1h0q0-.1,0-.1v.3q-.2,0,0,.1h0v-.3h0Z"/>
            <path class="cls-8" d="M599.6,384.2h0q0-.1,0-.1l-.2.3h0q0,.1,0,.1l.3-.3h0Z"/>
          </g>
          <g>
            <path class="cls-8" d="M593.2,384.6h-.2v-.2h0c.3-.2.8-.6,1.6,0v.2h-.2c-.6-.5-1-.2-1.2,0h0Z"/>
            <path class="cls-8" d="M593.9,384.2c.2,0,.4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4Z"/>
            <path class="cls-8" d="M593.2,384.1h0q0-.1,0-.1v.3q.2,0,0,.1h0v-.3h0Z"/>
            <path class="cls-8" d="M593,384.2h0q0-.1,0-.1l.3.3h0q0,.1,0,.1l-.2-.3h0Z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'forrent',
    props: {
    manualHighlight: Boolean
  },
    data() {
      return {
        highlight: false,
        found: false
      }
    },
    mounted: function(){
      var hb = gsap.timeline({repeat: -1, repeatDelay:3, ease:"Bounce.easeInOut"});
      hb.to('#fr-armR', 1, {rotation:-20})
      hb.to('#fr-armR', 1, {rotation:0})

      var hb2 = gsap.timeline({repeat: -1, repeatDelay:3, ease:"Bounce.easeInOut"});
      hb2.to('#fr-armL', 1, {rotation:20})
      hb2.to('#fr-armL', 1, {rotation:0})
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('ForRent')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #629c8a;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-15, .cls-16, .cls-17, .cls-18, .cls-20 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #7bc3ad;
      }

      .cls-3 {
        fill: #f77917;
      }

      .cls-21 {
        stroke: #000;
        stroke-width: .6px;
      }

      .cls-21, .cls-17, .cls-19 {
        fill: #fff;
      }

      .cls-4 {
        fill: #eb6600;
      }

      .cls-5 {
        fill: #70a30a;
      }

      .cls-6 {
        fill: #69990a;
      }

      .cls-7 {
        fill: #d4a15e;
      }

      .cls-8 {
        fill: #471e04;
      }

      .cls-9 {
        fill: #f6aa32;
      }

      .cls-10 {
        fill: #f2d291;
      }

      .cls-11 {
        fill: #e6e6e6;
      }

      .cls-22 {
        fill: #000;
        font-family: Arial-BoldMT, Arial;
        font-size: 5.9px;
        font-weight: 700;
      }

      .cls-22, .cls-23 {
        isolation: isolate;
      }

      .cls-12 {
        fill: #e39f32;
      }

      .cls-13 {
        fill: #f7c5a3;
      }

      .cls-14 {
        fill: #630;
      }

      .cls-15 {
        fill: #f60;
      }

      .cls-16 {
        fill: #ff0;
      }

      .cls-18 {
        fill: #edba68;
      }

      .cls-20 {
        fill: #a65b10;
      }
  </style>
