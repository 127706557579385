<template>
  <div id="sailboat" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
      <g id="sb-boat-all">
      <g id="sb-boat">
      <path class="cls-1" d="M222.8,300.9c-3.2,4.2-8.2,6.9-13.8,6.9s-10.7-2.7-13.8-6.9h27.6Z"/>
      <path class="cls-3" d="M213.4,298.9v-22.5s-12.8,6.1-18.2,22.5c4.8-3.1,11.2-4.7,18.2,0Z"/>
      <path class="cls-3" d="M215.5,298.9v-19.1s6.1,6.9,10.6,15l-10.6,4.1Z"/>
      <polygon class="cls-2" points="205.1 273.8 209.3 273.2 213.4 272.5 213.4 273.8 213.4 275.2 209.3 274.5 205.1 273.8"/>
      <path class="cls-2" d="M199.8,289.3c3.6-1,9.1-1.6,13.5,1.5v-6.5c-1.3-.7-3.9-1.8-8.2-1.6-1.8,1.8-3.6,3.9-5.3,6.5h0Z"/>
      <path class="cls-2" d="M208.6,279.7c1.6,0,3.2,0,4.8.6v-3.7s-2,1-4.8,3.2h0Z"/>
      </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){

     var sb = gsap.timeline({repeat: -1, yoyo: true, defaults: {ease: "Power2:easeInOut"}});
    sb.fromTo('#sb-boat', 2, {y:0, rotation: -2},{y:-2, rotation: 2, transformOrigin:"0% 0%"});
    sb.fromTo('#sb-boat', 2, {y:-2, rotation: 2},{y:0, rotation: -2});

    var sb2 = gsap.timeline({repeat: -1, yoyo: true, defaults: {ease: "Power2:easeInOut"}});
    sb2.fromTo('#sb-boat-all', 12, {x:0,y:0},{x:40,y:10});

  }
}
</script>

<style scoped>
      .cls-1 {
        fill: #152e56;
      }

      .cls-1, .cls-2, .cls-3 {
        fill-rule: evenodd;
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #c20212;
      }

      .cls-3 {
        fill: #fff;
      }
</style>
