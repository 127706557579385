<template>
  <div id="waterripples" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
  <rect id="r11" class="cls-1" x="319.9" y="231.5" width="17.2" height="3.3" rx="1.6" ry="1.6"/>
  <rect id="r12" class="cls-1" x="339.7" y="231.5" width="10.1" height="3.3" rx="1.6" ry="1.6"/>
  <path id="r21" class="cls-1" d="M212.9,262.3h21.8c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-21.8c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r22" class="cls-1" d="M190.4,258.5h3.6c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-3.6c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r23" class="cls-1" d="M250.8,257.3h9.7c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-9.7c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r24" class="cls-1" d="M259.5,252.3h19c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-19c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r31" class="cls-1" d="M646.2,243.1h3.6c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-3.6c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r32" class="cls-1" d="M526.4,288h3.6c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-3.6c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r33" class="cls-1" d="M100.9,237h9.7c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-9.7c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r34" class="cls-1" d="M594.9,273.5h9.7c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-9.7c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
  <path id="r35" class="cls-1" d="M603.5,268.5h19c.8,0,1.5.7,1.5,1.5h0c0,.8-.7,1.5-1.5,1.5h-19c-.8,0-1.5-.7-1.5-1.5h0c0-.8.7-1.5,1.5-1.5Z"/>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){
    var ripples = gsap.timeline({repeat:-1, repeatDelay:2.6, ease:"Cubic.easeInOut"});
    ripples.fromTo('#r11',2,{y:0,alpha:0},{y:3,alpha:1});
    ripples.to('#r11',.5,{alpha:0});
    ripples.fromTo('#r12',2,{y:0,alpha:0},{y:3,alpha:1},'-=1.5');
    ripples.to('#r12',.5,{alpha:0});

    var ripples2 = gsap.timeline({delay:2, repeat:-1, repeatDelay:2.3, ease:"Cubic.easeInOut"});
    ripples2.fromTo('#r21',2,{y:0,alpha:0},{y:3,alpha:1});
    ripples2.to('#r21',.5,{alpha:0});
    ripples2.fromTo('#r22',2,{y:0,alpha:0},{y:3,alpha:1},'-=2');
    ripples2.to('#r22',.5,{alpha:0});
    ripples2.fromTo('#r23',2,{y:0,alpha:0},{y:3,alpha:1},'-=2');
    ripples2.to('#r23',.5,{alpha:0});
    ripples2.fromTo('#r24',2,{y:0,alpha:0},{y:3,alpha:1},'-=2');
    ripples2.to('#r24',.5,{alpha:0});

    var ripples3 = gsap.timeline({delay: 1.3, repeat:-1, repeatDelay:2.1, ease:"Cubic.easeInOut"});
    ripples3.fromTo('#r31',2,{y:0,alpha:0},{y:3,alpha:1});
    ripples3.to('#r31',.5,{alpha:0});
    ripples3.fromTo('#r32',2,{y:0,alpha:0},{y:3,alpha:1},'-=1.5');
    ripples3.to('#r32',.5,{alpha:0});
    ripples3.fromTo('#r33',2,{y:0,alpha:0},{y:3,alpha:1},'-=1.5');
    ripples3.to('#r33',.5,{alpha:0});
    ripples3.fromTo('#r34',2,{y:0,alpha:0},{y:3,alpha:1},'-=1.5');
    ripples3.to('#r34',.5,{alpha:0});
    ripples3.fromTo('#r35',2,{y:0,alpha:0},{y:3,alpha:1},'-=1.5');
    ripples3.to('#r35',.5,{alpha:0});


  }
}
</script>

<style scoped>
  .cls-1 {
    fill: #fff;
    stroke-width: 0px;
  }
</style>
