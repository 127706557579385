<template>
    <div id="newhome" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-32" d="M564.7,183.3l-18.1-18.1h-3.1v-13.3h-26.4v13.3h-14.4c-1-.6-2.2-1-3.3-1s-3.1.6-4.3,1.8l-1.5,1.5h0c0,.1-27.2,27.3-27.2,27.3-1.1,1.1-1.8,2.7-1.8,4.3s.6,3.2,1.8,4.3c.5.5,1,.8,1.5,1.1v4.1c0,3.1,2.7,5.8,5.8,5.8s2-.3,2.9-.8h88.7v-18.3c2.5-.7,4.4-3.1,4.4-5.9s-2.2-5.5-5-6Z"/>
  <g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <path class="cls-30" d="M566.4,193.6c-.5,0-1,0-1.4.2-.5-1.1-1.6-1.9-2.9-2s-2.1.4-2.8,1.2c-.4-.2-.9-.4-1.5-.4-1.5,0-2.9.9-3.3,2.3-.4-.2-.8-.3-1.2-.3-1.8,0-3.4,1.3-3.5,3.2,0,.4,0,.8.2,1.2-1,.5-1.7,1.6-1.7,2.8s1,3,2.4,3.4c0,1.7,1.4,3.1,3.2,3.2,4,.2,7.7,0,11.7.1s3.4-1.3,3.5-3.2,0-.8,0-1.2c1.3-.4,2.3-1.6,2.4-3s-.8-2.7-2-3.2c0-.3,0-.6.2-.9,0-1.8-1.3-3.4-3.2-3.5h0Z"/>
      <polygon class="cls-30" points="471.8 199.2 480.1 199.2 480.2 190.9 471.8 199.2"/>
      <path class="cls-3" d="M474.5,204.5h5.6c.4,0,.8.4.8.8s-.4.8-.8.8h-5.6v2.4c0,.4-.4.8-.8.8s-.8-.4-.8-.8v-12.5c0-.4.4-.8.8-.8s.8.4.8.8v5.3h5.6c.4,0,.8.4.8.8s-.4.8-.8.8h-5.6v1.6h0Z"/>
      <polygon class="cls-14" points="480.1 208.5 480.1 189.4 499.3 170.2 524.8 195.8 524.8 208.5 480.1 208.5"/>
      <polygon class="cls-14" points="524.8 195.8 524.8 208.5 560.3 208.5 560.3 189.4 536.9 189.4 542.9 195.8 524.8 195.8"/>
      <polygon class="cls-19" points="538.7 208.5 560.3 208.5 560.3 189.4 536.9 189.4 542.9 195.8 525.9 195.8 538.7 208.5"/>
      <polygon class="cls-30" points="544.5 170.2 499.3 170.2 524.8 195.8 542.9 195.8 536.9 189.4 563.7 189.4 544.5 170.2"/>
      <rect class="cls-4" x="525" y="161.5" width="10.6" height="16.8"/>
      <rect class="cls-4" x="522.1" y="156.9" width="16.4" height="4.7"/>
      <rect class="cls-32" x="485.9" y="189.6" width="5.7" height="13.1"/>
      <rect class="cls-32" x="496.1" y="189.6" width="5.7" height="13.1"/>
      <rect class="cls-4" x="508" y="192.7" width="5.7" height="15.8"/>
      <rect class="cls-32" x="530.9" y="199.3" width="5.7" height="5.1"/>
      <polygon class="cls-20" points="530.9 199.3 536.6 199.3 536.6 204.4 534.6 204.4 530.9 200.7 530.9 199.3"/>
      <rect class="cls-34" x="547" y="193.6" width="9.5" height="10"/>
      <path class="cls-19" d="M547,198.1c-.4,0-.8-.4-.8-.8s.4-.8.8-.8h9.5c.4,0,.8.4.8.8s-.4.8-.8.8h-9.5Z"/>
      <path class="cls-9" d="M498.6,169.5c.4-.4,1.1-.4,1.5,0l25.6,25.7c.4.4.4,1.1,0,1.5s-1.1.4-1.5,0l-24.8-24.9-28,28c-.4.4-1.1.4-1.5,0s-.4-1.1,0-1.5l28.7-28.7h0Z"/>
      <path class="cls-9" d="M524.8,196.8c-.6,0-1.1-.5-1.1-1.1s.5-1.1,1.1-1.1h18.1c.6,0,1.1.5,1.1,1.1s-.5,1.1-1.1,1.1h-18.1Z"/>
      <path class="cls-9" d="M536.9,190.4c-.6,0-1.1-.5-1.1-1.1s.5-1.1,1.1-1.1h26.7c.6,0,1.1.5,1.1,1.1s-.5,1.1-1.1,1.1h-26.7Z"/>
      <polygon class="cls-8" points="525 161.5 535.6 161.5 535.6 178.4 525 161.5"/>
      <polygon class="cls-23" points="480.2 190.9 499.3 171.7 501.4 173.8 484.3 190.9 480.2 190.9"/>
      <polygon class="cls-20" points="485.9 189.6 491.7 189.6 491.7 202.7 485.9 189.6"/>
      <polygon class="cls-20" points="496.1 189.6 501.9 189.6 501.9 202.7 496.1 189.6"/>
      <polygon class="cls-8" points="508 192.7 513.7 192.7 513.7 208.5 508 192.7"/>
    </g>
    <g>
      <path class="cls-17" d="M524.8,213.9c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.4-1,.5-2,0-3-.9.4-1.7,1.1-2.2,2,0,.1,0,.2,0,.3,0-.1,0-.2,0-.3-.4-1-1.2-1.7-2.2-2-.3.9-.3,2,0,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM536.3,213.9c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-22" d="M530.5,206.1c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <g>
      <path class="cls-17" d="M536.6,213.9c.2,1.2.9,2.3,2,3s2.3,1,3.5.8v3.7h.4v-7.2c.8-.4,1.6-1.1,2-2,.5-1,.5-2,0-3-.9.4-1.7,1.1-2.2,2,0,.1,0,.2,0,.3,0-.1,0-.2,0-.3-.5-1-1.2-1.7-2.2-2-.3.9-.3,2,0,3,.4.9,1.1,1.6,2,2v3.4c-.3-1.1-.9-2.1-1.9-2.8-1.1-.7-2.3-1-3.5-.8h0ZM548,213.9c-.2,1.2-.9,2.3-2,3s-2.3,1-3.5.8c.2-1.2.9-2.3,2-3s2.3-1,3.5-.8Z"/>
      <path class="cls-22" d="M542.3,206.1c.9,0,1.6.7,1.6,1.6s-.7,1.6-1.6,1.6-1.6-.7-1.6-1.6.7-1.6,1.6-1.6Z"/>
    </g>
    <g>
      <path class="cls-2" d="M520.4,241.3c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM514.6,248.9c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7-5.7,2.6-5.7,5.7Z"/>
      <path class="cls-27" d="M520.4,242.7c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM514.6,248.9c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7-5.7,2.6-5.7,5.7Z"/>
      <path class="cls-26" d="M520.1,247.6c0,.3,0,.5.3.6.3,0,.5,0,.6-.3l5.5-14.1v-.3c0,0,0-.2,0-.2l-.2-.2-.7.7h0l-5.4,13.8h0Z"/>
      <path class="cls-10" d="M526.1,233.1l-2-1.6h-.2c0-.1-.2-.1-.2-.1,0,0-6.5.9-1.8,4.1.2.2.5,0,.7-.1.2-.2,0-.5,0-.7-2.2-1.5.4-2.1,1.1-2.2l1.7,1.4.7-.7h0Z"/>
      <path class="cls-10" d="M530.5,245.9h1.7c.3,0,.5.2.5.5h0c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5h0c0-.3.2-.5.5-.5Z"/>
      <path class="cls-26" d="M525.7,236.9c-.3-.3-.7-.3-1,0s-.3.7,0,1l9.7,11.3c.3.3.7.3,1,0s.3-.7,0-1l-9.7-11.3Z"/>
      <path class="cls-26" d="M525.6,235.9c-.4,0-.8.2-.9.6,0,.4.2.8.6.9l12.7,2.8c.4,0,.8-.2.9-.6,0-.4-.2-.8-.6-.9l-12.7-2.8Z"/>
      <path class="cls-26" d="M540.4,234.9c0-.3,0-.5-.3-.6-.3,0-.5,0-.6.3l-5,13.8c0,.3,0,.5.3.6.3,0,.5,0,.6-.3l5-13.8Z"/>
      <path class="cls-2" d="M546.3,241.3c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM540.5,248.9c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7-5.7,2.6-5.7,5.7Z"/>
      <path class="cls-27" d="M546.3,242.7c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM540.5,248.9c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7-5.7,2.6-5.7,5.7Z"/>
      <path class="cls-10" d="M542.1,233.3h-5.3c-1.2,0-1,1.1.2,1.1s2.3.8,3.3.8h1.8c.5,0,.9-.4.9-.9h0c0-.5-.4-.9-.9-.9h0Z"/>
      <path class="cls-2" d="M535.8,245.8c0,0-.3,0-.3.2s0,.3.2.3l10.9,1.7c0,0,.3,0,.3-.2s0-.3-.2-.3l-11-1.7h0Z"/>
      <path class="cls-2" d="M535.3,250.4c0,0-.2.1-.2.3s0,.3.3.2l11.1-.6c0,0,.2-.1.2-.3s0-.3-.3-.2l-11.1.6Z"/>
      <path class="cls-26" d="M538.6,239.3c-.2-.2-.5-.2-.7,0s-.2.5,0,.7l7.7,8.9c.2.2.5.2.7,0s.2-.5,0-.7l-7.7-8.9Z"/>
      <circle class="cls-4" cx="520.4" cy="248.9" r="1.2"/>
      <path class="cls-26" d="M534.8,248.2c-.3,0-.5.2-.5.5s.2.5.5.5h11.5c.3,0,.5-.2.5-.4,0-.3-.2-.5-.5-.5h-11.5Z"/>
      <circle class="cls-4" cx="546.3" cy="248.9" r="1.2"/>
      <path class="cls-10" d="M535.1,246.1c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3,2.3-1,2.3-2.3-1-2.3-2.3-2.3Z"/>
      <path class="cls-29" d="M535.1,247.6c-.5,0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9Z"/>
      <path class="cls-2" d="M537.4,251.8c0,.1.2.1.3,0,0,0,0-.2,0-.4l-2.4-3.2c0-.1-.2-.1-.3,0,0,0,0,.2,0,.4l2.4,3.2Z"/>
      <path class="cls-10" d="M536.7,251.4h1.7c.3,0,.5.2.5.5h0c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5h0c0-.3.2-.5.5-.5Z"/>
    </g>
    <g>
      <g>
        <rect class="cls-21" x="558.9" y="215.2" width="8.1" height="10.2"/>
        <g>
          <polygon class="cls-16" points="563.6 234.3 565.7 234.3 565.6 245.8 563.6 245.7 563.6 240.9 563.6 234.3"/>
          <polygon class="cls-16" points="561.9 234.3 559.8 234.3 559.9 245.8 561.9 245.7 561.9 240.9 561.9 234.3"/>
        </g>
        <path class="cls-26" d="M559.8,245.3h2.1c.2.7.4,1.2.4,2.1h-4.5c0-1.1,1.6-1.7,2-2.1Z"/>
        <rect class="cls-7" x="557.8" y="247.1" width="4.6" height=".3"/>
        <path class="cls-26" d="M565.7,245.3h-2.1c-.2.7-.4,1.2-.4,2.1h4.5c0-1.1-1.6-1.7-2-2.1Z"/>
        <rect class="cls-7" x="563.1" y="247.1" width="4.6" height=".3"/>
        <path class="cls-10" d="M558.6,234.6h8.4v-9.1c0-1-.8-1.9-1.9-1.9h-4.7c-1,0-1.9.8-1.9,1.9v9.1h.1Z"/>
        <g>
          <g>
            <path class="cls-16" d="M558.7,227.2s-.8,5.7-1.2,8.4h-.6l-1-.8c.4-2.9,1.1-7.8,1.1-7.8l1.7.2h0Z"/>
            <path class="cls-10" d="M556.8,227.5l2,.4.8-4.2c-1.5-.3-2.3,1.2-2.4,1.9l-.4,1.9h0Z"/>
            <path class="cls-16" d="M557.4,236.8l-.4-1.1c0-.2,0-.4.2-.4h0c.2,0,.4,0,.4.2l.4,1.1c0,.2,0,.4-.2.4h0c-.2,0-.4,0-.4-.2Z"/>
            <path class="cls-16" d="M556.5,235.7v2.2c0,.2.2.3.4.3h0c.2,0,.3-.2.3-.4v-2.2c0-.2-.2-.3-.4-.3h0c-.2,0-.3.2-.3.4Z"/>
            <path class="cls-16" d="M556.1,235.2v2.2c0,.2.2.3.4.3h0c.2,0,.3-.2.3-.4v-2.2c0-.2-.2-.3-.4-.3h0c-.2,0-.3.2-.3.4Z"/>
            <path class="cls-16" d="M555.9,234.7v2.2c0,.2.2.3.4.3h0c.2,0,.3-.2.3-.4v-2.2c0-.2-.2-.3-.4-.3h0c-.2,0-.3.2-.3.4Z"/>
          </g>
          <g>
            <path class="cls-16" d="M566.8,227.2s.8,5.7,1.2,8.4h.6l1-.8c-.4-2.9-1.1-7.8-1.1-7.8l-1.7.2h0Z"/>
            <path class="cls-10" d="M568.7,227.5l-2,.4-.8-4.2c1.5-.3,2.3,1.2,2.4,1.9l.4,1.9h0Z"/>
            <path class="cls-16" d="M568.1,236.8l.4-1.1c0-.2,0-.4-.2-.4h0c-.2,0-.4,0-.4.2l-.4,1.1c0,.2,0,.4.2.4h0c.2,0,.4,0,.4-.2Z"/>
            <path class="cls-16" d="M569,235.7v2.2c0,.2-.2.3-.4.3h0c-.2,0-.3-.2-.3-.4v-2.2c0-.2.2-.3.4-.3h0c.2,0,.3.2.3.4Z"/>
            <path class="cls-16" d="M569.4,235.2v2.2c0,.2-.2.3-.4.3h0c-.2,0-.3-.2-.3-.4v-2.2c0-.2.2-.3.4-.3h0c.2,0,.3.2.3.4Z"/>
            <path class="cls-16" d="M569.6,234.7v2.2c0,.2-.2.3-.4.3h0c-.2,0-.3-.2-.3-.4v-2.2c0-.2.2-.3.4-.3h0c.2,0,.3.2.3.4Z"/>
          </g>
        </g>
        <path class="cls-27" d="M565.9,223.6h0v.2c0,.8-.4,1.4-1,1.9s-1.4.8-2.2.8-1.6-.3-2.2-.8c-.6-.5-1-1.2-1-1.9s0-.1,0-.2h0c.4-.1.9-.2,1.3-.2h1v.4h-1.4c0,.5.3.9.7,1.2s1,.6,1.7.6,1.3-.2,1.7-.6c.4-.3.6-.7.7-1.2h-1.4v-.4h1c.4,0,.9,0,1.3.2h-.2Z"/>
        <path class="cls-16" d="M565.5,223.5v.3c0,1.2-1.2,2.2-2.8,2.2s-2.8-1-2.8-2.2,0-.2,0-.3h1.3v-1.5h0c0-.1,2.7-.1,2.7-.1h0v1.6h1.6Z"/>
        <path class="cls-12" d="M564.7,225.5l-3.3-3.3h0l.6-.2h0c0-.1,2.1-.1,2.1-.1h0v1.6h1.3v.3c0,.6-.3,1.2-.8,1.6h.1Z"/>
        <path class="cls-16" d="M562.7,212.5c-1,0-3.9.1-4,3.5,0,3.4.7,4.7,1.1,5.2.5.5.9,1,1.9,1.2.2.2.5.3.9.4.4,0,.7-.1.9-.4,1-.2,1.4-.7,1.9-1.2s1.2-1.8,1.1-5.2c0-3.4-3-3.5-4-3.5h.2Z"/>
        <path class="cls-28" d="M562.7,212.5c1,0,3.9.1,4,3.5,0,3.4-.7,4.7-1.1,5.2-.5.5-.9,1-1.9,1.2-.2.2-.5.3-.9.4v-10.4h0Z"/>
        <path class="cls-16" d="M559.3,217.8c0-.2-.2-.4-.5-.4s-.8,0-.8.9.7,1.5,1.1,1.4c.4,0,.2-1.6.2-1.8h0Z"/>
        <path class="cls-33" d="M564.9,214.5c.4.8.4,2.1,1.9,2.9,1-3.7-1.6-5.5-4.1-5.4-2.7,0-4.6,1.8-3.8,5.5.8-1.2,5.6-2.4,6.1-3h-.1Z"/>
        <path class="cls-27" d="M564.1,220.3c0,.7-.7,1.2-1.3,1.2s-1.2-.5-1.3-1.2h2.7-.1Z"/>
        <path class="cls-1" d="M566.8,234.5h-8.1v2.1h8.2v-2.1h-.1Z"/>
        <path class="cls-33" d="M562.7,217.2c-1.3,0-2.7-.2-3.8-.5,0-.7,0-1.4,0-2.1.8-2.6,3.7-2.4,3.7-2.4,0,0,2.9-.2,3.7,2.4,0,.7,0,1.4,0,2.1-.2,0-.7.1-1,.2-.2,0,0-.8-.6-1.8.3,1,.2,1.9,0,2-.7,0-1.6.2-2.2.2h0Z"/>
        <path class="cls-33" d="M565.5,215.2h1.4l.6,10.1c0,.8-1.3,1.9-2.1,1.9h0v-12h0Z"/>
        <g>
          <path class="cls-27" d="M561.8,228.3c-.3-.3-.7-.6-.9-.7-.2,0,0,1.2,0,1.5,0,.4-.2.9.4,1.7.5.8,1.2,1.1,1.5.9.3.2,1-.1,1.5-.9.6-.8.4-1.3.4-1.7s.2-1.6,0-1.5c-.2,0-.6.4-.9.7-.7-.3-1.3-.3-2,0Z"/>
          <path class="cls-5" d="M562.3,230.7h0c0,.1,0,.1,0,.1,0,0-.2-.4-.8-.5,0,0,0,0,0-.1,0,0,0-.1,0,0,.7,0,.9.6.9.6h-.1Z"/>
          <path class="cls-5" d="M563.3,230.8h0c0-.1.2-.6.9-.7h0c-.6.1-.8.6-.8.6h-.1Z"/>
          <path class="cls-5" d="M562.7,231.6s-.2-.2-.3-.2c0-.2.6-.2.6,0,0,0-.2.2-.3.2Z"/>
        </g>
        <path class="cls-18" d="M556,234.2l1.7.3c.2,0,.3.2.3.4h0c0,.2-.2.3-.4.3l-1.7-.3c-.2,0-.3-.2-.3-.4h0c0-.2.2-.3.4-.3Z"/>
      </g>
      <g>
        <path class="cls-33" d="M564.4,217.2s-.2,0-.2-.2c0,0,0-.2.2-.2,0,0,.3,0,.6.1,0,0,0,.1,0,.2,0,0,0,.1-.2,0h-.5.1Z"/>
        <path class="cls-33" d="M561.1,216.9s.2,0,.2.2c0,0,0,.2-.2.2h-.7v-.2c.3-.1.6-.1.6-.1h.1Z"/>
        <g>
          <g>
            <path class="cls-33" d="M565.1,218.2h0c-.2-.3-.6-.6-1.2-.1v.2h.2c.5-.4.8-.2,1,0h0Z"/>
            <path class="cls-33" d="M564.6,217.9c-.2,0-.3.1-.3.3s0,.3.3.3.3-.1.3-.3,0-.3-.3-.3Z"/>
            <path class="cls-33" d="M565.1,217.9h0v.2q0,0,0,0h0v-.2Z"/>
            <path class="cls-33" d="M565.3,217.9h0l-.2.2h0l.2-.2Z"/>
          </g>
          <g>
            <path class="cls-33" d="M560.4,218.2h-.2c.2-.3.6-.6,1.2-.1v.2h-.2c-.5-.4-.8-.2-1,0h.2Z"/>
            <path class="cls-33" d="M560.9,217.9c.2,0,.3.1.3.3s0,.3-.3.3-.3-.1-.3-.3,0-.3.3-.3Z"/>
            <path class="cls-33" d="M560.3,217.9h0v.2q0,0,0,0h0v-.2Z"/>
            <path class="cls-33" d="M560.2,217.9h0l.2.2h0l-.2-.2Z"/>
          </g>
        </g>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path class="cls-16" d="M460.9,239.4h5.3c0-1.2-1.2-1.3-2.1-1.6-.3-.1-.8-.4-1-.7,0-3.5.1-6.9.2-10.4h-2.7v10.5c0,.9-.7,2.4.3,2.3h0Z"/>
          <rect class="cls-31" x="460.3" y="238.9" width="6" height=".5"/>
          <path class="cls-25" d="M466.2,238.9c-.6.7-5.9,0-5.9,0,0,0,2.8-.9,4.3-1.1.3,0,1.9.5,1.6,1h0Z"/>
        </g>
        <g>
          <path class="cls-16" d="M458.7,239.4h-5.3c0-1.2,1.2-1.3,2.1-1.6.3-.1.8-.4,1-.7,0-3.5-.1-6.9-.2-10.4h2.7v10.5c0,.9.7,2.4-.3,2.3h0Z"/>
          <rect class="cls-31" x="453.3" y="238.9" width="6" height=".5"/>
          <path class="cls-25" d="M453.4,238.9c.6.7,5.8,0,5.8,0,0,0-2.8-.9-4.2-1.1-.3,0-2,.5-1.6,1h0Z"/>
        </g>
        <path class="cls-13" d="M464,226.1h-8.4v8.3h3.7v-6.7h.9v6.7h3.8v-8.3Z"/>
        <path class="cls-24" d="M455.6,227.3h8.4v-10.3c0-1-.8-1.9-1.9-1.9h-4.7c-1,0-1.9.8-1.9,1.9v10.3h.1Z"/>
        <path class="cls-16" d="M455.8,219s-.7,5.5-1.2,8.3h0c0,.1.4,1.2.4,1.2,0,.2,0,.4-.2.5h0c-.2,0-.4,0-.5-.2v-.4c-.1,0,0,1.4,0,1.4,0,.2-.1.4-.3.4h0c-.2,0-.4-.1-.4-.3v-.2h0c-.2,0-.4-.1-.4-.3v-.2c-.1,0-.2-.2-.2-.3v-2.4c.4-3,1-7.6,1-7.6l1.7.2h.1Z"/>
        <path class="cls-16" d="M463.8,219s.7,5.5,1.2,8.3h0c0,.1-.4,1.2-.4,1.2,0,.2,0,.4.2.5h0c.2,0,.4,0,.5-.2v-.4c.1,0,0,1.4,0,1.4,0,.2.1.4.3.4h0c.2,0,.4-.1.4-.3v-.2h0c.2,0,.4-.1.4-.3v-.2c.1,0,.2-.2.2-.3v-2.4c-.4-3-1-7.6-1-7.6l-1.7.2h-.1Z"/>
        <path class="cls-24" d="M453.3,222.2l2.1.4,1.3-7.3c-1.6-.3-2.3,1.2-2.4,1.9l-.9,5h-.1Z"/>
        <path class="cls-16" d="M458.1,214.1h3.4v1.1l-1.7,3.6-1.8-3.6h0v-.9h0c0-.1.1-.2.1-.2Z"/>
        <path class="cls-12" d="M458.1,214.3l3.4-.3h0v1.1l-.9,1.8-2.6-2.6h.1Z"/>
        <path class="cls-16" d="M459.8,204.7c-1,0-4,.1-4,3.6s.7,4.8,1.2,5.3,1,1,1.9,1.2c.3.3.5.3.9.4v-10.5Z"/>
        <path class="cls-28" d="M459.8,204.7c1,0,4,.1,4,3.6s-.7,4.8-1.2,5.3-1,1-1.9,1.2c-.3.3-.5.3-.9.4v-10.5Z"/>
        <path class="cls-28" d="M463.3,210c.1-.2.3-.4.6-.5.3,0,.8,0,.8.9s-.7,1.5-1.1,1.4c-.4,0-.2-1.9-.2-1.9h0Z"/>
        <path class="cls-6" d="M463.8,208.2c-.2-1.1-2.1-1.4-3.1-2.1-.1,0,1.1,1.1.9,1.1-.5,0-1.1,0-1.7-.1-1-.1-2-.4-2.7-.9-1.1,1.4-1.1.9-1.4,2-.3-1.6-.2-2.1.5-3,1.1-1.4,3.3-1.9,5-1.4,2.9,1.1,3,3.5,2.6,4.4h0Z"/>
        <path class="cls-16" d="M456.3,210c0-.2-.2-.4-.5-.5-.3,0-.8,0-.8.9s.7,1.5,1.1,1.4c.4,0,.2-1.6.2-1.9h0Z"/>
        <path class="cls-27" d="M461.1,212.2c0,.6-.6,1.1-1.3,1.1s-1.2-.5-1.3-1.1h2.6,0Z"/>
        <polygon class="cls-11" points="459.8 218.7 461.6 217.8 461.8 215.1 461.6 215.1 459.8 218.7"/>
        <rect class="cls-15" x="453.5" y="220.7" width="1.1" height="2.4" transform="translate(160.3 633) rotate(-80.5)"/>
        <path class="cls-24" d="M466.3,222.2l-2.1.4-1.3-7.3c1.6-.3,2.3,1.2,2.4,1.9l.9,5h.1Z"/>
        <rect class="cls-15" x="464" y="222" width="2.4" height="1.1" transform="translate(-30.3 79.8) rotate(-9.5)"/>
        <polygon class="cls-11" points="459.8 218.7 458.1 217.8 457.8 215.1 458.1 215.1 459.8 218.7"/>
      </g>
      <g>
        <path class="cls-33" d="M457.6,208.9h.9c.1,0,.2,0,.2-.2h0c0-.1,0-.2-.2-.2h-.9c-.1,0-.2,0-.2.2h0c0,.1,0,.2.2.2Z"/>
        <path class="cls-33" d="M461.2,208.9h.9c.1,0,.2,0,.2-.2h0c0-.1,0-.2-.2-.2h-.9c-.1,0-.2,0-.2.2h0c0,.1,0,.2.2.2Z"/>
        <g>
          <path class="cls-33" d="M461.8,209.5c-.2,0-.3.2-.3.3s.2.3.3.3.3-.2.3-.3-.2-.3-.3-.3Z"/>
          <path class="cls-33" d="M462.2,209.8h.1c-.2-.3-.6-.6-1.2-.1h0c0,.1.1.1.1.1.5-.4.8-.2.9,0h.1Z"/>
          <path class="cls-33" d="M457.9,209.5c.2,0,.3.2.3.3s-.2.3-.3.3-.3-.2-.3-.3.2-.3.3-.3Z"/>
          <path class="cls-33" d="M457.5,209.8h-.1c.2-.3.6-.6,1.2-.1h0c0,.1-.1.1-.1.1-.5-.4-.8-.2-.9,0h-.1Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'newhome',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
        found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('NewHome')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-3, .cls-5, .cls-6, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-33 {
        fill-rule: evenodd;
      }

      .cls-1, .cls-7 {
        fill: #366;
      }

      .cls-2 {
        fill: #5b412a;
      }

      .cls-3 {
        fill: #7bc3ad;
      }

      .cls-4, .cls-10 {
        fill: #f77917;
      }

      .cls-5 {
        fill: #fcc;
      }

      .cls-6 {
        fill: #1f0f05;
      }

      .cls-8 {
        fill: #eb6600;
      }

      .cls-9 {
        fill: #70a30a;
      }

      .cls-11 {
        fill: #ff6;
      }

      .cls-12 {
        fill: #d4a15e;
      }

      .cls-13 {
        fill: #bf3611;
      }

      .cls-14 {
        fill: #f6aa32;
      }

      .cls-15 {
        fill: #fc0;
      }

      .cls-16 {
        fill: #f2d291;
      }

      .cls-17 {
        fill: #8bb408;
      }

      .cls-18 {
        fill: #f06;
      }

      .cls-19 {
        fill: #d79432;
      }

      .cls-20, .cls-34 {
        fill: #e6e6e6;
      }

      .cls-21, .cls-33 {
        fill: #000;
      }

      .cls-22 {
        fill: #f1502d;
      }

      .cls-23 {
        fill: #e39f32;
      }

      .cls-24 {
        fill: #f60;
      }

      .cls-25, .cls-31 {
        fill: #fbca0f;
      }

      .cls-26 {
        fill: #ff0;
      }

      .cls-27, .cls-32 {
        fill: #fff;
      }

      .cls-28 {
        fill: #edba68;
      }

      .cls-29 {
        fill: #ffebb4;
      }

      .cls-30 {
        fill: #628f09;
      }
  </style>
