<template>
  <div id="geothermal" class="layer home">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
      <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-34" d="M429.6,618.6l-38.5-38.5c-1.3-1.3-3.1-2-4.9-1.9-1.4,0-2.7.6-3.7,1.4h-58.6l-24.4,24.4c-3.2.3-5.7,3-5.7,6.4s2.6,6.1,5.9,6.4v24.2h117.7v-12.5h4.2c1.2.8,2.5,1.2,3.9,1.1,1.7,0,3.2-.8,4.4-2.1,1.2-1.3,1.8-3,1.8-4.7s-.8-3.2-2-4.3h0Z"/>
      <g>
        <ellipse class="cls-28" cx="290.8" cy="647.5" rx="2.4" ry=".6"/>
        <ellipse class="cls-28" cx="295.7" cy="647.5" rx="2.4" ry=".6"/>
        <ellipse class="cls-28" cx="331" cy="642.4" rx="2.4" ry=".6"/>
        <ellipse class="cls-28" cx="335" cy="639.8" rx="2.4" ry=".6"/>
        <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
          <polygon class="cls-27" points="423.1 624.2 412 624.2 411.9 613 423.1 624.2"/>
          <path class="cls-18" d="M419.5,631.4h-7.5c-.6,0-1.1.5-1.1,1.1s.5,1.1,1.1,1.1h7.5v3.1h2.1v-16.7c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1,1.1v7.2h-7.5c-.6,0-1.1.5-1.1,1.1s.5,1.1,1.1,1.1h7.5v2.1h.1Z"/>
          <polygon class="cls-33" points="412 636.7 412 611 386.2 585.3 352 619.6 352 636.7 412 636.7"/>
          <polygon class="cls-18" points="352 619.6 352 636.7 304.3 636.7 304.3 611 335.7 611 327.7 619.6 352 619.6"/>
          <polygon class="cls-27" points="325.6 585.3 386.2 585.3 352 619.6 327.7 619.6 335.7 611 299.9 611 325.6 585.3"/>
          <rect class="cls-17" x="396.5" y="611.4" width="7.7" height="17.6"/>
          <rect class="cls-17" x="382.8" y="611.4" width="7.7" height="17.6"/>
          <rect class="cls-6" x="365.8" y="615.5" width="8.8" height="21.3"/>
          <path class="cls-30" d="M387.2,584.3c-.6-.6-1.5-.6-2,0l-34.3,34.4c-.6.6-.6,1.5,0,2,.6.6,1.5.6,2,0l33.3-33.4,37.6,37.6c.6.6,1.5.6,2,0,.6-.6.6-1.5,0-2l-38.6-38.6Z"/>
          <path class="cls-30" d="M352,621c.8,0,1.4-.6,1.4-1.4s-.6-1.4-1.4-1.4h-24.2c-.8,0-1.4.6-1.4,1.4s.6,1.4,1.4,1.4h24.2Z"/>
          <path class="cls-30" d="M335.7,612.5c.8,0,1.4-.6,1.4-1.4s-.6-1.4-1.4-1.4h-35.9c-.8,0-1.4.6-1.4,1.4s.6,1.4,1.4,1.4h35.9Z"/>
        </g>
        <g>
          <g>
            <rect class="cls-14" x="289.2" y="641.5" width="3.2" height="5.9"/>
            <rect class="cls-14" x="329.4" y="636.5" width="3.2" height="5.9"/>
            <rect class="cls-22" x="294.1" y="641.5" width="3.2" height="5.9"/>
          </g>
          <rect class="cls-31" x="285.7" y="628.9" width="26.2" height="12.6"/>
          <line class="cls-36" x1="288.1" y1="636.7" x2="303.9" y2="636.7"/>
          <line class="cls-36" x1="288.1" y1="637.5" x2="303.9" y2="637.5"/>
          <line class="cls-36" x1="288.1" y1="638.3" x2="303.9" y2="638.3"/>
          <g>
            <rect class="cls-14" x="311.8" y="635.7" width="20.7" height="3.2"/>
            <rect class="cls-22" x="311.8" y="630.8" width="24.8" height="3.2"/>
          </g>
          <rect class="cls-22" x="333.4" y="633.9" width="3.2" height="5.9"/>
        </g>
        <g id="gt-carrots">
          <g id="gt-c1">
            <ellipse class="cls-9" cx="380.2" cy="657.6" rx="3.3" ry="3.4"/>
            <path class="cls-32" d="M379.9,655.1l-3.7-.9c-.5,0-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7h.5l-1.5-1.4c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l2.1,2.2v-3c0-.6.4-1,1-1s1,.4,1,1v3l2.1-2.2c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-1.5,1.6h.5c.5-.3,1.1,0,1.2.6.1.5-.2,1.1-.7,1.2l-3.7.9h-.6,0Z"/>
          </g>
          <g id="gt-c2">
            <path class="cls-10" d="M390.6,654.2c1.8,0,3.3,1.5,3.3,3.4s-1.5,3.4-3.3,3.4-3.3-1.5-3.3-3.4,1.5-3.4,3.3-3.4Z"/>
            <path class="cls-32" d="M390.3,655.1l-3.7-.9c-.5,0-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7h.5l-1.5-1.4c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l2.1,2.2v-3c0-.6.4-1,1-1s1,.4,1,1v3l2.1-2.2c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-1.5,1.6h.5c.5-.3,1.1,0,1.2.6.1.5-.2,1.1-.7,1.2l-3.7.9h-.6,0Z"/>
          </g>
          <g id="gt-c3">
            <path class="cls-10" d="M401.1,654.2c1.8,0,3.3,1.5,3.3,3.4s-1.5,3.4-3.3,3.4-3.3-1.5-3.3-3.4,1.5-3.4,3.3-3.4Z"/>
            <path class="cls-32" d="M400.8,655.1l-3.7-.9c-.5,0-.9-.7-.7-1.2.1-.5.7-.9,1.2-.7h.5l-1.5-1.4c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l2.1,2.2v-3c0-.6.4-1,1-1s1,.4,1,1v3l2.1-2.2c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-1.5,1.6h.5c.5-.3,1.1,0,1.2.6.1.5-.2,1.1-.7,1.2l-3.7.9h-.6,0Z"/>
          </g>
          <rect class="cls-2" x="374.5" y="658.5" width="38.8" height="6.2" rx="3.1" ry="3.1"/>
        </g>
        <g id="gt-cat">
          <g>
            <path id="gt-cattail" class="cls-3" d="M427.8,641.4h1.2c.2,1.4,0,2.5-.3,3.3-.3.6-.6,1.1-1.1,1.4-.5.3-.9.3-1.4,0s-1.1-.9-1.6-1.9c-.6-1.4-.9-2.6-.8-3.7,0-.9.3-1.9.8-2.2.2,0,.9-.3,1.1,0,0,.3-.5.3-.6.9-.2.6-.2,1.2,0,2,.8,3.9,3.1,2.3,2.8,0h0l-.2.2Z"/>
            <path class="cls-3" d="M428.1,640.5l1.7,7.3,2.3-.5c-.5-2.2-.8-3-1.2-5.1,0-.6-.6-3.1-1.7-3.4-.6,0-.5,1.6-1.1,1.7Z"/>
            <path class="cls-23" d="M430.7,646.4c-.6,0-1.4,0-1.4.9s0,.8.5.8h.5s.3.3.6.3h.9c.3,0,.6-.3.6-.8,0-.9-1.1-1.1-1.7-1.1h0v-.2Z"/>
            <path class="cls-3" d="M429.2,640.5l-1.7,7.3-2.3-.5c.5-2.2.8-3,1.2-5.1,0-.6.6-3.3,1.9-3.6.6,0,.3,1.7.8,1.9h.2Z"/>
            <path class="cls-23" d="M426.5,646.4c.6,0,1.4,0,1.4.9s0,.8-.5.8h-.5s-.3.3-.6.3h-.9c-.3,0-.6-.3-.6-.8,0-.9,1.1-1.1,1.7-1.1h0v-.2Z"/>
            <rect class="cls-34" x="427.6" y="638.5" width="5.9" height="6.2" rx="1.4" ry="1.4"/>
            <polygon class="cls-33" points="430.1 640 429.9 647.8 427.5 647.7 427.8 639.9 430.1 640"/>
            <path class="cls-3" d="M428.9,646.7c.6,0,1.4,0,1.4.9s0,.8-.5.8h-.5s-.3.3-.6.3h-.9c-.3,0-.6-.3-.6-.8,0-.9,1.1-1.1,1.7-1.1h0v-.2Z"/>
            <polygon class="cls-33" points="433.5 639.9 433.7 647.7 431.3 647.8 431 640 433.5 639.9"/>
            <path class="cls-3" d="M432.4,646.7c-.6,0-1.4,0-1.4.9s0,.8.5.8h.5s.3.3.6.3h.9c.3,0,.6-.3.6-.8,0-.9-1.1-1.1-1.7-1.1h0v-.2Z"/>
            <path class="cls-33" d="M427.8,633.2c-1.1.8-.6,2.6-.3,3.4,0,.6.3,1.4,1.1,2l2.2-.5,2.2.5c.8-.5.9-1.4,1.1-2,.2-.8.8-2.5-.3-3.4-1.4-1.1-4.4-1.1-5.7,0h-.2Z"/>
            <g>
              <path class="cls-33" d="M428.9,633.4h.8c.5,0,.5-.3.3-.9-1.1-2.6-2-2.5-2.3.5,0,.6,0,.9.6.8h.6v-.3Z"/>
              <path class="cls-33" d="M432.4,633.4h-.8c-.5,0-.5-.3-.3-.9,1.1-2.6,2-2.5,2.3.5,0,.6,0,.9-.6.8h-.6v-.3Z"/>
            </g>
            <path class="cls-3" d="M430.7,639.3c0,.3-.3.8-.9.8-.9,0-.9-1.1-.8-1.2,0-.3.6-.9,1.7,0,1.1-.9,1.7-.3,1.7,0s0,1.2-.8,1.2-.8-.3-.9-.8Z"/>
            <path class="cls-35" d="M430.4,638.8h.5c.2,0,.5-.3.5-.8h0s0-.3-.2-.3h-.9s-.2,0-.2.3h0c0,.5.2.8.5.8h-.2Z"/>
            <path class="cls-35" d="M428.7,636.2c.2,0,.5,0,.5.5v.3h-.6s.2-.5.5-.5h-.3v-.3Z"/>
            <path class="cls-33" d="M428.9,636.6h0Z"/>
            <path class="cls-35" d="M432.6,636.2c-.2,0-.5,0-.5.5v.3h.6s-.2-.5-.5-.5h.3v-.3Z"/>
            <path class="cls-33" d="M432.7,636.6h0Z"/>
          </g>
          <rect class="cls-15" x="430.6" y="638.5" height=".6"/>
          <line class="cls-5" x1="431.8" y1="638.8" x2="435.1" y2="638.2"/>
          <line class="cls-5" x1="431.8" y1="639.1" x2="435.1" y2="639.6"/>
          <line class="cls-5" x1="429.6" y1="638.8" x2="426.4" y2="638.2"/>
          <line class="cls-5" x1="429.6" y1="639.1" x2="426.4" y2="639.6"/>
        </g>
        <path class="cls-7" d="M405.8,631.5c.9,1,1.5,2.7,1.6,4.5,1-1.2,2.2-1.6,3.3-1.3.1,1.9-.3,3.9-1.3,5.3s-1.9,1.8-3,1.8h-1c-1.1,0-2.2-.6-3-1.8s-1.4-3.4-1.3-5.3c1.2-.3,2.4.3,3.3,1.3.1-1.8.7-3.4,1.6-4.5h-.2Z"/>
        <path class="cls-7" d="M396.5,631.5c.9,1,1.5,2.7,1.6,4.5,1-1.2,2.2-1.6,3.3-1.3.1,1.9-.3,3.9-1.3,5.3s-1.9,1.8-3,1.8h-1c-1.1,0-2.2-.6-3-1.8s-1.4-3.4-1.3-5.3c1.2-.3,2.4.3,3.3,1.3.1-1.8.7-3.4,1.6-4.5h-.2Z"/>
        <path class="cls-7" d="M382.1,631.5c.9,1,1.5,2.7,1.6,4.5,1-1.2,2.2-1.6,3.3-1.3.1,1.9-.3,3.9-1.3,5.3s-1.9,1.8-3,1.8h-1c-1.1,0-2.2-.6-3-1.8s-1.4-3.4-1.3-5.3c1.2-.3,2.4.3,3.3,1.3.1-1.8.7-3.4,1.6-4.5h-.2Z"/>
      </g>
      <g id="gt-groundhog">
        <g id="gt-dirt">
          <path class="cls-19" d="M333.9,662.6c0,.3,0,.5-.2.7,0,0,0,0,0,0,.5-.4,1.1-.7,1.9-.7,1.4,0,2.5.9,2.5,2s0,.2,0,.3c.4-.2.7-.3,1.2-.3.8,0,1.5.3,2,.8.4-.5,1.2-.8,2-.8,1.4,0,2.5.9,2.5,2s0,0,0,.1c.3,0,.6-.1.9-.1.8,0,1.5.3,2,.8.2-.9,1.2-1.6,2.4-1.6s1.7.4,2.1,1c.4-.6,1.2-1,2.1-1s1.2.2,1.7.5c.2-1,1.2-1.7,2.4-1.7s.6,0,.9.1c0,0,0,0,0-.1,0-1.1,1.1-2,2.5-2s1.1.2,1.5.4c0,0,0-.1,0-.2,0-1.1,1.1-2,2.5-2s.1,0,.2,0c-.5-.2-.8-.3-1.1-.5-2.6-1.7-2.7-1.7-3.9-2.4-2.3-1.7-7.3-3-13-3s-10.5,1.2-12.9,2.9c0,0,0,0,0,0q-1.3.8-4,2.5c-.1,0-.2.1-.4.2,1.3,0,2.4.9,2.4,2Z"/>
          <g>
            <path class="cls-8" d="M357.3,658.5c0,2.4-3.7,3-8.3,3s-8.3-.6-8.3-3,3.7-3,8.3-3,8.3,1.3,8.3,3Z"/>
            <path class="cls-13" d="M357.3,659c0,1.6-3.7,2.5-8.3,2.5s-8.3-.9-8.3-2.5,3.7-2.8,8.3-2.8,8.3,1.3,8.3,2.8Z"/>
          </g>
        </g>
        <g id="gt-ghog">
          <g>
            <g>
              <path class="cls-21" d="M355,652.6c.2.1.5.2.9,0-.3.2-.9-.4-1.1-1.7-.3-2.3-2.2-4.1-4.6-4.1h-2.4c-2.5,0-4.5,1.9-4.6,4.4-.3,1.1-.8,1.6-1.1,1.4.4.2.7.2.9,0-.3.6-.7.8-.9.7.5.3.9,0,1.1,0v7.8h11.7v-7.9c.1.1.6.5,1.1.1-.2.1-.6-.1-.9-.7Z"/>
              <path class="cls-4" d="M349,655.5c-3.2,0-5.8,2.5-5.8,5.6,0,0,3.5.3,5.9.3s5.8-.3,5.8-.3c0-3.1-2.6-5.6-5.8-5.6Z"/>
              <g>
                <circle class="cls-21" cx="344.8" cy="648.1" r="2.3"/>
                <circle class="cls-21" cx="353.1" cy="648.1" r="2.3"/>
              </g>
              <path class="cls-29" d="M348.8,660.6c-2.4,0-4.5-.3-5.5-.7,0,.4-.1.8-.1,1.2,0,0,3.5.3,5.9.3s5.8-.3,5.8-.3c0-.5,0-.9-.2-1.3-.9.5-3.2.9-5.8.9Z"/>
            </g>
            <path class="cls-20" d="M353.8,649.2c.4-.2.7-.7.7-1.2,0-.8-.6-1.4-1.4-1.4s-1,.3-1.2.8c.8.4,1.5,1,1.9,1.7Z"/>
            <path class="cls-20" d="M344.1,649.3c.4-.7,1.1-1.3,1.9-1.7-.2-.5-.7-.8-1.3-.8s-1.4.6-1.4,1.4.3,1,.7,1.2Z"/>
          </g>
          <g>
            <g>
              <g>
                <ellipse class="cls-1" cx="346" cy="650" rx="1" ry=".6" transform="translate(-386.3 572.2) rotate(-55.9)"/>
                <ellipse class="cls-34" cx="345.6" cy="649.9" rx=".4" ry=".2"/>
              </g>
              <g>
                <ellipse class="cls-1" cx="352" cy="650" rx=".6" ry="1" transform="translate(-303.8 309) rotate(-34.1)"/>
                <ellipse class="cls-34" cx="352.4" cy="649.9" rx=".4" ry=".2"/>
              </g>
            </g>
            <g>
              <path class="cls-12" d="M351.3,653.1c0,1.2-1.1,2.3-2.4,2.3s-2.4-1-2.4-2.3,4.8-1.2,4.8,0Z"/>
              <g>
                <polygon class="cls-34" points="349.9 652.3 348 652.3 348.4 655.8 349.5 655.8 349.9 652.3"/>
                <polygon class="cls-16" points="349 652.3 348.9 652.3 348.9 655.8 349 655.8 349 652.3"/>
              </g>
              <rect class="cls-1" x="347.2" y="650" width="3.5" height="2.9" rx="1.5" ry="1.5"/>
              <circle class="cls-24" cx="347.6" cy="652.9" r="1.5"/>
              <circle class="cls-24" cx="350.3" cy="652.9" r="1.5"/>
              <ellipse class="cls-11" cx="350.3" cy="652.6" rx="1.5" ry="1.3"/>
              <ellipse class="cls-11" cx="347.6" cy="652.6" rx="1.5" ry="1.3"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <circle class="cls-11" cx="342.9" cy="660.7" r="1"/>
                <circle class="cls-11" cx="344.4" cy="661.7" r="1"/>
                <circle class="cls-11" cx="346.1" cy="661.8" r="1"/>
                <ellipse class="cls-25" cx="344.7" cy="660.5" rx="1.4" ry="2.6" transform="translate(-376.8 846.5) rotate(-76.9)"/>
              </g>
              <g>
                <path class="cls-26" d="M344.6,662.6c0,.2-.4.6-.6.5s-.2-.6-.1-.8.3-.3.5-.2.3.3.2.5Z"/>
                <path class="cls-26" d="M342.5,661.4c-.1.2-.5.5-.7.4s0-.6,0-.8.4-.2.5-.1.2.4.1.5Z"/>
                <path class="cls-26" d="M346.7,662.4c0,.2,0,.7-.2.7s-.5-.3-.6-.5,0-.4.3-.5.4,0,.5.3Z"/>
              </g>
            </g>
            <g>
              <g>
                <circle class="cls-11" cx="355.1" cy="660.7" r="1"/>
                <circle class="cls-11" cx="353.6" cy="661.7" r="1"/>
                <circle class="cls-11" cx="351.9" cy="661.8" r="1"/>
                <ellipse class="cls-25" cx="353.3" cy="660.5" rx="2.6" ry="1.4" transform="translate(-140.6 97.3) rotate(-13.1)"/>
              </g>
              <g>
                <path class="cls-26" d="M353.5,662.6c0,.2.4.6.6.5s.2-.6.1-.8-.3-.3-.5-.2-.3.3-.2.5Z"/>
                <path class="cls-26" d="M355.6,661.4c.1.2.5.5.7.4s0-.6,0-.8-.4-.2-.5-.1-.2.4-.1.5Z"/>
                <path class="cls-26" d="M351.3,662.4c0,.2,0,.7.2.7s.5-.3.6-.5,0-.4-.3-.5-.4,0-.5.3Z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  mounted: function(){
    var gt1 = gsap.timeline({delay:5, repeat: -1, repeatDelay:10, defaults:{ease:"Bounce.easeIn"}});
    gt1.fromTo('#gt-cat', 2, {y:-20, x:-20, alpha: 0},{y:0, x:0, alpha: 1, ease:"Power2.easeOut"});
    gt1.fromTo('#gt-c1', .5, {scaleY: 1},{scaleY: 0, transformOrigin:"100% 100%"},'+=3');
    gt1.fromTo('#gt-c2', .5, {scaleY: 1},{scaleY: 0, transformOrigin:"100% 100%"});
    gt1.fromTo('#gt-c3', .5, {scaleY: 1},{scaleY: 0, transformOrigin:"100% 100%"});
    gt1.fromTo('#gt-dirt', .5, {alpha:0},{alpha:1, ease:"Power2.easeIn"});
    gt1.fromTo('#gt-ghog', .5, {y:16,scaleY: 0},{scaleY: 1, transformOrigin:"100% 100%"},'+=1');
    gt1.fromTo('#gt-cat', .3, {y:0},{y:-10, scaleY:.8, ease:"Ease.none"});
    gt1.to('#gt-cat', .5, {y:0, scaleY: 1, ease:"Power2.easeOut"});
    gt1.to('#gt-cat', .3, {x:20, alpha: 0, ease:"Ease.none"},'-=.1');
    gt1.to('#gt-ghog', .5, {scaleY: 0, transformOrigin:"100% 100%", ease:"Power2.easeIn"},'+=5');
    gt1.to('#gt-dirt', .5, {alpha:0, ease:"Power2.easeIn"},'-=.3');
    gt1.to('#gt-c1', .2, {scaleY: 1, transformOrigin:"100% 100%"},'+=5');
    gt1.to('#gt-c2', .2, {scaleY: 1, transformOrigin:"100% 100%"});
    gt1.to('#gt-c3', .2, {scaleY: 1, transformOrigin:"100% 100%"});

    var gt2 = gsap.timeline({delay:0, repeat: -1, yoyo: true, defaults:{ease:"Bounce.easeIn"}});
    gt2.fromTo('#gt-cattail', 3, {rotation: 0},{rotation: 10, transformOrigin:"100% 100%"});
  },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('Geothermal')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
      .cls-1 {
        fill: #4d3221;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35 {
        stroke-width: 0px;
      }

      .cls-2, .cls-7 {
        fill: #70a30a;
      }

      .cls-3 {
        fill: #c4c4c4;
      }

      .cls-3, .cls-7, .cls-10, .cls-18, .cls-23, .cls-27, .cls-30, .cls-32, .cls-33, .cls-35 {
        fill-rule: evenodd;
      }

      .cls-4 {
        fill: #965a3f;
      }

      .cls-5, .cls-36 {
        fill: none;
      }

      .cls-6, .cls-27 {
        fill: #c20212;
      }

      .cls-8 {
        fill: #a8e038;
      }

      .cls-9, .cls-10 {
        fill: #ffc400;
      }

      .cls-11 {
        fill: #e39d78;
      }

      .cls-12 {
        fill: #7b3322;
      }

      .cls-13 {
        fill: #2e4204;
      }

      .cls-14 {
        fill: #00b1f6;
      }

      .cls-15, .cls-35 {
        fill: #000;
      }

      .cls-16 {
        fill: #d8d9d8;
      }

      .cls-17 {
        fill: #8fdbf3;
      }

      .cls-18 {
        fill: #ccc;
      }

      .cls-19, .cls-32 {
        fill: #5b8408;
      }

      .cls-20 {
        fill: #e19b75;
      }

      .cls-21 {
        fill: #875138;
      }

      .cls-22 {
        fill: #bb1c00;
      }

      .cls-23 {
        fill: #adadad;
      }

      .cls-24 {
        fill: #c98058;
      }

      .cls-25 {
        fill: #613a29;
      }

      .cls-26 {
        fill: #fff1ba;
      }

      .cls-28 {
        fill: #628f09;
      }

      .cls-29 {
        fill: #7b4934;
      }

      .cls-36 {
        stroke: #919191;
        stroke-miterlimit: 10;
        stroke-width: .3px;
      }

      .cls-30 {
        fill: #ce323f;
      }

      .cls-31 {
        fill: #374a3a;
      }

      .cls-33, .cls-34 {
        fill: #fff;
      }
</style>
