<template>
  <div id="birds" class="layer">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722 676.6">
      <g id="bird1">
        <path class="cls-1" d="M507.5,165.5c-.4-.2-.9-.5-1.3-1.4-.4-1-.7-.8-1.5-1.2s-2-.6-3-.6.9,1.1,1.3,1.4c.4.2,1.3.5,1.4.8,0,.3.6,1.3.9,1.5.3.3,1.1.9.8,1.2-.3.4-1.3.7-1.4.7s.3,1.3,1.5,1.5c.3-.5.5-1.1.6-1.4,0-.2.6-.3,1.1.2s1.5.7,1.9.8c.4,0,1,1.1,1.5,1.5s1.2,1,1.5,1.1c.3,0-.2-1.3-.4-1.7-.2-.3-1.5-2.1-1.8-2.4-.3-.2-1-.2-1.6-.6q-.6-.4-.6-.6c0-.2.6-.7.7-.9,0-.2,0-.3-.3-.3s-.9.2-1.3.4Z"/>
        <path class="cls-3" d="M509.6,164.9s-.4.3-.5.4c0,0-.2-.1-.2-.2,0,0,.3-.2.7-.2Z"/>
      </g>
      <g id="bird2">
        <path class="cls-1" d="M341.9,101.6c-1.1-.2-2.3-.9-3.6-3-1.3-2.2-1.9-1.6-3.9-2.3-2-.7-4.9-.8-7.4-.6-2.4.2,2.6,2.4,3.6,2.8s3.2.7,3.5,1.5c.3.7,1.9,2.8,2.6,3.4.7.5,2.8,1.8,2.3,2.7-.5.9-2.9,2.1-3.2,2.2-.3,0,1.2,3,4.1,3,.6-1.4.8-2.9,1-3.4s1.4-1,2.8,0,3.7,1.2,4.7,1.4c1,0,2.8,2.3,4.1,3.1,1.3.7,3.1,2.1,3.8,2.2.7,0-.8-3.1-1.4-3.9-.6-.7-4.3-4.6-5.1-5.1-.8-.5-2.4-.2-3.9-.9-1.5-.7-1.5-.8-1.6-1.3,0-.5,1.3-1.9,1.4-2.4s-.3-.7-.9-.6-2.2.7-3,1.4v-.2Z"/>
        <path class="cls-3" d="M346.8,99.5c-.2.2-1,.9-1.1,1.2-.1.2-.5-.2-.5-.4s.7-.7,1.6-.8Z"/>
      </g>
      <g id="bird3">
        <path class="cls-2" d="M344.4,100.3c-.1,0-.2,0-.2.2,0,0,.1.2.2,0,.1,0,.2,0,.2-.2,0,0-.1-.2-.2,0Z"/>
        <path class="cls-4" d="M232.3,155.5c.6-.2,1.3-.7,1.8-2,.6-1.4,1-1.1,2.1-1.6,1.1-.6,2.8-.8,4.2-.9,1.4,0-1.3,1.6-1.9,1.9-.6.3-1.8.7-1.9,1.1-.1.4-.9,1.8-1.2,2.1s-1.5,1.2-1.1,1.7,1.8,1,2,1-.5,1.8-2.1,2c-.5-.7-.7-1.6-.8-1.9-.1-.3-.9-.5-1.6.2-.7.7-2,1-2.6,1.1s-1.4,1.5-2.2,2.1-1.6,1.4-2,1.5.2-1.9.5-2.3c.3-.5,2.1-3,2.5-3.3s1.4-.3,2.2-.8.8-.6.8-.9-.9-1-1-1.3.1-.4.5-.4,1.3.2,1.8.6h0Z"/>
        <path class="cls-3" d="M229.3,154.7c.1,0,.6.5.7.6.1.1.3-.2.3-.3,0,0-.5-.3-1-.3Z"/>
      </g>
      <g id="bird4">
      <path class="cls-1" d="M484.1,154.4c-.5.6-1.4,1.1-3.2,1.2-1.8,0-1.7.6-2.8,1.6-1.2,1-2.3,2.8-3,4.4-.8,1.6,2.5-.8,3.1-1.2.6-.5,1.6-1.7,2.2-1.7s2.4-.2,3-.4,2.1-1.1,2.5-.4c.4.7.3,2.6.2,2.8,0,.2,2.3.3,3.3-1.5-.6-.9-1.5-1.5-1.8-1.8s-.1-1.2,1-1.7,2.1-1.9,2.5-2.4c.4-.6,2.4-.9,3.4-1.5.9-.6,2.4-1.2,2.7-1.6.3-.4-2.3-.6-2.9-.5-.7,0-4.4,1-5,1.3s-1,1.4-2,2.1c-1,.7-1.1.7-1.4.5s-.8-1.5-1-1.8c-.3-.3-.6,0-.7.3-.1.4-.3,1.6-.2,2.4h0Z"/>
      <path class="cls-3" d="M484.1,150.7c0,.2.2.9.2,1.1s.4,0,.5,0c0-.1-.2-.7-.7-1.1Z"/>
      <path class="cls-2" d="M484.8,152.3v.2h.2v-.2h-.2Z"/>
      </g>
      <g id="bird5">
        <path class="cls-1" d="M128.5,117.4c.6.2,1.1.6,1.8.4s1.4-.3,1.7,0c.3.2,2.2,2.1,3.3,5.3,1.1,3.2-.5.7-1,.4-.5-.4-1.9-2.7-2.4-3-.6-.3-2.2,0-3.7-.5-.4-.2-1.1,0-1.4.8-.3.7-.5,1.7-.5,1.7,0,0-1.4.3-2.9-1.5.5-.5,1.6-1.1,1.9-1.4,0-.3-.2-.6-.5-.9-.3-.2-1.4-1.1-2-1.8-.6-.7-1.3-.5-2-.4-.8,0-2.3.3-2.9.3s-1.6,0,0-.6c1.5-.7,5.1-2,6.4-1.7.9.2.7.4,1.1,1,.4.5,1.2,1.7,1.8,1.2.6-.4.9-.6,1.3-.8.4,0,.8,0,.6.4-.1.4-.3,1.2-.3,1.2h-.2Z"/>
        <path class="cls-3" d="M129.5,115.6c-.1,0-.7,0-.8.2-.2.2.1.2.2.2,0,0,.4-.3.6-.4Z"/>
        <path class="cls-2" d="M128.1,115.8v.2h.2v-.2h-.2Z"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  mounted: function(){
   
    var b1 = gsap.timeline({delay:2.7,repeat: -1, repeatDelay: 10, ease:"none"});
    b1.fromTo('#bird1', 2, {alpha:0,x:0,y:0},{x:40,y:-20,alpha:1,rotation:20});
    b1.to('#bird1', 4, {x:80,y:-40,rotation:45,alpha:0,rotation:40},'-=1');

    var b2 = gsap.timeline({delay:5.3,repeat: -1, repeatDelay: 12.7, ease:"none"});
    b2.fromTo('#bird2', 3, {alpha:0,x:0,y:70},{x:20,y:30,alpha:1,rotation:5});
    b2.to('#bird2', 10, {x:60,y:-30,rotation:45,alpha:0,rotation:20},'-=1');

    var b3 = gsap.timeline({delay:1.6,repeat: -1, repeatDelay: 9.2, ease:"none"});
    b3.fromTo('#bird3', 3, {alpha:0,x:0,y:0},{x:-10,y:-20,alpha:1});
    b3.to('#bird3', 4, {x:-20,y:-40,alpha:0},'-=1');

    var b4 = gsap.timeline({delay:7.1,repeat: -1, repeatDelay: 3.7, ease:"none"});
    b4.fromTo('#bird4', 3, {alpha:0,x:0,y:0},{x:-10,y:-20,alpha:1});
    b4.to('#bird4', 4, {x:-20,y:-40,alpha:0},'-=1');

    var b5 = gsap.timeline({repeat: -1, repeatDelay: 6, ease:"none"});
    b5.fromTo('#bird5', 2, {alpha:0,x:0},{x:30,y:-20,alpha:1,rotation:20});
    b5.to('#bird5', 4, {x:60,y:-40,rotation:45,alpha:0,rotation:40},'-=1');
  }
}
</script>

<style scoped>
      .cls-1 {
        fill: #8fdbf3;
      }

      .cls-1, .cls-2, .cls-3, .cls-4 {
        fill-rule: evenodd;
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #152e56;
      }

      .cls-3 {
        fill: #c20212;
      }

      .cls-4 {
        fill: #fff;
      }
    </style>
