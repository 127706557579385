<template>
  <div id="daycare" class="layer home">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 722.3 676.6">
  <defs>
    <clipPath id="clippath">
      <rect class="cls-4" x="350.4" y="401.8" width="27.8" height="18.9"/>
    </clipPath>
    <clipPath id="clippath-1">
      <rect class="cls-4" x="346.3" y="391.4" width="52.7" height="34.1"/>
    </clipPath>
  </defs>
  <g class="cls-46">
    <g id="Layer_1" data-name="Layer 1">
      <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-36" d="M408.4,388.4l-6.6-15.2h-56.9c0,0-1.3,0-1.3,0l-6.6,15c-1.3,2.8-1.1,6,.4,8.6.7,1.2,1.7,2.3,2.9,3l1,1.6v28.9h62.7v-29.2l1-1.5c1-.7,2-1.7,2.7-2.8,1.6-2.6,1.8-5.8.7-8.5Z"/>
      <g>
        <g>
          <path class="cls-23" d="M468,423.5l-4.5-5h0l-7.4-8.3h0l-.9-1h-.2v.2l.8.9v6.9h.4v-6.4l3.2,3.6v7h.4v-6.5l3.5,3.9v6.8h.4v-6.4l3.5,3.8h-.1v7h.4v-6.7l.5.6h.2v-.2l-.2-.2h0Z"/>
          <rect class="cls-35" x="441.5" y="418.6" width="2" height="11.6"/>
          <rect class="cls-35" x="440.6" y="400.8" width="1.2" height="9.6"/>
          <rect class="cls-35" x="452.3" y="400.8" width="1.2" height="9.6"/>
          <rect class="cls-35" x="451.9" y="418.6" width="2" height="11.6"/>
          <rect class="cls-27" x="441.1" y="430.2" width="2.8" height=".9"/>
          <rect class="cls-27" x="451.5" y="430.2" width="2.8" height=".9"/>
          <g>
            <path class="cls-8" d="M440.9,410.2c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7v-6.5h-.1Z"/>
            <path class="cls-19" d="M442.6,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-35" d="M444.3,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-8" d="M446,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-19" d="M447.7,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-35" d="M449.5,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-8" d="M451.2,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-19" d="M452.9,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
            <path class="cls-35" d="M454.6,416.6v-6.5c0-.5-.4-.8-.8-.8h0c-.5,0-.8.4-.8.8v6.5h1.7-.1Z"/>
          </g>
          <g>
            <polygon class="cls-10" points="466.2 427 464.3 427 464.3 425 462.4 425 462.4 423 460.4 423 460.4 421 458.5 421 458.5 419 456.6 419 456.6 417.7 456.6 417.7 456.6 416.6 439.2 416.6 439.2 417.7 452.7 417.7 453.9 419 454.1 419.2 455.7 421 455.8 421.1 457.5 423 457.6 423.1 459.4 425 459.5 425.1 461.2 427 461.3 427.1 463.1 429.1 466.2 429.1 466.2 427"/>
            <polygon class="cls-10" points="463.1 429.1 463.1 429.1 465 431.1 468.2 431.1 468.2 429.1 463.1 429.1"/>
          </g>
          <rect class="cls-2" x="440.8" y="417.7" width="13.8" height=".9"/>
          <polygon class="cls-8" points="450.7 395.9 447.1 395.9 443.5 395.9 439 401 447.1 401 455.2 401 450.7 395.9"/>
          <path class="cls-23" d="M422.6,428s.7,1.3,1.8.2,15.1-15.8,15.1-15.8v5l-13,13.6h-3.9v-3h0Z"/>
        </g>
        <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
          <rect class="cls-20" x="346.3" y="391.4" width="52.7" height="34.1"/>
          <rect class="cls-11" x="382.4" y="403.3" width="12.5" height="22.2"/>
          <g class="cls-43">
            <g>
              <polygon class="cls-6" points="350.4 420.7 353.4 420.7 378.2 406.7 378.2 401.8 366.1 401.8 350.4 410.4 350.4 420.7"/>
              <polygon class="cls-29" points="353.4 420.7 378.2 420.7 378.2 406.7 353.4 420.7"/>
              <polygon class="cls-29" points="350.4 410.4 350.4 401.8 366.1 401.8 350.4 410.4"/>
            </g>
          </g>
          <g class="cls-45">
            <g class="cls-44">
              <path class="cls-39" d="M349.8,381h-3l-6.1,13.9c0,2.3,2,4.2,4.6,4.2h0c2.5,0,4.6-1.9,4.6-4.2v.5c0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6v-14.4h-7.6Z"/>
              <path class="cls-39" d="M357.4,395.4c0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6v-14.4h-7.6v14.4h.1Z"/>
              <path class="cls-39" d="M365.1,395.4c0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6v-14.4h-7.6v14.4h.1Z"/>
              <path class="cls-39" d="M380.3,381h-7.6v14.4c0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6,0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6v-14.4h-7.6.2Z"/>
              <path class="cls-39" d="M398.5,381h-10.6v14.4c0,2,1.6,3.6,3.6,3.6h.3c2,0,3.6-1.6,3.6-3.6v-.5c0,2.3,2,4.2,4.6,4.2h0c2.5,0,4.6-1.9,4.6-4.2l-6.1-13.9Z"/>
            </g>
          </g>
          <path class="cls-7" d="M345.3,396.4h-.4c-2.9-.2-4.6-3.3-3.4-5.9l5.3-12.1h3v13.9c0,2.3-2,4.2-4.6,4.2h.1Z"/>
          <path class="cls-11" d="M349.8,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-7" d="M357.4,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-11" d="M365.1,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-7" d="M372.7,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-11" d="M380.3,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-7" d="M387.9,378.3h7.6v14.2c0,2.1-1.7,3.8-3.8,3.8h0c-2.1,0-3.8-1.7-3.8-3.8v-14.2h0Z"/>
          <path class="cls-11" d="M400,396.4h0c-2.5,0-4.6-1.9-4.6-4.2v-13.9h3l5.3,12.1c1.1,2.6-.6,5.7-3.4,5.9h-.4.1Z"/>
          <rect class="cls-7" x="384.1" y="405" width="9.1" height="20.5"/>
        </g>
        <g id="dc-boy">
          <g>
            <g>
              <path class="cls-17" d="M414,429.8h3.2c0-.7-.7-.8-1.2-1-.2,0-.5-.2-.6-.4,0-2.1,0-4.2.1-6.3h-1.6v6.4c0,.5-.4,1.4.2,1.4h-.1Z"/>
              <rect class="cls-33" x="413.6" y="429.5" width="3.6" height=".3"/>
              <path class="cls-26" d="M417.2,429.5c-.4.4-3.6,0-3.6,0,0,0,1.7-.5,2.6-.7.2,0,1.2.3.9.6h.1Z"/>
            </g>
            <g>
              <path class="cls-17" d="M412.6,429.8h-3.2c0-.7.7-.8,1.2-1,.2,0,.5-.2.6-.4,0-2.1,0-4.2-.1-6.3h1.6v6.4c0,.5.4,1.4-.2,1.4h.1Z"/>
              <rect class="cls-33" x="409.4" y="429.5" width="3.6" height=".3"/>
              <path class="cls-26" d="M409.4,429.5c.4.4,3.5,0,3.5,0,0,0-1.7-.5-2.6-.7-.2,0-1.2.3-1,.6h.1Z"/>
            </g>
            <path class="cls-16" d="M415.9,421.8h-5.1v5h2.3v-4.1h.5v4.1h2.3v-5h0Z"/>
            <path class="cls-25" d="M410.8,422.5h5.1v-6.2c0-.6-.5-1.1-1.1-1.1h-2.8c-.6,0-1.1.5-1.1,1.1v6.2h-.1Z"/>
            <g id="dc-armL">
              <path class="cls-17" d="M410.9,417.4s-.4,3.3-.7,5h0l.2.6c0,.1,0,.2-.1.3h0c-.1,0-.2,0-.3-.1v-.2.9c0,.1,0,.2-.2.2h0c-.1,0-.2,0-.2-.2h0c-.1-.1-.2-.1-.2-.3h0c0-.2-.1-.2-.1-.3v-1.3h0c.3-1.9.6-4.7.6-4.7h1Z"/>
            </g>
            <g id="dc-armR">
              <path class="cls-17" d="M415.8,417.4s.4,3.3.7,5h0l-.2.6c0,.1,0,.2.1.3h0c.1,0,.2,0,.3-.1v-.2.9c0,.1,0,.2.2.2h0c.1,0,.2,0,.2-.2h0c.1-.1.2-.1.2-.3h0c0-.2.1-.2.1-.3v-1.3h0c-.3-1.9-.6-4.7-.6-4.7h-1Z"/>
            </g>
            <path class="cls-25" d="M409.4,419.4l1.3.2.8-4.4c-.9-.2-1.4.7-1.5,1.2l-.6,3h0Z"/>
            <path class="cls-17" d="M412.3,414.5h2.1v.6l-1.1,2.2-1.1-2.2h0v-.5h.1Z"/>
            <path class="cls-12" d="M412.3,414.6l2.1-.2h0v.6l-.5,1.1-1.6-1.6h0Z"/>
            <path class="cls-17" d="M413.3,408.8c-.6,0-2.4,0-2.4,2.2s.4,2.9.7,3.2.6.6,1.2.8c.2.2.3.2.6.2v-6.4h-.1Z"/>
            <path class="cls-31" d="M413.3,408.8c.6,0,2.4,0,2.4,2.2s-.4,2.9-.7,3.2-.6.6-1.2.8c-.2.2-.3.2-.6.2v-6.4h.1Z"/>
            <path class="cls-31" d="M415.4,412c0-.1.2-.2.3-.3.2,0,.5,0,.5.5s-.4.9-.7.9-.2-1.1-.2-1.1h.1Z"/>
            <path class="cls-3" d="M415.8,411c-.1-.7-1.3-.9-1.9-1.3,0,0,.7.7.6.7h-1c-.6,0-1.2-.2-1.7-.5-.7.8-.7.5-.9,1.2-.2-1-.1-1.3.3-1.8.6-.8,2-1.2,3-.8,1.8.6,1.8,2.1,1.6,2.6h0Z"/>
            <path class="cls-17" d="M411.2,412c0-.1-.1-.2-.3-.3-.2,0-.5,0-.5.5s.4.9.7.9.1-1,.1-1.1h0Z"/>
            <path class="cls-30" d="M414.1,413.4c0,.4-.4.7-.8.7s-.7-.3-.8-.7h1.6,0Z"/>
            <path class="cls-25" d="M417.3,419.4l-1.3.2-.8-4.4c.9-.2,1.4.7,1.5,1.2l.6,3h0Z"/>
          </g>
          <g>
            <path class="cls-37" d="M411.9,411.4h.6s.1,0,.1-.1h0s0-.1-.1-.1h-.6s-.1,0-.1.1h0s0,.1.1.1Z"/>
            <path class="cls-37" d="M414.2,411.4h.6s.1,0,.1-.1h0s0-.1-.1-.1h-.6s-.1,0-.1.1h0s0,.1.1.1Z"/>
            <g>
              <path class="cls-37" d="M414.5,411.7c-.1,0-.2,0-.2.2s0,.2.2.2.2,0,.2-.2,0-.2-.2-.2Z"/>
              <path class="cls-37" d="M414.8,411.9h0c-.1-.1-.4-.3-.7,0h0c.3-.2.5,0,.6,0h.1Z"/>
              <path class="cls-37" d="M412.2,411.7c.1,0,.2,0,.2.2s0,.2-.2.2-.2,0-.2-.2,0-.2.2-.2Z"/>
              <path class="cls-37" d="M411.9,411.9h0c.1-.1.4-.3.7,0h0c-.3-.2-.5,0-.6,0h-.1Z"/>
            </g>
          </g>
        </g>
        <g>
          <path class="cls-17" d="M383.3,448c0,.1,0,.3-.1.4h1.9c-.2-.2-.5-.3-.5-.4v-.4h-1.2v.4h0Z"/>
          <g>
            <path class="cls-40" d="M382.5,448.3c0,.2.1.5,0,.8h-2.6c0-.4.3-.6.6-.8h2Z"/>
            <path class="cls-40" d="M383.2,448.3c0,.2-.1.5,0,.8h2.6c0-.4-.3-.6-.6-.8h-2Z"/>
          </g>
          <path class="cls-17" d="M382.4,448c0,.1,0,.3.1.4h-1.9c.2-.2.5-.3.5-.4v-.4h1.2v.4h0Z"/>
          <rect class="cls-14" x="380" y="449" width="2.7" height=".2"/>
          <rect class="cls-14" x="383" y="449" width="2.7" height=".2"/>
          <polygon class="cls-9" points="383 441.1 383.9 441.1 384.9 441.1 384.9 447.7 383.1 447.7 383.1 442.6 382.6 442.6 382.6 447.7 380.7 447.7 380.7 441.1 382 441.1 382.7 441.1 383 441.1"/>
          <path class="cls-16" d="M384.9,428.9c.5-.6,1.6-.9,2.4.2,1.1,1.5-.8,3.3-.8,4.1s.4.9.4.9c0,0-1.1.1-1.8-.9-.6-1,.5-3.5.6-4,0-.5-.4-.3-.5,0-.2.3-.3-.4-.3-.4h0Z"/>
          <path class="cls-26" d="M380.4,442.3h4.8v-5.9c0-.7-.5-1.1-1.1-1v1.2c0,.7-.6,1.3-1.3,1.3h0c-.7,0-1.3-.6-1.3-1.3v-1.2c-.6,0-1.1.4-1.1,1v5.9Z"/>
          <path class="cls-18" d="M384.5,428.3c.8,0,1.4.6,1.4,1.4s-.6,1.4-1.4,1.4-1.4-.6-1.4-1.4.6-1.4,1.4-1.4Z"/>
          <path class="cls-17" d="M381.8,434h2v1.4h.3v1.2c0,.7-.6,1.3-1.3,1.3h0c-.7,0-1.3-.6-1.3-1.3v-1.2h.3v-1.4Z"/>
          <path class="cls-12" d="M384.1,436.6l-2.3-2.3v-.4h2v1.4h.3v1.2h0Z"/>
          <path class="cls-17" d="M382.8,428.9c.6,0,2.4,0,2.4,2.2s-.4,2.9-.7,3.2-.6.6-1.2.7c-.2.2-.3.2-.6.2s-.4,0-.6-.2c-.6-.2-.9-.4-1.2-.7s-.7-1.1-.7-3.2,1.8-2.2,2.4-2.2h.2Z"/>
          <path class="cls-31" d="M382.8,428.9c.6,0,2.4,0,2.4,2.2s-.4,2.9-.7,3.2-.6.6-1.2.7c-.2.2-.3.2-.6.2v-6.3h.1Z"/>
          <path class="cls-17" d="M380.7,432.2c0-.1-.1-.2-.3-.3-.2,0-.5,0-.5.5s.4.9.7.9.1-1,.1-1.1h0Z"/>
          <path class="cls-31" d="M384.9,432.2c0-.1.2-.2.3-.3.2,0,.5,0,.5.5s-.4.9-.7.9-.1-1.1-.1-1.1h0Z"/>
          <path class="cls-40" d="M384.1,430.1c.2.5.2,1.3,1.2,1.8.6-2.3-1-3.3-2.5-3.3s-3.1,1.1-2.6,3.4c1.1-.2,1.7-.8,2.2-1.4-.2.5-.5.8-.4.8,1-.3,1.4-.3,2.2-1.2h-.1Z"/>
          <path class="cls-30" d="M383.6,433.6c0,.4-.4.7-.8.7s-.7-.3-.8-.7h1.6,0Z"/>
          <rect class="cls-41" x="380.6" y="447.3" width="2" height=".5"/>
          <rect class="cls-41" x="383" y="447.3" width="2" height=".5"/>
          <g>
            <g>
              <rect class="cls-5" x="380.4" y="441.3" width="4.8" height=".6"/>
              <rect class="cls-5" x="380.4" y="440.2" width="4.8" height=".6"/>
              <rect class="cls-5" x="380.4" y="439.2" width="4.8" height=".6"/>
              <rect class="cls-5" x="380.4" y="438.2" width="4.8" height=".6"/>
              <path class="cls-32" d="M380.6,437.8h1.8c-.3-.1-.5-.3-.6-.6h-1v.6h-.2,0ZM383.3,437.8h1.8v-.6h-1.1c-.1.3-.4.5-.7.6h0Z"/>
            </g>
            <g>
              <path class="cls-32" d="M380.9,436.2h.7v.6h-.8v-.6h.1,0ZM384.1,436.2h.7v.6c.1,0-.7,0-.7,0v-.6Z"/>
              <path class="cls-32" d="M380.9,435.8h.6v-.4h-.6v.3h0ZM384.1,435.8h.6v-.3h0c-.2,0-.4-.1-.6,0v.4h0Z"/>
            </g>
          </g>
          <path class="cls-31" d="M380.6,432.3s0-.1-.2-.2c-.1,0-.3,0-.3.3s.2.6.4.5v-.2s-.2-.1-.1-.3c0,0,0-.2.1-.2h.1Z"/>
          <path class="cls-12" d="M385.1,432.3s0-.1.2-.2c.1,0,.3,0,.3.3s-.2.6-.4.5v-.2s.2-.1.1-.3c0,0,0-.2-.1-.2h-.1Z"/>
          <path class="cls-15" d="M383.9,431.7s-.1,0-.1-.1c0,0,0-.1.1-.1h.4c0,0,0,.1-.1.1h-.3Z"/>
          <path class="cls-15" d="M381.8,431.5s.1,0,.1.1c0,0,0,.1-.1.1h-.4c.2-.1.4-.1.4-.1h0Z"/>
          <g>
            <g>
              <path class="cls-15" d="M384.3,432.4h0c-.1-.1-.4-.3-.8,0h0c.3-.3.5-.1.6,0h.2,0Z"/>
              <path class="cls-15" d="M384,432.2c-.1,0-.2,0-.2.2s0,.2.2.2.2,0,.2-.2,0-.2-.2-.2Z"/>
              <path class="cls-15" d="M384.3,432.1h0v.2h0v-.2Z"/>
              <path class="cls-15" d="M384.4,432.2h-.1v.2h.1v-.2Z"/>
            </g>
            <g>
              <path class="cls-15" d="M381.3,432.4h0c.1-.1.4-.3.8,0h0c-.3-.3-.5-.1-.6,0h-.2,0Z"/>
              <path class="cls-15" d="M381.6,432.2c.1,0,.2,0,.2.2s0,.2-.2.2-.2,0-.2-.2,0-.2.2-.2Z"/>
              <path class="cls-15" d="M381.3,432.1h0v.2h0v-.2Z"/>
              <path class="cls-15" d="M381.2,432.2h.1v.2h-.1v-.2Z"/>
            </g>
          </g>
          <path class="cls-17" d="M379.5,437.7c0,.7-.4,2.7-.6,4h0v1.4s0,.1.1.2h0c0,.2,0,.3.2.3h0c0,.2,0,.3.2.3h0c.1,0,.2,0,.2-.2v-.8.2c0,.1.2.2.3.1h0c.1,0,.2-.2.1-.3l-.2-.6h0c.2-1.3.5-3.6.6-4.4h.1l.4-2.4c-1.3,0-1.4,1.4-1.5,2.2h0Z"/>
          <path class="cls-17" d="M386.4,437.5c.2.7.7,2.6,1,3.9h0v1.3c.1,0,0,.1,0,.2h0c0,.2,0,.3-.2.3h0c0,.2,0,.3-.2.3h0c-.1,0-.2,0-.2-.2v-.8.2c-.1.1-.2.2-.3.2h0c-.1,0-.2-.1-.2-.2v-.6h.2c-.3-1.3-.9-3.5-1.2-4.3h-.4l-.4-2.4c1.3-.2,1.5,1.3,1.8,2.1h.1Z"/>
        </g>
        <g>
          <g>
            <g id="dc-TarmL">
              <path class="cls-17" d="M373.6,427.4c.2,1.7.9,6.4,1.3,9.1h0c0,.1-.5,1.4-.5,1.4,0,.2,0,.5.2.5h0c.2,0,.5,0,.5-.2l.2-.5v1.7c0,.2.1.4.3.4h0c.2,0,.4-.2.4-.4v-.2h0c.2,0,.4-.2.4-.4v-.2c.1,0,.2-.2.2-.3v-2.9c-.5-3.6-.9-7.1-1.6-10.6-.1-.8-.9-2.6-2.8-2.3l.9,5h.5Z"/>
            </g>
            <path class="cls-42" d="M372.4,424.3c2.8-1.7,3-7.9,1.7-11.4-1.1-2.9-2.1-3.8-5.2-3.8s-4.1.9-5.2,3.8c-1.3,3.4-1.1,9.7,1.7,11.4,2.9,1.7,4.2,1.7,7.1,0h-.1Z"/>
            <path class="cls-17" d="M364.1,427.4c-.2,1.7-.9,6.4-1.3,9.1h0c0,.1.5,1.4.5,1.4,0,.2,0,.5-.2.5h0c-.2,0-.5,0-.5-.2l-.2-.5v1.7c0,.2-.1.4-.3.4h0c-.2,0-.4-.2-.4-.4v-.2h0c-.2,0-.4-.2-.4-.4v-.2c-.1,0-.2-.2-.2-.3v-2.9c.5-3.6.9-7.1,1.6-10.6.1-.8.9-2.6,2.8-2.3l-.9,5h-.5Z"/>
            <polygon class="cls-17" points="369.8 435.2 372.4 435.2 372.2 448.8 369.9 448.7 369.8 442.9 369.8 435.2"/>
            <polygon class="cls-17" points="367.8 435.2 365.3 435.2 365.5 448.8 367.8 448.7 367.8 442.9 367.8 435.2"/>
            <path class="cls-25" d="M363.7,440.5h10.4l-.6-16c0-1.2-.6-2.3-1.8-2.3h-5.6c-1.2,0-1.8,1.1-1.8,2.3l-.6,16Z"/>
            <path class="cls-28" d="M365.4,448.2h2.5c.3.8.5,1.5.4,2.5h-5.3c0-1.3,1.9-2,2.3-2.5h.1Z"/>
            <rect class="cls-22" x="363" y="450.3" width="5.5" height=".3"/>
            <path class="cls-28" d="M372.3,448.2h-2.5c-.3.8-.5,1.5-.4,2.5h5.3c0-1.3-1.9-2-2.3-2.5h-.1Z"/>
            <rect class="cls-22" x="369.3" y="450.3" width="5.5" height=".3"/>
            <path class="cls-17" d="M367.2,420h3.2v2.9c0,2.1-3.2,2.1-3.2,0v-2.9Z"/>
            <path class="cls-12" d="M370.3,423.7l-3.1-3.1v-.6h3.2v2.9c0,.3,0,.5-.1.7h0Z"/>
            <path class="cls-17" d="M368.8,409.2c1.2,0,4.7.1,4.7,4.2s-.8,5.6-1.4,6.2-1.1,1.2-2.3,1.5c-.3.3-.6.4-1.1.5-.5,0-.8-.2-1.1-.5-1.1-.3-1.7-.8-2.3-1.5-.6-.6-1.4-2.1-1.4-6.2s3.6-4.2,4.7-4.2h.2,0Z"/>
            <path class="cls-31" d="M368.8,409.2c1.2,0,4.7.1,4.7,4.2s-.8,5.6-1.4,6.2-1.1,1.2-2.3,1.5c-.3.3-.6.4-1.1.5v-12.3h0Z"/>
            <path class="cls-17" d="M364.8,415.5c-.1-.3-.2-.5-.6-.5s-.9.1-.9,1.1.8,1.8,1.3,1.7c.5-.1.3-1.9.2-2.2h0Z"/>
            <path class="cls-31" d="M372.9,415.5c.1-.3.3-.5.6-.5s.9.1.9,1.1-.8,1.8-1.3,1.7c-.5-.1-.3-2.2-.3-2.2h0Z"/>
            <path class="cls-42" d="M364.2,415c0-.4-.1-.9,0-1.5,0-4.1,3.6-4.3,4.8-4.3s4.7.1,4.8,4.3v1.5c-.8-.3-1.7-1.3-2.4-3.1-.6,1.1-4.1,2.6-7,3.1h-.2,0Z"/>
            <path class="cls-30" d="M370.4,418.5c-.1.8-.8,1.4-1.6,1.4s-1.5-.6-1.6-1.4h3.2Z"/>
            <g>
              <polygon class="cls-28" points="363.8 437.9 374 437.9 374 439.1 363.7 439.1 363.8 437.9"/>
              <polygon class="cls-28" points="363.8 435.8 373.9 435.8 373.9 437 363.8 437 363.8 435.8"/>
            </g>
          </g>
          <g>
            <path class="cls-15" d="M370.9,414.6c-.1,0-.2,0-.2-.2s0-.2.2-.2c0,0,.4,0,.8.1.1,0,.2.2.1.3,0,.1-.2.2-.3.1-.3-.1-.6-.1-.6-.1h0Z"/>
            <path class="cls-15" d="M366.8,414.1c.1,0,.2.1.2.2s-.1.2-.2.2c0,0-.3,0-.6.1-.1,0-.2,0-.3-.1,0-.1,0-.2.1-.3.4-.1.8-.1.8-.1h0Z"/>
            <g>
              <g>
                <path class="cls-15" d="M371.9,415.9h.2v-.2h0c-.3-.2-.8-.6-1.6,0v.2h.2c.6-.5,1-.2,1.2,0h0Z"/>
                <path class="cls-15" d="M371.2,415.5c-.2,0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4Z"/>
                <path class="cls-15" d="M371.9,415.4h0c0,0,0-.1-.1-.1v.3c-.1,0-.2,0-.1.1h.1v-.3h.1Z"/>
                <path class="cls-15" d="M372.1,415.5h0c0,0,0-.1-.1-.1l-.2.3h0c0,0,0,0,.1.1l.2-.3Z"/>
              </g>
              <g>
                <path class="cls-15" d="M365.8,415.9h-.2v-.2h0c.3-.2.8-.6,1.6,0v.2h-.2c-.6-.5-1-.2-1.2,0h0Z"/>
                <path class="cls-15" d="M366.5,415.5c.2,0,.4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4Z"/>
                <path class="cls-15" d="M365.8,415.4h0c0,0,0-.1.1-.1v.3c.1,0,.2,0,.1.1h-.1v-.3h-.1Z"/>
                <path class="cls-15" d="M365.6,415.5h0c0,0,0-.1.1-.1l.2.3h0c0,0,0,0-.1.1l-.2-.3Z"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g id="dc-teeter">
            <path class="cls-24" d="M410.3,439.6h0l-6.2-.6c-.3,0-.6-.3-.7-.6l-.9-5.2c0-.4.2-.8.6-.9.4,0,.8.2.9.6l.8,4.6,5.7.5c.4,0,.7.4.7.8s-.4.7-.7.7h-.2,0Z"/>
            <path class="cls-24" d="M451.5,443.5h0l-6.2-.7c-.4,0-.7-.4-.7-.8s.4-.7.8-.7l5.7.6,1.7-4.4c.1-.4.6-.6,1-.4.4.1.6.6.4,1l-1.9,4.9c-.1.3-.4.5-.7.5h0Z"/>

              <path class="cls-38" d="M412.5,441h0c-.2,0-.4-.2-.4-.5l.4-5c0-.2.2-.4.5-.4s.4.2.4.5l-.4,5c0,.2-.2.4-.4.4h-.1Z"/>
            <path class="cls-38" d="M443,444.1h0c-.2,0-.4-.2-.4-.5l.4-5c0-.2.2-.4.5-.4s.4.2.4.5l-.4,5c0,.2-.2.4-.4.4h-.1Z"/>
              <path class="cls-21" d="M409.7,440.3c-.4.8-1.3,1.4-2.3,1.4s-2.1-.8-2.5-1.8h-1c.3,1.5,1.8,2.7,3.5,2.7s2.7-.9,3.3-2.2h-1Z"/>
              <path class="cls-21" d="M450.2,444.2c-.4.8-1.3,1.4-2.3,1.4s-2.1-.8-2.5-1.8h-1c.3,1.5,1.8,2.7,3.5,2.7s2.7-.9,3.3-2.2h-1Z"/>
            <path class="cls-13" d="M451.1,445h0l-47.2-4.5c-.4,0-.7-.4-.7-.8s.4-.7.8-.7l47.2,4.5c.4,0,.7.4.7.8s-.4.7-.7.7h0Z"/>
          </g>
          <path class="cls-34" d="M431.3,447.5v-5.1c0-2-1.7-3.7-3.7-3.7s-3.7,1.7-3.7,3.7v5.1h7.4Z"/>
          <circle class="cls-1" cx="427.6" cy="442" r="1.4"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: 'daycare',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  mounted: function(){
      var hb = gsap.timeline({repeat: -1, repeatDelay: 3});
      hb.fromTo('#dc-armR', 1, {rotation:4},{rotation:-8})
      hb.to('#dc-armR', 1, {rotation:4})

      var hb2 = gsap.timeline({repeat: -1, repeatDelay: 3});
      hb2.fromTo('#dc-armL', 1, {rotation:-4},{rotation:8})
      hb2.to('#dc-armL', 1, {rotation:-4})

      var hb3 = gsap.timeline({repeat: -1, repeatDelay: 3, defaults:{ease:"Ease.none"}});
      hb3.fromTo('#dc-boy', .5, {y:0, scaleY:1},{y:-3, scaleY:.95})
      hb3.to('#dc-boy', .5, {y:0, scaleY:1})

      var hb4 = gsap.timeline({repeat: -1, repeatDelay: 5});
      hb4.fromTo('#dc-TarmL', 1, {rotation:0},{rotation:-8})
      hb4.to('#dc-TarmL', 1, {rotation:0},'+=3')

      var hb5 = gsap.timeline({repeat: -1, repeatDelay: 5});
      hb5.fromTo('#dc-teeter', 1, {rotation:0},{rotation:-8,transformOrigin:"50% 50%"})
      hb5.to('#dc-teeter', 1, {rotation:0},'+=5')
    },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('DayCare')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
      .cls-1 {
        fill: #f9ce88;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37, .cls-38, .cls-39, .cls-40, .cls-41, .cls-42 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #ab7e4f;
      }

      .cls-3 {
        fill: #1f0f05;
      }

      .cls-3, .cls-9, .cls-12, .cls-15, .cls-16, .cls-17, .cls-18, .cls-25, .cls-26, .cls-28, .cls-30, .cls-31, .cls-32, .cls-37, .cls-40, .cls-42 {
        fill-rule: evenodd;
      }

      .cls-43 {
        clip-path: url(#clippath);
      }

      .cls-4 {
        fill: none;
      }

      .cls-5, .cls-32 {
        fill: #ffc;
      }

      .cls-6 {
        fill: #5d9e9d;
      }

      .cls-7 {
        fill: #be574d;
      }

      .cls-8 {
        fill: #f3634d;
      }

      .cls-9 {
        fill: #699;
      }

      .cls-10 {
        fill: #8c603c;
      }

      .cls-11 {
        fill: #f27350;
      }

      .cls-12 {
        fill: #d4a15e;
      }

      .cls-13 {
        fill: #3c8485;
      }

      .cls-44 {
        mix-blend-mode: multiply;
      }

      .cls-14, .cls-16 {
        fill: #bf3611;
      }

      .cls-15 {
        fill: #471e04;
      }

      .cls-45 {
        clip-path: url(#clippath-1);
      }

      .cls-17 {
        fill: #f2d291;
      }

      .cls-18 {
        fill: #fc0;
      }

      .cls-19 {
        fill: #f9d087;
      }

      .cls-20 {
        fill: #e3e5e5;
      }

      .cls-21 {
        fill: #744a26;
      }

      .cls-22 {
        fill: #f7c5a3;
      }

      .cls-23 {
        fill: #3e7f87;
      }

      .cls-24 {
        fill: #f7654d;
      }

      .cls-25 {
        fill: #f60;
      }

      .cls-26, .cls-33 {
        fill: #fbca0f;
      }

      .cls-27 {
        fill: #5f4849;
      }

      .cls-28 {
        fill: #ff0;
      }

      .cls-29 {
        fill: #155e71;
      }

      .cls-30, .cls-36 {
        fill: #fff;
      }

      .cls-46 {
        isolation: isolate;
      }

      .cls-31 {
        fill: #edba68;
      }

      .cls-34 {
        fill: #f5634b;
      }

      .cls-35 {
        fill: #fe9b5a;
      }

      .cls-37 {
        fill: #000;
      }

      .cls-38 {
        fill: #3ec7b4;
      }

      .cls-39 {
        fill: #d5d5d5;
      }

      .cls-40 {
        fill: #d43c13;
      }

      .cls-41 {
        fill: #9cc;
      }

      .cls-42 {
        fill: #a65b10;
      }
    </style>
