<template>
    <div id="mobilehome" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-5" d="M701.4,461.4c1.3-5.4,1.8-10.4,1.8-11.1v-.3c.3-7.2-1.6-13.1-5.7-17.5-4.7-5.1-11.8-7.8-21.2-8.2-4.2-.1-8.8.4-12.4.9v-.2l-5.9,1.1c0,0-.1,0-.2,0-2.2-1.1-5-1.3-7.6-.4-3.3,1.1-5.7,3.8-6.4,6.7-3.3,2.4-8.8,6.8-10.1,9.2-3.8,6.8-3.5,11.9-1.4,19.6h0c.8,3,1.1,3.7,3.5,8.2v7.5h11.4c2,3.4,5.6,5.6,9.8,5.6s7.8-2.2,9.8-5.6h13.9v5h11.7v-5h17.4v-15.7h-8.4Z"/>
  <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
    <g>
      <ellipse class="cls-7" cx="653" cy="434" rx="4.5" ry="3.5" transform="translate(-105.7 236.2) rotate(-19)"/>
      <path class="cls-1" d="M658.9,431.3c2.6-.5,10.6-2.1,17.3-1.9,24.3.9,22,19.1,22,20.9s-2.5,18.9-6.8,20.9c-7.8.4-49.1,0-49.1,0-4.4-8.3-4.3-8-5.2-11.1-1.9-6.9-2.1-10.5.9-15.8.9-1.6,8.5-7.7,11.2-9.3s7-3.3,9.7-3.8h0Z"/>
      <rect class="cls-7" x="636.2" y="451.2" width="61.5" height="1.6"/>
      <polygon class="cls-6" points="703 466.4 703 470.3 640.8 470.3 640.8 472.1 685.7 472.1 685.7 477.1 687.4 477.1 687.4 472.1 703 472.1 704.8 472.1 704.8 470.3 704.8 466.4 703 466.4"/>
      <circle class="cls-3" cx="657" cy="471.4" r="6.3"/>
      <circle class="cls-5" cx="657" cy="471.4" r="1.9"/>
      <rect class="cls-2" x="671.4" y="440.4" width="15.2" height="27.4" rx="3" ry="3"/>
      <g>
        <path class="cls-4" d="M652.9,444.4h-5.6c-2.3,0-4.2,1.9-4.2,4.2v3.6c0,2.3,1.9,4.2,4.2,4.2h5.6v-12.1h0Z"/>
        <path class="cls-4" d="M660.6,444.4h-5.6v12.1h5.6c2.3,0,4.2-1.9,4.2-4.2v-3.6c0-2.3-1.9-4.2-4.2-4.2h0Z"/>
      </g>
      <circle class="cls-5" cx="679" cy="448.5" r="4.1"/>
    </g>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'forsale',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('MobileHome')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #7bc3ad;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke-width: 0px;
      }

      .cls-1, .cls-4, .cls-6 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #fc0;
      }

      .cls-3, .cls-6 {
        fill: #2f3233;
      }

      .cls-4, .cls-5 {
        fill: #fff;
      }

      .cls-7 {
        fill: #72b5a1;
      }
  </style>
