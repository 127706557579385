<template>
  <div id="treehouse" class="layer home">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <polygon v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-38" points="677.4 280.4 678.4 281.5 678.3 279.7 659.8 258.8 642.6 277 644.3 278.7 646.8 275.8 647.6 291.5 646.4 291.5 646.4 294.2 678.3 294.2 678.3 291.5 677.1 291.5 677.4 280.4"/>
  <g>
    <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
      <g>
        <polygon class="cls-5" points="659.9 258.3 642.7 276.6 644.4 278.2 659.8 260.5 678.5 281 678.4 279.2 659.9 258.3"/>
        <polygon class="cls-20" points="647.8 291.9 677.2 291.9 677.5 279.9 659.8 260.5 646.9 275.4 647.8 291.9"/>
        <polygon class="cls-32" points="659.9 277.8 652.2 277.5 652.7 291.1 659.7 291.1 659.9 277.8"/>
        <polygon class="cls-32" points="670.9 284.5 663.9 284.3 663.7 277.2 671.1 276.5 670.9 284.5"/>
        <polygon class="cls-34" points="653.3 278.3 659.9 278.5 659.9 277.8 652.2 277.5 652.7 291.1 653.8 291.1 653.3 278.3"/>
        <polygon class="cls-34" points="664.3 278.1 671 277.4 671.1 276.5 663.7 277.2 663.9 284.3 664.5 284.3 664.3 278.1"/>
        <polygon class="cls-17" points="648.2 276.9 661.2 262 659.8 260.5 646.9 275.4 647.8 291.9 649.1 291.9 648.2 276.9"/>
        <rect class="cls-5" x="646.5" y="291.1" width="31.9" height="2.7"/>
        <polygon class="cls-23" points="658.3 293.8 667.3 306.9 665.2 311.7 655 293.8 658.3 293.8"/>
        <polygon class="cls-22" points="658.3 295.2 667 307.7 667.3 306.9 658.3 293.8 655 293.8 655.8 295.2 658.3 295.2"/>
        <rect class="cls-13" x="646.9" y="296.7" width="13.7" height="2.5"/>
        <rect class="cls-13" x="647" y="301.3" width="13.7" height="2.5" transform="translate(-17.3 40.5) rotate(-3.5)"/>
        <rect class="cls-13" x="652.3" y="301.3" width="2.5" height="13.7" transform="translate(310.5 944.1) rotate(-86.9)"/>
        <rect class="cls-13" x="646.9" y="311.7" width="13.7" height="2.5" transform="translate(-27.9 66.5) rotate(-5.7)"/>
        <rect class="cls-13" x="652.6" y="311.8" width="2.5" height="13.7" transform="translate(250.5 923.9) rotate(-82.3)"/>
        <rect class="cls-13" x="646.8" y="323.7" width="13.7" height="2.5" transform="translate(-18.6 40.5) rotate(-3.5)"/>
        <path class="cls-5" d="M649.5,334.2c-.3,0-.5-.2-.5-.5l-.4-41.7c0-.3.2-.5.5-.5h0c.3,0,.5.2.5.5l.4,41.7c0,.3-.2.5-.5.5h0Z"/>
        <path class="cls-5" d="M658.8,334.2c-.3,0-.5-.2-.5-.5l-.4-41.7c0-.3.2-.5.5-.5s.5.2.5.5l.4,41.7c0,.3-.2.5-.5.5h0Z"/>
      </g>
      <path class="cls-30" d="M708,290.6c-.3-.6-.8-1-1.5-1s-.2-.2-.3-.2v-.4c-.2-.4-.5-.7-.9-.9-.5-.6-1.3-.8-2-.5s-.4.2-.5.4c-.5-.6-1.3-.8-2.1-.5s-.5.3-.6.5c-.5-.3-1.1-.3-1.6,0s-1,.9-1,1.5c-.2,0-.5,0-.7.2-.9.4-1.3,1.4-.9,2.3s0,.3.2.4c-.5.5-.7,1.3-.4,2s.8,1,1.5,1,.2.2.3.2v.4c.2.4.5.7.9.9.5.6,1.3.8,2,.5s.4-.2.5-.4c.5.6,1.3.8,2.1.5s.5-.3.6-.5c.5.3,1.1.3,1.6,0s1-.9,1-1.5c.2,0,.5,0,.7-.2.9-.4,1.3-1.4.9-2.3,0-.1,0-.3-.2-.4.5-.5.7-1.3.4-2Z"/>
      <path class="cls-31" d="M695.4,253.7c0-1.8-1-3.5-2.5-4.3-.2-.3-.3-.7-.6-1v-1.1c0-1.3-.5-2.5-1.3-3.3-.6-2-2.5-3.5-4.7-3.5s-1.2.1-1.7.3c-.6-2.1-2.5-3.6-4.7-3.6s-1.5.2-2.2.5c-.9-1.2-2.3-2-3.9-2s-3.6,1.1-4.4,2.7c-.6-.3-1.3-.4-2-.4-2.7,0-4.9,2.2-4.9,4.9s0,.9.2,1.3c-1.9.7-3.2,2.5-3.2,4.6s1,3.5,2.5,4.3c.2.3.3.7.6,1v1.1c0,1.3.5,2.5,1.3,3.3.6,2,2.5,3.5,4.7,3.5s1.2-.1,1.7-.3c.6,2.1,2.5,3.6,4.7,3.6s1.5-.2,2.2-.5c.9,1.2,2.3,2,3.9,2s3.6-1.1,4.4-2.7c.6.3,1.3.4,2,.4,2.7,0,4.9-2.2,4.9-4.9s0-.9-.2-1.3c1.9-.7,3.2-2.5,3.2-4.6h0Z"/>
      <path class="cls-30" d="M718.5,271.3c-.3-.6-.8-1-1.5-1s-.2-.2-.3-.2v-.4c-.2-.4-.5-.7-.9-.9-.5-.6-1.3-.8-2-.5s-.4.2-.5.4c-.5-.6-1.3-.8-2.1-.5s-.5.3-.6.5c-.5-.3-1.1-.3-1.6,0s-1,.9-1,1.5c-.2,0-.5,0-.7.2-.9.4-1.3,1.4-.9,2.3s0,.3.2.4c-.5.5-.7,1.3-.4,2s.8,1,1.5,1,.2.2.3.2v.4c.2.4.5.7.9.9.5.6,1.3.8,2,.5s.4-.2.5-.4c.5.6,1.3.8,2.1.5s.5-.3.6-.5c.5.3,1.1.3,1.6,0s1-.9,1-1.5c.2,0,.5,0,.7-.2.9-.4,1.3-1.4.9-2.3,0-.1,0-.3-.2-.4.5-.5.7-1.3.4-2Z"/>
    </g>
    <g>
      <path class="cls-16" d="M679.2,260.8c-.2-.8-.4-1.6-.6-2.4.2,1,.3,1.7.6,2.4Z"/>
      <path class="cls-16" d="M676.8,250.3c.2,1.8,1.1,4.9,1.8,8.1,0-.7-.2-1.4-.2-2.2-.3-5.4-2.1-10.3-1.6-5.8h0Z"/>
      <path class="cls-16" d="M717.6,262.1c-1.8-.9-5.5,1-6.3-.8s0-1,3.4-3.3c1.6-1.1,5-2.3,7.5-3.3v-.6c-6.7,1.6-7.1,2.3-7.9,2.8-.8.6-12.5,7.6-15.3,11.6,1-4.6.9-8,4.8-10.3,3.9-2.3,4.6-2.1,6.1-4.7,1.5-2.6-1.4-2.4-2.5-.5s-4.3,5-5.5,5.7c.7-3.2,1.4-1.7,3.4-5.3,2.1-3.5,1.9-9,.5-5.2-1.5,3.9-1,5.2-3.7,7.6.6-3.3-1.1-1.8.5-5.5s1.4-9.4.2-6.5c-1.1,2.9-.7,8.5-1.8,11.9s-3.9,12.2-5.6,15.6c-1.7,3.3-6.8,11.3-9.8,15.5-.9-5.3-3.5-9.8-4.2-14.2-.7-4.3-1.7-5.2,1.8-11,3.5-5.8,2.7-8.7,1.9-6.6-.8,2.1-3.8,8.1-4.3,9.5-.3-1.2-1-2-1.6-3.8.9,4,1.3,7.7,0,9.1-2.5-3.1-4.5-5.6-6.1-8s-2.7-1.6-1,1c2.6,4,12.5,20.6,6.6,28.4-5.8,7.8-12,11.6-13.5,17.1s1.1,17.5-1.1,21.3c-2.3,3.8-.7,5-6,6-5.3.9,40.4-.6,40.4-.6,0,0-5.3-1.9-8.6-5.3-3.3-3.3-10.1-12.1-8.9-16.9,1.1-4.8,3.8-13.6,18.3-19.2,0,0,2.1-2.5-.7-1.2-2.7,1.4-5,2.7-10.9,5.4,1.1-4.8,3-12.7,10.5-16.9,7.6-4.2,8.9-2.2,14.9-1.6s4-2.2,2.3-1.8c-1.7.3-7.7.5-10.8.1-.7-2.6,2.1-1.8,7.1-3.6s2.7-1.9,1.1-1.8c-1.6.1-13.5,3.9-17.1,7.7,2.4-4.9,5.3-17.9,21.9-17.7h0Z"/>
    </g>
    <path class="cls-30" d="M719.9,241.8c-.6-1.3-1.8-2.1-3.1-2.2-.2-.2-.5-.4-.7-.5,0-.3,0-.5-.2-.8-.4-.9-1.1-1.6-2-1.9-1.1-1.2-2.8-1.7-4.4-1-.4.2-.8.5-1.1.8-1.1-1.3-2.9-1.8-4.5-1.1s-1,.6-1.4,1.1c-1-.6-2.3-.7-3.4-.2s-2.1,1.9-2.2,3.3c-.5,0-1.1.1-1.6.3-1.9.9-2.7,3.1-1.9,5s.3.6.5.8c-1.1,1.1-1.5,2.8-.8,4.3s1.8,2.1,3.1,2.2c.2.2.5.4.7.5,0,.3,0,.5.2.8.4.9,1.1,1.6,2,1.9,1.1,1.2,2.8,1.7,4.4,1s.8-.5,1.1-.8c1.1,1.3,2.9,1.8,4.5,1.1s1-.6,1.4-1.1c1,.6,2.3.7,3.4.2s2.1-1.9,2.2-3.3c.5,0,1.1-.1,1.6-.3,1.9-.9,2.7-3.1,1.9-5,0-.3-.3-.6-.5-.8,1.1-1.1,1.5-2.8.8-4.3Z"/>
    <path class="cls-31" d="M722.3,244.7s-.2.1-.3.2c-1.1-1.3-2.9-1.8-4.5-1.1-.6.2-1,.6-1.4,1.1-1-.6-2.3-.7-3.4-.2-1.3.6-2.1,1.9-2.2,3.3-.5,0-1.1.1-1.6.3-1.9.9-2.7,3.1-1.9,5,0,.3.3.6.5.8-1.1,1.1-1.5,2.8-.8,4.3.6,1.3,1.8,2.1,3.1,2.2.2.2.5.4.7.5,0,.3,0,.5.2.8.4.9,1.1,1.6,2,1.9,1.1,1.2,2.8,1.7,4.4,1,.4-.2.8-.5,1.1-.8.9,1.1,2.4,1.6,3.9,1.3v-20.6h.2Z"/>
    <path class="cls-30" d="M722.3,271.8c-.4,0-.8,0-1.2.3-.4.2-.8.5-1.1.8-.8-.5-1.8-.5-2.7-.1-1.1.5-1.7,1.5-1.7,2.6-.4,0-.8,0-1.2.3-1.5.7-2.2,2.4-1.5,3.9,0,.2.3.5.4.7-.9.8-1.2,2.2-.6,3.3.5,1,1.4,1.7,2.5,1.7.2.2.4.3.6.4,0,.2,0,.4.2.6.3.7.9,1.2,1.6,1.5.8,1,2.2,1.3,3.4.8.3-.2.6-.4.9-.6.2.2.4.4.6.5v-16.6h-.2Z"/>
    <g>
      <path class="cls-3" d="M701.3,326.7c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM701.3,328.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-28" d="M701.3,328c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM701.3,328.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-15" d="M701.6,333c0,.3,0,.5-.3.6-.3,0-.5,0-.6-.3l-5.5-14.1v-.5l.2-.2.7.7h0l5.4,13.8h0Z"/>
      <path class="cls-28" d="M695.6,318.4l2-1.6h.2c0-.1.2-.1.2-.1,0,0,6.5.9,1.8,4.1-.2.2-.5,0-.7-.1-.2-.2,0-.5,0-.7,2.2-1.5-.4-2.1-1.1-2.2l-1.7,1.4-.7-.7h0Z"/>
      <path class="cls-28" d="M691.2,331.2h-1.7c-.3,0-.5.2-.5.5h0c0,.3.2.5.5.5h1.7c.3,0,.5-.2.5-.5h0c0-.3-.2-.5-.5-.5Z"/>
      <path class="cls-15" d="M696,322.2c.3-.3.7-.3,1,0s.3.7,0,1l-9.7,11.3c-.3.3-.7.3-1,0s-.3-.7,0-1l9.7-11.3Z"/>
      <path class="cls-15" d="M696.1,321.3c.4,0,.8.2.9.6,0,.4-.2.8-.6.9l-12.7,2.8c-.4,0-.8-.2-.9-.6,0-.4.2-.8.6-.9l12.7-2.8Z"/>
      <path class="cls-15" d="M681.2,320.2c0-.3,0-.5.3-.6.3,0,.5,0,.6.3l5,13.8c0,.3,0,.5-.3.6-.3,0-.5,0-.6-.3l-5-13.8Z"/>
      <path class="cls-3" d="M675.4,326.7c4.2,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5-7.5-3.4-7.5-7.5,3.4-7.5,7.5-7.5h0ZM675.4,328.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-28" d="M675.4,328c3.4,0,6.2,2.8,6.2,6.2s-2.8,6.2-6.2,6.2-6.2-2.8-6.2-6.2,2.8-6.2,6.2-6.2h0ZM675.4,328.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7,5.7-2.6,5.7-5.7-2.6-5.7-5.7-5.7Z"/>
      <path class="cls-28" d="M679.5,318.6h5.3c1.2,0,1,1.1-.2,1.1s-2.3.8-3.3.8h-1.8c-.5,0-.9-.4-.9-.9h0c0-.5.4-.9.9-.9h0Z"/>
      <path class="cls-3" d="M685.9,331.2s.3,0,.3.2,0,.3-.2.3l-10.9,1.7s-.3,0-.3-.2,0-.3.2-.3l11-1.7h0Z"/>
      <path class="cls-3" d="M686.3,335.7s.2.1.2.3,0,.3-.3.2l-11.1-.6s-.2-.1-.2-.3,0-.3.3-.2l11.1.6Z"/>
      <path class="cls-15" d="M683.1,324.7c.2-.2.5-.2.7,0s.2.5,0,.7l-7.7,8.9c-.2.2-.5.2-.7,0s-.2-.5,0-.7l7.7-8.9Z"/>
      <circle class="cls-32" cx="701.3" cy="334.2" r="1.2"/>
      <path class="cls-15" d="M686.9,333.6c.3,0,.5.2.5.5s-.2.5-.5.5h-11.5c-.3,0-.5-.2-.5-.4,0-.3.2-.5.5-.5h11.5Z"/>
      <circle class="cls-32" cx="675.4" cy="334.2" r="1.2"/>
      <path class="cls-8" d="M686.6,331.4c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3-2.3-1-2.3-2.3,1-2.3,2.3-2.3Z"/>
      <path class="cls-29" d="M686.6,332.9c.5,0,.9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9Z"/>
      <path class="cls-3" d="M684.3,337.1c0,.1-.2.1-.3,0v-.4l2.4-3.2c0-.1.2-.1.3,0v.4l-2.4,3.2Z"/>
      <path class="cls-28" d="M684.9,336.7h-1.7c-.3,0-.5.2-.5.5h0c0,.3.2.5.5.5h1.7c.3,0,.5-.2.5-.5h0c0-.3-.2-.5-.5-.5Z"/>
    </g>
    <g>
      <path class="cls-24" d="M629.3,331.1s-.8.3-1,.9-1.2,4.2-1.1,4.8c0,.5.2,1,1.5,1.1,1.3.2,2-.1,2.3-.2s-.3-5.8-1.7-6.6h0Z"/>
      <path class="cls-10" d="M629.3,331.7c-.3,0-.4.4-.4.8s-.4,2.4-.4,2.7-.3.5-.7.5h-.6s.8-3.2,1-3.6c0-.5.5-.7.8-.8.3,0,.3.6.3.6v-.2h0Z"/>
      <path class="cls-33" d="M634.1,308.8c.2,0,.4,0,.6.2.2,0,.4-.1.6,0,.2,0,.4.1.5.3h.6c.2,0,.3.2.4.3.2,0,.4,0,.6.1.2.1.3.3.3.4.2,0,.4.1.5.2s.2.3.2.5c.2,0,.3.2.4.3v.5c.2,0,.3.2.4.4v.5c0,.1.2.3.3.5v.5c0,.1.2.3,0,.5,0,.2,0,.3-.3.5v.5c0,.2-.2.3-.4.4v.5c0,.2-.3.3-.4.3,0,.2,0,.3-.2.5,0,.1-.3.2-.5.2,0,.2-.2.3-.3.4-.2.1-.4.2-.6.1,0,.2-.2.3-.4.4h-.6c0,.1-.3.2-.5.3h-.6c-.2.1-.3.2-.6.2s-.4,0-.6-.2c-.2,0-.4.1-.6,0-.2,0-.4-.1-.5-.3h-.6c-.2,0-.3-.2-.4-.4-.2,0-.4,0-.6-.1-.2-.1-.3-.3-.3-.4-.2,0-.4-.1-.5-.2s-.2-.3-.2-.5c-.2,0-.3-.2-.4-.3v-.5c-.2,0-.3-.2-.4-.4v-.5c0-.1-.2-.3-.3-.5v-.5c0-.1-.2-.3,0-.5,0-.2,0-.3.3-.5v-.5c0-.2.2-.3.4-.4v-.5c0-.2.3-.3.4-.3,0-.2,0-.3.2-.5,0-.1.3-.2.5-.2,0-.2.2-.3.3-.4.2-.1.4-.2.6-.1,0-.2.2-.3.4-.3h.6c0-.1.3-.2.5-.3h.6c.2-.1.3-.2.6-.2Z"/>
      <path class="cls-26" d="M629.9,332.5h8.2v-9.6c0-1-.8-1.8-1.8-1.8h-4.6c-1,0-1.8.8-1.8,1.8v9.6h0Z"/>
      <path class="cls-37" d="M630.3,341.5h3.1c.3.8.2,1.5.2,2.3h-5.4c0-1.2,2.1-1.5,2.1-2.3h0Z"/>
      <rect class="cls-1" x="628.1" y="343.3" width="5.6" height=".5"/>
      <path class="cls-37" d="M637.8,341.5h-3.1c-.3.8-.2,1.5-.2,2.3h5.4c0-1.2-2.1-1.5-2.1-2.3h0Z"/>
      <rect class="cls-1" x="634.4" y="343.3" width="5.6" height=".5"/>
      <path class="cls-35" d="M638,331.4h-7.9v10.1h3.6l-.3-8.6h1.3l-.3,8.6h3.6v-10.1h0Z"/>
      <path class="cls-2" d="M639.5,324.4s1.1,3.7-.4,6.9c0,.3-.3.7-.6,1-.2.3-.5.6-.7.8l-1-1.3s.2-.2.4-.4c.2-.3.4-.6.4-.7,1.2-2.7.3-5.8.3-5.8l1.6-.4h0Z"/>
      <path class="cls-9" d="M639.8,324.9l-1.9.4-.7-4c1.5-.3,2.1,1.1,2.3,1.8l.4,1.8h0Z"/>
      <polygon class="cls-6" points="636.7 331.5 636.3 331.9 637.7 333.6 638.1 333.2 636.7 331.5"/>
      <g>
        <path class="cls-28" d="M639.3,322.7h-1.9v.5h2.1v-.2c0-.1,0-.2-.2-.4h0Z"/>
        <path class="cls-28" d="M637.2,321.6h1.3c.2.2.4.3.5.5h-1.7v-.5h0Z"/>
        <polygon class="cls-28" points="637.6 323.8 639.5 323.8 639.7 324.4 637.7 324.4 637.6 323.8"/>
        <polygon class="cls-28" points="639.8 324.9 637.8 324.9 637.8 325.3 639.8 324.9 639.8 324.9"/>
      </g>
      <path class="cls-28" d="M636.2,321.1h0v.2c0,.5-.4.9-.8,1.2-.4.3-.8.5-1.3.5s-1-.2-1.3-.5c-.4-.3-.7-.7-.8-1.2h0v-.2h1c0,.2.2.4.4.6s.4.2.7.2.5,0,.7-.2c.2-.1.3-.3.4-.6h1,0Z"/>
      <path class="cls-2" d="M632.5,320.1h3.2v1c-.2.7-.9,1.3-1.6,1.3s-1.4-.5-1.6-1.3v-.9h0q0-.1,0-.1Z"/>
      <path class="cls-18" d="M632.5,320.4l3.2-.3h0v1c-.2.6-.6,1.1-1.2,1.2l-2-2h0Z"/>
      <g>
        <path class="cls-2" d="M634.1,310.1c-1.1,0-4.4.1-4.4,4s.8,5.2,1.3,5.8c.5.6,1.1,1.1,2.1,1.4.3.3.6.4,1,.4v-11.6h0Z"/>
        <path class="cls-7" d="M634.1,310.1c1.1,0,4.4.1,4.4,4s-.8,5.2-1.3,5.8c-.5.6-1.1,1.1-2.1,1.4-.3.3-.6.4-1,.4v-11.6h0Z"/>
      </g>
      <path class="cls-2" d="M630.2,316c0-.3-.2-.4-.5-.5-.3,0-.9,0-.9,1s.7,1.7,1.2,1.6.2-1.8.2-2h0Z"/>
      <path class="cls-7" d="M637.8,316c0-.2.3-.4.6-.5.3,0,.9,0,.9,1s-.7,1.7-1.2,1.6-.3-2-.3-2h0Z"/>
      <path class="cls-28" d="M635.6,318.7c0,.7-.7,1.3-1.5,1.3s-1.4-.6-1.5-1.3h3Z"/>
      <path class="cls-33" d="M629.7,315.8c.4-1.5.4-3.3,1.1-3.6.8-.3,1.9.5,3.3.4,1.4,0,2.5-.7,3.3-.4.8.3.7,2.1,1.1,3.6,2-5.6-3-6.1-4.4-5.8-1.4-.4-6.4.2-4.4,5.8h0Z"/>
      <path class="cls-7" d="M630,316.3c0-.1-.2-.3-.4-.3s-.5,0-.5.6.4,1,.7,1c0,0,0-.2.2-.3-.2,0-.3-.3-.2-.5,0-.2,0-.3.3-.4h0Z"/>
      <path class="cls-18" d="M638.1,316.3c0-.1.2-.3.4-.3s.5,0,.5.6-.4,1-.7,1c0,0,0-.2-.2-.3.2,0,.3-.3.2-.5,0-.2,0-.3-.3-.4h0Z"/>
      <path class="cls-33" d="M631.5,315.1h1s.2-.1.2-.2h0c0-.1,0-.2-.2-.2h-1s-.2.1-.2.2h0c0,.1,0,.2.2.2Z"/>
      <path class="cls-33" d="M635.6,315.1h1s.2-.1.2-.2h0c0-.1,0-.2-.2-.2h-1s-.2.1-.2.2h0c0,.1,0,.2.2.2Z"/>
      <g>
        <path class="cls-33" d="M636.2,315.9c-.2,0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4Z"/>
        <path class="cls-33" d="M636.7,316.2h.2v-.2h0c-.3-.2-.7-.6-1.4,0v.2h.2c.5-.4.9-.2,1.1,0h0Z"/>
        <path class="cls-33" d="M631.9,315.9c.2,0,.4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4Z"/>
        <path class="cls-33" d="M631.4,316.2h-.2v-.2h0c.3-.2.7-.6,1.4,0v.2h-.2c-.5-.4-.9-.2-1.1,0h0Z"/>
      </g>
      <path class="cls-10" d="M637.4,321.1s-.5,6.7-7.2,11l-.6-.9c6.3-4,6.7-10.1,6.7-10.1h1.1Z"/>
      <path class="cls-2" d="M630.2,324.8s-.9,3.2.3,5.8c0,.2.2.5.4.7,0,.2.3.3.4.4l-1,1.3c-.3-.2-.5-.5-.7-.8-.3-.4-.5-.8-.6-1-1.4-3.2-.4-6.9-.4-6.9l1.6.4h0Z"/>
      <path class="cls-9" d="M628.4,324.9l1.9.4.7-4c-1.5-.3-2.1,1.1-2.3,1.8l-.4,1.8h0Z"/>
      <polygon class="cls-6" points="631.4 331.5 631.9 331.9 630.4 333.6 630 333.2 631.4 331.5"/>
      <g>
        <path class="cls-28" d="M630.8,322.7h-1.9v.6h1.9v-.5h0Z"/>
        <path class="cls-28" d="M629.7,321.6h1.3v.5h-1.8c0-.2.3-.4.5-.5Z"/>
        <polygon class="cls-28" points="628.6 323.8 630.6 323.8 630.5 324.4 628.5 324.4 628.6 323.8"/>
        <polygon class="cls-28" points="630.4 324.9 628.4 324.9 628.4 324.9 630.3 325.3 630.4 324.9"/>
      </g>
    </g>
  </g>
  <g id="th-hole">
    <ellipse class="cls-4" cx="670" cy="246.7" rx="4.3" ry="2.7"/>
    <path class="cls-21" d="M676.7,246.7c0-1.3-1.1-2.4-2.4-2.4s-.3,0-.5,0c0,0,0-.2,0-.3,0-1.3-1.1-2.4-2.4-2.4s-2.1.8-2.4,1.9c-.4-.6-1.1-.9-1.9-.9-1.3,0-2.4,1.1-2.4,2.4s.3,1.3.7,1.7c-.9.4-1.6,1.2-1.6,2.3s1.1,2.4,2.4,2.4.4,0,.6,0c.1,1.2,1.2,2.2,2.4,2.2s1.9-.6,2.2-1.5c.4.3.9.5,1.4.5,1.3,0,2.4-1.1,2.4-2.4s0-.7-.2-1c.9-.3,1.6-1.2,1.6-2.3ZM668.6,248.7c0-.6-.3-1-.7-1.4.7-.3,1.3-.9,1.5-1.7.4.6,1.1.9,1.9.9s.3,0,.5,0c0,0,0,.2,0,.3,0,.4,0,.7.2,1-.6.2-1.1.7-1.4,1.3-.4-.3-.9-.5-1.4-.5s-.4,0-.6,0Z"/>
  </g>
  <g id="th-squirrel">
    <g>
      <path class="cls-19" d="M672.2,244.6c0,0,.2,0,.3,0-.1,0-.3-.2-.4-.7,0-.2,0-.5-.2-.7.2-.3.3-.7.2-1.1-.1-.7-.6-1.1-1.1-1-.4,0-.6.5-.6,1,0,0,0,0,0,0h-.9s0,0,0,0c0-.5-.3-.9-.6-1-.5-.1-.9.3-1.1,1,0,.5,0,.9.3,1.2,0,.2-.1.5-.1.7-.1.5-.3.7-.4.6.1,0,.2,0,.3,0-.1.3-.2.3-.3.3.2.1.3,0,.4,0v3.3h4.2v-3.3s.2.2.4,0c0,0-.2,0-.3-.3Z"/>
      <g>
        <path class="cls-36" d="M670,245.8c-1.1,0-2.1,1.1-2.1,2.4,0,0,1.2.1,2.1.1s2.1-.1,2.1-.1c0-1.3-.9-2.4-2.1-2.4h0Z"/>
        <path class="cls-19" d="M669.9,247.9c-.9,0-1.6-.1-2-.3,0,.2,0,.3,0,.5,0,0,1.2.1,2.1.1s2.1-.1,2.1-.1c0-.2,0-.4,0-.5-.3.2-1.1.4-2.1.4h0Z"/>
      </g>
      <path class="cls-36" d="M668.3,242.8c.2-.3.5-.6.9-.7,0-.3-.2-.5-.4-.5s-.6.2-.6.6,0,.5.1.7h0Z"/>
      <path class="cls-36" d="M671.7,242.9c.1-.1.2-.3.1-.7s-.4-.6-.6-.6-.4.3-.4.5c.3.1.6.4.9.7h0Z"/>
    </g>
    <g>
      <g>
        <g>
          <ellipse class="cls-27" cx="668.9" cy="243.4" rx=".4" ry=".2" transform="translate(159.5 740.6) rotate(-64.3)"/>
          <!-- <ellipse class="cls-32" cx="668.8" cy="243.4" rx=".1" ry="0"/> -->
        </g>
        <g>
          <ellipse class="cls-27" cx="671.1" cy="243.4" rx=".2" ry=".4" transform="translate(-39.1 315.2) rotate(-25.7)"/>
          <!-- <ellipse class="cls-32" cx="671.2" cy="243.4" rx=".1" ry="0"/> -->
        </g>
      </g>
      <g>
        <path class="cls-14" d="M670.8,244.8c0,.5-.4,1-.9,1s-.9-.4-.9-1,1.7-.5,1.7,0Z"/>
        <g>
          <polygon class="cls-32" points="670.3 244.4 669.6 244.4 669.8 245.4 670.2 245.4 670.3 244.4"/>
          <polygon class="cls-12" points="670 244.4 670 244.4 670 245.4 670 245.4 670 244.4"/>
        </g>
        <path class="cls-25" d="M669.9,243.4h.2c.3,0,.5.3.5.6h0c0,.3-.2.6-.5.6h-.2c-.3,0-.5-.3-.5-.6h0c0-.3.2-.6.5-.6Z"/>
        <path class="cls-11" d="M670.5,244c-.2,0-.4.2-.5.4,0-.2-.3-.4-.5-.4s-.5.3-.5.6.2.6.5.6.4-.2.5-.4c0,.2.3.4.5.4s.5-.3.5-.6-.2-.6-.5-.6Z"/>
        <path class="cls-36" d="M670.5,244c-.2,0-.4.1-.5.3,0-.2-.3-.3-.5-.3s-.5.2-.5.5.2.5.5.5.4-.1.5-.3c0,.2.3.3.5.3s.5-.2.5-.5-.2-.5-.5-.5Z"/>
      </g>
    </g>
    <g>
      <g>
        <path class="cls-36" d="M669,248c-.1,0-.2,0-.3.2,0-.1-.2-.2-.3-.2s-.1,0-.2,0c0,0,0,0,0,0,0-.2-.2-.4-.4-.4s-.4.2-.4.4.2.4.4.4.1,0,.2,0c0,0,0,0,0,0,0,.2.2.4.4.4s.2,0,.3-.2c0,.1.2.2.3.2.2,0,.4-.2.4-.4s-.2-.4-.4-.4Z"/>
        <ellipse class="cls-11" cx="668.4" cy="247.9" rx=".6" ry=".9" transform="translate(224.2 805.5) rotate(-71.8)"/>
      </g>
      <g>
        <path class="cls-36" d="M672.2,247.5c-.2,0-.4.2-.4.4s0,0,0,0c0,0-.1,0-.2,0-.1,0-.3,0-.3.2,0-.1-.2-.2-.3-.2-.2,0-.4.2-.4.4s.2.4.4.4.3,0,.3-.2c0,.1.2.2.3.2.2,0,.4-.2.4-.4s0,0,0,0c0,0,.1,0,.2,0,.2,0,.4-.2.4-.4s-.2-.4-.4-.4Z"/>
        <ellipse class="cls-11" cx="671.5" cy="247.9" rx=".9" ry=".6" transform="translate(-43.8 222.1) rotate(-18.2)"/>
      </g>
    </g>
  </g>
</svg>

  </div>
</template>

<script>
export default {
  name: '',
  props: {
    manualHighlight: Boolean
  },
  data() {
    return {
      highlight: false,
      found: false
    }
  },
  mounted: function(){
    var hb = gsap.timeline({delay:4,repeat: -1, repeatDelay:7});
    hb.fromTo('#th-hole', .6, {alpha:0,scaleY:0,y:9},{alpha:1,scaleY:1,y:9,transformOrigin:"50% 50%",ease:"Bounce.easeOut"})
    hb.fromTo('#th-squirrel', .3, {alpha:0,scaleY:0,y:10},{alpha:1,scaleY:1,y:10,transformOrigin:"50% 100%",ease:"Bounce.easeOut"})
    hb.to('#th-squirrel', .3, {alpha:0,scaleY:0,y:10,transformOrigin:"50% 100%",ease:"Bounce.easeOut"},'+=3')
    hb.to('#th-hole', .3, {alpha:0,scaleY:0,y:9,transformOrigin:"50% 50%",ease:"Bounce.easeIn"},'-=.2')

  },
  methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('TreeHouse')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
.cls-1 {
        fill: #333;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33, .cls-34, .cls-35, .cls-36, .cls-37 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #a44624;
      }

      .cls-2, .cls-3, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-15, .cls-18, .cls-24, .cls-26, .cls-28, .cls-29, .cls-30, .cls-31, .cls-33, .cls-35, .cls-37 {
        fill-rule: evenodd;
      }

      .cls-3 {
        fill: #5b412a;
      }

      .cls-4 {
        fill: #ebd008;
      }

      .cls-5 {
        fill: #fbe98b;
      }

      .cls-6 {
        fill: #9cc;
      }

      .cls-38 {
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 10px;
      }

      .cls-38, .cls-28, .cls-32 {
        fill: #fff;
      }

      .cls-7 {
        fill: #78331a;
      }

      .cls-8 {
        fill: #a6a6a6;
      }

      .cls-9 {
        fill: #f93;
      }

      .cls-10 {
        fill: #663;
      }

      .cls-11 {
        fill: #5c5c5c;
      }

      .cls-12 {
        fill: #d8d9d8;
      }

      .cls-13 {
        fill: #e38046;
      }

      .cls-14 {
        fill: #611200;
      }

      .cls-15 {
        fill: #ff5050;
      }

      .cls-16 {
        fill: #2c3b2e;
      }

      .cls-17 {
        fill: #dd6238;
      }

      .cls-18 {
        fill: #4e2111;
      }

      .cls-19 {
        fill: #878787;
      }

      .cls-20 {
        fill: #e28048;
      }

      .cls-21, .cls-31 {
        fill: #f6ff09;
      }

      .cls-22 {
        fill: #b84c29;
      }

      .cls-23 {
        fill: #c36233;
      }

      .cls-24 {
        fill: #404020;
      }

      .cls-25 {
        fill: #0f0a07;
      }

      .cls-26 {
        fill: #fbca0f;
      }

      .cls-27 {
        fill: #291a11;
      }

      .cls-29 {
        fill: #ffebb4;
      }

      .cls-30 {
        fill: #f2a100;
      }

      .cls-33 {
        fill: #000;
      }

      .cls-34 {
        fill: #e6e6e6;
      }

      .cls-35 {
        fill: #3b696a;
      }

      .cls-36 {
        fill: #b0b0b0;
      }

      .cls-37 {
        fill: #c63;
      }
</style>
