<template>
    <div id="oldhome" class="layer home">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 722.3 676.6">
  <path v-if="highlight || found || manualHighlight" v-bind:class="{ active: found }" class="cls-12" d="M232.5,206.4h6.6l-10.9-23.4h-17.4c-.3-1.5-1.1-2.8-2.4-3.8l-20.2-15.2c-.1-.1-.3-.2-.4-.3l-.3-.2-1.2-.5c-.3,0-.6-.2-.9-.2h-1c0,0-.2,0-.2,0h-.1s-.8,0-.8,0c-.3,0-.7.1-1,.2l-1.4.7c-.2,0-.3.2-.4.3l-20.3,15.2c-1.2.9-2.1,2.3-2.4,3.8h-17.4l-10.9,23.4h6.6v12.6h-4.5v17.1h105.2v-17.1h-4.5v-12.6Z"/>
  <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
    <rect class="cls-9" x="136.8" y="223.9" width="95.2" height="7.1"/>
    <polygon class="cls-1" points="186.3 223.9 217.1 223.9 226.6 231 185 231 186.3 223.9"/>
    <rect class="cls-8" x="141.3" y="201.4" width="86.2" height="22.6"/>
    <polygon class="cls-4" points="225 188 143.8 188 137.5 201.4 231.3 201.4 225 188"/>
    <rect class="cls-12" x="149.3" y="206.6" width="15.6" height="11.4"/>
    <path class="cls-5" d="M149.3,213.1c-.5,0-1-.4-1-1s.4-1,1-1h15.6c.5,0,1,.4,1,1s-.4,1-1,1h-15.6Z"/>
    <rect class="cls-12" x="203.9" y="206.6" width="15.6" height="11.4"/>
    <polygon class="cls-6" points="145.4 205.1 227.5 205.1 227.5 201.4 141.3 201.4 145.4 205.1"/>
    <polygon class="cls-6" points="184.4 199.3 217.1 223.9 184.4 223.9 184.4 199.3"/>
    <polygon class="cls-7" points="209.3 218 203.9 218 203.9 214 209.3 218"/>
    <polygon class="cls-2" points="166.6 195.3 174.7 201.4 217.9 201.4 200.8 188 166.6 188 166.6 195.3"/>
    <polygon class="cls-5" points="166.6 182.3 166.6 195.3 200.8 195.3 200.8 181.9 184.4 168.9 166.6 182.3"/>
    <rect class="cls-12" x="171.8" y="185.1" width="9" height="6.6"/>
    <rect class="cls-12" x="186.6" y="185.1" width="9" height="6.6"/>
    <path class="cls-11" d="M184.4,175.6c1.7,0,3.1,1.4,3.1,3.1h-6.2c0-1.7,1.4-3.1,3.1-3.1Z"/>
    <polygon class="cls-6" points="171.4 183.9 186.8 172.4 184.4 170.5 166.6 183.9 171.4 183.9"/>
    <path class="cls-4" d="M164.9,185.2c-.6.4-1.4.3-1.8-.3-.4-.6-.3-1.4.3-1.8l20.3-15.3s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0c0,0,.1,0,.2,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0c0,0,.1,0,.2,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0h0s0,0,0,0c0,0,0,0,0,0l20.3,15.3c.6.4.7,1.2.3,1.8-.4.6-1.2.7-1.8.3l-19.5-14.7-19.5,14.7Z"/>
    <polygon class="cls-11" points="184.4 199.3 174.3 206.9 177.5 207 177.5 223.9 184.4 223.9 191.3 223.9 191.3 207 194.5 206.9 184.4 199.3"/>
    <rect class="cls-8" x="171.6" y="223.9" width="25.6" height="3.6"/>
    <rect class="cls-8" x="167.2" y="227.5" width="34.4" height="3.6"/>
    <polygon class="cls-6" points="187.4 223.9 197.2 223.9 197.2 227.5 191.7 227.5 187.4 223.9"/>
    <polygon class="cls-6" points="183 227.5 201.6 227.5 201.6 231 187.3 231 183 227.5"/>
    <rect class="cls-3" x="180.8" y="207.4" width="7.2" height="16.5"/>
    <path class="cls-4" d="M175,208c-.6.4-1.4.3-1.8-.3-.4-.6-.3-1.4.3-1.8l10.1-7.6c.5-.4,1.1-.3,1.5,0l10.1,7.6c.6.4.7,1.2.3,1.8-.4.6-1.2.7-1.8.3l-9.4-7-9.4,7Z"/>
    <polygon class="cls-10" points="188 223.9 188 207.4 180.8 207.4 188 223.9"/>
    <path class="cls-5" d="M158.1,221.8c0,.5-.4,1-1,1s-1-.4-1-1v-15.6c0-.5.4-1,1-1s1,.4,1,1v15.6Z"/>
    <path class="cls-5" d="M203.9,213.1c-.5,0-1-.4-1-1s.4-1,1-1h15.6c.5,0,1,.4,1,1s-.4,1-1,1h-15.6Z"/>
    <path class="cls-5" d="M212.6,218c0,.5-.4,1-1,1s-1-.4-1-1v-11.9c0-.5.4-1,1-1s1,.4,1,1v11.9Z"/>
  </g>
</svg>
    </div>
  </template>

  <script>
  export default {
    name: 'oldhome',
    props: {
      manualHighlight: Boolean
    },
    data() {
      return {
        highlight: false,
found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        this.$parent.showModal('OldHome')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
  }
  </script>

  <style scoped>
        .cls-1 {
        fill: #629c8a;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-4, .cls-5, .cls-6, .cls-7, .cls-10, .cls-11 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #eb6600;
      }

      .cls-3 {
        fill: #cc3;
      }

      .cls-4 {
        fill: #f77917;
      }

      .cls-5, .cls-8 {
        fill: #f6aa32;
      }

      .cls-6 {
        fill: #d79432;
      }

      .cls-7 {
        fill: #e6e6e6;
      }

      .cls-9 {
        fill: #7bc3ad;
      }

      .cls-10 {
        fill: #a3a328;
      }

      .cls-11, .cls-12 {
        fill: #fff;
      }
  </style>
