import { render, staticRenderFns } from "./DayCare.vue?vue&type=template&id=65b9c946&scoped=true"
import script from "./DayCare.vue?vue&type=script&lang=js"
export * from "./DayCare.vue?vue&type=script&lang=js"
import style0 from "./DayCare.vue?vue&type=style&index=0&id=65b9c946&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b9c946",
  null
  
)

export default component.exports