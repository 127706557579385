<template>
  <div id="birdhouse" class="layer home">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 722.3 676.6">
  <defs>
    <clipPath id="clippath">
      <rect class="cls-3" x="511" y="431.2" width="3.1" height="4.9"/>
    </clipPath>
  </defs>
  <g class="homeHotSpot" @click="clickHome" @mouseover="showHighlight" @mouseleave="hideHighlight">
    <g>
      <g>
        <path class="cls-11" d="M513.4,438.6c-.2,0-.4,0-.5.1-.2-.5-.7-.8-1.2-.8s-.8.2-1.1.6c-.2,0-.4-.1-.6-.1-.6,0-1.1.4-1.3,1-.1,0-.3,0-.5,0-.7,0-1.3.6-1.3,1.4s0,.4,0,.5c-.4.2-.6.7-.6,1.2s.4,1.2,1,1.4c0,.7.6,1.3,1.3,1.3,1.6,0,3.1-.2,4.7-.2s1.3-.6,1.3-1.4,0-.3,0-.5c.5-.2.9-.7.9-1.3s-.4-1.1-.9-1.3c0-.1,0-.2,0-.4,0-.8-.6-1.4-1.3-1.4Z"/>
        <g>
          <rect class="cls-4" x="517.6" y="435" width="2.1" height="24"/>
          <path class="cls-1" d="M508.6,432.1c0-.5.2-.9.4-1.3-1-.7-1.6-1.8-1.5-3.1.1-1.1.7-1.9,1.6-2.4-.2-.5-.2-1-.2-1.5.1-1.5,1.3-2.6,2.7-2.7,0-.4-.1-.8,0-1.2.2-1.8,1.7-3,3.5-2.7.4,0,.8.2,1.2.4,1.3-1.7,5-2.2,6.5,0,.2-.1.5-.2.8-.3,1.7-.3,3.5.8,3.9,2.6.1.5.1.9,0,1.4,1.1.3,2.1,1.3,2.4,2.5.2,1,0,2.1-.7,2.8.3.4.5.8.7,1.3.3,1.5-.4,2.9-1.6,3.5.2.3.4.7.5,1.1.4,1.7-.7,3.4-2.4,3.7-.4,0-.8,0-1.2,0-1.9,3.7-6.2,1.9-6.6.2-.6,1.2-1.8,1.9-3.2,1.7-1.4-.2-2.5-1.2-2.8-2.5-.3,0-.6,0-.8,0-1.8-.3-3.1-1.9-2.9-3.6Z"/>
        </g>
        <path class="cls-4" d="M517.8,445.5c-1.9-.4-3.7-1.2-5.3-2.3l.5-.7c1.5,1.1,3.2,1.8,5,2.2l-.2.8Z"/>
        <g>
          <polygon class="cls-8" points="512.3 425.3 509.1 430.1 510 436.6 516.1 436.7 516.2 429.5 512.3 425.3"/>
          <polygon class="cls-9" points="523.2 429.8 516.2 429.5 516.1 436.7 522.1 436.9 523.2 429.8"/>
          <g>
            <polygon class="cls-14" points="521.8 425 512.6 424.7 516.5 429.5 523.2 429.8 521.8 425"/>
            <path class="cls-2" d="M523.2,430.4s0,0,0,0l-6.6-.3c-.2,0-.3,0-.5-.2l-3.9-4.8c-.1-.2-.2-.4,0-.6.1-.2.3-.3.6-.3l9.1.3c.3,0,.5.2.6.4l1.4,4.8c0,.2,0,.4-.1.5-.1.1-.3.2-.5.2ZM516.8,428.9l5.5.2-1.1-3.6-7.4-.2,2.9,3.6Z"/>
          </g>
          <ellipse class="cls-2" cx="512.4" cy="429.3" rx="1.2" ry="1.2"/>
          <polygon class="cls-2" points="512 436.7 511.6 432.3 514.1 432.3 514 436.7 512 436.7"/>
          <path class="cls-6" d="M508.5,430.8c-.1,0-.2,0-.4-.1-.3-.2-.3-.6-.1-.8l4.1-5.6c.1-.2.3-.2.5-.2.2,0,.4,0,.5.2l3.9,5.1c.2.3.1.6-.1.8-.3.2-.6.1-.8-.1l-3.5-4.5-3.6,4.9c-.1.2-.3.2-.5.2Z"/>
          <path class="cls-6" d="M523.4,437.4s0,0,0,0l-14.7-.3c-.3,0-.6-.3-.6-.6,0-.3.3-.6.6-.6l14.7.3c.3,0,.6.3.6.6,0,.3-.3.6-.6.6Z"/>
        </g>
        <path class="cls-11" d="M520.7,433.2c0-.3.1-.7.3-.9-.7-.5-1.2-1.4-1.1-2.3,0-.8.5-1.4,1.2-1.8-.1-.3-.2-.7-.1-1.1.1-1.1.9-1.9,2-2,0-.3,0-.6,0-.9.1-1.3,1.3-2.2,2.6-2,.3,0,.6.1.9.3,1-1.2,3.7-1.6,4.8,0,.2,0,.4-.2.6-.2,1.3-.2,2.6.6,2.9,1.9,0,.3,0,.7,0,1,.8.2,1.5.9,1.8,1.8.2.8,0,1.5-.5,2.1.2.3.4.6.5,1,.3,1.1-.3,2.1-1.2,2.6.2.2.3.5.3.8.3,1.3-.5,2.5-1.8,2.8-.3,0-.6,0-.9,0-1.4,2.7-4.6,1.4-4.9.2-.4.9-1.4,1.4-2.4,1.3-1-.1-1.8-.9-2.1-1.9-.2,0-.4,0-.6,0-1.3-.2-2.3-1.4-2.1-2.7Z"/>
        <path class="cls-4" d="M518.9,438.9l-.4-.7c2.4-1.3,4.6-3.1,6.4-5.3l.7.5c-1.9,2.3-4.2,4.2-6.7,5.5Z"/>
      </g>
      <g class="cls-15">
        <g>
          <path class="cls-7" d="M513.7,432.7c-.2.5-.2.6-1,1.3.6-.3.8-.4,1.7-.4,1.2,0,1.9.6,2.2,0,.2.5,0,2.5-2.8,2.5-2.3,0-2.6-1-2.6-1.4,0-.4.3-.9.8-1,.6-.3.7-.5.6-.7,0-.2,1-.5,1.2-.3Z"/>
          <path class="cls-10" d="M512.4,433.3c0-.1.1-.2,0-.3,0-.2,1-.5,1.2-.3-.1.3-.2.4-.3.6h0c-.1,0-.4.1-.9,0Z"/>
          <path class="cls-7" d="M512.4,435c.1.2-.2.5-.6.7-.6-.3-.7-.8-.7-1,0-.4.3-.9.8-1,0,0,0,0,.1,0h0c.4.2.7.2.9.2,0,0-.1.1-.2.2.3-.1.4-.2.6-.3l-.9,1.2Z"/>
          <path class="cls-7" d="M513.3,433.3c-.1,0-.4.1-.9,0,0,0-.2.2-.4.3.4.1.7.2.9.2.2-.2.3-.3.4-.5Z"/>
          <path class="cls-5" d="M512.5,432.3s-.3.3-.6.3c-.3,0,.3.6.7.4.4-.2,0-.6,0-.7Z"/>
          <path class="cls-7" d="M513,431.6c-.4,0-.7.3-.7.8s.3.8.7.8.7-.3.7-.8-.3-.8-.7-.8Z"/>
          <path class="cls-13" d="M512.7,432.1c0,0-.2,0-.2.2s0,.2.2.2.2,0,.2-.2,0-.2-.2-.2Z"/>
          <path class="cls-10" d="M512.7,434c-.2.1-1.2,1,0,1.5,1.1.5,3.2-.2,3.6-1.6-.4,0-1-.3-1.9-.2-.9,0-1.1.1-1.7.4Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    },
    data() {
      return {
        highlight: false,
found:false
      }
    },
    methods:{
      clickHome: function(){
        this.found = true
        // this.$parent.showModal('BirdHouse')
      },
      showHighlight: function(){
        this.highlight = true
      },
      hideHighlight: function(){
        this.highlight = false
      }
    }
}
</script>

<style scoped>
      .cls-1 {
        fill: #588008;
      }

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14 {
        stroke-width: 0px;
      }

      .cls-1, .cls-5, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-13, .cls-14 {
        fill-rule: evenodd;
      }

      .cls-2, .cls-14 {
        fill: #602b09;
      }

      .cls-15 {
        clip-path: url(#clippath);
      }

      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: #2c3b2e;
      }

      .cls-5 {
        fill: #f1502d;
      }

      .cls-6 {
        fill: #a84b10;
      }

      .cls-7 {
        fill: #73f8ff;
      }

      .cls-8 {
        fill: #f3a135;
      }

      .cls-9 {
        fill: #c05d08;
      }

      .cls-10 {
        fill: #57bcc2;
      }

      .cls-11 {
        fill: #628f09;
      }

      .cls-12 {
        fill: #fff;
      }

      .cls-13 {
        fill: #000;
      }
</style>
